import './fieldErrors.scss';

/**
 * Плагин для показа сообщения обязательного поля и валидации,
 * по умолчанию используется в оверрайде Ext.form.field.Base
 * @param showRequiredMsg (Boolean) параметр для постоянного показа обязательного сообщения
 */
const PTYPE_FIELD_ERROR = 'field_error';

Ext.define('UI.plugin.fieldError', {
	extend: 'Ext.AbstractPlugin',
	alias: `plugin.${PTYPE_FIELD_ERROR}`,
	showRequiredMsg: true,

	_requiredFieldCls: 'ui-field-required',
	_invalidFieldCls: 'ui-field-invalid',
	_requiredStatusCls: 'ui-field-status-required',
	_errorStatusCls: 'ui-field-status-error',
	_hiddenOffsetsCls: 'x-hidden-offsets',

	init: function (field) {
		const plugin = this;

		field.on('afterrender', function (field) {
			if (field.ariaStatusEl) {
				field.ariaStatusEl.removeCls(plugin._hiddenOffsetsCls);
			}
			if (field.ariaErrorEl) {
				field.ariaErrorEl.addCls(plugin._hiddenOffsetsCls);
			}
			if (field.errorWrapEl) {
				field.errorWrapEl.addCls(plugin._hiddenOffsetsCls);
			}

			plugin.onMandatory(field);
		});
	},

	onMandatory: function (field) {
		const plugin = this;

		if (!field.ariaStatusEl) {
			return;
		}

		let isEmpty = Ext.isEmpty(field.getValue()),
			errors = field.getErrors(),
			isError = !!errors?.length,
			isMandatory = field.hasOwnProperty('allowBlank') && !field.allowBlank,
			showRequiredMsg = field.hasOwnProperty('showRequiredMsg') ? field.showRequiredMsg : plugin.showRequiredMsg;

		field[!isEmpty && isError ? 'addCls' : 'removeCls'](plugin._invalidFieldCls);
		field.ariaStatusEl[!isMandatory && showRequiredMsg && !isError ? 'addCls' : 'removeCls'](
			plugin._hiddenOffsetsCls
		);

		if (isMandatory) {
			if (showRequiredMsg && !isError) {
				field.ariaStatusEl.dom.innerHTML = Ext.String.htmlEncode(field.blankText);
				if (typeof field.updateLayout === 'function') {
					//после отображения текста "обязательно для заполнения" на валидном поле (пишем напрямую в DOM)
					//поэтому обновим layout поля, т.к. текст может быть как на 1 так и на несколько строк, а Экст об
					//этом ничего не знает и надо его пнуть
					field.updateLayout();
				}
			}

			field.ariaStatusEl[showRequiredMsg ? 'addCls' : 'removeCls'](plugin._requiredStatusCls);
			field.ariaStatusEl[isEmpty ? 'addCls' : 'removeCls'](plugin._errorStatusCls);
			field[isEmpty ? 'addCls' : 'removeCls'](plugin._requiredFieldCls);
		} else {
			field.removeCls(plugin._requiredFieldCls);
		}

		if (isError) {
			field.ariaStatusEl[isError ? 'addCls' : 'removeCls'](plugin._errorStatusCls);
		}
	}
});

export { PTYPE_FIELD_ERROR };
