import './ComboItemsDisabled.scss';

/**
 * Плагин для компонента Combobox, отключает элемент списка, если в record задать [disabledName]: true
 *
 * !!! Блокировка пунктов меню, стандартный функционал, нужно перенести в Boundlist
 */
const PTYPE_COMBO_ITEMS_DISABLEABLE = 'comboitemsdisableable';

Ext.define('Ext.ux.plugin.ComboItemsDisableable', {
	extend: 'Ext.AbstractPlugin',
	alias: `plugin.${PTYPE_COMBO_ITEMS_DISABLEABLE}`,
	disabledCls: 'ui-plugin-combo-item-disabled',
	disabledName: 'disabled',

	init: function (cmp) {
		var me = this;
		me.callParent([cmp]);

		const picker = cmp.getPicker();

		picker.on('viewlist', function (__self, record, itemEl) {
			itemEl = Ext.fly(itemEl);
			if (itemEl) {
				if (record.get(me.disabledName)) {
					itemEl.addCls(me.disabledCls);
				} else {
					itemEl.removeCls(me.disabledCls);
				}
			}
		});

		cmp.on('beforeselect', function (combo, record) {
			return !record.get(me.disabledName);
		});
		cmp.on('beforedeselect', function (combo, record) {
			return !record.get(me.disabledName);
		});
	}
});

export { PTYPE_COMBO_ITEMS_DISABLEABLE };
