import './DetailLabel';
import { i18n } from '@UIkit/methods';

Ext.define('UI.components.DetailDateLabel', {
	extend: 'UI.components.DetailLabel',

	getTemplate: function () {
		const me = this;
		const titleCls = `${me.selfCls}-title ${me.titleCls}`;
		const contentCls = `${me.selfCls}-content ${me.contentCls}`;
		const emptyContentCls = `${me.selfCls}-content ${me.selfCls}-content-empty ${me.contentCls}`;
		return Ext.create(
			'Ext.XTemplate',
			'<tpl for=".">',
			'<tpl if="values.startDate &gt; 0">',
			`<span class="${titleCls}">{title}: </span> <span class="${contentCls}"> `,
			`{[this.formatDate(values.startDate)]} <span class="${titleCls}"> - </span> `,
			'{[this.formatDate(values.endDate)]} </span>',
			'</tpl>',
			'<tpl if="!values.startDate">',
			`<span class="${emptyContentCls}">`,
			'{[this.showEmptyText(values)]} ',
			'</span>',
			'</tpl>',
			'</tpl>',
			{
				formatDate: function (values) {
					let dateFormat = config.dateFormat || edi.constants.DATE_FORMAT.FNS;
					let srcFormat = config.srcFormat || edi.constants.DATE_FORMAT.CLIENT;
					return edi.utils.formatDate(values, dateFormat, srcFormat);
				},
				showEmptyText: function (values) {
					return `<span class="${titleCls}">${i18n('value.not.specified')}</span>`;
				}
			}
		);
	}
});

/**
 * Компонент для отображения полей для страниц деталей документов (серый label: черный text)
 * @param	{Object}	config	в формате  {...labelProps, contentTextArray: {title: String, content: String, isNewLine: String}[]}
 */
export const createDetailDateLabel = function (config) {
	return Ext.create('UI.components.DetailDateLabel', config);
};
