/**
 * @author Darja Matjuha
 * Models processing
 */
Ext.namespace("edi.models");

edi.models = new function() {
	var __self = this;
	this.names = {};

	/**
	 * create model instance
	 * @param       {String}    name            model name
	 * @param       {Object}    properties      values for instance properties
	 * @returns     {Object}
	 */
	this.createInstance = function(name, properties) {
		var instance = null;
		if (__self.getModel(name)) {
			instance = Ext.create(__self.getModel(name), properties);
		}
		return instance;
	};

	/**
	 * get model by name
	 * @param       {String}    name    model name
	 * @returns     {Object}
	 */
	this.getModel = function(name) {
		var model = null;
		if (__self.names[name]) {
			model = __self.names[name];
		}
		if (!model) {
			edi.core.logMessage("Model " + name + " is not defined!", "warn");
		}
		return model;
	};
	/**
	 * Defines models from passed map of configs
	 * @param       {Object}    config      Define models described in config
	 */
	this.setModels = function(config) {
		var __self = this;
		for (var modelName in config) {
			if (config.hasOwnProperty(modelName)) {
				var fullName = "edi.models." + modelName, modelConf = config[modelName], ableToExtend = true, extName = "";
				if (modelConf.extend && -1 != modelConf.extend.indexOf("edi.models.")) {
					extName = modelConf.extend.replace("edi.models.", "");
					ableToExtend = !!__self.names[extName];
				}
				if (ableToExtend) {
					Ext.applyIf(modelConf, {
						extend: 'Ext.data.Model',
						idProperty: 'id'
					});
					//Ext 7 не позволяет повторно define модель с таким же именем.
					//Поэтому на каждом оверрайде модели в подпроекте увеличиваем ей имя на 'overrides.'
					//В коде модели используюся через edi.models.getModel('ORGANIZATIONS')
					//Поэтому настоящее имя модели ни на что не влияет и хранится в edi.models.names
					if (__self.names[modelName]) {
						modelConf.extend = __self.names[extName];
						fullName = 'overrides.' + __self.names[extName] + '-' + Ext.id();
					}
					Ext.define(fullName, modelConf);
					__self.names[modelName] = fullName;
				}
				else {
					edi.core.logMessage("Model " + name + " could not be defined, as model to extend with name " + extName + " is not defined!", "warn");
				}
			}
		}
	}
}();
