import {createButton} from "@Components/buttons.js";

const createCreateAndSendButton = function(callback, options, isEdit) {
	return createButton(Ext.applyIf(options || {}, {
		text: edi.i18n.getMessage(isEdit ? 'form.btn.save.and.send' : 'form.btn.create.and.send'),
		glyph: edi.constants.ICONS.SEND,
		cls: "edi-form-button-right",
		handler: callback
	}));
};

const createResetButton = function(callback, options) {
	return createButton(Ext.applyIf(options || {}, {
		text: edi.i18n.getMessage('form.btn.reset'),
		glyph: edi.constants.ICONS.HIGHLIGHT_OFF,
		handler: callback
	}));
};

const createSearchButton = function(callback, options) {
	return createButton(Ext.applyIf(options || {}, {
		text: edi.i18n.getMessage('form.btn.search'),
		glyph: edi.constants.ICONS.SEARCH,
		formBind: true,
		disabled: true,
		handler: callback
	}));
};

const createCancelButton = function(callback, options) {
	return createButton(Ext.applyIf(options || {}, {
		text: edi.i18n.getMessage('form.btn.close'),
		glyph: edi.constants.ICONS.CANCEL,
		formBind: false,
		disabled: false,
		handler: callback
	}));
};

const createDownloadButton = function(callback, options) {
	return createButton(Ext.applyIf(options || {}, {
		text: edi.i18n.getMessage('form.btn.download'),
		glyph: edi.constants.ICONS.FILE_DOWNLOAD,
		formBind: false,
		disabled: false,
		handler: callback
	}));
};

const createCloseButton = function(callback, options) {
	return createButton(Ext.applyIf(options || {}, {
		text: edi.i18n.getMessage("action.close"),
		glyph: edi.constants.ICONS.CLOSE,
		handler: callback
	}));
};

const createAddButton = function(callback, options) {
	return createButton(Ext.applyIf(options || {}, {
		tooltip: edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage('form.btn.add') : undefined,
		text: !edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage('form.btn.add') : undefined,
		glyph: edi.constants.ICONS.ADD,
		handler: callback
	}));
};

const createDeleteButton = function(callback, options) {
	return createButton(Ext.applyIf(options || {}, {
		text: edi.i18n.getMessage('form.btn.delete'),
		glyph: edi.constants.ICONS.DELETE,
		tooltip: edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage('form.btn.delete') : undefined,
		disabled: true,
		handler: callback
	}));
};

const createCreateButton = function(callback, options) {
	return createButton(Ext.applyIf(options || {}, {
		cls: "edi-combo-button",
		glyph: edi.constants.ICONS.CREATE,
		scale: "large",
		text: edi.i18n.getMessage('form.btn.create'),
		handler: callback
	}));
};

const createSendButton = function(callback, options) {
	return createButton(Ext.applyIf(options || {}, {
		text: edi.i18n.getMessage('form.btn.send'),
		glyph: edi.constants.ICONS.SEND,
		formBind: true,
		disabled: true,
		handler: callback
	}));
};

const createSaveButton = function(callback, options) {
	return createButton(Ext.applyIf(options || {}, {
		text: edi.i18n.getMessage('form.btn.save'),
		glyph: edi.constants.ICONS.SAVE,
		formBind: true,
		disabled: true,
		handler: callback
	}));
};

Ext.namespace('edi.special');
Ext.apply(edi.special, {
	createCreateAndSendButton,
	createResetButton,
	createSearchButton,
	createCancelButton,
	createDownloadButton,
	createCloseButton,
	createAddButton,
	createDeleteButton,
	createCreateButton,
	createSendButton,
	createSaveButton
});

export {
	createCreateAndSendButton,
	createResetButton,
	createSearchButton,
	createCancelButton,
	createDownloadButton,
	createCloseButton,
	createAddButton,
	createDeleteButton,
	createCreateButton,
	createSendButton,
	createSaveButton
};