/**
 * Плагин блокирует повторные клики в течение заданного промежутка времени
 */
const PTYPE_DISABLE_DOUBLE_CLICK = 'disable_double_click';
Ext.define('Ext.ux.plugin.DisableDoubleClick', {
	extend: 'Ext.AbstractPlugin',
	alias: `plugin.${PTYPE_DISABLE_DOUBLE_CLICK}`,

	multiClickDelay: 600,

	init: function (cmp) {
		let plugin = this;
		cmp.lastClick = 0;
		cmp.multiClickDelay = plugin.multiClickDelay;

		let interceptedOnClick = Ext.Function.createInterceptor(cmp.onClick, plugin.onClickInterceptor);
		cmp.onClick = function () {
			interceptedOnClick.apply(cmp, arguments);

			//блокируем скрытие меню, иначе оно пропадет из-за blur у родителя
			let oldOnFocusLeave = cmp.onFocusLeave;
			cmp.onFocusLeave = () => {};

			if (typeof cmp.el?.dom?.blur === 'function') {
				cmp.el.dom.blur();
			}
			if (typeof cmp.blur === 'function') {
				cmp.blur();
			}

			cmp.onFocusLeave = oldOnFocusLeave;
		};
	},
	onClickInterceptor: function () {
		let cmp = this;
		let allow = false;
		if (cmp.allowMultiClick) {
			allow = true;
		}
		if (!allow && !cmp.disabled) {
			var time = new Date().getTime();
			allow = !(time - cmp.lastClick < cmp.multiClickDelay);
			cmp.lastClick = time;
		}
		return allow;
	}
});

export { PTYPE_DISABLE_DOUBLE_CLICK };
