import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { i18n } from '@UIkit/methods';
import renderTpl from './DatePicker.ext.tpl';

import './MonthPicker';
import './DatePicker.scss';

Ext.define('UI.picker.Date', {
	extend: 'Ext.picker.Date',
	baseCls: 'ui-datepicker',

	width: 296,

	dayNames: [
		'day.sunday.short',
		'day.monday.short',
		'day.tuesday.short',
		'day.wednesday.short',
		'day.thursday.short',
		'day.friday.short',
		'day.saturday.short'
	],

	renderTpl: [
		renderTpl,
		{
			isEndOfWeek: function (value) {
				// convert from 1 based index to 0 based
				// by decrementing value once.
				value--;
				// eslint-disable-next-line vars-on-top
				var end = value % 7 === 0 && value !== 0;
				return end ? '</tr><tr role="row">' : '';
			},
			renderTodayBtn: function (values, out) {
				Ext.DomHelper.generateMarkup(values.$comp.todayBtn.getRenderTree(), out);
			},
			renderMonthBtn: function (values, out) {
				Ext.DomHelper.generateMarkup(values.$comp.monthBtn.getRenderTree(), out);
			}
		}
	],

	beforeRender: function () {
		/*
		 * days array for looping through 6 full weeks (6 weeks * 7 days)
		 * Note that we explicitly force the size here so the template creates
		 * all the appropriate cells.
		 */
		var me = this,
			encode = Ext.String.htmlEncode,
			days = new Array(me.numDays),
			today = Ext.Date.format(new Date(), me.format);

		me.callParent();

		if (me.padding && !me.width) {
			me.cacheWidth();
		}

		me.monthBtn = createButton({
			cls: [BUTTON_CLS.light, BUTTON_CLS.small],
			ownerCt: me,
			ownerLayout: me.getComponentLayout(),
			text: '',
			tooltip: me.monthYearText,
			tabIndex: -1,
			ariaRole: 'presentation',
			showEmptyMenu: false,
			menu: [],
			listeners: {
				click: me.doShowMonthPicker,
				scope: me
			}
		});

		if (me.showToday) {
			me.todayBtn = createButton({
				cls: [BUTTON_CLS.primary, BUTTON_CLS.small],
				ui: me.footerButtonUI,
				ownerCt: me,
				ownerLayout: me.getComponentLayout(),
				text: Ext.String.format(me.todayText, today),
				tooltip: Ext.String.format(me.todayTip, today),
				tooltipType: 'title',
				tabIndex: -1,
				ariaRole: 'presentation',
				handler: me.selectToday,
				scope: me
			});
		}

		Ext.applyIf(me, {
			renderData: {}
		});

		Ext.apply(me.renderData, {
			dayNames: me.dayNames.map((k) => {
				return i18n(k);
			}),
			showToday: me.showToday,
			prevText: encode(me.prevText),
			nextText: encode(me.nextText),
			todayText: encode(me.todayText),
			ariaMinText: encode(me.ariaMinText),
			ariaMaxText: encode(me.ariaMaxText),
			ariaDisabledDaysText: encode(me.ariaDisabledDaysText),
			ariaDisabledDatesText: encode(me.ariaDisabledDatesText),
			days: days
		});

		me.protoEl.unselectable();
	},

	createMonthPicker: function () {
		var me = this,
			picker = me.monthPicker;

		if (!picker) {
			me.monthPicker = picker = new UI.picker.Month({
				renderTo: me.el,
				// We need to set the ownerCmp so that owns() can correctly
				// match up the component hierarchy so that focus does not leave
				// an owning picker field if/when this gets focus.
				ownerCmp: me,
				floating: true,
				padding: me.padding,
				shadow: false,
				small: me.showToday === false,
				footerButtonUI: me.footerButtonUI,
				listeners: {
					scope: me,
					cancelclick: me.onCancelClick,
					okclick: me.onOkClick,
					yeardblclick: me.onOkClick,
					monthdblclick: me.onOkClick
				}
			});

			if (!me.disableAnim) {
				// hide the element if we're animating to prevent an initial flicker
				picker.el.setStyle('display', 'none');
			}

			picker.hide();
			me.on('beforehide', me.doHideMonthPicker, me);
		}

		return picker;
	}
});

export const createDatePicker = function (cfg) {
	return new UI.picker.Date(cfg);
};
