/**
 * Creates modal dialog with 2 grids for data exchange
 * @param	{Object}	config	config of parameters - model, right.columns, left.columns are mandatory
 * @returns	{Object}	Ext.window.Window instance
 */
import {createButton} from "@Components/buttons";
import {createCheckboxSelectionModel, createGrid} from "@Components/grid";
import {
	createForm,
	createModalPanel,
	createPanel,
	createTab,
	createTabPanel
} from "@Components/panels";
import {createProxyConfig} from "@Components/storeComponents";
import {createTwoColumnsLayout} from "@Components/miscComponents";
import {
	createCombo,
	createField, createFile,
	createNumberField,
	createTextField
} from "@Components/fields";
import {createModuleFilterForm} from "@Components/moduleFilterForm";

const createModalDataExchanger = function(config) {
	config = "object" == typeof config ? Ext.applyIf({}, config) : {};
	if (config.left && config.right && config.model) {
		var moveHandler = function(source, destination) {
			var gridSelModel = source.getSelectionModel(), dataToMove;
			if (gridSelModel.getCount()) {
				dataToMove = gridSelModel.getSelection();
				if (dataToMove.length) {
					var i, data = [], range;
					range = destination.getStore().getRange();
					for (i = 0; i < range.length; i++) {
						data.push(range[i].getData());
					}
					for (i = 0; i < dataToMove.length; i++) {
						data.push(dataToMove[i].getData());
					}
					source.getStore().remove(dataToMove);
					destination.getStore().loadData(data);
				}
			}
		}, dropHandler = function(destination) {
			var i, range, data = [];
			range = destination.getStore().getRange();
			for (i = 0; i < range.length; i++) {
				data.push(range[i].getData());
			}
			destination.getStore().loadData(data);
		};
		var leftGrid, leftStore, rightGrid, rightStore, btnToRight, btnToLeft;
		if (config.btnToRight) {
			btnToRight = createButton(Ext.applyIf(config.btnToRight, {
				tooltip: edi.i18n.getMessage('form.btn.delete'),
				glyph: edi.constants.ICONS.ARROW_FORWARD,
				disabled: true,
				handler: function() {
					moveHandler(leftGrid, rightGrid);
					"function" == typeof config.dataProcessor ? config.dataProcessor(leftGrid, rightGrid) : null;
				}
			}));
		}
		if (config.btnToLeft) {
			btnToLeft = createButton({
				tooltip: edi.i18n.getMessage('form.btn.add'),
				glyph: edi.constants.ICONS.ARROW_BACK,
				disabled: true,
				handler: function() {
					moveHandler(rightGrid, leftGrid);
					"function" == typeof config.dataProcessor ? config.dataProcessor(leftGrid, rightGrid) : null;
				}
			});
		}
		leftStore = edi.stores.createInlineStore(config.leftData ? config.leftData : [], config.model, undefined);
		leftGrid = createGrid({
			viewConfig: {
				plugins: {
					ptype: 'gridviewdragdrop',
					dragGroup: 'leftDDGroup',
					dropGroup: 'rightDDGroup'
				},
				listeners: {
					drop: function() {
						dropHandler(leftGrid);
						"function" == typeof config.dataProcessor ? config.dataProcessor(leftGrid, rightGrid) : null;
					}
				},
				border: 1
			},
			gridConfig: Ext.applyIf(config.left, {
				store: leftStore,
				disablePaging: true,
				padding: "0 0 0 0",
				cls: "edi-value-grid-left",
				autoScroll: true,
				height: "100%",
				selModel: btnToRight ? createCheckboxSelectionModel({
					listeners: {
						selectionchange: function(model, selected) {
							btnToRight.setDisabled(!selected.length);
						}
					}
				}) : undefined,
				flex: 1,
				border: 1
			})
		});
		rightStore = edi.stores.createInlineStore(config.rightData ? config.rightData : [], config.model);
		rightGrid = createGrid({
			viewConfig: {
				plugins: {
					ptype: 'gridviewdragdrop',
					dragGroup: 'rightDDGroup',
					dropGroup: 'leftDDGroup'
				},
				listeners: {
					drop: function() {
						dropHandler(rightGrid);
						"function" == typeof config.dataProcessor ? config.dataProcessor(leftGrid, rightGrid) : null;
					}
				}
			},
			gridConfig: Ext.applyIf(config.right, {
				store: rightStore,
				disablePaging: true,
				padding: "0 0 0 0",
				cls: "edi-value-grid-right",
				flex: 1,
				height: "100%",
				autoScroll: true,
				selModel: btnToLeft ? createCheckboxSelectionModel({
					listeners: {
						selectionchange: function(model, selected) {
							btnToLeft.setDisabled(!selected.length);
						}
					}
				}) : undefined
			})
		});
		var modal = createModalPanel(Ext.applyIf(config.modal, {
			cls: "edi-modal-form",
			title: config.modalTitle,
			width: edi.constants.DEFAULT.MODAL.WIDTH_LARGER,
			heigth: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
			minHeight: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
			minWidth: edi.constants.DEFAULT.MODAL.WIDTH_LARGER,
			items: [
				createPanel({
					layout: 'hbox',
					layoutConfig: {
						align: 'stretch'
					},
					items: [
						leftGrid,
						{
							layout: "vbox",
							cls: "edi-value-buttons-set-container",
							height: "100%",
							items: [
								{
									type: "container",
									flex: 1
								},
								btnToLeft,
								{
									type: "container",
									height: 7
								},
								btnToRight,
								{
									type: "container",
									flex: 1
								}
							]
						},
						rightGrid
					]
				})
			]
		}));
		modal.rightGrid = rightGrid;
		modal.leftGrid = leftGrid;
		modal.show();
		return modal;
	}
};

/**
 *
 * @param	{String}	remoteURL
 * @param	{Function}	[callback]
 * @param	{Object}	[options]
 * @param	{Function}	[checkedHandler]
 * @returns	{Object}	Ext.window.Window instance
 */
const createModalRemoteSelect = function(remoteURL, callback, options, checkedHandler) {
	var filterForm = null, grid;
	options = Ext.apply({}, options);
	options.storeConfig = options.storeConfig ? options.storeConfig : {};
	options.proxyConfig = options.proxyConfig ? options.proxyConfig : {};
	options.gridConfig = options.gridConfig ? options.gridConfig : {};
	options.pagingBarConfig = "object" == typeof options.pagingBarConfig ? Ext.apply({}, options.pagingBarConfig) : undefined;
	var createFilterForm = function() {
		var oldRequests = {}, searchTimeout = null;
		var fireSearch = function() {
			if (buttonSave) {
				buttonSave.setDisabled(true);
			}
			if (searchTimeout) {
				clearTimeout(searchTimeout);
			}
			searchTimeout = setTimeout(function() {
				var values, store, args = {}, oldProxy, url, proxyParams = Ext.clone(proxyConfig);
				if (form.isValid()) {
					values = form.getValues();
					if (typeof form.processHeaderChips === 'function') {
						form.processHeaderChips(form, values);
					}
					else {
						edi.filters.processHeaderChips(form, values);
					}
					edi.utils.clearEmptyValues(values);

					store = grid.getStore();

					if (options.createArgs) {
						args = options.createArgs(values);
						if (args) {
							delete args.filterFormAutoSearchCheckbox;
						}
					}

					oldProxy = store.getProxy();
					if (!proxyParams.extraParams) {
						proxyParams.extraParams = {};
					}
					Ext.applyIf(proxyParams.extraParams, args);
					if (oldRequests[oldProxy.url]) {
						if (Ext.encode(oldRequests[oldProxy.url]) == Ext.encode(args)) {
							edi.rest.abortRequest(oldProxy.url, args);
						}
						delete oldRequests[oldProxy.url];
					}

					url = edi.utils.compileURL(remoteURL, edi.rest.getGlobalRequestParams());
					oldRequests[url] = args;
					proxyParams.url = url;
					store.setProxy(createProxyConfig(proxyParams));
					if (1 != store.currentPage) {
						grid.pagingBar.moveFirst();
					}
					else {
						store.load();
					}
				}

			}, edi.constants.DEFAULT.FILTER.FIRE_SEARCH_DELAY);
		};

		var form;
		if (!options.createFormItemsMap) {//для новой панели фильтров formItemsMap обязателен
			form = edi.filters.createModuleFilterForm(
				Ext.merge({
					items: typeof options.createFilterFormItems === "function"
						? options.createFilterFormItems(options.createFilterFormItemsArgs || null)
						: !!options.createFilterFormItems
							? options.createFilterFormItems
							: edi.filters.config.organizations.createFormItems()
				}, options.filterFormConfig),
				fireSearch
			);
		}
		else {
			form = createModuleFilterForm(
				Ext.merge({
					formItemsMap: typeof options.createFormItemsMap === "function"
						? options.createFormItemsMap(options.createFilterFormItemsArgs || null)
						: !!options.createFormItemsMap
							? options.createFormItemsMap
							: {},
					items: typeof options.createFilterFormItems === "function"
						? options.createFilterFormItems(options.createFilterFormItemsArgs || null)
						: !!options.createFilterFormItems
							? options.createFilterFormItems
							: []
				}, options.filterFormConfig),
				fireSearch
			);
		}

		form.fireSearch = fireSearch;
		form.clearSearchTimeout = function() {
			clearTimeout(searchTimeout);
		};
		return form;
	};
	var storeConfig = {
		model: edi.models.getModel(options.model ? options.model : "ORGANIZATION"),
		sortOnLoad: true,
		autoLoad: false,
		sorters: options.sorters ? options.sorters : {
			property: 'creationDate',
			direction: 'DESC'
		}
	};
	Ext.apply(storeConfig, options.storeConfig);
	var selectedRecord = null;
	var selectedRecords = [];
	var gridConfig = {
		columns: options.columns && "object" == typeof options.columns ? options.columns : edi.columns.get(options.columns ? options.columns : 'organization'),
		region: "center",
		selModel: options.checkboxes ? createCheckboxSelectionModel({
			showHeaderCheckbox: !options.hideHeaderCheckbox,
			listeners: {
				selectionchange: function(model, selected) {
					selectedRecords = selected;
					if ("function" == typeof checkedHandler) {
						checkedHandler(selected, filterForm);
					}
					if (buttonSave) {
						buttonSave.setDisabled(!selected.length);
					}
				}
			}
		}) : undefined,
		listeners: !options.checkboxes && !options.hideSaveButton ? {
			select: function(comp, record) {
				if (!options.checkboxes) {
					selectedRecord = record;
					buttonSave.setDisabled(false);
				}
			},
			celldblclick: function(view, td, cellIndex, record) {
				"function" == typeof callback ? callback(record.getData()) : null;
				selectedRecord = null;
				if (buttonSave && !buttonSave.isDestroyed) {
					buttonSave.setDisabled(true);
				}
				!options.doNotClose && modal ? modal.close() : null;
			}
		} : undefined,
		disablePaging: options.disablePaging
	};
	Ext.apply(gridConfig, options.gridConfig);
	var proxyConfig = {
		type: "ajax",
		url: remoteURL
	};
	Ext.apply(proxyConfig, options.proxyConfig);
	grid = createGrid({
		storeConfig: storeConfig,
		store: options.store,
		gridConfig: gridConfig,
		proxyConfig: proxyConfig,
		enableTextSelection: options.enableTextSelection,
		pagingBarConfig: options.pagingBarConfig,
		viewConfig: options.viewConfig
	});
	if (!options.noFilter) {
		filterForm = createFilterForm();
	}
	var container = createPanel({
		cls: "edi-form",
		layout: "border",
		items: [filterForm, grid]
	});
	var buttonSave = options.hideSaveButton ? undefined : createButton({
		text: edi.i18n.getMessage("form.btn.select"),
		glyph: edi.constants.ICONS.SAVE,
		disabled: true,
		handler: function() {
			if (selectedRecord || selectedRecords.length) {
				modal.setLoading(true);
				if (options.checkboxes) {
					var i;
					var gridSelModel = grid.getSelectionModel();
					if (options.groupOperation) {
						var result = [];
						for (i = 0; i < selectedRecords.length; i++) {
							result.push(selectedRecords[i].getData());
						}
						"function" == typeof callback ? callback(result) : null;
					}
					else {
						for (i = 0; i < selectedRecords.length; i++) {
							"function" == typeof callback ? callback(selectedRecords[i].getData()) : null;
						}
					}
					gridSelModel.deselectAll();
					selectedRecords = [];
				}
				else {
					"function" == typeof callback ? callback(selectedRecord.getData()) : null;
					selectedRecord = null;
				}

				if (!buttonSave.isDestroyed) {//modal dialog could be closed inside callback
					buttonSave.setDisabled(true);
				}

				!options.doNotClose ? modal.close() : modal.setLoading(false);
			}
		}
	});
	var buttons = [];
	if (options.additionalControls) {
		if (Ext.isArray(options.additionalControls)) {
			buttons = options.additionalControls;
		}
		else {
			buttons.push(options.additionalControls);
		}
	}
	if (buttonSave) {
		buttons.push(buttonSave);
	}

	buttons.push(createButton({
		text: edi.i18n.getMessage(options.cancelTitle ? options.cancelTitle : 'form.btn.cancel'),
		glyph: edi.constants.ICONS.CANCEL,
		handler: function() {
			modal.close();
		}
	}));

	var modalConf = {
		cls: options.cls ? options.cls : "edi-modal-form",
		title: options.title ? edi.i18n.getMessage(options.title) : edi.i18n.getMessage('organization.select.title'),
		width: options.width || edi.constants.DEFAULT.MODAL.WIDTH_LARGER,
		height: options.height || edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
		items: [container],
		buttons: buttons,
		modalGrid: grid,
		listeners: filterForm ? {
			close: function() {
				filterForm.clearSearchTimeout();
			}
		} : undefined
	};

	var modal = createModalPanel(modalConf);
	!options.store && filterForm ? filterForm.fireSearch() : null;
	modal.form = filterForm;
	modal.show();
	return modal;
};

/**
 * Creates modal relation selection dialog
 * @param	{Object[]}	relations	relations array
 * @param	{Function}	[callback]	function that will be called on grid selection
 * @param	{Object}	[options]	dialog options
 * @returns	{Object}	Ext.window.Window instance
 */
const createModalRelationSelect = function(relations, callback, options) {
	options = options ? options : {};
	var searchTimeout, dataLines = [], i, buttonSelect, buttons = [];
	var fireSearch = function() {
		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}
		searchTimeout = setTimeout(function() {
			if (form.isValid()) {
				var filters = [];
				var values = form.getValues();
				if (typeof form.processHeaderChips === 'function') {
					form.processHeaderChips(form, values);
				}
				else {
					edi.filters.processHeaderChips(form, values);
				}
				if (values) {
					delete values.filterFormAutoSearchCheckbox;
				}
				var store = grid.getStore();
				store.clearFilter(true);
				for (var i in values) {
					if (values.hasOwnProperty(i)) {
						filters.push({
							property: i,
							value: values[i],
							anyMatch: true
						});
					}
				}
				store.filter(filters);
				store.setProxy(new Ext.ux.data.PagingMemoryProxy({
					data: dataLines
				}));
				if (1 != store.currentPage) {
					grid.pagingBar.moveFirst();
				}
				else {
					store.load();
				}
			}
		}, edi.constants.DEFAULT.FILTER.FIRE_SEARCH_DELAY);
	};

	for (i = 0; i < relations.length; i++) {
		var org = edi.models.createInstance("ORGANIZATION", relations[i]);
		dataLines.push(org);
	}
	var proxy = new Ext.ux.data.PagingMemoryProxy({
		data: dataLines
	});

	var grid = createGrid({
		proxy: proxy,
		storeConfig: {
			remoteFilter: true,
			model: edi.models.getModel("ORGANIZATION"),
			listeners: {
				load: function(store, records) {
					var sortedData = records && records.length ? edi.methods.filiations.sortOrgsByFiliations(records, true) : [];
					store.loadData(sortedData);
				}
			}
		},
		gridConfig: {
			columns: edi.columns.get('organization_with_filiations'),
			region: "center",
			listeners: !options.multiSelect ? {
				select: function(comp, record) {
					var data = record.getData();
					"function" == typeof callback ? callback(data) : null;
					modal.close();
				}
			} : undefined,
			selModel: options.multiSelect ? createCheckboxSelectionModel({
				listeners: {
					selectionchange: function(model, selected) {
						buttonSelect.setDisabled(!(selected.length));
					}
				}
			}) : undefined
		}
	});

	if (options.multiSelect) {
		buttonSelect = createButton({
			text: edi.i18n.getMessage("form.btn.select"),
			glyph: edi.constants.ICONS.SAVE,
			disabled: true,
			handler: function() {
				var i, gridSelModel = grid.getSelectionModel(), data = gridSelModel.getSelection();
				var result = [];
				for (i = 0; i < data.length; i++) {
					result.push(data[i].getData());
				}
				"function" == typeof callback ? callback(result) : null;

				gridSelModel.deselectAll();

				buttonSelect.setDisabled(true);
				!options.doNotClose ? modal.close() : null;
			}
		});
	}
	var form = edi.filters.createModuleFilterForm({
		items: [
			createTwoColumnsLayout([
				createField({
					title: edi.i18n.getMessage('company.name'),
					input: createTextField({
						columnWidth: 1,
						name: 'name'
					}),
					useFieldLabel: true
				}),
				createField({
					title: edi.i18n.getMessage('company.regNr'),
					input: createNumberField({
						columnWidth: 1,
						allowDecimals: false,
						name: 'regNr'
					}),
					useFieldLabel: true
				})
			], [
				createField({
					title: edi.i18n.getMessage('company.inn'),
					input: createNumberField({
						columnWidth: 1,
						allowDecimals: false,
						name: 'inn'
					}),
					useFieldLabel: true
				}),
				createField({
					title: edi.i18n.getMessage('company.gln'),
					input: createNumberField({
						columnWidth: 1,
						allowDecimals: false,
						name: 'iln'
					}),
					useFieldLabel: true
				})
			], 0.5)
		]
	}, fireSearch);
	form.fireSearch = fireSearch;
	form.clearSearchTimeout = function() {
		clearTimeout(searchTimeout);
	};
	if (buttonSelect) {
		buttons.push(buttonSelect);
	}
	buttons.push(createButton({
		text: edi.i18n.getMessage('form.btn.cancel'),
		glyph: edi.constants.ICONS.CANCEL,
		handler: function() {
			modal.close();
		}
	}));

	var modal = createModalPanel({
		cls: "edi-modal-form test-modal-relation-selector",
		title: edi.i18n.getMessage('document.organization.select.title'),
		width: edi.constants.DEFAULT.MODAL.WIDTH_MAX,
		height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
		items: [
			createPanel({
				cls: "edi-form",
				layout: "border",
				items: [form, grid]
			})
		],
		buttons: buttons,
		listeners: {
			close: function() {
				form.clearSearchTimeout();
			}
		}
	});
	modal.show();
	return modal;
};

/**
 * create modal form with tabs
 * @param	{String}	[title]			form title
 * @param	{Object}	[tabConfigs]	array of tab configs
 * @param	{Object}	[callback]		callback for modal form save action
 * @param	{Object}	[btnSelectProd]	additional button
 * @param	{Boolean}	[isEdit]		true if we in the edit mode
 * @param	{Object}	[modalOptions]	modal dialog options
 * @returns	{Object}	Ext.window.Window instance
 */
const createModalTabForm = function(title, tabConfigs, callback, btnSelectProd, isEdit, modalOptions) {
	title = title ? title : "Untitled";
	tabConfigs = tabConfigs ? tabConfigs : [];
	modalOptions = "object" == typeof modalOptions ? modalOptions : {};

	var defaultTabConfig = {
		title: "Untitled",
		closable: false,
		bodyPadding: 10,
		autoScroll: true,
		items: []
	};

	var tabs = [];
	for (var i = 0; i < tabConfigs.length; i++) {
		var tabConfig = tabConfigs[i];
		Ext.applyIf(tabConfig, defaultTabConfig);
		tabs.push(createTab(tabConfig));
	}

	var tabPanel = createTabPanel({
		activeTab: 0,
		items: tabs
	});

	var formPanel = createForm({
		cls: "edi-form",
		layout: "fit",
		submitEmptyText: false,
		items: [tabPanel]
	});

	var modalConf = {
		cls: "edi-modal-form",
		title: edi.i18n.getMessage(title),
		width: edi.constants.DEFAULT.MODAL.WIDTH_LARGER,
		maxHeight: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
		listeners: {
			activate: function(comp) {
				comp.updateLayout();
			}
		},
		items: [formPanel],
		buttons: [
			btnSelectProd,
			createButton({
				text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.add'),
				glyph: edi.constants.ICONS.SAVE,
				handler: function() {
					if (formPanel.isValid() || edi.constants.ALLOW_INVALID_LINES_CREATION) {
						var values = edi.utils.collectFormValues(formPanel);
						var amount;
						if (typeof (values.OrderedQuantity) !== 'undefined') {
							values.OrderedNetAmount = edi.utils.roundTo(parseFloat(values.OrderedUnitNetPrice || 0) * parseFloat(values.OrderedQuantity || 0), 4) + '';
						}
						else if (typeof (values.InvoiceQuantity) !== 'undefined') {
							amount = edi.utils.roundTo(parseFloat(values.InvoiceUnitNetPrice || 0) * parseFloat(values.InvoiceQuantity || 0), 4);
							values.NetAmount = amount + '';
							values.TaxAmount = edi.utils.roundTo(amount / 100 * parseFloat(values.TaxRate || 0), 4) + '';
						}
						else if (typeof (values.QuantityDespatched) !== 'undefined') {
							amount = edi.utils.roundTo(parseFloat(values.UnitNetPrice || 0) * parseFloat(values.QuantityDespatched || 0), 4);
							values.NetAmount = amount + '';
							values.TaxAmount = edi.utils.roundTo(amount / 100 * parseFloat(values.TaxRate || 0), 4) + '';
						}
						else if (typeof (values.QuantityReceived) !== 'undefined') {
							values.NetAmount = edi.utils.roundTo(parseFloat(values.UnitNetPrice || 0) * parseFloat(values.QuantityReceived || 0), 4) + '';
						}
						callback ? callback(values) : null;
						modal.close();
					}
					else {
						edi.core.showError("product.error.fill.all.fields");
					}
				}
			}),
			createButton({
				text: edi.i18n.getMessage('form.btn.cancel'),
				glyph: edi.constants.ICONS.CANCEL,
				handler: function() {
					modal.close();
				}
			})
		]
	};
	Ext.applyIf(modalOptions, modalConf);
	var modal = createModalPanel(modalOptions);
	formPanel.isValid();
	return modal;
};

/**
 * Renders modal for uploading files
 * @param	{String}	doctype			type of the document for witch we create modal dialog
 * @param	{Function}	[onSuccess]		callback that will be called on success
 * @param	{Function}	[onFailure]		callback that will be called on failure
 * @param	{Function}	[onClose]		callback that will be called on modal window close
 * @param	{Object}	[properties]	configuration properties
 */
const createUploadModalDialog = function(doctype, onSuccess, onFailure, onClose, properties) {
	properties = "object" == typeof properties ? properties : {};

	var selectedType = properties.selectedType ? properties.selectedType : Ext.isArray(doctype) ? doctype[0].id : null,
		items = [], createBtn, formPanel;

	onSuccess = "function" == typeof onSuccess ? onSuccess : null;
	onFailure = "function" == typeof onFailure ? onFailure : null;

	Ext.applyIf(properties, {
		limitValue: null,
		uploadTitle: edi.i18n.getMessage("root.certificate.file.field.title"),
		uploadButtonTitle: edi.i18n.getMessage("root.certificate.upload.select.button"),
		modalTitle: edi.i18n.getMessage("root.certificate.upload.modal.title"),
		uploadWaitMsg: edi.i18n.getMessage("root.certificate.upload.wait.msg"),
		notValidMsg: "root.certificate.upload.select.file.error",
		typeTitle: edi.i18n.getMessage('payments.factory.register.type'),
		uploadFieldName: "attachment",
		beforeUpload: null
	});

	if (Ext.isArray(doctype) && selectedType) {
		items.push(createField({
			title: properties.typeTitle,
			input: createCombo({
				columnWidth: 0.7,
				value: selectedType,
				store: edi.stores.createInlineStore(doctype),
				autoSelect: true,
				allowBlank: false,
				name: "type"
			}),
			containerConfig: {
				cls: "edi-base-field-line",
				margin: "0 0 5 0"
			}
		}));
	}

	items.push(createField({
		title: properties.uploadTitle,
		input: createFile({
			columnWidth: 0.7
		}, {
			name: properties.uploadFieldName,
			allowBlank: false,
			buttonText: properties.uploadButtonTitle
		}),
		containerConfig: {
			cls: "edi-base-field-line"
		}
	}));

	if (properties.additionalHiddenFields && properties.additionalHiddenFields.length) {
		for (var i = 0; i < properties.additionalHiddenFields.length; i++) {
			items.push(new Ext.form.field.Hidden(properties.additionalHiddenFields[i]));
		}
	}

	var modal = createModalPanel({
		cls: "edi-modal-form",
		title: properties.modalTitle,
		width: 400,
		maxHeight: 300,
		items: [
			formPanel = createForm({
				cls: "edi-form",
				bodyPadding: 10,
				submitEmptyText: false,
				items: items
			})],
		listeners: {
			close: function() {
				"function" == typeof onClose ? onClose() : null;
			}
		},
		buttonsBefore: [
			createBtn = createButton({
				text: edi.i18n.getMessage('form.btn.save'),
				glyph: edi.constants.ICONS.SAVE,
				handler: function() {
					var form = formPanel.getForm();
					if (form.isValid()) {
						var values = edi.utils.collectFormValues(form);
						var url = "function" == typeof properties.saveUrl ? properties.saveUrl(values, doctype) : properties.saveUrl;
						var processSubmit = function() {
							edi.core.submitUploadForm(form, url, properties.uploadWaitMsg, function(responseData) {
								if (responseData.items && onSuccess) {
									onSuccess(responseData.items);
								}
								else if (responseData.data && onSuccess) {
									onSuccess(responseData.data);
								}
								else if (onFailure) {
									onFailure(responseData);
								}
								modal.close();

							}, function(responseData) {
								edi.core.logMessage("Error uploading file for " + doctype, "warn");
								onFailure ? onFailure(responseData) : null;
								modal.close();
							});
						};
						if ("function" == typeof properties.beforeUpload){
							properties.beforeUpload(processSubmit);
						}
						else {
							processSubmit();
						}
					}
					else if (properties.notValidMsg) {
						edi.core.showError(properties.notValidMsg);
					}
				}
			})
		]
	});

	formPanel.on('validitychange', function() {
		createBtn.setDisabled(!formPanel.isValid());
	});

	formPanel.isValid();
	modal.show();

	if ('function' == typeof properties.onShowModal) {
		properties.onShowModal(modal);
	}
};

/**
 * Creates modal with grid displaying local data & provades local search
 * @param	{Object}	[config]	Modal config
 * @param	{Function}	[callback]	Save button callback (optional)
 * @return	{Object}	Ext.window.Window instance
 */
const createModalWithLocalGrid = function(config, callback) {
	config = config ? config : {};
	var searchTimeout, dataLines = [], buttonSelect, modelName = config.model || 'ORGANIZATION',
		allowSingleSelection = !config.disableSelection && !config.multiSelect,
		allowMultiSelection = !config.disableSelection && config.multiSelect;

	dataLines = Ext.Array.map(config.data ? config.data : [], function(item) {
		return edi.models.createInstance(modelName, item);
	});

	var grid = createGrid({
		proxy: createProxyConfig({
			type: "memory",
			data: {
				items: dataLines
			}
		}),
		storeConfig: {
			model: edi.models.getModel(modelName)
		},
		gridConfig: {
			columns: edi.columns.get(config.columns || 'organization_with_filiations'),
			region: "center",
			listeners: allowSingleSelection ? {
				select: function(comp, record) {
					var data = record.getData();
					"function" == typeof callback ? callback(data) : null;
					modal.close();
				}
			} : undefined,
			selModel: allowMultiSelection ? createCheckboxSelectionModel({
				listeners: {
					selectionchange: function(model, selected) {
						buttonSelect.setDisabled(!selected.length);
					}
				}
			}) : undefined
		}
	});

	if (allowMultiSelection) {
		buttonSelect = createButton({
			text: edi.i18n.getMessage("form.btn.select"),
			glyph: edi.constants.ICONS.SAVE,
			disabled: true,
			handler: function() {
				var selectionModel = grid.getSelectionModel(), selectedRecords = selectionModel.getSelection(),
					result = Ext.Array.map(selectedRecords, function(selectedRecord) {
						return selectedRecord.getData();
					});

				"function" == typeof callback ? callback(result) : null;

				buttonSelect.setDisabled(true);
				!config.doNotClose ? modal.close() : null;
			}
		});
	}

	if (!config.noFilter) {
		var fireSearch = function() {
			if (searchTimeout) {
				clearTimeout(searchTimeout);
			}

			searchTimeout = setTimeout(function() {
				if (filterForm.isValid()) {
					var filters = [], values = filterForm.getValues(), store = grid.getStore(), args = {};
					if (typeof filterForm.processHeaderChips === 'function') {
						filterForm.processHeaderChips(filterForm, values);
					}
					else {
						edi.filters.processHeaderChips(filterForm, values);
					}
					edi.utils.clearEmptyValues(values);

					args = 'function' == typeof config.createArgs ? config.createArgs(values) : values;
					if (args) {
						delete args.filterFormAutoSearchCheckbox;
					}

					store.clearFilter();

					if( config.createModalFilterFn ){
						store.filter([{
							filterFn: config.createModalFilterFn(args)
						}]);
					} else {
						for (var i in args) {
							if (args.hasOwnProperty(i)) {
								filters.push({
									property: i,
									value: args[i],
									anyMatch: true
								});
							}
						}
						store.filter(filters);
					}

					store.setProxy(new Ext.ux.data.PagingMemoryProxy({
						data: dataLines
					}));

					if (1 != store.currentPage) {
						grid.pagingBar.moveFirst();
					}
					else {
						store.load();
					}
				}
			}, edi.constants.DEFAULT.FILTER.FIRE_SEARCH_DELAY);
		};

		var filterForm = edi.filters.createModuleFilterForm({
			items: 'function' == typeof config.createFilterForm ? config.createFilterForm() : edi.filters.config.generic.createFormItems()
		}, fireSearch);

		filterForm.fireSearch = fireSearch;

		filterForm.clearSearchTimeout = function() {
			clearTimeout(searchTimeout);
		};
	}

	var modal = createModalPanel({
		cls: "edi-modal-form test-modal-relation-selector",
		title: edi.i18n.getMessage(config.title || 'document.organization.select.title'),
		width: config.width || edi.constants.DEFAULT.MODAL.WIDTH_MAX,
		height: config.height || edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
		items: [
			createPanel({
				cls: "edi-form",
				layout: "border",
				items: [filterForm, grid]
			})
		],
		buttonsBefore: buttonSelect ? [buttonSelect] : [],
		listeners: {
			close: function() {
				filterForm && 'function' == typeof filterForm.clearSearchTimeout ? filterForm.clearSearchTimeout() : null;
			}
		}
	});

	modal.show();
	return modal;
};

Ext.namespace('edi.special');
Ext.apply(edi.special, {
	createModalDataExchanger,
	createModalRemoteSelect,
	createModalRelationSelect,
	createModalTabForm,
	createUploadModalDialog,
	createModalWithLocalGrid
});

export {
	createModalDataExchanger,
	createModalRemoteSelect,
	createModalRelationSelect,
	createModalTabForm,
	createUploadModalDialog,
	createModalWithLocalGrid
};