/**
 * Возвращает перевод, если сервис переводов вообще есть (в проекте он есть, а в UIkit нет)
 * @param    {String}     key             key used for translation
 * @param    {Object=}     [formatValues]    key used for translation
 * @param    {Boolean=true}    [dontClear]       true to not clear placeholders
 * @param    {Boolean=true}    [isSource]        true to show translation text - param only for debug showKeys
 */
export const i18n = function (key, formatValues, dontClear, isSource) {
	return edi?.i18n?.getMessage(key, formatValues, dontClear, isSource) || key;
};
