/**
 * Новый тип layout - grid
 * позволяет выстраивать вложенные компоненты по сетке
 * принимает конфигурацию:
 * columns {Number} - количество колонок
 * gap {Number || Array[column gap, row gap]} - отступы между компонентами в px
 * area {Array} - описание сетки постраения вложеных компонентов
 *
 * конфигурация для вложенных компонентов
 * параметр grid {Object}, включает в себя параметры:
 * col {Number} - ширина компонента, компонент растягивается на col колонок, максимальное значение <= значение columns родителя
 * row {Number} - высота компонента
 * scol {Number} - колонка с которой начинаеться компонент, если колонка занята то компонент перескакивает на следующую строку
 * srow {Number} - строка с которой начинается компонент
 */
Ext.define('Ext.layout.container.Grid', {
	alias: 'layout.grid',
	extend: 'Ext.layout.container.Auto',
	type: 'grid',
	columns: 12,
	gap: 16,
	area: [],
	baseCls: 'ui-grid',
	targetCls: 'ui-grid-body',
	itemCls: 'ui-grid-item',
	beginLayout: function (ownerContext) {
		var me = this;
		me.callParent(arguments);

		var targetEl = me.innerCt,
			styles,
			items = me.getLayoutItems(),
			colGap = me.gap,
			rowGap = me.gap;

		me.owner.addCls(me.baseCls);

		me.createArea(items);
		me.buildArea(items);

		// Старый функционал позиционирование items
		if (Array.isArray(me.gap)) {
			colGap = me.gap[1]; //<-- OVERRIDE Ediweb
			rowGap = me.gap[0]; //<-- OVERRIDE Ediweb
		}

		styles = {
			position: 'relative',
			display: 'grid',
			height: 'auto',
			gridTemplateColumns: `repeat(${me.columns}, 1fr)`,
			gridTemplateRows: 'auto',
			columnGap: `${colGap}px`
		};

		targetEl.setStyle(styles);

		var cstart = 1,
			cend = 1,
			style,
			ieRowMax = 1,
			defaultGrid = {
				col: 1
			};
		for (var i = 0; i < items.length; ++i) {
			var childContext = items[i],
				gridConfig = Object.assign(defaultGrid, childContext.grid || {});

			if (!!childContext.grid) {
				if (childContext.hidden) {
					gridConfig.col = 0;
				}

				cend = (gridConfig.scol || cend) + gridConfig.col;
				style = {
					marginBottom: `${rowGap}px`,
					gridColumn: `${gridConfig.scol || cstart} / ${cend}`,
					gridRow: `${gridConfig.srow || 1} / ${(gridConfig.row || 1) + (gridConfig.srow || 1)}`
				};
				cstart = cstart + gridConfig.col;

				if (childContext?.grid?.row) {
					delete style.marginBottom;
				}

				childContext.el.setStyle(style);

				ieRowMax = gridConfig.srow > ieRowMax ? gridConfig.srow : ieRowMax;
			}
		}
		// Старый функционал позиционирование items
	},
	createArea: function (items) {
		const me = this;
		let areaCount = 0,
			area = me.area,
			createArea = function (length) {
				return Array.from({ length }, () => 12);
			};

		if (!area.length) {
			area = createArea(items.length);
		} else {
			for (let i in area) {
				if (Ext.isArray(area[i])) {
					for (let p in area[i]) {
						areaCount++;
					}
				} else {
					areaCount++;
				}
			}

			if (areaCount <= items.length) {
				let diffCount = items.length - areaCount;
				if (diffCount !== 0) {
					area = area.concat(createArea(diffCount));
				}
			}
		}

		me.area = area;
	},
	buildArea: function (items) {
		const me = this;
		let pos = 0,
			rowGap = me.gap,
			itemsArea = [];

		if (Ext.isArray(me.gap)) {
			rowGap = me.gap[0];
		}

		for (let yi in me.area) {
			let cols = me.area[yi],
				rowItems = [],
				scol = 1;

			if (Ext.isNumber(cols)) {
				cols = [cols];
			}

			for (let xi in cols) {
				let item = items[pos],
					col = Number(cols[xi]),
					cend = col + scol + (xi > 0 ? cols[xi - 1] : 0),
					style;

				scol = scol + (xi > 0 ? cols[xi - 1] : 0);

				if (yi == 0) {
					style = {
						marginBottom: `${rowGap}px`,
						gridColumn: `${scol} / ${cend}`
					};
				} else {
					style = {
						marginBottom: `${rowGap}px`,
						gridColumn: `${scol} / ${cend}`,
						gridRow: `${Number(yi) + 1} / ${1 + (Number(yi) + 1)}`
					};
				}

				if (!!item) {
					item.el.setStyle(style);
				}
				pos++;

				if (!!item && !item?.hidden) {
					rowItems.push(item);
				}

				// хак для компонентов которые занимают пространство по высоте 2 и более строки
				if (!!item && item?.grid?.row && item?.grid?.row === me.area.length) {
					item.el.setStyle({
						marginBottom: 0
					});
				}
			}

			if (rowItems.length) {
				itemsArea.push(rowItems);
			}
		}

		let lastRow = itemsArea.pop();
		if (!lastRow) return;

		for (let item of lastRow) {
			item.el.setStyle({
				marginBottom: 0
			});
		}
	}
});
