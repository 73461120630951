import { i18n } from '@UIkit/methods';

/**
 * Creates a standalone label element to be associated with a field element on a form
 * @param	{Object}	[config]	config options
 * @returns	{Object}	Ext.form.Label instance
 */
export const createLabel = function (config) {
	config = 'object' == typeof config ? config : {};
	let valueLabel = !!config.valueLabel;
	let showZero = !!config.showZero;
	delete config.valueLabel;
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	let defaults = {
		text: ''
	};
	if (valueSrc && config.name) {
		defaults.text = edi.utils.getObjectProperty(valueSrc, config.name);
	}
	Ext.applyIf(config, defaults);
	if (config.text) {
		config.text = String('function' == typeof config.text ? config.text() : config.text).trim();
	}
	if (valueLabel) {
		config.cls = config.cls ? config.cls : '';
		config.cls += ' edi-value-label';
		if (showZero && config.text === 0) {
			config.text = String(config.text);
		} else if (showZero && config.text === '') {
			config.text = '';
		} else if (!config.text && !config.html) {
			config.text = i18n('value.not.specified');
			config.cls += ' edi-empty-label';
		}
	}
	return new Ext.form.Label(config);
};
