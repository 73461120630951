Ext.define('UI.components.FormContainer', {
	extend: 'UI.components.Container',

	layout: {
		type: 'grid',
		gap: [0, 24],
		area: []
	},

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	modifyConfig: function (cfg) {
		const config = Ext.clone(cfg);
		config.layout = {
			type: 'grid',
			gap: config.gap || [0, 24],
			area: config.area || []
		};
		config.cls = 'ediweb-form-container ' + (config.cls || '');

		return config;
	},

	beforeInit: function () {},

	afterInit: function () {}
});

/**
 * Специальный контейнер layout grid для вставки в форму, обязательный параметр area
 * @param {{cls?: string, gap?: number[], area?: number[]}} config
 */
export const createFormContainer = function (config) {
	return Ext.create('UI.components.FormContainer', config);
};
