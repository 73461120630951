import '@UIkit/components/fields/Text/Text';
import './TagTextField.scss';

Ext.define('UI.components.TagTextField', {
	extend: 'UI.components.TextField',

	delimiter: '[\\s,]',
	enableDeleteTagByBackspace: false,
	valueArray: true,

	fieldWrapperCls: 'ui-tag-text-field',
	tagsWrapperCls: 'ui-tag-text-field-wrap',

	fieldSubTpl: [
		'<div class="{baseCls}">',
		'<div id="{tagsId}" class="{wrapCls}"></div>',
		'<input id="{id}" data-ref="inputEl" type="hidden" {inputAttrTpl} size="1"',
		'<tpl if="name"> name="{name}"</tpl>',
		'<tpl if="value"> value="{value}"</tpl>',
		'<tpl if="readOnly"> readonly="readonly"</tpl>',
		'<tpl if="disabled"> disabled="disabled"</tpl>',
		' class="" autocomplete="off"/>',
		'</div>'
	],

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	beforeInit: function () {
		const me = this;
		me.tagsId = `${me.id}-tags`;
		me.store = new Ext.data.Store({
			fields: ['id', 'value']
		});
		me.store.loadData(me.valueToList(me.value));

		me.callParent();
	},

	afterInit: function () {
		const me = this;
		me.store.on('datachanged', () => {
			me.setRawValue(me.getRawValue());
			me.isValid();
		});
		me.on('afterrender', me.handleAfterRender);
		me.on('disable', () => (me.tagInput.disabled = me.isDisabled()));
		me.on('writeablechange', (comp, readOnly) => (me.tagInput.readOnly = readOnly));

		me.callParent();
	},

	handleAfterRender: function () {
		const me = this;
		const tagsComp = me.renderTags();

		tagsComp.on('refresh', (comp) => {
			let tagInput = comp.el.query('.tags-input')[0];
			if (tagInput) {
				tagInput.addEventListener('keyup', me.createDeleteTagOnInputEnd.bind(me));
				tagInput.addEventListener('keydown', me.createDeleteTagOnInputEnd.bind(me));
				tagInput.addEventListener('blur', function () {
					if (!me.isDestroyed && !me.editDeleteInProgress) {
						me.addOrEditTag(tagInput.value);
					}
				});
			}
			me.tagInput = tagInput;
		});
	},

	setValue: function (value) {
		const me = this;
		me.setRawValue(value);
		me.store.loadData(me.valueToList(value));
	},

	getRawValue: function () {
		const me = this;
		const storeData = me.getStoreData();
		return me.valueArray ? storeData : storeData.join(',');
	},

	hasAnyValue: function () {
		const me = this;
		return !!me.store?.getCount() || (!me.store?.getCount() && !!me.tagInput?.value);
	},

	getErrors: function () {
		const me = this;
		//получаем ошибки стандартным способом
		let errors = me.callParent(arguments);
		//если поле обязательное и в инпуте что-то написано или что-то в тэгах уже есть
		//то удалим ошибку об обязательности
		if (!me.allowBlank && me.hasAnyValue()) {
			errors = errors.filter((err) => err !== me.blankText);
		}
		//кинем событие, если изменилась валидность поля
		me.checkValidityChange(errors.length === 0);
		return errors;
	},

	createDeleteTagOnInputEnd: function (e) {
		const me = this;
		const el = e.target;
		const val = el.value;
		const isEditMode = me.tagsComp.editedTagRecord;

		//по Backspace удаляем тэг, когда стерли все внутри инпута (по отпусканию клавиши, что б сработало только 1 раз)
		//в режиме редактирования не будем удалять тэги (т.к. инпут внутри тэга)
		if (me.enableDeleteTagByBackspace && !isEditMode && !val && e.keyCode === 8 && e.type === 'keyup') {
			me.removeTag();
		}

		//по Enter добавляем тэг (по отпусканию клавиши, что б сработало только 1 раз)
		//в режиме редактирования тэг с пустым значением тоже нужно обрабатывать
		if ((isEditMode || (!isEditMode && !!val)) && e.keyCode === 13 && e.type === 'keyup') {
			me.addOrEditTag(val);
		}
		//все остальное рассматриваем как пользовательский ввод
		//причем проверять будем и нажатие и отпускание клавиш, т.к. клавиши можно нажимать одновременно
		//и тогда может случиться так что юзер жмет пробел и буквы вместе и пробел оказывается посреди строки,
		//в такой реализации он успеет нажать или отпустить только одну клавишу после символа-разделителя
		else {
			//если начинаем писать тэг с разделителя, то ничего не произойдет
			let delimiterIndex = val.match(new RegExp(me.delimiter))?.index;
			if (delimiterIndex === 0) {
				el.value = '';
			}
			//если в тексте встретился разделитель, то возьмем все что до него и засунем в тэг
			else if (delimiterIndex) {
				let newTagValue = val.slice(0, delimiterIndex);
				if (newTagValue) {
					me.addOrEditTag(newTagValue);
					//очистим поле, чтобы новый тэг начался с пустой строки (вдруг что-то как-то туда попало)
					el.value = '';
				}
			}

			me.isValid();
		}
	},

	highlightTag: function (tag) {
		if (tag?.classList) {
			tag.classList.add('tags-item-invalid');
			setTimeout(() => tag?.classList?.remove('tags-item-invalid'), 400);
		}
	},

	addOrEditTag: function (value) {
		const me = this;
		if (me.isDestroyed) {
			return;
		}
		me.editDeleteInProgress = true;
		value = value.replace(new RegExp(me.delimiter), '');
		value = value.trim();

		//в режиме редактирования вернем инпут на место "после тэгов"
		//и обновим значение в текущем рекорде или удалим пустой тэг
		if (me.tagsComp?.editedTagRecord) {
			if (me.tagInput) {
				me.tagInput.value = '';
				me.tagsComp.el.dom.append(me.tagInput);
				me.tagInput.focus();
			}
			//обновляем значение если оно поменялось
			if (!!value && value !== me.tagsComp.editedTagRecord.get('value')) {
				me.tagsComp.editedTagRecord.set('value', value);
			}
			//возвращаем старый текст на место (если не изменилось значение)
			else if (!!value && value === me.tagsComp.editedTagRecord.get('value')) {
				const editedTag = me.findTagByValue(value);
				editedTag.insertBefore(document.createTextNode(value), editedTag.childNodes[0]);
				me.tagsComp.editedTagRecord.set('value', value);
			}
			//удалим тэг если значение полностью стерли
			else if (!value) {
				me.removeTag(me.tagsComp.editedTagRecord);
			}

			//поищем дубликаты тэгов и удалим все кроме первого
			if (!!value) {
				let sameValueRecords = me.store.getRange().filter((r) => r.get('value') === value);
				if (sameValueRecords.length > 1) {
					sameValueRecords.shift();
					sameValueRecords.forEach((rec) => me.removeTag(rec));
					me.highlightTag(me.findTagByValue(value));
				}
			}
		}
		//в обычном режиме (не редактирование) подсветим тэг-дубликат (не будем его создавать) или добавим новый
		else if (!!value) {
			let tag = me.findTagByValue(value);
			if (!!tag) {
				me.highlightTag(tag);
			} else {
				me.store.add({
					id: Ext.id(),
					value: value
				});
				me.tagInput.value = '';
				me.fireEvent('change', me, me.getRawValue(), me.getValue());
				me.tagInput.focus();
			}
		}

		me.tagsComp.editedTagRecord = null;
		me.editDeleteInProgress = false;
	},

	removeTag: function (record) {
		const me = this;
		me.editDeleteInProgress = true;
		if (!!record) {
			me.store.remove(record);
		} else {
			if (me.store.getCount()) {
				me.store.remove(me.store.last());
			}
		}

		me.fireEvent('change', me, me.getRawValue(), me.getValue());
		me.tagInput.focus();
		me.editDeleteInProgress = false;
	},

	findTagByValue: function (value) {
		const me = this;
		const record = me.store.findRecord('value', new RegExp(`^${value}$`, 'g'), 0, true, true, true);
		return !!record ? me.el.query(`#${record.get('id')}`)[0] : null;
	},

	renderTags: function () {
		const me = this;
		const tpl = new Ext.XTemplate(
			'<tpl for=".">',
			'<span id="{id}" class="tags-item">',
			'{[Ext.util.Format.htmlEncode(values.value)]}',
			'<span class="tags-item-remove"></span>',
			'</span>',
			'</tpl>',
			'<input class="tags-input" type="{type}"',
			'<tpl if="placeholder"> placeholder="{placeholder}"</tpl>',
			'{%if (values.maxLength !== undefined){%} maxlength="{maxLength}"{%}%}',
			'<tpl if="readOnly"> readonly="readonly"</tpl>',
			'<tpl if="disabled"> disabled="disabled"</tpl>',
			' class="" autocomplete="off"/>'
		);
		let tagsComp = new Ext.DataView({
			baseCls: 'tags-wrap',
			itemCls: 'tags-item',
			itemRemoveCls: 'tags-item-remove',
			store: me.store,
			tpl: tpl,
			renderTo: me.tagsId,
			itemSelector: 'span.tags-item',
			listeners: {
				afterrender: function (dataView) {
					dataView.navigationModel.setPosition = function () {
						setTimeout(() => me.tagInput?.focus(), 10);
					};
				},
				itemclick: function (comp, record, item, index, e) {
					if (e.target.className === comp.itemRemoveCls) {
						me.removeTag(record);
					}
				},
				itemdblclick: function (comp, record, item, index, e) {
					//при двойном клике на тэге перейдем в режим его редактирования
					//перенесем инпут внутрь тэга и подставим в него текущее значение тэга
					const tagEl = e.target;
					if (me.tagInput && tagEl && tagEl?.childNodes[0]) {
						tagEl.childNodes[0].remove();
						tagEl.insertBefore(me.tagInput, tagEl.childNodes[0]);
						me.tagInput.value = record.get('value');
						comp.editedTagRecord = record;
						me.tagInput.focus();
					}
				}
			}
		});
		me.tagsComp = tagsComp;
		return tagsComp;
	},

	valueToList: function (str) {
		let list = [];
		if (!!str) {
			list = 'string' == typeof str ? str.split(',') : str;
			list = list.map((value) => ({
				id: Ext.id(),
				value: value.trim()
			}));
		}
		return list;
	},

	getSubTplData: function () {
		const me = this;
		const data = me.callParent(arguments);
		return Object.assign(
			{
				tagsId: me.tagsId,
				baseCls: me.fieldWrapperCls,
				wrapCls: me.tagsWrapperCls
			},
			data
		);
	},

	getStoreData: function () {
		const me = this;
		const list = [];
		me.store.each((record) => {
			list.push(record.get('value'));
		});
		return list;
	}
});

const createTagTextField = function (cfg) {
	return Ext.create('UI.components.TagTextField', cfg);
};

export { createTagTextField };
