import './FieldSet.scss';
import { createField } from '@Components/fields';
import { createContainer } from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';

Ext.define('UI.components.FieldSet', {
	extend: 'Ext.form.FieldSet',
	baseCls: 'ui-fieldset',

	createToggleCmp: function () {
		var me = this;
		me.toggleCmp = createButton({
			cls: `${BUTTON_CLS.secondary} ${BUTTON_CLS.small} ${me.baseCls}-toggle-btn`,
			glyph: edi.constants.ICONS.ARROW_UP_LARGE,
			handler: me.toggle,
			scope: me
		});
		return me.toggleCmp;
	},
	createLegendCt: function () {
		var me = this,
			items = [],
			legendCfg = {
				baseCls: me.baseCls + '-header',
				layout: 'container',
				ui: me.ui,
				id: me.id + '-legend',
				autoEl: 'legend',
				ariaRole: null,
				items: items,
				ownerCt: me,
				shrinkWrap: true,
				ownerLayout: me.componentLayout
			},
			legend;
		items.push(me.createTitleCmp());
		if (me.checkboxToggle) {
			items.push(me.createCheckboxCmp());
		} else if (me.collapsible) {
			items.push(me.createToggleCmp());
		}
		legend = createContainer(legendCfg);
		return legend;
	}
});

export const createFieldSet = function (config, inputs) {
	config = Ext.clone(config || {});
	let type = config.type;
	delete config.type;
	let fieldSet = Ext.create('UI.components.FieldSet', config);

	if (inputs) {
		for (let i = 0; i < inputs.length; i++) {
			fieldSet.add(createField(inputs[i], type));
		}
	}
	return fieldSet;
};
