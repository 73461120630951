import { BUTTON_CLS } from '@UIkit/components/buttons';
import './ButtonContainer.scss';

Ext.define('UI.components.ButtonContainer', {
	extend: 'Ext.toolbar.Toolbar',
	layout: 'hbox',
	baseCls: 'ui-button-container',
	cleanupClassesPostfix: [
		BUTTON_CLS.primary,
		BUTTON_CLS.secondary,
		BUTTON_CLS.outline,
		BUTTON_CLS.light,
		BUTTON_CLS.link
	],
	primaryClass: BUTTON_CLS.primary,
	secondaryClass: BUTTON_CLS.secondary,

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	beforeInit: function () {},

	afterInit: function () {
		const me = this;
		me.on('afterrender', () => me.modifyButtons());
		me.items.each((item) => {
			item.on('hide', function () {
				me.modifyButtons();
			});
			item.on('show', function () {
				me.modifyButtons();
			});
		});
	},

	cleanStyleClasses: function (button) {
		this.cleanupClassesPostfix.forEach((className) => {
			if (button.hasCls(className)) {
				button.removeCls(className);
			}
		});
	},

	modifyButtons: function () {
		const me = this;
		const buttons = me.items.items;
		const visibleButtons = buttons.filter((btn) => btn.isVisible());

		visibleButtons.forEach(function (button, i) {
			me.cleanStyleClasses(button);
			if (i) {
				if (button.glyph) {
					button.cacheGlyph = button.glyph;
				}
				button.setGlyph(null);
				button.addCls(me.secondaryClass);
			} else {
				if (!button.hasCls(me.primaryClass)) {
					button.addCls(me.primaryClass);
					button.setGlyph(button.cacheGlyph);
				}
			}
		});
	}
});

/**
 * Компонент для группировки кнопок. Согласно макетам должна быть только одна главная кнопка, поэтому:
 * для первой кнопки должен быть задан глиф и primary класс, для остальных secondary класс без глифа
 */
export const createButtonContainer = function (config) {
	return Ext.create('UI.components.ButtonContainer', config);
};
