import './ToolBar.scss';

Ext.define('UI.components.ToolBar', {
	extend: 'Ext.toolbar.Toolbar',
	baseCls: 'ui-toolbar',
	dock: 'top',
	layout: {
		type: 'hbox',
		pack: 'start',
		align: 'center'
	},
	items: [],

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	beforeInit: function () {},

	afterInit: function () {}
});

/**
 * Creates tool bar
 * @param	{Object}	[properties]	items, dock ...
 * @returns	{Object}	Ext.toolbar.Toolbar instance
 */
export const createToolBar = function (properties) {
	return Ext.create('UI.components.ToolBar', Object.assign({}, properties));
};
