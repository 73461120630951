const registeredPaths = {};
const scripts = {};

const loadScript = function (cfg, preventLoading) {
	cfg = 'object' == typeof cfg ? cfg : {};
	if (!cfg.path) {
		return;
	}

	if (!(cfg.scriptId = registeredPaths[cfg.path])) {
		cfg.loaded = false;
		cfg.scriptId = Ext.id(null, edi.constants.ID_PREFIX);
		cfg.script = document.createElement('script');
		cfg.script.setAttribute('type', 'text/javascript');
		cfg.script.id = cfg.scriptId;
		registeredPaths[cfg.path] = cfg.scriptId;
		/**
		 * Script handler that called on script load
		 */
		var loaded = function () {
			if (!cfg.loaded) {
				//Prevent IE from calling method 2 times
				cfg.loaded = true;
				'function' == typeof cfg.success ? cfg.success() : null;
			}
		};
		if ('function' == typeof cfg.failure) {
			cfg.script.onerror = function () {
				'function' == typeof cfg.failure ? cfg.failure() : null;
			};
		}

		if (Ext.isIE) {
			cfg.script.onreadystatechange = function () {
				if (this.readyState === 'complete' || this.readyState === 'loaded') {
					loaded();
				}
			};
		} else {
			cfg.script.addEventListener('load', loaded, false);
		}

		scripts[cfg.scriptId] = cfg;
		cfg.script.src = cfg.path + '?build=' + buildVersion.versionEncoded;
		if (preventLoading) {
			setTimeout(loaded, 10);
		} else {
			document.querySelector('head').appendChild(cfg.script);
		}
	}
	return scripts[cfg.scriptId];
};
Ext.namespace('edi.loading.processor');
edi.loading.processor.loadScript = loadScript;

export { loadScript };
