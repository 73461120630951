import {createPanel, createTabPanel} from "@Components/panels";
import {createContainer} from "@Components/miscComponents";
import {createNavigationPanel} from "@Components/navigation";
import {createBreadCrumb, createHeaderControls} from "@Components/topPanel";

/**
 * Singleton class with core methods, application start point
 * @author Anatoli Deryshev
 */
Ext.USE_NATIVE_JSON = true;
Ext.namespace("edi.core");
let __self = edi.core;
let debug = {
	enabled: isDevelopment,
	isDevEnv: (function() {
		var isDev = true, url = window.location.href, i;
		for (i = 0; i < edi.constants.PRODUCTION_SERVERS.length; i++) {
			if (url.match(edi.constants.PRODUCTION_SERVERS[i])) {
				isDev = false;
				break;
			}
		}
		return isDev;
	})(),
	messageLevel: "debug, info, warn, error",
	disabledMessageLevel: "warn, error"
};
let layout = {}, errorRecursion = 0, modules = [], buildVersion = Ext.applyIf({}, window.buildVersion), lastUpdatedData = "{}",
	saveTimeout, currentUserOrg, currentUserPositions = [], userData = {}, uiRendered, modStart = [], language;

Ext.merge(edi.core, {
	loadLocalizationGroups: [],

	init: function() {
		language = edi.i18n.getLanguage();
		edi.constants.DEFAULT.CURRENCY = edi.constants.LANGUAGES[language].CURRENCY;
		edi.i18n.loadExtLocale(edi.constants.LANGUAGES[language] && edi.constants.LANGUAGES[language].EXT_LOCALE ? language : edi.constants.DEFAULT.LANGUAGE, function() {
			__self._createViewport();
			edi.login.init();
			__self._setUIRendered(false);
			edi.i18n.loadMessages(__self._coreInit, true, __self.afterLoadMessages);
		});
		__self.init = function() {
			__self.handleException("Core class could not be reinitialized");
		};
	},

	afterLoadMessages: function() {
		layout.navigation ? layout.navigation.setTitle(edi.core.getBuildVersion().title) : null;
		layout.footer ? edi.renderers.renderFooterContents(layout) : null;
		layout.header ? edi.renderers.renderHeaderContents(layout) : null;
	},
	/**
	 * Returns current build information
	 */
	getBuildVersion: function() {
		if (!buildVersion.changeSet) {
			var tmp = buildVersion.displayVersion.replace("v" + buildVersion.version + "-rev_", "");
			tmp = tmp.split("_");
			buildVersion.changeSet = tmp[0];
		}
		return Ext.clone(buildVersion);
	},
	/**
	 * Returns ui rendering status
	 */
	getUiRendered: function() {
		return uiRendered;
	},
	/**
	 * Returns current debug config
	 */
	getDebugConfig: function() {
		return Ext.clone(debug);
	},
	/**
	 * Generates unique id
	 * @return    {String}    generated id
	 */
	getId: function() {
		return Ext.id(null, edi.constants.ID_PREFIX);
	},
	/**
	 * Outputs message to the console(if enabled)
	 * @param    {Object}    message        message to output to the console panel
	 * @param    {String}    type        type of the message, by default uses "debug"
	 */
	logMessage: function(message, type) {
		var errLevel = debug.enabled ? debug.messageLevel : debug.disabledMessageLevel;
		type = type ? type : "debug";
		errorRecursion++;
		if (message && "object" == typeof message && message.message) {
			message = message.message + " in " + message.fileName + " at line " + message.lineNumber + " col " + message.columnNumber;
		}
		if ("undefined" != typeof console && message && -1 != errLevel.indexOf(type) && 1 == errorRecursion) {
			message = message.split("<br/>").join("\n\n");
			if ("warn" === type && "function" == typeof console.warn) {
				console.warn(message);
			}
			else if ("error" === type && "function" == typeof console.error) {
				console.error(message);
			}
			else if ("debug" === type && "function" == typeof console.debug) {
				console.debug(message);
			}
			else if ("info" === type && "function" == typeof console.info) {
				console.info(message);
			}
			else if ("function" == typeof console.log) {
				console.log(message); // NOSONAR
			}
		}
		errorRecursion = 0;
	},
	/**
	 * Outputs exception to the console
	 * @param    {Object}    e    error message
	 */
	handleException: function(e) {
		__self.logMessage(e.stack || e, "error");
	},
	/**
	 * Shows message box with info
	 * @param   {String}        text           text to display in the box
	 * @param   {Function}      callback        optional
	 */
	showInfo: function(text, callback) {
		var m = Ext.Msg.alert(__self.getMessage("info"), __self.getMessage(text) + edi.utils.msgWidthCorrection(), callback);
		m.setAutoScroll(true);
		return m;
	},
	/**
	 * Shows message box with warning
	 * @param   {String}        text           text to display in the box
	 * @param   {Function}      callback        optional
	 */
	showWarn: function(text, callback) {
		var m = Ext.Msg.alert(__self.getMessage("warn"), __self.getMessage(text) + edi.utils.msgWidthCorrection(), callback);
		m.setAutoScroll(true);
		return m;
	},
	/**
	 * Shows message box with error
	 * @param    {String}        text           text to display in the box
	 * @param   {Function}      callback        optional
	 */
	showError: function(text, callback) {
		var m = Ext.Msg.alert(__self.getMessage("error"), __self.getMessage(text) + edi.utils.msgWidthCorrection(), callback);
		m.setAutoScroll(true);
		return m;
	},
	/**
	 * Shows message box without buttons and close
	 * @param    {String}        text           text to display in the box
	 */
	showWarnWithoutClose: function(text) {
		var m = Ext.Msg.show({
			title: __self.getMessage('warn'),
			msg: __self.getMessage(text),
			minHeight: 130,
			minWidth: 300,
			buttons: undefined,
			resizable: false,
			closable: false,
			draggable: false,
			modal: false
		});
		m.setAutoScroll(true);
		return m;
	},
	/**
	 * Shows confirmation dialog
	 * @param  {String}         title      The title bar text
	 * @param  {String}         msg        The message box body text
	 * @param  {Function}       success    Invoked when answer is yes
	 * @param  {Function}       failure    Invoked when answer is no
	 * @param  {Function}       callback   Invoked after the message box is closed
	 * @param  {Function}       cancel     Invoked when answer is cancel
	 */
	confirm: function(title, msg, success, failure, callback, cancel) {
		var m = Ext.Msg.confirm(__self.getMessage(title ? title : 'confirmation.title'), __self.getMessage(msg) + edi.utils.msgWidthCorrection(), function(answer) {
			if ("yes" === answer) {
				"function" == typeof success ? success() : null;
			}
			else if ("no" === answer) {
				"function" == typeof failure ? failure() : null;
			}
			else if ("cancel" === answer) {
				"function" == typeof cancel ? cancel() : null;
			}
			if ("cancel" !== answer) {
				"function" == typeof callback ? callback() : null;
			}
		});
		m.setAutoScroll(true);
		return m;
	},
	/**
	 * Returns navigation object
	 * @returns {Object|*}
	 */
	getNavigationPanel: function() {
		return layout.navigation;
	},
	/**
	 * Returns center panel
	 * @returns {Object|*}
	 */
	getTabPanel: function() {
		return layout.tabPanel;
	},
	/**
	 * Returns viewport
	 * @returns {Object|*}
	 */
	getViewPort: function() {
		return layout.viewport;
	},
	/**
	 * Returns header
	 * @returns {Object|*}
	 */
	getHeader: function() {
		return layout.header;
	},
	/**
	 * Returns current documents html head element
	 */
	getHtmlHead: function() {
		return document.getElementsByTagName("head")[0];
	},
	/**
	 * Submit upload form
	 * @param    {Object}      form
	 * @param    {String}      url
	 * @param    {String}      waitMsg    text that will be displayed during form submit
	 * @param    {Function}    success    success callback
	 * @param    {Function}    failure    failure callback
	 * @param    {Object}      options    options
	 */
	submitUploadForm: function(form, url, waitMsg, success, failure, options) {
		if (form && url) {
			var processOptions = options && "object" == typeof options.process ? options.process : {};
			var submitOptions = options && "object" == typeof options.submit ? options.submit : {};
			var responseCallback = function(form, action) {
				edi.rest.processResponse(action.response, success, failure, processOptions);
			};
			var baseConfig = {};
			if (options && options.params && "object" == typeof options.params) {
				baseConfig.params = options.params;
			}
			form.submit(Ext.apply(baseConfig, {
				url: submitOptions.noUrlAuth ? url : edi.login.setUrlAuth(url),
				waitMsg: edi.i18n.getMessage(waitMsg || 'uploading.file'),
				success: responseCallback,
				failure: responseCallback
			}));
		}
	},
	/**
	 * Returns translation from messages bundle, or key if no translation found
	 * @param    {String}     key             key used for translation
	 * @param    {Object}     formatValues    key used for translation
	 * @param    {Boolean}    dontClear       true to not clear placeholders
	 */
	getMessage: function(key, formatValues, dontClear) {
		return edi.i18n.getMessage(key, formatValues, dontClear);
	},
	/**
	 * Save extra data
	 * @param callback
	 */
	_saveData: function(callback) {
		var extraData = edi.core.getUserData().userData;
		!extraData ? extraData = {} : null;
		var user = extraData.user || {};
		var organization = extraData.organization || {};
		var process = function(obj) {
			for (var i in obj) {
				if (obj.hasOwnProperty(i)) {
					if ("object" == typeof obj[i]) {
						obj[i] = Ext.encode(obj[i]);
					}
				}
			}
			return obj;
		};
		extraData = {
			organization: process(organization),
			user: process(user)
		};
		var dataToSave = Ext.encode(extraData);
		if (dataToSave != lastUpdatedData) {
			var success = function() {
				lastUpdatedData = String(dataToSave);
				edi.core.logMessage("User extra data saved", "info");
			};
			var failure = function(data) {
				edi.core.handleException(edi.utils.formatComplexServerError(data, "User extra data did not saved properly"));
			};
			edi.rest.sendRequest(edi.rest.services.USER.SELF.EXTRA_INFO.PUT, "PUT", dataToSave, success, failure, callback);
		}
		else {
			"function" == typeof callback ? callback() : null;
		}
	},
	/**
	 * Set extra data
	 * @param name        dot separated path "prop1.prop2"
	 * @param value
	 * @param callback    if defined - save and fire callback, else - save with delay
	 */
	setExtraData: function(name, value, callback) {
		if (!edi.core.DISABLE_EXTRA_DATA) {
			saveTimeout ? clearTimeout(saveTimeout) : null;
			var extraData = edi.core.getUserData().userData;
			if (name) {
				edi.utils.setObjectProperty(extraData, name, String(value));
			}
			else if ("object" == typeof value) {
				var user = value.user || {};
				var organization = value.organization || {};

				Ext.applyIf(user, extraData.user);
				Ext.applyIf(organization, extraData.organization);

				extraData.user = user;
				extraData.organization = organization;
			}
			callback ? __self._saveData(callback) : saveTimeout = setTimeout(__self._saveData, 100);
		}
		else {
			"function" == typeof callback ? callback() : null;
		}
	},
	/**
	 * Get extra data
	 * @param name        dot separated path "prop1.prop2"
	 * @param asArray   return value as array
	 * @returns {String|Array}
	 */
	getExtraData: function(name, asArray) {
		var extraData = edi.core.getUserData() ? edi.core.getUserData().userData : {};
		return edi.utils.getObjectProperty(extraData, name, asArray);
	},
	/**
	 * get module by moduleName
	 * @param   {String}    moduleName
	 * @returns {Object}
	 */
	getModule: function(moduleName) {
		var module = null;
		if (modules.length) {
			for (var i = 0; i < modules.length; i++) {
				if (modules[i].modName == moduleName) {
					module = modules[i];
					break;
				}
			}
		}
		return module;
	},
	/**
	 * Opens module
	 * @param      {String}     moduleName      system name of the module to open
	 * @param      {Object}     moduleData      module data
	 * @param      {String}     titlePostfix    additional text after module title
	 * @param      {Boolean}    isEdit          adds '.edit' to title and pencil glyph
	 * @param      {Number}     customId        Custom module id
	 * @param      {Object}     addConf         additional module config object
	 * @param      {Function}   afterInit       method that should be called after module initialisation
	 * @returns    {Boolean}                    true if module loading started, false in other case
	 */
	openModule: function(moduleName, moduleData, titlePostfix, isEdit, customId, addConf, afterInit) {
		moduleData = moduleData || null;
		addConf = "object" == typeof addConf ? addConf : {};
		if (moduleData && 'object' == typeof moduleData.addConf) {
			Ext.mergeIf(addConf, moduleData.addConf);
		}
		var module = edi.core.getModule(moduleName), moduleLoadStarted = false, isCopy = !!addConf.isCopy;
		if (!customId && addConf && addConf.meta && addConf.meta.id) {
			customId = addConf.meta.id;
		}
		if (module) {
			var permissions = isEdit ? module.permissionsEdit : module.permissions;
			if (edi.permissions.hasPermissions(permissions)) {
				var config = {
					isEditModule: isEdit,
					glyph: module.glyph,
					name: module.modName + module.menuId + (customId ? "_" + customId : (moduleData && moduleData.hasOwnProperty('id') ? "_" + moduleData.id : "")) + (isCopy ? "_copy" : ""),
					id: customId ? customId : moduleData ? moduleData.id : undefined,
					modName: module.modName,
					title: edi.i18n.getMessage(module.title + (isEdit ? ".edit" : "")) + (titlePostfix ? " " + titlePostfix : ""),
					menuId: module.menuId,
					objectId: customId ? customId : moduleData ? moduleData.id : undefined,
					folder: module.folder,
					permissions: permissions,
					data: moduleData
				};
				Ext.apply(config, addConf);
				edi.modulesHandler.loadModule(config, afterInit);
				moduleLoadStarted = true;
			}
			else {
				edi.core.logMessage("User do not have permissions to open " + moduleName, "warn");
			}
		}
		else {
			edi.core.logMessage("Module " + moduleName + " is not defined!", "warn");
			edi.core.showError("error.module.not.defined");
		}
		return moduleLoadStarted;
	},
	/**
	 * get user organization id
	 * @returns    {String}
	 */
	getUserOrgID: function() {
		var data = __self.getUserData();
		return data && data.org ? data.org.id : null;
	},
	/**
	 * get user data
	 * @returns    {Object}
	 */
	getUserData: function() {
		var data = userData && userData.id ? edi.models.createInstance("USER", userData).data : null;
		if (data) {
			if (data.orgs) {
				for (var n = 0; n < data.orgs.length; n++) {
					data.orgs[n] = edi.models.createInstance("ORGANIZATION", data.orgs[n]).data;
					if (currentUserOrg == data.orgs[n].id || (!currentUserOrg && 0 == n)) {
						data.org = data.orgs[n];
					}
				}
			}
		}
		return data;
	},
	/**
	 * update user data
	 * @returns    {Object}
	 */
	updateUserData: function() {
		edi.login.getUser(null, true, function (data) {
			userData = data;
			!userData.userData ? userData.userData = {} : null;
			lastUpdatedData = Ext.encode(userData.userData);
		});
	},
	/**
	 * get user positions
	 * @returns    {Array}
	 */
	getPositions: function() {
		var result = [];
		if (currentUserPositions) {
			for (var i = 0; i < currentUserPositions.length; i++) {
				result.push(edi.models.createInstance("WORKER_POSITION", currentUserPositions[i]).getData());
			}
		}
		return result;
	},
	/**
	 * Shows login form
	 * @param    {Function}    success    callback that should be called on success login
	 */
	startLogin: function(success) {
		edi.login.showlogin(success, function(data) {
			let extraUserData = userData?.userData || {};
			userData = data;
			userData.userData = extraUserData;
			lastUpdatedData = Ext.encode(userData.userData);
		});
	},
	/**
	 * Gets user calcualtion presision for legacy documents
	 */
	getPrecisionNumber: function() {
		var costDecimals = edi.utils.getObjectProperty(userData, 'org.attributes.costDecimals.value');
		return parseInt(costDecimals, 10) || edi.constants.DEFAULT.PRECISION_NUMBER;
	},
	/**
	 * Loads initial necessary data - user, organization, positions and relations
	 * @returns	{Promise<void>}
	 * @Private
	 */
	_loadInitData: () => new Promise(resolve => {
		if (edi.constants.LOG_UA_DATA) {
			__self._logUserAgentInfo();
		}
		edi.login.getUser(function(isNotAllowed) {
			if (isNotAllowed) {
				__self._selfDestroy();
			}
			else {
				var continueLoading = function() {
					edi.stores.beforeInit();
					edi.login.getCurrentOrganization(function() {
						if (edi.core.getUserOrgID()) {
							edi.permissions.init(function() {
								var userData = __self.getUserData();
								var isPaymentDebt = edi.constants.CHECK_PAYMENT_DEBT && userData?.org?.paymentDebt;

								var afterPositions = function() {
									if (isPaymentDebt || edi.constants.SKIP_RELATIONS) {
										edi.relations.init([], function() {
											edi.stores.init(resolve);
										});
									}
									else {
										__self._getRelations(function() {
											edi.stores.init(resolve);
										});
									}
								};

								if (isPaymentDebt || edi.constants.SKIP_POSITIONS) {
									afterPositions();
								}
								else {
									edi.login.getPositions(function(data) {
										currentUserPositions = data || [];
										afterPositions();
									});
								}
							});
						}
						else {
							edi.stores.init(resolve);
						}
					}, false, function(data) {
						currentUserOrg = data.id;
					});
				};
				var lang = edi.utils.getObjectProperty(__self.getUserData(), "language");
				var continueAfterExtTranslations = function() {
					edi.i18n.loadMessages(continueLoading, false, edi.core.afterLoadMessages, lang);
				};
				edi.i18n.loadExtLocale(lang || language, continueAfterExtTranslations);
			}
		}, true, function(data) {
			userData = data;
			!userData.userData ? userData.userData = {} : null;
			lastUpdatedData = Ext.encode(userData.userData);
		});
	}),

	/**
	 * get user organization relations to other organizations and set it to relations
	 */
	_getRelations: function(callback) {
		var success = function(data) {
			var items = data.items;
			if (Ext.isArray(items) && items.length) {
				items = Ext.Array.map(items, function(orgItem) {
					if (orgItem.orgData) {
						var orgData = orgItem.orgData;
						delete orgItem.orgData;

						delete orgData.creationDate;
						delete orgData.modifyDate;
						delete orgData.header;
						delete orgData.id;

						Ext.apply(orgItem, orgData);
					}
					return orgItem;
				});
			}
			items.push(edi.core.getUserData().org);

			if (!!edi.utils.getObjectProperty(edi.core.getUserData().org, 'attributes.costDecimals.value')) {
				edi.constants.DEFAULT.SUMM_DISPLAY_PRECISION_NUMBER = edi.utils.getObjectProperty(edi.core.getUserData().org, 'attributes.costDecimals.value');
				edi.constants.DEFAULT.PRECISION_NUMBER = edi.utils.getObjectProperty(edi.core.getUserData().org, 'attributes.costDecimals.value');
			}

			edi.relations.init(items, callback);
			__self.logMessage("Relations loaded", "info");
		};
		var failure = function() {
			__self.handleException("Relations did not loaded properly");
			"function" == typeof callback ? callback() : null;
		};
		edi.rest.sendRequest(edi.rest.services.USER.PARTNERS.GROUPED.GET, "GET", null, success, failure);
	},
	/**
	 * Creates viewport
	 */
	_createViewport: function() {
		var viewportConf = Ext.apply({}, edi.constants.DEFAULT.VIEWPORT);
		layout.mainPanel = createPanel({
			cls: "edi-main-panel",
			region: 'center',
			layout: 'border',
			hidden: true,
			flex: 1
		});
		viewportConf.items = [layout.mainPanel];
		if (edi.constants.DEFAULT.BACKGROUND.ON_START && ((edi.constants.DEFAULT.BACKGROUND.IMG_LIST && edi.constants.DEFAULT.BACKGROUND.IMG_LIST.length) || "MONTH" === edi.constants.DEFAULT.BACKGROUND.ROTATION)) {
			var bgNr, bgCls = "";
			if ("MONTH" === edi.constants.DEFAULT.BACKGROUND.ROTATION) {
				bgNr = (new Date()).getMonth() + 1;
				bgCls = edi.constants.DEFAULT.BACKGROUND.BASE_CLASS + " " + edi.constants.DEFAULT.BACKGROUND.CLASS_NAME_PREFIX + bgNr;
			}
			else {
				bgNr = parseInt(edi.utils.getCookie(edi.constants.DEFAULT.BACKGROUND.COOKIE_NAME) || 0, 10);
				if (bgNr >= edi.constants.DEFAULT.BACKGROUND.IMG_LIST.length) {
					bgNr = 0;
				}
				bgCls = edi.constants.DEFAULT.BACKGROUND.BASE_CLASS + " " + edi.constants.DEFAULT.BACKGROUND.CLASS_NAME_PREFIX + edi.constants.DEFAULT.BACKGROUND.IMG_LIST[bgNr];
			}
			viewportConf.cls = (viewportConf.cls ? viewportConf.cls + " " : "") + bgCls;
			if (!edi.constants.DEFAULT.HIDE_LOGIN_FOOTER) {
				layout.footer = createPanel({
					cls: "edi-viewport-footer",
					region: 'south',
					height: 80
				});
				viewportConf.items.push(layout.footer);
			}
			if (edi.constants.DEFAULT.SHOW_LOGIN_HEADER) {
				layout.header = createPanel({
					cls: "edi-viewport-header",
					region: 'north',
					align: "center",
					maxWidth: 779,
					height: 53
				});
				viewportConf.items.push(layout.header);
			}
		}

		layout.viewport = new Ext.Viewport(viewportConf);
	},
	/**
	 * Creates initial layout
	 */
	_createLayout: function() {
		var items = [], infoPanelWrapper;
		if (edi.constants.LEFT_NAVIGATION_V2) {
			layout.navigation = createNavigationPanel(Ext.apply({}, edi.constants.DEFAULT.NAVIGATION_V2_CONFIG));
			layout.mainPanel.add(layout.navigation);
		}

		if (edi.constants.SHOW_HEADER) {
			var config = Ext.apply({}, edi.constants.DEFAULT.HEADER_CONFIG);
			if (layout.header) {
				layout.header.hide();
			}
			layout.header = createPanel(config);
			if (!edi.constants.HIDE_HEADER_BUTTONS) {
				layout.header.add(createHeaderControls(layout.header));
			}
			layout.mainPanel.add(layout.header);
		}

		layout.tabPanel = createTabPanel(
			Ext.apply({}, edi.constants.DEFAULT.TABPANEL_HEADER_CONFIG)
		);

		if (edi.constants.BREAD_CRUMB && !edi.constants.SHOW_HEADER) {
			layout.breadCrumb = createBreadCrumb(layout.tabPanel);
			layout.mainPanel.add(layout.breadCrumb);
		}
		if (!edi.constants.MODULES_TAB_BAR) {
			layout.tabPanel.tabBar.hide();
		}
		layout.mainPanel.add(layout.tabPanel);

		var userData = __self.getUserData();
		if (userData?.org?.paymentDebt && edi.constants.CHECK_PAYMENT_DEBT) {
			Ext.Msg.show({
				title: edi.i18n.getMessage('warn'),
				msg: edi.i18n.getMessage('error.organization.has.payment.debt.warning.text'),
				buttons: undefined,
				resizable: false,
				closable: false,
				draggable: false,
				modal: false
			});
			var tabPanelMask = new Ext.LoadMask({
				useMsg: false,
				target: layout.tabPanel
			});
			tabPanelMask.show();
		}

		if (edi.constants.INFO_FOOTER && 'function' == typeof edi.methods.getInfoFooterElements) {
			var footerItems = edi.methods.getInfoFooterElements();
			if (Ext.isArray(footerItems) && footerItems.length) {

				var footerConfig = Ext.apply({
					cls: 'edi-bottom-info-panel',
					animCollapse: false,
					collapsible: true,
					collapsed: true,
					titleCollapse: true,
					hideCollapseTool: true,
					maxHeight: 150
				}, Ext.isObject(edi.constants.INFO_FOOTER) ? edi.constants.INFO_FOOTER : {});

				footerConfig.header = Ext.apply({
					minHeight: 35
				}, Ext.isObject(footerConfig.header) ? footerConfig.header : {});

				//Items for short info sline in panel header
				footerConfig.header.items = [createContainer({
					cls: 'edi-info-header-panel',
					width: '100%',
					autoScroll: true,
					items: footerItems
				})];

				//create info panel
				layout.infoPanel = createPanel(footerConfig);

				infoPanelWrapper = createContainer({
					region: 'south',
					width: layout.viewport.getWidth(),
					cls: "edi-info-footer-wrapper",
					items: [
						createContainer({
							items: [layout.infoPanel]
						})
					]
				});

				items.push(infoPanelWrapper);
			}
		}

		if (items.length) {
			layout.viewport.add(items);
		}

		layout.mainPanel.show();
		if (layout.footer) {
			layout.footer.hide();
		}

		__self.logMessage("Created main layout");
	},

	/**
	 * Loads modules from backend that current user can access (options - "isMain:true")
	 */
	getMainModules: function() {
		return modStart;
	},

	/**
	 * destroy layout
	 */
	_selfDestroy: function() {
		if (edi.login.getAuthType() !== "AB" && edi.login.getAuthType() !== "ACTIVATION") {
			edi.login.logout();
		}
		else {
			layout.viewport.destroy();
		}
		window.close();
	},
	/**
	 * Sets UI rendered state
	 * @param state
	 */
	_setUIRendered: function(state) {
		var rendered = {};
		uiRendered = state;
		rendered[edi.constants.RENDERED_UI_ATTR] = uiRendered;
		if (state && layout && layout.viewport) {
			layout.viewport.addCls("edi-ui-completely-rendered");
			edi.events.modules.fireEvent("rendered");
		}
		Ext.getBody().set(rendered);
	},
	/**
	 * logs user agent info for monitoring purposes
	 */
	_logUserAgentInfo: function() {
		if (window && "function" == typeof window.UAParser) {
			var parser = new UAParser(), result = parser.getResult(), pxr = window.devicePixelRatio || 1, objectToSave = {
				"browser": result.browser.name,
				"browserVersion": result.browser.version,
				"os": result.os.name ? (result.os.name + (result.os.version ? " " + result.os.version : "")) : "",
				"deviceType": result.device && result.device.type ? result.device.type : "desktop",
				"screenResolution": window.screen ? (Math.round(window.screen.width * pxr / 10) * 10 + 'x' + Math.round(window.screen.height * pxr / 10) * 10) : ""
			};
			edi.rest.sendRequest(edi.rest.services.USER_AGENT_LOG.POST, "POST", Ext.encode(objectToSave), undefined, undefined, undefined, {
				suppressDefaultError: true
			});
		}
	},
	/**
	 * Main initialization method
	 * @returns	{Promise<void>}
	 * @Private
	 */
	_coreInit: async function() {
		if (edi.debug) {
			if (edi.constants.AUTO_CHECK_SCOPE_SHARING) {
				edi.debug.startAutoScopeSharingCheck(edi.constants.AUTO_CHECK_SCOPE_SHARING_INTERVAL);
			}
			if (edi.constants.SESSION_KEEP_ALIVE && !buildVersion.isProductionServer) {
				edi.debug.keepSessionAlive();
			}
			if( edi.utils.getURLParams() && edi.utils.getURLParams().showKeys){
				edi.debug.getTranslateToKey();
			}
		}
		layout.viewport.setLoading({
			msg: edi.i18n.getMessage("loading.text"),
			cls: "edi-viewport-mask-msg",
			msgWrapCls: "x-mask edi-viewport-mask"
		});

		if (edi.constants.SKIP_LOGIN)  {
			layout.viewport.setLoading(false);
			await __self._initAppLayout();
		}
		else {
			await __self._loadInitData();
			if (!userData.id) {
				edi.core.showError("error.getting.user", __self._selfDestroy);
				return;
			}
			else if (!currentUserOrg) {
				edi.core.showError("error.getting.organization.data", __self._selfDestroy);
				return;
			}

			layout.viewport.setLoading(false);
			await __self._initAppLayout();
		}
	},
	/**
	 * Runs functions in edi.constants.BEFORE_LOAD_MODULES before application's layout is created
	 * @returns {Promise<void>}
	 * @Private
	 */
	_runFunctionsBeforeModules: () => new Promise(resolve => {
		let functionsBeforeLoadModules = edi.constants.BEFORE_LOAD_MODULES;

		let recursionBeforeLoadModules = function (step = 0) {
			if (step < functionsBeforeLoadModules.length - 1) {
				functionsBeforeLoadModules[step](function () {
					recursionBeforeLoadModules(step + 1)
				});
			} else {
				functionsBeforeLoadModules[step](function () {
					resolve();
				});
			}
		};
		if (functionsBeforeLoadModules?.length > 0) {
			recursionBeforeLoadModules();
		} else {
			resolve();
		}
	}),
	/**
	 * Creates application's layout
	 * @Returns	{Promise<void>}
	 * @private
	 */
	_initAppLayout: async function() {
		await __self._runFunctionsBeforeModules();

		__self._createLayout();
		Ext.getBody().on( 'keydown', function(e) {
			if (e.getKey() === 8) {
				var target = e.getTarget();
				if (target.type !== "text" && target.type !== "textarea" && target.type !== "password") {
					e.preventDefault();
				}
				else if (target.readOnly) {
					e.preventDefault();
				}
			}
		});
		if (currentUserOrg && !edi.constants.HIDE_HEADER_BUTTONS && edi.notifications) {
			edi.notifications.getCountNotifications();
		}

		await __self._loadModulesAndCreateNavigation();
		edi.core.isInitFinish = true;
		layout.viewport.setLoading(false);
		__self._setUIRendered(true);
	},
	/**
	 * Creates navigation panel from edi.modulesCfg
	 * @private
	 */
	_loadModulesAndCreateNavigation: () => new Promise(resolve => {
		modules = [];
		for (let i in edi.modulesCfg) {
			if (edi.modulesCfg.hasOwnProperty(i)) {
				edi.modulesCfg[i].menuId = edi.modulesCfg[i].highlightMenuId || edi.modulesCfg[i].modName;
				edi.modulesCfg[i].isDetails = -1 !== edi.modulesCfg[i].modName.indexOf(".details");
				edi.modulesCfg[i].isMain ? modStart.push(Ext.clone(edi.modulesCfg[i])) : null;
				modules.push(edi.modulesCfg[i]);
			}
		}
		edi.events.modules.fireEvent("modulesLoad");
		__self.logMessage("Modules loaded and parsed", "info");

		var urlParams = edi.utils.getURLParams(), module = null, urlModule = null;
		if (urlParams.module && modules.length) {
			module = urlModule = __self.getModule(urlParams.module);
			if (module && !module.isDetails && module.permissions && !edi.permissions.hasPermissions(module.permissions)) {
				module = null;
			}
		}

		var setModule = function(moduleObject) {
			if (!moduleObject.isDetails && (!moduleObject.permissions || edi.permissions.hasPermissions(moduleObject.permissions))) {
				module = moduleObject;
				return true;
			}
			return false;
		};

		if ((!urlModule || "AB" !== edi.constants.AUTH_TYPE) && !module) {
			var moduleOnly = edi.core.getModule(edi.core.getExtraData("user.module"));
			if (!moduleOnly || !setModule(moduleOnly)) {
				for (var i = 0; i < modules.length; i++) {
					if (modules[i].isMain && setModule(modules[i])) {
						break;
					}
				}
			}
		}
		var renderNav = function() {
			if (edi.constants.LEFT_NAVIGATION_V2) {
				edi.navigation.renderNavigation(() => resolve());
			}
			else {
				resolve();
			}
		};

		if (module) {
			edi.modulesHandler.loadModule({
				name: module.modName + module.menuId,
				modName: module.modName,
				isMain: module.isMain,
				title: module.title,
				glyph: module.glyph,
				icon: module.icon,
				iconCls: module.iconCls,
				menuId: module.menuId,
				folder: module.folder,
				permissions: module.permissions
			}, renderNav);
		}
		else {
			if ("AB" === edi.constants.AUTH_TYPE) {
				edi.events.login.fireEvent("userOrganizationLoad");//force header panel update
			}
			renderNav();
		}
	})
});
