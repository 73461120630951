import { BUTTON_CLS, createButton } from './Buttons';

BUTTON_CLS.action = 'ui-button-action';

const createActionsButton = function (cfg) {
	let defaults = {
		cls: [BUTTON_CLS.light, BUTTON_CLS.action]
	};
	let config = Object.assign({}, defaults, cfg);

	return createButton(config);
};

export { createActionsButton };
