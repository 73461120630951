import renderTpl from './Buttons.ext.tpl';
import './Buttons.scss';
import { PTYPE_DISABLE_DOUBLE_CLICK } from '@UIkit/plugins';

let baseCls = 'ui-button';

const BUTTON_PRESET = {};

const BUTTON_CLS = {
	small: `${baseCls}-small`,
	rounded: `${baseCls}-rounded`,
	stretch: `${baseCls}-stretch`,
	icon: `${baseCls}-icon`,

	primary: `${baseCls}-primary`,
	secondary: `${baseCls}-secondary`,
	outline: `${baseCls}-outline`,
	light: `${baseCls}-light`,
	white: `${baseCls}-white`,
	dark: `${baseCls}-dark`,
	link: `${baseCls}-link`,
	withoutArrow: `${baseCls}-withoutArrow`,

	menuItem: `${baseCls}-menu-item`
};

Ext.define('UI.button.Button', {
	extend: 'Ext.button.Button',
	baseCls,

	beforeInit: function () {
		const me = this;
		me.addCls(`${me.baseCls}-default`);
		if (me.menu) {
			me.addCls(`${me.baseCls}-menu`);
		}

		//для кнопок без меню добавим плагин, если ничего снаружи не передавали
		if (!me.menu && !me.plugins) {
			me.plugins = [PTYPE_DISABLE_DOUBLE_CLICK]
		}

		Ext.apply(me, {
			_btnWrapCls: `${me.baseCls}-wrap`,
			_btnCls: `${me.baseCls}-button`,
			_baseIconCls: `${me.baseCls}-icon-el`,
			_glyphCls: `${me.baseCls}-glyph`,
			_innerCls: `${me.baseCls}-inner`,
			_textCls: `${me.baseCls}-text`,
			_noTextCls: `${me.baseCls}-no-text`,
			_hasIconCls: `${me.baseCls}-icon`,
			_hasOnlyIconCls: `${me.baseCls}-icononly`,
			_hasOnlyTextCls: `${me.baseCls}-textonly`,
			_pressedCls: `${me.baseCls}-pressed`,
			_tooltipCls: `${me.baseCls}-tooltip`,

			overCls: `${me.baseCls}-over`,
			_disabledCls: `${me.baseCls}-disabled`,
			_menuActiveCls: `${me.baseCls}-menu-active`,
			_arrowElCls: `${me.baseCls}-arrow-el`,
			_focusCls: `${me.baseCls}-focus`
		});
	},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	afterInit: function () {
		const me = this;
		if ('object' == typeof me.bindToForm) {
			if (!Ext.isArray(me.bindToForm.bindElements)) {
				me.bindToForm.bindElements = [];
			}
			me.bindToForm.bindElements.push(me);
		}
	},

	renderTpl,

	getTemplateArgs: function () {
		var me = this,
			text = me.text,
			hasIcon = me._hasIcon();

		return Ext.apply(this.callParent(), {
			hasOnlyIconCls: hasIcon && !text ? me._hasOnlyIconCls : '',
			hasOnlyTextCls: !hasIcon && text ? me._hasOnlyTextCls : '',
			text
		});
	},
	updateText: function (text, oldText) {
		var me = this,
			btnInnerEl = me.btnInnerEl,
			btnEl = me.btnEl;
		// Coerce to string. Maybe set to a numeric value.
		text = text == null ? '' : String(text);
		oldText = oldText || '';
		if (me.rendered) {
			btnEl[text ? 'addCls' : 'removeCls'](me._textCls);
			btnEl[text ? 'removeCls' : 'addCls'](me._noTextCls);

			// Для wrap добавлены дополнительные классы
			btnEl[text && !me.glyph ? 'addCls' : 'removeCls'](me._hasOnlyTextCls);
			btnEl[text && !me.glyph ? 'removeCls' : 'addCls'](me._hasOnlyIconCls);
			if (text && me.glyph) {
				btnEl.removeCls(me._hasOnlyTextCls);
				btnEl.removeCls(me._hasOnlyIconCls);
			}

			btnInnerEl.setHtml(text);
			me.updateLayout();
		}
		me.fireEvent('textchange', me, oldText, text);
	},
	updateGlyph: function () {
		var me = this,
			btnEl = me.btnEl,
			text = me.text;

		if (me.rendered) {
			btnEl[text && !me.glyph ? 'addCls' : 'removeCls'](me._hasOnlyTextCls);
			btnEl[text && !me.glyph ? 'removeCls' : 'addCls'](me._hasOnlyIconCls);
			if (text && me.glyph) {
				btnEl.removeCls(me._hasOnlyTextCls);
				btnEl.removeCls(me._hasOnlyIconCls);
			}
		}

		me.callParent(arguments);
	}
});

/**
 * Creates button component
 * @param	{Object}	[config]
 * @returns	{Object}	UI.button.Button instance
 */
const createButton = (config) => new UI.button.Button(config);

export { createButton, BUTTON_PRESET, BUTTON_CLS };
