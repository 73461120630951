import { createCheckbox } from '@UIkit/components/fields/Checkbox/Checkbox.js';
import './ComboCheckedItem.scss';

/**
 * Плагин для компонента Combobox, который добавляет в каждый пункт выпадающего списка чекбокс.
 * @param {Boolean} multiSelect - Обязательный параметр для родительского Combobox
 */
const PTYPE_COMBO_CHECKED_ITEM = 'combo_checked_item';
Ext.define('Ext.ux.plugin.ComboCheckedItem', {
	extend: 'Ext.AbstractPlugin',
	alias: `plugin.${PTYPE_COMBO_CHECKED_ITEM}`,

	baseCls: 'ui-plugin-combo_checked_item',

	init: function (cmp) {
		var me = this;
		me.callParent([cmp]);

		const picker = cmp.getPicker(),
			checkboxArr = [];

		picker.on('selectionchange', function (selModel, records) {
			for (let cb of checkboxArr) {
				cb.setValue(!!records.find((r) => r.internalId == cb.listItemId));
			}
		});

		picker.on('viewlist', function (__self, record, itemEl) {
			const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();

			if (!record.phantom && !record?.isSection) {
				wrapEl.addCls(me.baseCls);

				let checkbox = checkboxArr.find((c) => c.listItemId == record.internalId);
				let isSelected = itemEl.is(`.${__self.selectedItemCls}`);

				if (!checkbox) {
					let checkbox = createCheckbox({
						listItemId: record.internalId,
						value: isSelected
					});
					checkboxArr.push(checkbox);
					wrapEl.insertFirst(checkbox.getRenderTree());
					checkbox.finishRender();
				} else {
					wrapEl.insertFirst(checkbox.getEl());
					checkbox.setValue(isSelected);
				}
			}
		});
	}
});

export { PTYPE_COMBO_CHECKED_ITEM };
