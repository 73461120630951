import tpl from './Trigger.ext.tpl';

/**
 * Дополнил модуль добавления иконки в триггер, теперь можно передавать праметром
 * @param {glyph}
 */

Ext.override(Ext.form.trigger.Trigger, {
	renderTpl: tpl,
	renderTrigger: function (fieldData) {
		var me = this,
			config = {};

		if (me.glyph) {
			let glyph = new Ext.Glyph(me.glyph);
			config = {
				glyphCls: `${me.baseCls}-glyph`,
				glyph: glyph.character,
				glyphStyle: `font-family: ${glyph.fontFamily};`
			};
		}

		if (me.extraCls && !me.glyph) {
			config = {
				extraCls: me.extraCls
			};
		}

		let defaultConfig = {
			$trigger: me,
			fieldData: fieldData,
			ui: fieldData.ui,
			childElCls: fieldData.childElCls,
			triggerId: (me.domId = me.field.id + '-trigger-' + me.id),
			cls: me.cls,
			baseCls: me.baseCls,
			triggerStyle: me.hidden ? 'display:none;' : '',
			ariaRole: me.ariaRole
		};

		return Ext.XTemplate.getTpl(me, 'renderTpl').apply(Ext.apply(defaultConfig, config));
	},
	setGlyph: function (glyph) {
		var me = this;
		let glyphConf = new Ext.Glyph(glyph);

		if (glyphConf.character && glyphConf.fontFamily) {
			me.el.update(glyphConf.character);
			me.el.setStyle({
				fontFamily: glyphConf.fontFamily
			});
		}
	}
});
