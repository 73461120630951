import './Number.scss';

Ext.define('UI.components.NumberField', {
	extend: 'Ext.form.field.Number',
	baseCls: 'ui-numberfield',

	// invalidText: i18n("invalid.number.format"),
	decimalSeparator: '.',
	// decimalPrecision: edi.core.getPrecisionNumber(),
	allowDecimals: false,
	allowExponential: false,
	minValue: 0,
	// maxValue: maxValue,
	// maxLength: maxLength,
	hideTrigger: true,

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	}
});

const createNumberField = (config) => new UI.components.NumberField(config);

export { createNumberField };
