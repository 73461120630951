// import { PTYPE_BOTTOM_ERRORS } from '@UIkit/plugins/bottomError/bottomError';
import { PTYPE_FIELD_ERROR } from '@UIkit/plugins/fieldError/fieldError';
import { PTYPE_COMBO_CHECKED_ITEM } from '@UIkit/plugins/ComboCheckedItem/ComboCheckedItem';
import { PTYPE_COMBO_ITEMS_ACTION } from '@UIkit/plugins/ComboItemsAction/ComboItemsAction';
import { PTYPE_COMBO_ITEMS_DISABLEABLE } from '@UIkit/plugins/ComboItemsDisableable/ComboItemsDisableable';
import { PTYPE_DISABLE_DOUBLE_CLICK } from '@UIkit/plugins/DisableDoubleCLick/DisableDoubleClick';

export {
	// PTYPE_BOTTOM_ERRORS,
	PTYPE_FIELD_ERROR,
	PTYPE_COMBO_CHECKED_ITEM,
	PTYPE_COMBO_ITEMS_ACTION,
	PTYPE_COMBO_ITEMS_DISABLEABLE,
	PTYPE_DISABLE_DOUBLE_CLICK
};
