/**
 * Creates a single button
 * @param	{Object}	config	config options
 * @returns	{Object}	Ext.button.Button instance
 */
const createButton = function(config) {
	config = "object" == typeof config ? config : {};
	let cfg = Ext.merge({
		text: "",
		plugins: config.menu ? null : [Ext.ux.DisableDoubleClick]
	}, config);
	let button = new Ext.button.Button(cfg);

	if ("object" == typeof cfg.bindToForm) {
		if (!Ext.isArray(cfg.bindToForm.bindElements)) {
			cfg.bindToForm.bindElements = [];
		}
		cfg.bindToForm.bindElements.push(button);
	}
	return button;
};

/**
 * Typical interface button
 * @param config
 * @return {*}
 */
const createSimpleButton = function(config) {
	return createLink(config)
};

/**
 * Creates a button to use on action panel
 * @param	{Object}	config	config options
 * @returns	{Object}	Ext.button.Button instance
 */
const createActionsButton = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		cls: "edi-actions-button",
		scale: "small"
	};
	Ext.applyIf(config, defaults);
	if (edi.constants.SHOW_TOOLTIPS && !config.tooltip) {
		config.tooltip = edi.constants.USE_TOOLTIPS ? config.text : undefined;
	}
	if (config.text) {
		if (edi.constants.SHOW_TOOLTIPS) {
			config.text = !edi.constants.USE_TOOLTIPS ? config.text : undefined;
		}
		else {
			delete config.tooltip
		}
	}
	return createButton(config);
};

/**
 * Creates a close button to use on action panel
 * @param	{Object}	moduleData	module data
 * @returns	{Object}	Ext.button.Button instance
 */
const createCloseActionsButton = function(moduleData) {
	return createActionsButton({
		tooltip: edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage("action.close") : undefined,
		text: !edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage("action.close") : undefined,
		glyph: edi.constants.ICONS.CLOSE,
		handler() {
			edi.modulesHandler.removeModule(moduleData);
		}
	});
};

/**
 * Creates add/save button
 * @param	{Object}	config	config options
 * @param	{Boolean}	isEdit	true if button used in edit mode
 * @returns	{Object}	Ext.button.Button instance
 */
 const createAddSaveButton = function(config, isEdit) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.add'),
		glyph: isEdit ? edi.constants.ICONS.SAVE : edi.constants.ICONS.ADD,
		handler() {
			edi.core.logMessage("Empty button handler called", "info");
		}
	};
	Ext.applyIf(config, defaults);
	return createButton(config);
};

/**
 * Creates add/edit button
 * @param	{Object}	config	config options
 * @param	{Boolean}	isEdit	true if button used in edit mode
 * @returns	{Object}	Ext.button.Button instance
 */
const createAddEditButton = function(config, isEdit) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		text: edi.i18n.getMessage(isEdit ? 'form.btn.edit' : 'form.btn.add'),
		glyph: isEdit ? edi.constants.ICONS.EDIT : edi.constants.ICONS.ADD,
		handler() {
			edi.core.logMessage("Empty button handler called", "info");
		}
	};
	Ext.applyIf(config, defaults);
	return createButton(config);
};

/**
 * Creates save button
 * @param	{Object}	config	config options
 * @param	{Boolean}	isEdit	true if button used in edit mode
 * @returns	{Object}	Ext.button.Button instance
 */
const createCreateSaveButton = function(config, isEdit) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.create'),
		glyph: isEdit ? edi.constants.ICONS.SAVE : edi.constants.ICONS.CREATE,
		handler() {
			edi.core.logMessage("Empty button handler called", "info");
		}
	};
	Ext.applyIf(config, defaults);
	return createButton(config);
};

/**
 * Create label button
 * @param	{Object}	config	config options
 * @returns	{Object}	Ext.button.Button instance
 */
const createLink = function(config) {
	config = "object" == typeof config ? config : {};
	let cls = " edi-label-button text-left";
	config.cls ? config.cls += cls : config.cls = cls;
	return createButton(config);
};

/**
 * Create tool for header of panel or grid
 * @param	{Object}	cfg
 * @returns	{Object}	Ext.panel.Tool instance ('tool' widget)
 */
const createTool = function(cfg) {
	return Ext.widget('tool', cfg);
};

/**
 * Create add action tool for header of panel or grid
 * @param	{Object}	cfg
 * @returns	{Object}	Ext.panel.Tool instance ('tool' widget)
 */
const createAddTool = function(cfg) {
	return createTool(Ext.merge({
		glyph: edi.constants.ICONS.ADD,
		tooltip: edi.i18n.getMessage('form.btn.add')
	}, cfg))
};


Ext.namespace("edi.components");
Ext.merge(edi.components, {
	createButton,
	createActionsButton,
	createCloseActionsButton,
	createAddSaveButton,
	createAddEditButton,
	createCreateSaveButton,
	createLink,
	createSimpleButton,
	createTool,
	createAddTool
});

export {
	createButton,
	createActionsButton,
	createCloseActionsButton,
	createAddSaveButton,
	createAddEditButton,
	createCreateSaveButton,
	createLink,
	createSimpleButton,
	createTool,
	createAddTool
};