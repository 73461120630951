import './DetailLabel.scss';
import { i18n } from '@UIkit/methods';

Ext.define('UI.components.DetailLabel', {
	extend: 'Ext.form.Label',
	selfCls: 'ui-detail-label',
	titleCls: '',
	contentCls: '',
	width: 'auto',
	content: '',
	text: '',
	contentTextArray: [],

	initComponent: function () {
		this.beforeInit();
		this.callParent([arguments]);
		this.afterInit();
	},

	beforeInit: function () {
		const me = this;
		me.addCls(me.selfCls);
		if (Array.isArray(me.contentTextArray) && me.contentTextArray.length > 0) {
			me.contentTextArray.forEach((item) => {
				item.isNewLine = !!item.isNewLine;
			});
		} else {
			me.contentTextArray = [];
		}

		if (me.text && me.contentTextArray.length === 0) {
			me.contentTextArray = [{ content: me.text }];
		}
		delete me.text;

		me.data = me.contentTextArray;
		me.tpl = me.getTemplate();
	},

	afterInit: function () {},

	getTemplate: function () {
		const me = this;
		const titleCls = `${me.selfCls}-title ${me.titleCls}`;
		const contentCls = `${me.selfCls}-content ${me.contentCls}`;
		const textAreaContentCls = `${me.selfCls}-content ${me.selfCls}-textarea ${me.contentCls}`;
		const emptyContentCls = `${me.selfCls}-content-empty ${me.contentCls}`;
		return Ext.create(
			'Ext.XTemplate',
			'<tpl for=".">',
			'<tpl if="content.length &gt; 0 || !!content || showEmptyTitleWithContent">',
			'<tpl if="isNewLine"><p></p></tpl>',
			`<span class="${titleCls}">{title}`,
			'<tpl if="title.length &gt; 0">: </tpl>',
			'</span> ' + `<span class="${contentCls}">`,
			'{[this.contentValue(values.content)]} ',
			'</span>',
			'</tpl>',
			'<tpl if="textAreaContent.length &gt; 0">',
			'<tpl if="isNewLine"><p></p></tpl>',
			`<p class="${titleCls}">{title}`,
			'	<tpl if="title.length &gt; 0">: </tpl>',
			'</p> ' + `<p class="${textAreaContentCls}">`,
			'{[this.contentValue(values.textAreaContent)]} ',
			'</p>',
			'</tpl>',
			'</tpl>',
			'<tpl>',
			`<span class="${emptyContentCls}">`,
			'{[this.showEmptyText(values)]} ',
			'</span>',
			'</tpl>',
			{
				contentValue: function (value) {
					return value || `<span class="${titleCls}">${i18n('value.not.specified')}</span>`;
				},
				showEmptyText: function (values) {
					var isAllEmpty = Ext.Array.every(values, function (item) {
						if (item.content) {
							return !item.content;
						} else if (item.textAreaContent) {
							return !item.textAreaContent;
						} else if (item.showEmptyTitleWithContent) {
							return !item.showEmptyTitleWithContent;
						}
						return true;
					});
					return isAllEmpty ? `<span class="${titleCls}">${i18n('value.not.specified')}</span>` : null;
				}
			}
		);
	}
});

/**
 * Компонент для отображения полей для страниц деталей документов (серый label: черный text)
 * @param	{Object}	config	в формате  {...labelProps, contentTextArray: {title: String, content: String, isNewLine: String}[]}
 */
export const createDetailLabel = function (config) {
	return Ext.create('UI.components.DetailLabel', config);
};
