import renderTpl from './Boundlist.ext.tpl';
import itemsTpl from './BoundlistItems.ext.tpl';
import './Boundlist.scss';

const baseCls = 'ui-boundlist';

/** Модификация компонента BoundList (Выпадающий список)
 * @param {String} itemField - переменная для родительского Combobox, дополняет displayField,
 * в поле ввода и в выпадающем скиске можно выводить разные переменные рекорда
 */

Ext.override(Ext.view.BoundList, {
	maxHeight: 272,
	baseCls,
	itemCls: `${baseCls}-item`,
	config: {
		itemField: undefined
	},
	// renderTpl,
	itemsTpl,

	initComponent: function () {
		var me = this,
			field = me.pickerField;

		me.itemField = field.itemField || undefined;

		me.tpl = new Ext.XTemplate(me.itemsTpl, {
			getItemCls: function (values) {
				return me.itemCls;
			},
			getItemText: function (values) {
				return Ext.String.htmlEncode(values[me.itemField || me.displayField]);
			},
			getQTip: function (values) {
				if (!field?.showQtip) {
					return;
				}
				let qtipText = values[me.itemField || me.displayField];
				if (field?.formatQtip) {
					qtipText = field.formatQtip(values);
				}
				return Ext.String.htmlEncode(qtipText);
			}
		});

		me.callParent(arguments);
	},

	beforeLayout: function () {
		var me = this;
		me.callParent(arguments);

		me.onViewList();
	},

	getInnerTpl: function (displayField) {
		return `{[edi.utils.safeString(values['${displayField}'])]}`;
	},

	/** Метод обновления layout выпадающего списка.
	 * Обработка каждого рекорда в сторе и привязанного к нему элемента в выпадающем списке в DOM модели.
	 */
	onViewList: function () {
		var me = this;

		if (me.store) {
			const records = me.store.getRange(),
				items = me.all;

			for (let i = 0; i < records.length; i++) {
				let internalId = records[i].internalId,
					itemEl = Ext.fly(me.listEl.selectNode(`[data-recordid="${internalId}"]`));

				this.fireEvent('viewlist', me, records[i], itemEl);
			}
		}
	}
});
