Ext.define('UI.components.ModalForm', {
	extend: 'Ext.form.Panel',

	cls: '',
	selfCls: 'ui-modal-form',
	bodyPadding: '8 24 8 24',
	layout: {
		type: 'grid',
		gap: [24, 16]
	},

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	},

	beforeInit: function () {},

	afterInit: function () {
		this.addCls(this.selfCls);
	}
});

export const createModalForm = function (config) {
	return Ext.create('UI.components.ModalForm', config);
};
