import { i18n } from '@UIkit/methods';
import './Password.scss';

const icons = {
	visibility_open: 'xe8f4@Material Icons',
	visibility_close: 'xe8f5@Material Icons'
};

Ext.define('UI.components.PasswordField', {
	extend: 'Ext.form.field.Text',
	baseCls: 'ui-passwordfield',
	inputType: 'password',

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	},

	triggers: {
		visibility: {
			visibility: true,
			glyph: icons.visibility_open,
			tooltip: i18n('login.form.password.show'),
			handler: function (cmp, trigger) {
				trigger.visibility = !trigger.visibility;

				let type = trigger.visibility ? 'password' : 'text';
				cmp.inputEl.set({ type: type });

				let glyphEye = trigger.visibility ? icons.visibility_open : icons.visibility_close;

				trigger.setGlyph(glyphEye);
			}
		}
	}
});

const createPasswordField = (config) => new UI.components.PasswordField(config);

export { createPasswordField };
