import './scss/Tab.scss';

Ext.define('UI.components.Tab', {
	extend: 'Ext.panel.Panel',
	baseCls: 'ui-tab',

	title: 'tab',
	glyph: null,
	closable: true,
	layout: 'card',

	initComponent: function () {
		var me = this;

		me.beforeInit();
		me.callParent(arguments);
		me.afterInit();
	},

	beforeInit: function () {
		const me = this;
		me.glyph = me.glyph || me.tabConfig?.glyph;
		if ('function' == typeof me.beforeclose) {
			me.on('beforeclose', me.beforeclose, me);
		}
		if ('function' == typeof me.activate) {
			me.on('activate', me.activate, me);
		}
	},
	afterInit: function () {}
});

/**
 * Creates UI.components.Tab
 * @param	{Object}	[config]
 * @returns	{Object}	UI.components.Tab instance
 */
const createTab = (config) => new UI.components.Tab(config);

export { createTab };
