/**
 * Creates checkbox selection model for grid
 * @param	{Object}	[properties]	configuration properties for selection model
 * @returns	{Object}	Ext.selection.CheckboxModel instance
 */
export const createCheckboxSelectionModel = function (properties) {
	properties = 'object' == typeof properties ? properties : {};
	var defaults = {
		checkOnly: true,
		headerWidth: 45
	};
	Ext.applyIf(properties, defaults);
	return Ext.create('Ext.selection.CheckboxModel', properties);
};

/**
 * Creates grid's selection model
 * @param	{Object}	[options]
 * @returns	{Object}	Ext.selection.CheckboxModel instance
 */
export const createGridCheckboxSelectionModel = function (options) {
	options = Ext.apply({}, options);
	var isProcessible = function (actionConf) {
			var allow =
				options.defaultAllowProcess || (actionConf && actionConf.id && actionConf.id !== 'OTHER_ACTIONS');
			if (allow && 'function' == typeof options.isProcessible) {
				allow = options.isProcessible(actionConf);
			}
			return allow;
		},
		topBar = options.topBar,
		selectionProcessor = options.selectionProcessor,
		actionItems = topBar && 'function' == typeof topBar.getMenuActionItems ? topBar.getMenuActionItems() : [],
		properties = Ext.apply(
			{
				listeners:
					'function' == typeof selectionProcessor
						? {
								selectionchange: function (model, selected) {
									topBar ? topBar.suspendLayouts() : null;
									selectionProcessor(model, selected, actionItems, isProcessible);
									topBar ? topBar.resumeLayouts() : null;
								}
						  }
						: undefined
			},
			options.properties
		);
	return createCheckboxSelectionModel(properties);
};
