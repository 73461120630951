import {
	createLabel,
	createTextField,
	createCombo,
	createInput,
	createDateField,
	createFieldSet,
	createCheckbox
} from './fields.js';
import {createButton} from './buttons.js';
import {createContainer} from './miscComponents.js';
import {createPanel, createTab, createTabPanel, createForm, createModalPanel} from './panels.js';
import {createAutocomplete} from '../core/specialComponents/fields.js';
import {createSaveButton} from '../core/specialComponents/buttons.js';
import {createPanelWithModalSelect} from '../core/specialComponents/miscComponents.js';
import {createModalRelationSelect} from '../core/specialComponents/modals.js';
import {createModalCatalogSelect} from '../edi/specialComponents/modalCatalogSelect.js';
import {createEditableGrid} from "../core/specialComponents/editableGrid";

Ext.define("edi.components.company.selector", {
	extend: 'Ext.panel.Panel',
	alias: "widget.edi-company-selector",
	invalidClass: "edi-company-details-with-errors",
	fieldBlockCls: "edi-company-selector-field-block",
	border: 0,
	layout: 'column',
	cls: "edi-create-field-line",
	margin: "5 10 10",
	focusable: true,
	tabIndex: 2,
	disableCommonAddressRenderer: false,
	disableCommonAddressTitleRenderer: false,
	defaultValues: {
		type: 0,
		address_type: 0,

		//Registration data
		company_name: "",
		company_iln: "",
		company_inn: "",
		company_kpp: "",
		company_okpo: "",
		company_okpof: "",

		individual_inn: "",
		individual_company_name: "",
		individual_lastname: "",
		individual_firstname: "",
		individual_patronymicname: "",
		individual_certificateRegistrationIP: "",

		physical_lastname: "",
		physical_firstname: "",
		physical_patronymicname: "",
		physical_inn: "",

		company_util_reg_nr: "",
		company_code_by_sender: "",
		company_code_by_receiver: "",
		company_code_by_supplier: "",
		company_code_by_buyer: "",
		structural_subdivision: "",

		//Address
		address: "",
		addr_country: "",
		addr_zip: "",
		addr_region_code: "",
		addr_region_name: "",
		addr_area: "",
		addr_city: "",
		addr_street: "",
		addr_locality: "",
		addr_home: "",
		addr_block: "",
		addr_flat: "",

		addr_rus_zip: "",
		addr_rus_region: "",
		addr_rus_county: "",
		addr_rus_community: "",
		addr_rus_city: "",
		addr_rus_street: "",
		addr_rus_home: "",
		addr_rus_block: "",
		addr_rus_flat: "",

		addr_for_country: "",
		addr_for_country_name: "",
		addr_for_text: "",

		addr_code_gar: "",

		//Contacts
		contact_phone: "",
		contact_fax: "",
		contact_email: "",
		operator_name: "",
		operator_phone: "",
		operator_email: "",
		representative_name: "",
		representative_phone: "",
		representative_email: "",
		contact_name: "",
		contact_web: "",

		//Bank dara
		bank_acc: "",
		bank_name: "",
		bank_corr_acc: "",
		bank_id: "",
		accounts: [],
		id: "",

		//License data
		license_type: "",
		license_name: "",
		license_series: "",
		license_number: "",
		license_issuing_authority: "",
		license_date_of_issue: "",
		license_expiration_date: ""
	},
	formGrids: {},

	setDefaults: function() {
		var defaults = {
			fieldsConf: {},
			manualChangeDisabled: false, // hide edit button
			relations: null, // array of organizations
			relationsOnly: false, // lock to use relations only
			selectedOrgValues: null, // organization selected from relations
			selectedOrg: null,
			alwaysShowSelect: false, // always show select button
			relationsFromDelcatByOrgId: null, // id of organization to get delcat
			relationsFromLoccatByOrgId: null, // id of organization to get loccat
			ownCatalog: true,
			ownOrg: null, // show "Own Org" button
			fieldsMap: null,
			fieldsMapOnly: false,
			fieldValues: null,
			valuesByMap: false, // use field values by fieldsMap
			excludeMap: {},
			allowBlank: false, // if true - allows to leave ALL fields empty. false - allows to leave only optional fields empty.
			allowReset: false, // show reset button?
			useHiddenFields: false,
			hasData: false,
			is_valid: false,
			readOnly: false,
			emptyClass: "edi-company-selector-empty",
			readOnlyClass: "edi-company-selector-read-only",
			mandatoryFields: {},
			allowEditButton: true,
			partnerOrgValues: null,
			callback: function() {
			},
			relationsRemoteURL: null,
			createModalRemoteSelect: null,
			createModalRemoteSelectOptions: null,
			processValues: null,
			forceChangeAnyField: false,
			forceChangeFields: {},
			downloadBankAccountsData: false,
			getObjectProperty: edi.utils.getObjectProperty
		};

		Ext.applyIf(this, defaults);
	},

	initComponent: function() {
		this.setDefaults();
		this.setOwnConfig();
		this.callParent();
		this.renderInfoPanelContents();
	},
	/**
	 * reset values
	 */
	reset: function() {
		var __self = this;
		__self.selectedOrgValues = null;
		__self.selectedOrg = null;
		__self.setValues({});
		__self.resetBtn ? __self.resetBtn.setDisabled(true) : null;
	},

	/**
	 * Creates hidden field configs by fieldsMap
	 */
	getHiddenFieldConfigs: function() {
		let __self = this;
		let items = [];

		if (!__self.fieldsMap) {
			return items;
		}

		Object.values(__self.fieldsMap).forEach(conf => {
			items.push({
				xtype: "hiddenfield",
				margin: 0,
				name: (conf && "object" === typeof conf) ? conf.name : conf
			});
		});
		return items;
	},

	/**
	 * Creates self panel
	 */
	createSelfPanel: function() {
		let __self = this, items = [], tbar = ['->'];

		if (!__self.readOnly) {
			tbar = tbar.concat(__self.createActionButtons());
		}

		if (__self.useHiddenFields) {
			items = items.concat(__self.getHiddenFieldConfigs());
		}

		// Label conf may be passed as 'labelConf' object or as 'title' & 'tooltip' properties inside selector config
		var tooltip = null, labelConf = this.labelConfig || {
			title: this.title,
			tooltip: this.tooltip
		};

		if (labelConf.title) {
			labelConf.html = edi.i18n.getMessage(labelConf.title);
		}

		if (labelConf.tooltip) {
			tooltip = edi.i18n.getMessage(labelConf.tooltip);
			delete labelConf.tooltip;
		}

		//Create selector label if html defined
		if (labelConf.html) {
			Ext.applyIf(labelConf, {
				margin: "8 0 0",
				columnWidth: 0.2
			});

			// In general modal title is the same as selector label
			if ("object" == typeof this.modalConf) {
				Ext.applyIf(this.modalConf, {
					title: labelConf.html
				});
			}
			delete this.title;
			delete this.tooltip;

			items.push(createLabel(labelConf));
		}

		//Create main selector panel
		var panel = createPanel({
			cls: "edi-company-details-container",
			bodyCls: "edi-company-details-container-body",
			bodyPadding: "0 0 5",
			columnWidth: this.columnWidth || 0.8,
			items: [
				this.infoPanel = createContainer({
					margin: "0 0 5"
				})
			],
			tbar: tbar
		});

		if (edi.constants.SHOW_TOOLTIPS) {
			if (tooltip) {
				panel.on('render', function(panel) {
					panel.toolTip = Ext.create('Ext.tip.ToolTip', {
						target: panel.getEl(),
						header:false,
						trackMouse: true,
						html: tooltip
					});
				});
				panel.on('destroy', function(panel) {
					if (panel.toolTip) {
						panel.toolTip.destroy();
					}
				});
			}
			else {
				panel.on('render', function(panel) {
					var tip = Ext.create('Ext.tip.ToolTip', {
						bodyCls: 'x-tip-body-form-invalid',
						header:false,
						target: panel.getEl(),
						trackMouse: true,
						html: edi.i18n.getMessage('form.field.invalid')
					});
					panel.toolTip = tip;
					tip.on('beforeshow', function() {
						if (__self.is_valid) {
							return false;
						}
					});
				});
				panel.on('destroy', function(panel) {
					if (panel.toolTip) {
						panel.toolTip.destroy();
					}
				});
			}
		}

		this.panel = panel;
		items.push(panel);

		//Create items container
		var containerConfig = {
			//layout: 'column',
			//cls: "edi-create-field-line",
			//margin: this.margin || "5 10 10",
			items: items
		};

		Ext.applyIf(this, containerConfig);
	},

	/**
	 * Recreates hidden fields by fieldsMap and updates infoPanel
	 */
	updateHiddenFields: function() {
		let __self = this;

		__self.suspendLayouts();
		let hiddenFields = __self.query('hiddenfield');
		hiddenFields.forEach(f => __self.remove(f));

		if (__self.useHiddenFields) {
			__self.add(__self.getHiddenFieldConfigs());
		}

		__self.renderInfoPanelContents(true);
		__self.resumeLayouts();
	},

	/**
	 * Builds mandatory fields map base on modal mandatory fields
	 */
	createModalMandatoryFieldMap: function() {
		var __self = this, tabsConfig = __self.modalConf ? __self.modalConf.tabs : null;
		if (!tabsConfig) {
			return;
		}

		var processFields = function(fields) {
			for (var i in fields) {
				if (fields.hasOwnProperty(i) && i !== 'tabConfig') {
					var fieldConf = fields[i];

					if (!fieldConf) {
						continue;
					}

					if (fieldConf.type === "fieldset" || fieldConf.type === "panel") {
						processFields(fieldConf.fields);
					}
					else {
						var inputConf = fieldConf.fieldConfig || {};
						if ((inputConf.allowBlank === false) || (inputConf.mandatory === true)) {
							__self.mandatoryFields[inputConf.name] = true;
						}
					}
				}
			}
		};

		for (var i in tabsConfig) {
			if (tabsConfig.hasOwnProperty(i)) {
				processFields(tabsConfig[i]);
			}
		}
	},

	/**
	 * Sets field values using fieldsMap
	 */
	applyFieldsMapToOrgValues: function() {
		var __self = this, applyToRelation = __self.selectedOrgValues && __self.selectedRelationByMap,
			mapValues = __self.fieldValues, relationValues = __self.selectedOrgValues, mapFieldName, fieldValue, i;

		__self.fieldValues = {};

		if (applyToRelation) {
			__self.selectedOrgValues = {};
		}

		for (i in __self.fieldsMap) {
			if (__self.fieldsMap.hasOwnProperty(i)) {
				mapFieldName = __self.fieldsMap[i];
				fieldValue = this.getObjectProperty(mapValues, mapFieldName);
				__self.fieldValues[i] = fieldValue;

				if (applyToRelation) {
					fieldValue = this.getObjectProperty(relationValues, mapFieldName);
					__self.selectedOrgValues[i] = fieldValue;
				}
			}
		}
	},

	setOwnConfig: function() {
		var __self = this;

		if (!__self.ownConfigSet) {
			__self.createModalMandatoryFieldMap();

			if (__self.valuesByMap) {
				__self.applyFieldsMapToOrgValues();
			}
			else {
				__self.fieldValues = __self.fieldValues || {};
			}

			Ext.applyIf(__self.fieldValues, __self.defaultValues);
			__self.clearNotInMapValues();

			__self.presetCompanyAndAddressTypes(__self.fieldValues);
			__self.hasData = !__self.isEmptyValues();
			__self.getInfoPanelComponentTpl();

			__self.createSelfPanel();
			__self.setIncluded();
			__self.setExcludeMap();
			__self.ownConfigSet = true;
			__self.forceChangeFields = __self.forceChangeFields || {};
		}
	},

	/**
	 * Creates panel buttons
	 * @return {Array}
	 */
	createActionButtons: function() {
		var __self = this, hasPartners = (__self.relations && __self.relations.length), tbar = [],
			hasRelation = hasPartners || __self.relationsFromDelcatByOrgId || __self.relationsFromLoccatByOrgId,
			showSelectButton = hasRelation || __self.alwaysShowSelect,
			isEdit = !__self.isEmptyValues() || __self.relationsOnly;

		var createButtonCmp = function(conf) {
			var text = edi.i18n.getMessage(conf.text);
			Ext.apply(conf, {
				margin: "0 5 0 0",
				ttip: edi.constants.USE_TOOLTIPS ? text : undefined,
				listeners: {
					afterrender: function(elm) {
						if (edi.constants.SHOW_TOOLTIPS) {
							Ext.create('Ext.tip.ToolTip', {
								target: elm.getEl().id,
								header:false,
								trackMouse: true,
								listeners: {
									beforeshow: function(tip) {
										if (__self.panel && "object" === typeof __self.panel.toolTip) {
											__self.panel.toolTip.close();
										}
										tip.update(elm.ttip);
									}
								}
							});
						}
					}
				},
				text: !edi.constants.USE_TOOLTIPS || !edi.constants.SHOW_TOOLTIPS ? text : undefined

			});

			return createButton(conf);
		};

		if (showSelectButton) {
			var canSelectPartner = hasRelation || (__self.relationsRemoteURL && __self.createModalRemoteSelect);
			this.selectBtn = createButtonCmp({
				text: 'form.btn.select',
				glyph: edi.constants.ICONS.SEARCH,
				itemId: 'selectBtn',
				disabled: !canSelectPartner,
				cls: 'test-companySelector-btn-select',
				onClick: function() {
					if (hasPartners) {
						__self.showModalCompanySelect();
					}
					else if (__self.relationsFromDelcatByOrgId) {
						__self.showModalDelcatSelect();
					}
					else if (__self.relationsFromLoccatByOrgId) {
						__self.showModalLoccatSelect();
					}
					else if (__self.relationsRemoteURL) {
						__self.showModalRemoteSelect();
					}
				}
			});

			tbar.push(this.selectBtn);
		}

		if (__self.allowEditButton) {
			this.editBtn = createButtonCmp({
				text: isEdit ? 'form.btn.edit' : 'form.btn.add',
				hidden: __self.manualChangeDisabled,
				cls: 'test-companySelector-btn-' + (isEdit ? 'edit' : 'add'),
				disabled: __self.relationsOnly && !__self.selectedOrgValues,
				glyph: isEdit ? edi.constants.ICONS.EDIT : edi.constants.ICONS.ADD,
				handler: function() {
					__self.showModalCompanyControl();
				}
			});

			tbar.push(this.editBtn);
		}

		if (this.allowReset) {
			this.resetBtn = createButtonCmp({
				text: 'form.btn.reset',
				cls: 'test-companySelector-btn-reset',
				glyph: edi.constants.ICONS.HIGHLIGHT_OFF,
				disabled: this.isEmptyValues(),
				handler: function() {
					edi.core.confirm("confirm.clear.org.title", "confirm.clear.org", function() {
						__self.reset();
					});
				}
			});

			tbar.push(this.resetBtn);
		}

		if (__self.ownOrg) {
			this.ownBtn = createButtonCmp({
				text: 'form.btn.own.org',
				cls: 'test-companySelector-btn-owner',
				glyph: edi.constants.ICONS.BRIEFCASE,
				disabled: !!(this.fieldValues && this.fieldValues.id == edi.core.getUserData().org.id),
				handler: function() {
					var setOwnOrg = function() {
						__self.setOrganization(edi.core.getUserData().org, function() {
							__self.ownBtn.setDisabled(true);
						});
					};

					if (__self.isEmptyValues()) {
						setOwnOrg();
					}
					else {
						edi.core.confirm("confirm.clear.org.title", "confirm.clear.org", function() {
							setOwnOrg();
						});
					}
				}
			});

			tbar.push(this.ownBtn);
		}

		if (__self.partnerOrg) {
			this.partnerBtn = createButtonCmp({
				text: __self.partnerOrgText || 'form.btn.partner.org',
				glyph: edi.constants.ICONS.BRIEFCASE,
				cls: 'test-companySelector-btn-partner',
				disabled: !!(!__self.partnerOrgValues || this.fieldValues && __self.partnerOrgValues && this.fieldValues.id == __self.partnerOrgValues.id),
				handler: function() {
					var setPartnerOrg = function() {
						__self.setOrganization(__self.partnerOrgValues, function() {
							__self.partnerBtn.setDisabled(true);
						});
					};

					if (__self.isEmptyValues()) {
						setPartnerOrg();
					}
					else {
						edi.core.confirm("confirm.clear.org.title", "confirm.clear.org", function() {
							setPartnerOrg();
						});
					}
				}
			});

			tbar.push(this.partnerBtn);
		}

		return tbar;
	},
	/**
	 * auto select relation if only one organization in relations and if fieldValues not set or empty
	 * @param    {Function}    callback
	 */
	presetFromRelation: function(callback) {
		var __self = this;
		if (__self.isEmptyValues() && __self.relations && __self.relations.length === 1) {
			__self.setOrganization(__self.relations[0], callback);
		}
		else {
			if ("function" == typeof callback) {
				callback();
			}
		}
	},
	clearNotInMapValues: function() {
		var i;
		if (this.fieldsMapOnly) {
			for (i in this.fieldValues) {
				if (this.fieldValues.hasOwnProperty(i)) {
					if (!this.fieldsMap[i] && i !== "accounts" && i !== "addr_country_name") {
						this.fieldValues[i] = this.defaultValues[i];
					}
				}
			}
			if (this.valuesByMap && this.selectedRelationByMap) {
				for (i in this.selectedOrgValues) {
					if (this.selectedOrgValues.hasOwnProperty(i)) {
						if (!this.fieldsMap[i] && i !== "addr_country_name" && this.defaultValues.hasOwnProperty(i)) {
							this.selectedOrgValues[i] = this.defaultValues[i];
						}
					}
				}
			}
		}
	},
	/**
	 * set partner org
	 * @param    {Object}     partnerOrg
	 */
	setPartnerOrg: function(partnerOrg) {
		this.partnerOrgValues = partnerOrg;
		if (this.partnerBtn) {
			this.partnerOrgValues ? this.partnerBtn.setDisabled(false) : this.partnerBtn.setDisabled(true);
		}
	},

	setOrgIdForCatalog: function(orgId, catalogType) {
		var __self = this, prevId;

		if (catalogType === "DELCAT") {
			prevId = __self.relationsFromDelcatByOrgId;
			__self.relationsFromDelcatByOrgId = orgId;
		}
		else if (catalogType === "LOCCAT") {
			prevId = __self.relationsFromLoccatByOrgId;
			__self.relationsFromLoccatByOrgId = orgId;
		}

		if (this.selectBtn) {
			this.selectBtn.setDisabled(!orgId);
			if (__self.selectedOrgValues && (!orgId || prevId != orgId)) {
				__self.selectedOrgValues = undefined;
				__self.selectedOrg = null;
				__self.setValues({});
				__self.resetBtn ? __self.resetBtn.setDisabled(true) : null;
			}
		}
	},
	/**
	 * set org id for delcat select
	 * @param    {String}     id
	 */
	setOrgIdForDelcat: function(id) {
		this.setOrgIdForCatalog(id, "DELCAT");
	},
	/**
	 * Returns currently set org id for delcat usage
	 * @returns {String|*}
	 */
	getOrgIdForDelcat: function() {
		return this.relationsFromDelcatByOrgId;
	},
	showModalDelcatSelect: function() {
		var __self = this;

		createModalCatalogSelect(__self.relationsFromDelcatByOrgId, function(org) {
			__self.setOrganization(org);
		}, "delcat", __self.ownCatalog);
	},
	/**
	 * set org id for loccat select
	 * @param    {String}     id
	 */
	setOrgIdForLoccat: function(id) {
		this.setOrgIdForCatalog(id, "LOCCAT");
	},
	/**
	 * Returns currently set org id for loccat usage
	 * @returns {String|*}
	 */
	getOrgIdForLoccat: function() {
		return this.relationsFromLoccatByOrgId;
	},
	showModalRemoteSelect: function() {
		var __self = this;
		if ("function" == typeof __self.createModalRemoteSelect) {
			__self.createModalRemoteSelect(__self.relationsRemoteURL, function(org) {
				__self.setOrganization(org);
			}, "object" == typeof __self.createModalRemoteSelectOptions ? Ext.apply({}, __self.createModalRemoteSelectOptions) : __self.createModalRemoteSelectOptions);
		}
	},
	showModalLoccatSelect: function() {
		var __self = this;

		createModalCatalogSelect(__self.relationsFromLoccatByOrgId, function(org) {
			__self.setOrganization(org);
		}, "loccat", __self.ownCatalog);
	},
	showModalCompanySelect: function() {
		var __self = this;
		createModalRelationSelect(__self.relations, function(org) {
			__self.setOrganization(org);
		});
	},
	/**
	 * convert organization values  to values suitable for control. To be replaced by exact methods for exact instances.
	 * @param    {Object}    org
	 * @returns    {Object}
	 */
	processCompanyValues: function(org) {
		var __self = this, values;

		values = __self.processValues ? __self.processValues(org) : edi.converters.convertOrgToPartie(org);
		__self.presetCompanyAndAddressTypes(values);
		return values;
	},

	/**
	 * Sets company type & address type if it is missing in parameters
	 * @param values
	 */
	presetCompanyAndAddressTypes: function(values) {
		var __self = this, isNewOrg = __self.isEmptyValues();

		if ("object" !== typeof values) {
			return;
		}

		var countriesStore = edi.stores.initCountryFullStore(), record = null;

		// legacy docs
		if (values["addr_country"] && !values["addr_country_name"]) {
			record = countriesStore.findRecord("iso_2", values["addr_country"]);

			if (record) {
				values["addr_country_name"] = record.get("name");
			}
		}

		//fns docs - foreign
		if (values["addr_for_country"] && !values["addr_for_country_name"]) {
			record = countriesStore.findRecord("iso_number_3", values["addr_for_country"]);
			if (record) {
				values["addr_for_country_name"] = record.get("name");
			}
		}

		//fns docs - russian
		if (values["addr_rus_region"] && !values["addr_for_country"]) {
			record = countriesStore.findRecord("iso_2", edi.constants.DEFAULT.COUNTRY);
			if (record) {
				values["addr_country_name"] = record.get("name");
			}
		}

		if (0 !== values["address_type"] && 1 !== values["address_type"] && 2 !== values["address_type"]) {
			var addrType = 0;
			if (!isNewOrg) { //Russian address by default for new org
				var country = values["addr_country"], fnsForeignCountry = values["addr_for_country"],
					garAddr = values["addr_code_gar"],
					isLegacyAddressForeign = country && country !== edi.constants.DEFAULT.COUNTRY_ISO_3,
					isForeignAddress = isLegacyAddressForeign || !!fnsForeignCountry;
				if (isForeignAddress) {
					addrType = 1
				}

				if (!isForeignAddress) {
					addrType = 0
				}

				if (garAddr) {
					addrType = 2
				}
			}

			values["address_type"] = addrType;
		}

		if (values["address_type"] == 0 || (values["addr_country"] === edi.constants.DEFAULT.COUNTRY)) {
			var regionCode = values["addr_rus_region"] || values["addr_region_code"];
			if (regionCode) {
				var regionsStore = edi.stores.initRegionsStore();
				record = regionsStore.findRecord("id", regionCode);
				if (record) {
					values["addr_region_name"] = record.get("name");
				}
			}

		}

		if (!Ext.Array.contains(["companyNotRegistered", "legalForeigner", "individual", "company", "individualParticipant"], values["type"])) {
			var value = "company"; // for new org
			if (!__self.isEmptyValues()) {
				if (values["foreign_name"]) {
					value = "legalForeigner"
				}
				if (values["individual_inn"]) {
					value = "individual"
				}
				if (values["company_name"]) {
					value = "company"
				}
				if (values["physical_firstname"]) {
					value = "individualParticipant"
				}
				if (values["notregistered_name"]) {
					value = "companyNotRegistered"
				}
			}

			values["type"] = value;
		}
	},

	/**
	 * Set organization to selected relation & disable buttons
	 * @param org
	 * @param callback
	 */
	setOrganization: function(org, callback) {
		var __self = this,
			continueSetOrganization = function () {
				var values = __self.processCompanyValues(org);
				__self.selectedOrgValues = values;
				__self.selectedOrg = org;

				__self.setValues(values);
				__self.editBtn ? __self.editBtn.setDisabled(false) : null;
				__self.resetBtn ? __self.resetBtn.setDisabled(false) : null;
				"function" == typeof callback ? callback() : null;
			};

		if (__self.downloadBankAccountsData && org.id && (!org.accounts || !org.accounts.length)) {
			__self.setDisabled(true);
			edi.rest.sendRequest(edi.utils.formatString(edi.rest.services.USER.BANK_ACCOUNTS.GET, {orgId: org.id}),
				"GET", undefined,
				function(data) {
					if (data && data.items) {
						org.accounts = data.items;
					}
				}, undefined, function () {
					__self.setDisabled(false);
					continueSetOrganization();
				});
		} else {
			continueSetOrganization();
		}
	},

	preConvertFormValues: function(values) {
		var deleteIPValues = function() {
			delete values.individual_lastname;
			delete values.individual_firstname;
			delete values.individual_patronymicname;
			delete values.individual_inn;
			delete values.individual_certificateRegistrationIP;
			delete values.individual_company_name;
		};
		var deleteIParticipantValues = function() {
			delete values.physical_lastname;
			delete values.physical_firstname;
			delete values.physical_patronymicname;
		};
		var deleteLegalValues = function() {
			delete values.company_inn;
			delete values.company_kpp;
		};
		var deleteNonLegalValues = function() {
			delete values.foreign_name;
			delete values.foreign_info;
			delete values.notregistered_name;
			delete values.notregistered_info;
		};
		switch (values.type) {
			case 'company':
				deleteIPValues();
				deleteNonLegalValues();
				deleteIParticipantValues();
				break;
			case 'individual':
				deleteLegalValues();
				deleteNonLegalValues();
				deleteIParticipantValues();
				break;
			case 'individualParticipant':
				deleteIPValues();
				deleteLegalValues();
				deleteNonLegalValues();

				delete values.company_name;
				break;
			case 'legalForeigner':
				deleteIPValues();
				deleteLegalValues();
				deleteIParticipantValues();

				delete values.company_name;
				delete values.notregistered_name;
				delete values.notregistered_info;
				break;
			case 'companyNotRegistered':
				deleteIPValues();
				deleteLegalValues();
				deleteIParticipantValues();

				delete values.company_name;
				delete values.foreign_name;
				delete values.foreign_info;
				break;
		}
	},
	/**
	 * set values and update validity and details
	 * @param    {Object}    values
	 */
	setValues: function(values) {
		var relation = this.selectedOrgValues || {};
		this.ownBtn ? this.ownBtn.setDisabled(false) : null;
		this.partnerBtn ? this.partnerBtn.setDisabled(false) : null;
		var allFieldsAreEmpty = true;
		for (var i in values) {
			if (values.hasOwnProperty(i)) {
				if (values[i]) {
					allFieldsAreEmpty = false;
				}
				else if (null === values[i]) {
					values[i] = undefined;
					//Needed to correctly work with ext applyIf method
				}
			}
		}
		if (this.allowBlank && allFieldsAreEmpty) {
			this.is_valid = true;
		}
		else {
			this.is_valid = !allFieldsAreEmpty;
		}

		this.fieldValues = values;
		Ext.applyIf(this.fieldValues, relation);
		Ext.applyIf(this.fieldValues, this.defaultValues);
		this.preConvertFormValues(this.fieldValues);
		this.clearNotInMapValues();
		this.presetCompanyAndAddressTypes(this.fieldValues);
		this.hasData = !this.isEmptyValues();
		this.renderInfoPanelContents(true);

		var objValid = {};
		if (!this.disableAutoValidation) {
			var allMandatoryFieldsAreOk = true;
			for (i in this.fieldValues) {
				if (this.fieldValues.hasOwnProperty(i)) {
					objValid[i] = !(this.mandatoryFields[i] && !this.fieldValues[i]);
					if (allMandatoryFieldsAreOk && !objValid[i]) {
						allMandatoryFieldsAreOk = false;
					}
				}
			}
			if (!allMandatoryFieldsAreOk) {
				this.is_valid = false;
			}
			this.markInvalid();
		}

		this.callback(values, this.selectedOrg, objValid);
	},

	/**
	 * Renders control data summary
	 * @param    {Boolean}    updateButton    true to update change button label text
	 */
	renderInfoPanelContents: function(updateButton) {
		var __self = this, isEmpty = this.isEmptyValues(), originalValues = this.fieldValues, fieldValues;

		__self.infoPanel.removeAll();
		__self.presetCompanyAndAddressTypes(originalValues);
		fieldValues = edi.utils.stringifyObjectFields(originalValues);

		var emptyTemplate = "<span class='edi-empty-label'>" + edi.i18n.getMessage('value.not.specified') + "</span>";

		var i, hidden;
		if (Ext.isArray(__self.hideInTemplate) && __self.hideInTemplate.length) {
			for (i = 0; i < __self.hideInTemplate.length; i++) {
				hidden = __self.hideInTemplate[i];
				if (fieldValues.hasOwnProperty(hidden)) {
					fieldValues[hidden] = "";
				}
			}
		}

		let tmpFieldValues = Ext.clone(fieldValues);

		Object.entries(tmpFieldValues).forEach(([key, value]) => {
			tmpFieldValues[key] = edi.utils.safeString(value);
		});

		__self.infoPanel.add({
			html: isEmpty && __self.readOnly ? emptyTemplate : __self.infoPanelComponentTpl.apply(tmpFieldValues),
			xtype: "container"
		});

		if (__self.useHiddenFields && __self.fieldsMap) {
			for (i in __self.fieldsMap) {
				if (__self.fieldsMap.hasOwnProperty(i)) {
					var name = (__self.fieldsMap[i].name ? __self.fieldsMap[i].name : __self.fieldsMap[i]) || '__UnknownFieldName__';
					var field = __self.down("hiddenfield[name=" + name + "]");
					if (field) {
						field.setValue(__self.fieldValues[i]);
					}
				}
			}
		}

		if (updateButton && this.editBtn) {
			var isEdit = !isEmpty || this.relationsOnly,
				editBtnText = edi.i18n.getMessage(isEdit ? 'form.btn.edit' : 'form.btn.add'),
				editBtnGlyph = isEdit ? edi.constants.ICONS.EDIT : edi.constants.ICONS.ADD;

			edi.constants.USE_TOOLTIPS ? (this.editBtn.ttip = editBtnText) : this.editBtn.setText(editBtnText);
			__self.editBtn.setGlyph(editBtnGlyph);

			this.editBtn.removeCls('test-companySelector-btn-' + (isEdit ? 'add' : 'edit'));
			this.editBtn.addCls('test-companySelector-btn-' + (isEdit ? 'edit' : 'add'));

			__self.resetBtn ? __self.resetBtn.setDisabled(isEmpty) : null;
			if (__self.relationsOnly) {
				__self.editBtn.setDisabled(isEmpty);
			}
		}

		__self.markEmpty();
		__self.markReadOnly();
	},
	/**
	 * Returns control values collected from modal form
	 * @param    {Boolean}    useMap    true to returnd values mapped to passed fields schema
	 */
	getValues: function(useMap) {
		if (this.fieldsMap && useMap) {
			return this.getValuesByMap();
		}
		else {
			return Ext.clone(this.fieldValues);
		}
	},
	/**
	 * Returns values according to components fields map
	 */
	getValuesByMap: function() {
		var values = null;
		if (this.fieldsMap) {
			values = {};
			for (var i in this.fieldsMap) {
				if (this.fieldsMap.hasOwnProperty(i) && this.fieldValues[i] !== "") {
					if ("object" == typeof this.fieldsMap[i]) {
						values[this.fieldsMap[i].name] = this.fieldValues[i];
					}
					else {
						values[this.fieldsMap[i]] = this.fieldValues[i];
					}
				}
			}
		}
		return values;
	},
	/**
	 * Returns validation status of current control and sets or remove invalid class
	 * @param    {Boolean}    disableMarkInvalid        True to NOT underline selector by red line
	 * @returns    {Boolean}
	 */
	isValid: function(disableMarkInvalid) {
		if (!disableMarkInvalid) {
			this.markInvalid();
		}
		return this.is_valid;
	},
	/**
	 * Returns is component not filled
	 * @returns    {Boolean}
	 */
	isEmptyValues: function() {
		var res = true;
		if (this.fieldValues) {
			for (var i in this.defaultValues) {
				if (this.defaultValues.hasOwnProperty(i)) {
					if (i === "address_type" || i === "type") {
						continue;
					}
					if (this.defaultValues[i] != this.fieldValues[i]) {
						res = false;
						break;
					}
				}
			}
		}
		return res;
	},
	/**
	 * Sets or remove empty class from component
	 */
	markEmpty: function() {
		var isEmpty = this.isEmptyValues();
		if (isEmpty && !this.panel.hasCls(this.emptyClass)) {
			this.panel.addCls(this.emptyClass);
		}
		else if (!isEmpty && this.panel.hasCls(this.emptyClass)) {
			this.panel.removeCls(this.emptyClass);
		}
	},
	/**
	 * Sets or remove empty class from component
	 */
	markReadOnly: function() {
		if (this.readOnly && !this.panel.hasCls(this.readOnlyClass)) {
			this.panel.addCls(this.readOnlyClass);
		}
		else if (!this.readOnly && this.panel.hasCls(this.readOnlyClass)) {
			this.panel.removeCls(this.readOnlyClass);
		}
	},
	/**
	 * Sets or remove invalid class from component
	 */
	markInvalid: function() {
		if (!this.is_valid && !this.panel.hasCls(this.invalidClass)) {
			this.panel.addCls(this.invalidClass);
		}
		else if (this.is_valid && this.panel.hasCls(this.invalidClass)) {
			this.panel.removeCls(this.invalidClass);
		}
	},
	/**
	 * check is field in selected relation
	 * @param    {String}    name    field name
	 * @returns    {Boolean}
	 */
	inSelectedRelation: function(name) {
		return this.selectedOrgValues && this.selectedOrgValues[name];
	},
	/**
	 * Fills fieldsConf.exclude by values that not in include (if include is defined in fieldsConf)
	 */
	setIncluded: function() {
		var i;
		if (this.fieldsMapOnly) {
			this.fieldsConf.include = [];
			for (i in this.fieldsMap) {
				if (this.fieldsMap.hasOwnProperty(i)) {
					this.fieldsConf.include.push(i);
				}
			}
		}
		if (this.fieldsConf.include && this.fieldsConf.include.length) {
			for (i in this.defaultValues) {
				if (this.defaultValues.hasOwnProperty(i) && i !== "addr_country_name") {
					var included = false;
					for (var j = 0; j < this.fieldsConf.include.length; j++) {
						if (this.fieldsConf.include[j] === i) {
							included = true;
							break;
						}
					}
					if (!included) {
						if (!this.fieldsConf.exclude) {
							this.fieldsConf.exclude = [];
						}
						this.fieldsConf.exclude.push(i);
					}
				}
			}
		}
	},
	/**
	 * Fills excluded fields name map for quick access
	 */
	setExcludeMap: function() {
		this.excludeMap = this.excludeMap || {};
		if (this.fieldsConf.exclude && this.fieldsConf.exclude.length) {
			for (var i = 0; i < this.fieldsConf.exclude.length; i++) {
				this.excludeMap[this.fieldsConf.exclude[i]] = true;
			}
		}
	},

	/**
	 * Hides and disables all fields that are excluded
	 */
	hideExcludedFields: function() {
		var __self = this;

		var setDisabledField = function(field, disable) {
			field = "object" == typeof field ? field : __self.formPanel.down("field[name=" + field + "]");
			if (field && "function" == typeof field.getName) {
				var name = field.getName();
				if (name) {
					var inRel = __self.inSelectedRelation(name),
						allowChangeField = __self.forceChangeAnyField || __self.forceChangeFields?.hasOwnProperty(name);

					disable = allowChangeField ? false : disable;

					field.setDisabled(allowChangeField ? false : (disable || inRel));
					var container = field.up("panel[cls=" + __self.fieldBlockCls + "]", 3);
					if (container && "function" == typeof container.setVisible) {
						container.setVisible(!disable);
					}
				}
			}
		};

		for (var i in this.excludeMap) {
			if (this.excludeMap.hasOwnProperty(i)) {
				setDisabledField(i, true);
			}
		}
	},

	isModalReadOnly() {
		let __self = this;
		return !!__self.modalConf?.readOnly;
	},

	createTextFieldCmp: function(config) {
		let __self = this;
		var fieldName = this.getObjectProperty(config, "name");

		if (!fieldName) {
			return null;
		}

		var panelConf = {
			title: config.title,
			panelConf: config.panelConf || {},
			labelConf: config.labelConf || {}
		};

		delete config.type;
		delete config.title;
		delete config.panelConf;
		delete config.labelConf;

		if (config.hasOwnProperty("emptyText")) {
			config.emptyText = edi.i18n.getMessage(config.emptyText);
		}

		if (config.hasOwnProperty("regexText")) {
			config.regexText = edi.i18n.getMessage(config.regexText);
		}

		Ext.applyIf(panelConf.labelConf, {
			margin: "5 0 0",
			columnWidth: 0.3
		});

		var allowForceChange = __self.forceChangeAnyField || __self.forceChangeFields?.hasOwnProperty(fieldName);
		var relation = __self.selectedOrgValues || {};
		Ext.applyIf(config, {
			columnWidth: 0.7,
			value: __self.fieldValues[fieldName] || config.defaultValue,
			allowBlank: !__self.mandatoryFields[fieldName],
			mandatory: !!__self.mandatoryFields[fieldName],
			disabled: (!!relation[fieldName] && !allowForceChange) || __self.isModalReadOnly(),
			modalInstance: __self,
			enforceMaxLength: config.hasOwnProperty("maxLength")
		});

		delete config.defaultValue;

		Ext.applyIf(panelConf.panelConf, {
			layout: 'column',
			margin: "0 0 5",
			cls: __self.fieldBlockCls
		});

		panelConf.input = createTextField(config);
		return createInput(panelConf);
	},

	createComboboxCmp: function(config, comboType) {
		config = config || {};
		let __self = this;
		var labelConf = config.labelConfig || {}, panelConf = config.panelConf || {},
			comboConf = config.fieldConfig || {}, fieldName = comboConf.name, storeConf = null,
			isAutocomplete = comboType === "autocomplete", combo, storeMethod = comboConf.store;

		if ('string' == typeof storeMethod && 'function' === typeof edi.stores[storeMethod]) {
			comboConf.store = edi.stores[storeMethod]();
		}

		if (isAutocomplete) {
			storeConf = Ext.clone(comboConf.store);
			if ('string' == typeof storeConf.model) {
				storeConf.model = edi.models.getModel(storeConf.model);
			}

			delete comboConf.store;
		}
		else {
			var allowForceChange = __self.forceChangeAnyField || __self.forceChangeFields?.hasOwnProperty(fieldName);
			var relation = __self.selectedOrgValues || {};
			Ext.applyIf(comboConf, {
				columnWidth: 0.7,
				forceSelection: true,
				valueField: "id",
				anyMatch: true,
				disabled: (!!relation[fieldName] && !allowForceChange) || __self.isModalReadOnly(),
				value: __self.fieldValues[fieldName],
				modalInstance: __self
			})
		}

		if (config.hasOwnProperty('title')) {
			Ext.applyIf(labelConf, {
				html: edi.i18n.getMessage(config.title)
			});
		}

		combo = isAutocomplete
			? createAutocomplete(comboConf, storeConf)
			: createCombo(comboConf);

		Ext.applyIf(panelConf, {
			layout: 'column',
			margin: "0 0 5",
			cls: __self.fieldBlockCls
		});

		Ext.applyIf(labelConf, {
			columnWidth: 0.3
		});

		panelConf.items = [
			createLabel(labelConf),
			combo
		];

		return createPanel(panelConf);
	},

	createDateFieldCmp: function(config) {
		let __self = this;
		var fieldConfig = Ext.clone(config), fieldName = this.getObjectProperty(fieldConfig, "name");

		if (!fieldName) {
			return null;
		}

		var createPanel = !config.hasOwnProperty('labelConf') || !!config.labelConf;
		createPanel |= !config.hasOwnProperty('panelConf') || !!config.panelConf;

		if (createPanel) {
			config.labelConf = config.labelConf || {};
			Ext.applyIf(config.labelConf, {
				margin: "5 0 0",
				columnWidth: 0.3
			});

			config.panelConf = config.panelConf || {};
			Ext.applyIf(config.panelConf, {
				layout: 'column',
				margin: "0 0 5",
				cls: __self.fieldBlockCls
			});
		}

		Ext.applyIf(fieldConfig, {
			columnWidth: 0.7,
			value: __self.fieldValues[fieldName]
		});

		if (fieldConfig.hasOwnProperty('emptyText')) {
			fieldConfig.emptyText = edi.i18n.getMessage(fieldConfig.emptyText);
		}

		var field = createDateField(fieldConfig);
		if (createPanel) {
			config.input = field;
			field = createInput(config);
		}

		return field;
	},

	createFieldSetCmp: function(config) {
		let __self = this;
		var fieldsetConf = Ext.apply({
			cls: "x-fieldset-party",
			margin: "10 0 0"
		}, config.fieldConfig);

		if (config.hasOwnProperty('title')) {
			fieldsetConf.title = edi.i18n.getMessage(config.title);
		}

		fieldsetConf = Ext.applyIf(fieldsetConf, config.panelConf); // To apply hidden property if needed
		fieldsetConf.items = __self.createFieldsArray(config.fields);

		return createFieldSet(fieldsetConf);
	},

	createCheckboxCmp: function(config) {
		let __self = this;
		var fieldName = config.fieldConfig.name;

		if (!fieldName) {
			return null;
		}

		var checkboxConf = Ext.apply({
			margin: "10 0 0",
			checked: __self.fieldValues[fieldName]
		}, config.fieldConfig);

		if (config.hasOwnProperty('title')) {
			checkboxConf.title = edi.i18n.getMessage(config.title);
		}

		checkboxConf = Ext.applyIf(checkboxConf, config.panelConf); // To apply hidden property if needed
		checkboxConf.items = __self.createFieldsArray(config.fields);
		checkboxConf.input = createCheckbox(checkboxConf);
		return createInput(checkboxConf);
	},

	createPanelCmp: function(config) {
		let __self = this;
		var conf = Ext.clone(config);
		var panelConf = Ext.apply({
			layout: 'column',
			margin: "0 0 5",
			cls: __self.fieldBlockCls
		}, conf.panelConf);

		panelConf.items = __self.createFieldsArray(conf.fields);
		return createPanel(panelConf);
	},

	createSelectCmp: function(config) {
		let __self = this;
		config = config || {};

		var labelConf = config.labelConfig || {}, panelConf = config.panelConf || {},
			selectConf = config.fieldConfig || {}, fieldName = selectConf.name, select, genUrl,
			allowForceChange = __self.forceChangeAnyField || __self.forceChangeFields?.hasOwnProperty(fieldName),
			isMandatory = !!labelConf.mandatory;
		var relation = __self.selectedOrgValues || {};
		Ext.applyIf(selectConf, {
			columnWidth: 0.7,
			isOnlyInput: true,
			forceSelection: true,
			valueField: "id",
			anyMatch: true,
			allowBlank: config.allowBlank,
			disabled: (!!relation[fieldName] && !allowForceChange) || __self.isModalReadOnly(),
			value: __self.fieldValues[fieldName],
			modalInstance: __self,
			createURL: function() {
				return genUrl || config.url;
			}
		});

		if (config.hasOwnProperty('title')) {
			Ext.applyIf(labelConf, {
				html: edi.i18n.getMessage(config.title) + (isMandatory ? " <span class='edi-mandatory'>*</span>" : "")
			});
		}

		if (relation && relation.id && "function" == typeof config.onCreate) {
			config.onCreate(function(url) {
				genUrl = url;
			}, relation);
		}

		select = createPanelWithModalSelect(selectConf);
		select.setUrl = function(url) {
			genUrl = url;
		};

		Ext.applyIf(panelConf, {
			layout: 'column',
			margin: "0 0 5",
			cls: __self.fieldBlockCls
		});

		Ext.applyIf(labelConf, {
			columnWidth: 0.3
		});

		panelConf.items = [
			createLabel(labelConf),
			select
		];

		return createPanel(panelConf);
	},

	createEditableGrid: function(config) {
		let __self = this;
		var fieldConfig = Ext.clone(config), fieldName = edi.utils.getObjectProperty(fieldConfig, "name");

		if (!fieldName) {
			return null;
		}
		__self.formGrids[fieldName] = createEditableGrid(fieldConfig.config, __self.fieldValues[fieldName]);
		return __self.formGrids[fieldName];
	},

	createFieldCmp: function(config) {
		let __self = this;
		var fieldConstructors = {
			'text': __self.createTextFieldCmp,
			'combo': __self.createComboboxCmp,
			'checkbox': __self.createCheckboxCmp,
			'select': __self.createSelectCmp,
			'number': __self.createTextFieldCmp,
			'autocomplete': __self.createComboboxCmp,
			'panel': __self.createPanelCmp,
			'fieldset': __self.createFieldSetCmp,
			'date': __self.createDateFieldCmp,
			'editableGrid': __self.createEditableGrid
		};
		var field = null, fieldType = Ext.clone(config.type) || 'text', constructor = fieldConstructors[fieldType];

		delete config.type;

		if ("function" == typeof constructor) {
			field = constructor.call(__self, config, fieldType);
		}
		else {
			edi.core.showWarn('Modal field type [' + fieldType + '] not recognized!');
		}

		if (field) {
			field.modalInstance = __self;
		}
		return field;
	},

	createFieldsArray: function(fieldsConfig) {
		let __self = this;
		var fields = [], fieldsConf = [];
		var relation = __self.selectedOrgValues || {};
		for (var i in fieldsConfig) {
			if (!fieldsConfig.hasOwnProperty(i) || !fieldsConfig[i]) {
				continue;
			}

			var conf = Ext.clone(fieldsConfig[i]) || {}, inputConfig = conf.fieldConfig ? conf.fieldConfig : conf,
				field = null;

			if ("function" == typeof inputConfig.value) {
				inputConfig.value = inputConfig.value(__self.fieldValues);
			}

			if ("function" == typeof inputConfig.disabled) {
				inputConfig.disabled = inputConfig.disabled(relation);
			}

			if ("function" == typeof conf.hidden) {
				conf.panelConf = conf.panelConf || {};
				conf.panelConf.hidden = conf.hidden(__self.fieldValues);
			}

			fieldsConf.push(conf);
			fieldsConf.sort(function(a, b) {
				return (a.order || 0) - (b.order || 0)
			});
		}

		for (var j = 0; j < fieldsConf.length; j++) {
			field = __self.createFieldCmp.call(__self, fieldsConf[j]);

			if (field && "function" == typeof fieldsConf[j].onCreate) {
				fieldsConf[j].onCreate(field, relation);
			}

			fields.push(field);
		}

		edi.utils.clearEmptyArrayValues(fields);
		return fields;
	},

	createTabCmp: function(config) {
		let __self = this;
		if (!config) {
			return null;
		}

		var tabConfig = config.tabConfig;
		delete config.tabConfig;

		if (edi.utils.isEmptyObject(config)) {
			return null;
		}

		Ext.applyIf(tabConfig, {
			panelType: 'tabpanel',
			closable: false,
			bodyPadding: 10,
			autoScroll: true
		});

		tabConfig.items = __self.createFieldsArray(config);
		return createTab(tabConfig);
	},

	createModalTabPanel: function() {
		let __self = this;
		let tabsConfig = __self.modalConf ? __self.modalConf.tabs : {};
		let tabs = [];
		for (var i in tabsConfig) {
			if (tabsConfig.hasOwnProperty(i) && tabsConfig[i]) {
				let conf = Ext.clone(tabsConfig[i]);
				conf.tabConfig = conf.tabConfig || {};
				let title = conf.title || ("company.tab." + (i === 'main' ? 'registry' : i) + ".data");
				conf.tabConfig.title = edi.i18n.getMessage(title);
				tabs.push(__self.createTabCmp(conf));
			}
		}

		edi.utils.clearEmptyArrayValues(tabs);
		__self.modalTabPanel = createTabPanel({
			items: tabs
		});

		return __self.modalTabPanel;
	},

	focusOnInvalidField: function() {
		let __self = this;
		if (__self.modalWindow?.isVisible()) {
			let isValidForm = true;
			let fields = __self.formPanel.getForm().getFields().items;
			for (let i = 0, len = fields.length; i < len; ++i) {
				let field = fields[i];
				if (!field.isValid() && field.isVisible() && !field.isDisabled()) {
					isValidForm = false;
					let tab = field.up('panel[panelType="tabpanel"]');
					__self.modalTabPanel.setActiveTab(tab);
					setTimeout(function() {
						field.focus();
					}, 0);
					break;
				}
			}
			//editableGrid
			if (isValidForm) {
				for (var key in __self.formGrids) {
					if (__self.formGrids.hasOwnProperty(key)) {
						if (!__self.formGrids[key].isValid()) {
							let tab = __self.formGrids[key].up('panel[panelType="tabpanel"]');
							__self.modalTabPanel.setActiveTab(tab);
							setTimeout(function() {
								__self.formGrids[key].focus();
							}, 0);
							break;
						}
					}
				}
			}
		}
	},

	createModalFormPanel: function() {
		var __self = this;
		__self.formGrids = {};
		__self.formPanel = createForm({
			cls: "edi-form",
			layout: "fit",
			submitEmptyText: false,
			items: [__self.createModalTabPanel()]
		});
		return __self.formPanel;
	},

	/**
	 * Creates modal company data form
	 */
	showModalCompanyControl: function() {
		let __self = this;
		let formPanel = __self.createModalFormPanel();

		__self.hideExcludedFields();
		var saveBtn = __self.isModalReadOnly() ? null : createSaveButton(function() {
			if (__self.checkFormValid()) {
				var values = edi.utils.collectFormValues(formPanel);
				for (var key in __self.formGrids) {
					if (__self.formGrids.hasOwnProperty(key)) {
						edi.utils.setObjectProperty(values, key, edi.utils.getDataFromGrid(__self.formGrids[key]));
					}
				}
				__self.setValues(values);
				__self.modalWindow?.close();
			}
			else {
				setTimeout(function() {
					__self.focusOnInvalidField()
				}, 100);
			}
		}, {
			formBind: false,
			disabled: false
		});

		var modalConf = Ext.apply({
			cls: "edi-modal-form  edi-modal-company-data-selector",
			title: edi.i18n.getMessage('modal.company.data'),
			width: edi.constants.DEFAULT.MODAL.WIDTH_LARGER,
			maxHeight: 500,
			items: [formPanel],
			buttonsBefore: [saveBtn]
		}, __self.modalConf);

		delete modalConf.tabs;

		if (modalConf.title) {
			modalConf.title = edi.i18n.getMessage(this.modalConf.title);
		}

		__self.modalWindow = createModalPanel(modalConf);
		__self.modalWindow.show();
		formPanel.isValid();

		setTimeout(function() {
			__self.focusOnInvalidField()
		}, 100);

		return __self.modalWindow;
	},

	checkFormValid: function() {
		var __self = this, values = edi.utils.collectFormValues(__self.formPanel), allFieldsEmpty = true;
		for (var i in values) {
			if (values.hasOwnProperty(i) && values[i]) {
				allFieldsEmpty = false;
				break;
			}
		}

		var formValid = __self.formPanel.isValid();
		var gridValid = true;
		//editableGrid
		for (var key in __self.formGrids) {
			if (__self.formGrids.hasOwnProperty(key)) {
				if (!__self.formGrids[key].isValid()) {
					gridValid = false;
					break;
				}
			}
		}

		return (__self.allowBlank && allFieldsEmpty) || (formValid && gridValid);
	},

	/**
	 * Checks orgData on modalForm validity
	 * @returns	{Boolean}	validity
	 */
	checkOrgDataValid: function() {
		let __self = this;
		let formPanel = __self.createModalFormPanel();

		let values = edi.utils.collectFormValues(formPanel);
		let fields = formPanel.getForm().getFields();
		fields?.items.forEach(field => {
			field.fireEvent('change', field, field.getValue());
		});

		let allFieldsEmpty = true;
		for (let i in values) {
			//игнорируем поле Тип, т.к. это чисто ui для определения типа адреса (юридический\почтовый\фактический)
			if (values.hasOwnProperty(i) && i !== 'type' && values[i]) {
				allFieldsEmpty = false;
				break;
			}
		}

		let formValid = formPanel.isValid();
		var gridValid = true;

		//editableGrid
		for (var key in __self.formGrids) {
			if (__self.formGrids.hasOwnProperty(key)) {
				if (!__self.formGrids[key].isValid()) {
					gridValid = false;
					break;
				}
			}
		}
		let valid = (__self.allowBlank && allFieldsEmpty) || (formValid && gridValid);

		__self.is_valid = valid;
		__self.isValid();
		return valid;
	},

	getInfoPanelComponentTpl: function() {
		let __self = this;
		if (!__self.infoPanelComponentTpl) {
			__self.infoPanelComponentTpl = new Ext.XTemplate(
					//Legal company name
					'<tpl if="type == \'company\'">',
					'<div class="edi-company-name">{company_name}&nbsp;</div>',
					'<tpl elseif="type == \'individual\'">',
					//Individual company name
					'<tpl if="individual_company_name.length &gt; 0">',
						'<div class="edi-company-name">',
							'<span class="row-data edi-company-individual-company-name">{individual_company_name}</span>&nbsp;',
						'</div>',
					'<tpl elseif="individual_lastname.length &gt; 0 || individual_firstname.length &gt; 0 || individual_patronymicname.length &gt; 0">',
						'<div class="edi-company-name">',
							'<span class="row-data edi-company-individual-lastname">{individual_lastname}</span>',
							'<span class="row-data edi-company-individual-firstname"> {individual_firstname}</span>',
							'<span class="row-data edi-company-individual-patronymicname"> {individual_patronymicname}</span>&nbsp;',
						'</div>',
					'</tpl>',

					'<tpl if="individual_inn.length &gt; 0">',
					'<div class="edi-company-row"><span class="code">' + edi.i18n.getMessage('company.inn.short') + ':</span><span class="row-data edi-company-individual-inn"> {individual_inn}</span></div>',
					'</tpl>',

					'<tpl if="individual_certificateRegistrationIP.length &gt; 0">',
					'<div class="edi-company-row"><span class="code">' + edi.i18n.getMessage('individual.certificateRegistration') + ':</span><span class="row-data edi-company-individual-registration"> {individual_certificateRegistrationIP}</span></div>',
					'</tpl>',

					'<tpl elseif="type == \'individualParticipant\'">',
					//Physical name
					'<tpl if="physical_lastname.length &gt; 0 || physical_firstname.length &gt; 0 || physical_patronymicname.length &gt; 0">',
					'<div class="edi-company-name">',
					'<span class="row-data edi-company-physical-lastname">{physical_lastname}</span>',
					'<span class="row-data edi-company-physical-firstname"> {physical_firstname}</span>',
					'<span class="row-data edi-company-physical-patronymicname"> {physical_patronymicname}</span>',
					'</div>',
					'</tpl>',

					'<tpl elseif="type == \'legalForeigner\'">',
					//Foreigner company name
					'<tpl if="foreign_name.length &gt; 0">',
					'<div class="edi-company-name">{foreign_name}</div>',
					'<tpl if="foreign_info.length &gt; 0">',
					'<div class="edi-company-row">',
					'<span class="code">' + edi.i18n.getMessage('company.type.legalForeigner.header.info') + ':</span> ',
					'<span class="row-data edi-company-address">{foreign_info}</span>',
					'</div>',
					'</tpl>',
					'<tpl if="foreign_identifier.length &gt; 0">',
					'<div class="edi-company-row">',
					'<span class="code">' + edi.i18n.getMessage('column.indentifier') + ':</span> ',
					'<span class="row-data edi-foreign-identifier">{foreign_identifier}</span>',
					'</div>',
					'</tpl>',
					'</tpl>',

					'<tpl elseif="type == \'companyNotRegistered\'">',
					//Not registered company name
					'<tpl if="notregistered_name.length &gt; 0">',
					'<div class="edi-company-name">{notregistered_name}</div>',
					'<tpl if="notregistered_info.length &gt; 0">',
					'<div class="edi-company-row">',
					'<span class="code">' + edi.i18n.getMessage('company.type.legalForeigner.header.info') + ':</span> ',
					'<span class="row-data edi-company-address">{notregistered_info}</span>',
					'</div>',
					'</tpl>',
					'</tpl>',
					'</tpl>',

					'<tpl if="company_inn.length &gt; 0 || company_kpp.length &gt; 0 || structural_subdivision.length &gt; 0">',
					'<div class="edi-company-row">',
					'<tpl if="company_inn.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.inn.short') + ':</span>',
					'<span class="row-data edi-company-inn"> {company_inn} </span>',
					'</tpl>',

					'<tpl if="company_kpp.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.kpp.short') + ':</span>',
					'<span class="row-data edi-company-kpp"> {company_kpp} </span>',
					'</tpl>',

					'<tpl if="structural_subdivision.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.structuralSubdivision') + ':</span>',
					'<span class="row-data edi-company-structural-subdivision"> {structural_subdivision} </span>',
					'</tpl>',
					'</div>',
					'</tpl>',

					'<tpl if="company_okpof.length &gt; 0">',
					'<div class="edi-company-row">',
					'<span class="code">' + edi.i18n.getMessage('company.okpof.short') + ':</span>',
					'<span class="row-data edi-company-okpof"> {company_okpof}</span>',
					'</div>',
					'</tpl>',

					// Registration data
					'<tpl if="company_iln.length &gt; 0 || company_util_reg_nr.length &gt; 0">',
					'<div class="edi-company-row">',
					'<tpl if="company_iln.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.gln.short') + ':</span> ',
					'<span class="row-data edi-company-gln">{company_iln}</span> ',
					'</tpl>',
					'<tpl if="company_util_reg_nr.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.util.reg.nr') + ':</span> ',
					'<span class="row-data edi-company-util-reg-nr">{company_util_reg_nr}</span> ',
					'</tpl>',
					'</div>',
					'</tpl>',
					'<tpl if="company_okpo.length &gt; 0">',
					'<div class="edi-company-row">',
					'<span class="code">' + edi.i18n.getMessage('company.okpo.short') + ':</span>',
					'<span class="row-data edi-company-okpo"> {company_okpo} </span>',
					'</div>',
					'</tpl>',

					// Address string
					'<tpl if="address.length &gt; 0 && this.isAddressNotGar(values)">',
						'<div class="edi-company-row">',
							'<span class="code">{[this.renderAddressCategory(values)]}:</span> ',
							'<span class="row-data edi-company-address">{address}</span>',
						'</div>',
					'<tpl elseif="this.isAddressNotGar(values)">',
						//Foreign address
						'<tpl if="addr_for_text.length &gt; 0">',
							'<div class="edi-company-row">',
								'<tpl if="addr_for_country_name.length &gt; 0">',
									'<span class="code">' + edi.i18n.getMessage('company.address') + ': </span>',
									'<tpl if="addr_for_country.length &gt; 0">',
										'<span class="row-data edi-company-addr-country">({addr_for_country})</span>',
										'<span class="row-data edi-company-addr-country-name"> {addr_for_country_name}</span>, ',
									'</tpl>',
								'</tpl>',
								'<span class="row-data edi-company-addr-text">{addr_for_text}</span>',
							'</div>',
						'<tpl else>',
							'<tpl if="this.allowCommonAddressRenderer(values)">',
								//COMMON ADDRESS BLOCK
								'<div class="edi-company-row">',
									'<span class="code edi-company-addr-header">{[this.renderAddressCategory(values)]} : </span>',
									'{[this.convertCommonAddress(values)]}',
								'</div>',
							'<tpl else>',
								// Legacy address block
								'<tpl if="addr_country.length &gt; 0 || addr_zip.length &gt; 0 || addr_area.length &gt; 0 || addr_block.length &gt; 0 || addr_city.length &gt;  0 || addr_street.length &gt; 0 || addr_home.length &gt; 0 || addr_flat.length &gt; 0">',
									'<div class="edi-company-row">',
										'<span class="code edi-company-addr-header">{[this.renderAddressCategory(values)]} : </span>',
										'<tpl if="addr_country_name.length &gt; 0">',
											'<span class="row-data edi-company-addr-country">{addr_country_name}</span>',
											'<tpl if="addr_zip.length &gt; 0 || addr_area.length &gt; 0 || addr_locality.length &gt; 0 || addr_block.length &gt; 0 || addr_city.length &gt;  0 || addr_street.length &gt; 0 || addr_home.length &gt; 0 || addr_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',

										'<tpl if="addr_zip.length &gt; 0">',
											'<span class="row-data edi-company-addr-zip">{addr_zip}</span>',
											'<tpl if="addr_area.length &gt; 0 || addr_locality.length &gt; 0 || addr_block.length &gt; 0 || addr_city.length &gt;  0 || addr_street.length &gt; 0 || addr_home.length &gt; 0 || addr_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',

										'<tpl if="addr_area.length &gt; 0">',
											'<span class="row-data edi-company-addr-county">{addr_area}</span>',
											'<tpl if="addr_block.length &gt; 0 || addr_locality.length &gt; 0 || addr_city.length &gt;  0 || addr_street.length &gt; 0 || addr_home.length &gt; 0 || addr_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',

										'<tpl if="addr_city.length &gt; 0">',
											'<span class="row-data edi-company-addr-city">{addr_city}</span>',
											'<tpl if="addr_block.length &gt; 0 || addr_locality.length &gt; 0 || addr_street.length &gt; 0 || addr_home.length &gt; 0 || addr_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',
										'<tpl if="addr_locality.length &gt; 0">',
											'<span class="row-data edi-company-addr-community">{addr_locality}</span>',
											'<tpl if="addr_block.length &gt;  0 || addr_street.length &gt; 0 || addr_home.length &gt; 0 || addr_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',

										'<tpl if="addr_street.length &gt; 0">',
											'<span class="row-data edi-company-addr-street">{addr_street}</span>',
											'<tpl if="addr_block.length &gt; 0 || addr_home.length &gt; 0 || addr_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',

										'<tpl if="addr_home.length &gt; 0">',
											' <span class="row-data edi-company-addr-home">{addr_home}</span>',
											'<tpl if="!addr_block.length &gt; 0 && addr_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',
										'<tpl if="addr_block.length &gt; 0">',
											' <span class="row-data edi-company-addr-block">{addr_block}</span>',
											'<tpl if="addr_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',
										'<tpl if="addr_flat.length &gt; 0">',
											'<span class="row-data edi-company-addr-flat">{addr_flat}</span>',
										'</tpl>',
									'</div>',
								'</tpl>',

								// FNS-documents address block
								'<tpl if="addr_rus_zip.length &gt; 0 || addr_rus_region.length &gt; 0 || addr_rus_county.length &gt; 0 || addr_rus_block.length &gt; 0 || addr_rus_city.length &gt;  0 || addr_rus_street.length &gt; 0 || addr_rus_home.length &gt; 0 || addr_rus_flat.length &gt; 0">',
									'<div class="edi-company-row">',
										'<span class="code">{[this.renderAddressCategory(values)]} : </span>',
										'<tpl if="addr_rus_zip.length &gt; 0">',
											'<span class="row-data edi-company-addr-zip">{addr_rus_zip}</span>',
											'<tpl if="addr_region_code.length &gt; 0 || addr_rus_county.length &gt; 0 || addr_rus_community.length &gt; 0 || addr_rus_block.length &gt; 0 || addr_rus_city.length &gt;  0 || addr_rus_street.length &gt; 0 || addr_rus_home.length &gt; 0 || addr_rus_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',
										'<tpl if="addr_rus_region.length &gt;0">',
											'<span class="row-data edi-company-addr-region">({addr_rus_region})</span>',
											'<span class="row-data edi-company-addr-region-name"> {addr_region_name}</span>',
											'<tpl if="addr_rus_county.length &gt; 0 || addr_rus_community.length &gt; 0 || addr_rus_block.length &gt; 0 || addr_rus_city.length &gt;  0 || addr_rus_street.length &gt; 0 || addr_rus_home.length &gt; 0 || addr_rus_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',
										'<tpl if="addr_rus_county.length &gt; 0">',
											'<span class="row-data edi-company-addr-county">{addr_rus_county}</span>',
											'<tpl if="addr_rus_block.length &gt; 0 || addr_rus_community.length &gt; 0 || addr_rus_city.length &gt;  0 || addr_rus_street.length &gt; 0 || addr_rus_home.length &gt; 0 || addr_rus_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',
										'<tpl if="addr_rus_city.length &gt; 0">',
											'<span class="row-data edi-company-addr-city">{addr_rus_city}</span>',
											'<tpl if="addr_rus_block.length &gt; 0 || addr_rus_community.length &gt;  0 || addr_rus_street.length &gt; 0 || addr_rus_home.length &gt; 0 || addr_rus_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',

										'<tpl if="addr_rus_community.length &gt; 0">',
											'<span class="row-data edi-company-addr-community">{addr_rus_community}</span>',
											'<tpl if="addr_rus_block.length &gt;  0 || addr_rus_street.length &gt; 0 || addr_rus_home.length &gt; 0 || addr_rus_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',
										'<tpl if="addr_rus_street.length &gt; 0">',
											'<span class="row-data edi-company-addr-street">{addr_rus_street}</span>',
											'<tpl if="addr_rus_block.length &gt; 0 || addr_rus_home.length &gt; 0 || addr_rus_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',
										'<tpl if="addr_rus_home.length &gt; 0">',
											' <span class="row-data edi-company-addr-home">{addr_rus_home}</span>',
											'<tpl if="addr_rus_block.length &gt; 0 || addr_rus_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',
										'<tpl if="addr_rus_block.length &gt; 0">',
											' <span class="row-data edi-company-addr-block">{addr_rus_block}</span>',
											'<tpl if="addr_rus_flat.length &gt; 0">',
												'<span>, <span>',
											'</tpl>',
										'</tpl>',
										'<tpl if="addr_rus_flat.length &gt; 0">',
											'<span class="row-data edi-company-addr-flat">{addr_rus_flat}</span>',
										'</tpl>',
									'</div>',
								'</tpl>',
							'</tpl>',
						'</tpl>',
					'</tpl>',

					//GAR
					'<tpl if="addr_code_gar.length &gt; 0 && !this.isAddressNotGar(values)">',
					'<div class="edi-company-row">',
					'<span class="code">' + edi.i18n.getMessage('company.addr.code.gar') + ':</span>',
					'<span class="row-data edi-company-gar"> {addr_code_gar}</span> ',
					'</div>',
					'</tpl>',


					//Bank data
					'<tpl if="bank_acc.length &gt; 0 || bank_name.length &gt; 0 || bank_id.length &gt; 0 || bank_corr_acc.length &gt; 0">',
					'<div class="edi-company-row">',
					'<tpl if="bank_id.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.bank.id') + ':</span>',
					'<span class="row-data edi-company-bank-id">{bank_id}</span> ',
					'</tpl>' +
					'<tpl if="bank_name.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.bank.name') + ':</span>',
					'<span class="row-data edi-company-bank-name">{bank_name}</span> ',
					'</tpl>',
					'<tpl if="bank_acc.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.bank.account') + ':</span>',
					'<span class="row-data edi-company-bank-acc">{bank_acc}</span> ',
					'</tpl>',
					'<tpl if="bank_corr_acc.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.bank.account.corr') + ':</span>',
					'<span class="row-data edi-company-bank-cor-acc">{bank_corr_acc}</span>',
					'</tpl>',
					'</div>',
					'</tpl>',

					//Operator data
					'<tpl if="operator_name.length &gt; 0 || operator_phone.length &gt; 0 || operator_email.length &gt; 0">',
					'<div class="edi-company-row">',
					'<span class="code">' + edi.i18n.getMessage('operator.name') + ':</span> ',
					'<tpl if="operator_name.length &gt; 0"> <span class="row-data edi-company-operator-name">{operator_name}</span><tpl if="operator_phone.length &gt; 0 || operator_email.length &gt; 0">, </tpl> </tpl>',
					'<tpl if="operator_phone.length &gt; 0"> <span class="row-data edi-company-operator-phone">{operator_phone}</span><tpl if="operator_email.length &gt; 0">, </tpl> </tpl>',
					'<tpl if="operator_email.length &gt; 0"> <span class="row-data edi-company-operator-email">{operator_email}</span></tpl>',
					'</div>',
					'</tpl>',

					//Representative data
					'<tpl if="representative_name.length &gt; 0 || representative_phone.length &gt; 0 || representative_email.length &gt; 0">',
					'<div class="edi-company-row">',
					'<span class="code">' + edi.i18n.getMessage('representative.name') + ':</span> ',
					'<tpl if="representative_name.length &gt; 0"> <span class="row-data edi-company-representative-name">{representative_name}</span><tpl if="representative_phone.length &gt; 0 || representative_email.length &gt; 0">, </tpl> </tpl>',
					'<tpl if="representative_phone.length &gt; 0"> <span class="row-data edi-company-representative-phone">{representative_phone}</span><tpl if="representative_email.length &gt; 0">, </tpl> </tpl>',
					'<tpl if="representative_email.length &gt; 0"> <span class="row-data edi-company-representative-email">{representative_email}</span></tpl>',
					'</div>',
					'</tpl>',

					'<tpl if="contact_name.length &gt; 0 || contact_phone.length &gt; 0 || contact_email.length &gt;  0 || contact_web.length &gt;   0 || contact_fax.length &gt; 0 || contact_person.length &gt; 0">',
					'<div class="edi-company-row">',
					'<span class="code">' + edi.i18n.getMessage('contact.name') + ':</span> ',
					'<tpl if="contact_name.length &gt; 0"> <span class="row-data edi-company-contact-name">{contact_name}</span>',
					'<tpl if="contact_phone.length &gt; 0 || contact_email.length &gt;  0 || contact_web.length &gt;  0 || contact_fax.length &gt; 0 || contact_person.length &gt; 0">, </tpl> </tpl>',
					'<tpl if="contact_phone.length &gt; 0"> <span class="row-data edi-company-contact-phone">{contact_phone}</span>',
					'<tpl if="contact_email.length &gt; 0 || contact_web.length &gt;  0 || contact_fax.length &gt; 0">, </tpl> </tpl>',

					'<tpl if="contact_fax.length &gt; 0"> <span class="row-data edi-company-contact-phone">{contact_fax}</span>',
					'<tpl if="contact_email.length &gt; 0 || contact_web.length &gt;  0">, </tpl> </tpl>',

					'<tpl if="contact_person.length &gt; 0"> <span class="row-data edi-company-contact-person">{contact_person}</span></tpl>',
					'<tpl if="contact_email.length &gt; 0"> <span class="row-data edi-company-contact-email">{contact_email}</span></tpl>',
					'<tpl if="contact_web.length &gt; 0"><span>  ,</span> <span class="row-data edi-company-contact-web">{contact_web}</span></tpl>',
					'</div>',
					'</tpl>',

					//License data
					'<tpl if="license_type.length &gt; 0 || license_name.length &gt; 0 || license_series.length &gt; 0 || license_number.length &gt; 0">',

					'<div class="edi-company-row">',
					'<span class="code">' + edi.i18n.getMessage('company.license') + ':</span> ',
					'<tpl if="license_type.length &gt; 0 || license_name.length &gt; 0">',
					'<tpl if="license_type.length &gt; 0">',
					'<span class="row-data edi-company-licence-type">{license_type}</span>',
					'<tpl if="license_name.length &gt; 0">, </tpl>',
					'</tpl>',
					'<tpl if="license_name.length &gt; 0">',
					'<span class="row-data edi-company-licence-name">{license_name}</span>',
					'</tpl>',
					'</tpl>',
					'</div>',

					'<tpl if="license_series.length &gt; 0 || license_number.length &gt; 0">',
					'<div class="edi-company-row">',
					'<tpl if="license_series.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.license.series') + ':</span>',
					'<span class="row-data edi-company-licence-series">{license_series}</span>',
					'</tpl>',
					'<tpl if="license_number.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.license.number') + ':</span>',
					'<span class="row-data edi-company-licence-number">{license_number}</span> ',
					'</tpl>',
					'</div>',
					'</tpl>',

					'<tpl if="license_issuing_authority.length &gt; 0">',
					'<div class="edi-company-row">',
					'<tpl if="license_issuing_authority.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.license.issuing.authority') + ':</span>',
					'<span class="row-data edi-company-licence-authority">{license_issuing_authority}</span>',
					'</tpl>',
					'</div>',
					'</tpl>',

					'<tpl if="license_date_of_issue.length &gt; 0 || license_expiration_date.length &gt; 0">',
					'<div class="edi-company-row">',
					'<tpl if="license_date_of_issue.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.license.date.of.issue') + ':</span>',
					'<span class="row-data edi-company-licence-date-of-issue">{license_date_of_issue}</span>',
					'</tpl>',
					'<tpl if="license_expiration_date.length &gt; 0">',
					'<span class="code">' + edi.i18n.getMessage('company.license.expiration.date') + ':</span>',
					'<span class="row-data edi-company-expiration-date">{license_expiration_date}</span>',
					'</tpl>',
					'</div>',

					'</tpl>',
					'</tpl>',
				{
					isAddressNotGar: function(values) {
						return values.address_type !== '2';
					},
					allowCommonAddressRenderer: function(values) {
						return __self.disableCommonAddressRenderer !== true && !!values?.addr_category;
					},
					renderAddressCategory: function(values) {
						return __self.disableCommonAddressTitleRenderer === true || !values?.addr_category
							? edi.i18n.getMessage("company.address.header")
							: edi.i18n.getMessage("address.type." + values.addr_category);
					},
					convertCommonAddress: function (values) {
						var addrProperties = [
							'addr_country_name',
							'addr_zip',
							'addr_region',
							'addr_area',
							'addr_city',
							'addr_locality',
							'addr_street',
							'addr_home',
							'addr_block',
							'addr_flat'
						];
						return addrProperties.filter(function (prop) {
							return values.hasOwnProperty(prop) && values[prop].length;
						}).reduce(function (acc, prop, i, arr) {
							acc += ('<span class="row-data edi-' + prop.replace('_', '-') + '">' + values[prop] + '</span>' + (i !== arr.length - 1? '<span>, </span>' : ''));
							return acc;
						}, '')
					}
				}
			);
		}
	}
});

/**
 * Creates instance of company.selector
 * @param	{Object}	cfg
 * @return	{Object}	company.selector instance
 */
const createCompanySelector = function(cfg) {
	return Ext.create('edi.components.company.selector', cfg);
};

export {createCompanySelector};