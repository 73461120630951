import './Text.scss';
import './Textarea.scss';

Ext.define('UI.components.TextField', {
	extend: 'Ext.form.field.Text',
	baseCls: 'ui-textfield',

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	}
});

Ext.define('UI.components.TextAreaField', {
	extend: 'Ext.form.field.TextArea',
	baseCls: 'ui-textareafield',
	rows: 4,

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	},

	afterRender: function () {
		var me = this;
		me.callParent(arguments);

		me.inputEl.set({
			rows: me.rows
		});
	}
});

const createTextField = (config) => {
	if (config.isTextarea) {
		return new UI.components.TextAreaField(config);
	} else {
		return new UI.components.TextField(config);
	}
};

export { createTextField };
