import './Switch.scss';

Ext.define('UI.components.Switch', {
	extend: 'Ext.form.field.Checkbox',
	baseCls: 'ui-switchfield',
	hideLabel: true,
	boxLabel: '',
	boxLabelAlign: 'after', // after | before

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	modifyConfig: function (cfg) {
		return Ext.merge(
			{
				showRequiredMsg: false
			},
			cfg
		);
	}
});

const createSwitch = (config) => new UI.components.Switch(config);

export { createSwitch };
