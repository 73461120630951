import {createPanel} from "@Components/panels";
import {createLabel} from "@Components/fields";
import {createContainer} from "@Components/miscComponents";

const checkConfirmationKey = (key) => new Promise(resolve => {
	if (key) {
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.SSO.EMAIL_CONFIRMATION, {key}),
			'GET',
			null,
			() => resolve(true),
			() => resolve(false)
		);
	}
	else {
		resolve(false);
	}
});

const getConfirmationContent = function(key, isSuccess) {
	let headerLabel, contentLabel, logoContainer, image;
	if (key) {
		logoContainer = createContainer({
			cls: 'edi-ediweb-header-logo edi-ediweb-header-logo-big'
		});
		if(isSuccess) {
			image = createPanel({
				margin: "40 0 0 0",
				items: [
					{
						xtype: "container",
						cls: 'edi-confirmation-success-image',
						html: "",
					}
				]
			});
			headerLabel = createLabel({
				text: edi.i18n.getMessage("email.confirmation.success.header"),
				cls: "edi-confirmation-header-label"
			});
		}
		else {
			image = createPanel({
				margin: "40 0 0 0",
				items: [
					{
						xtype: "container",
						cls: 'edi-confirmation-failure-image',
						html: "",
					}
				]
			});
			headerLabel = createLabel({
				text: edi.i18n.getMessage("header.email.confirmation.failed"),
				cls: "edi-confirmation-header-label"
			});
			contentLabel = createLabel({
				cls: "edi-confirmation-content-label",
				html: edi.i18n.getMessage("onboarding.email.confirmation.failed")
			});
		}
	}
	else {
		headerLabel = createLabel({
			text: edi.i18n.getMessage("email.confirmation.not.allowed"),
			cls: "edi-confirmation-header-label"
		});
	}

	return createPanel({
		width: 580,
		items: [
			logoContainer,
			image,
			createPanel({
				layout: {
					type: 'hbox',
					align: 'stretch',
					pack: 'center'
				},
				padding: "24 0",
				items: [
					headerLabel
				]
			}),
			contentLabel
		]
	});
};

const renderConfirmationPage = function(key) {
	return createPanel({
		cls: "edi-main-panel edi-main-confirmation-panel",
		items: [],
		listeners: {
			async afterrender(panel) {
				panel.setLoading(true);
				let result = await checkConfirmationKey(key);
				panel.setLoading(false);
				panel.removeAll();
				panel.add(getConfirmationContent(key, result));
			}
		}
	})
};

export {renderConfirmationPage};