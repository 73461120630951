import './scss/TabPanel.scss';

const baseCls = 'ui-tabpanel';

const TAB_PANEL_CLS = {
	simple: `${baseCls}-simple`,
	simpleWithoutPadding: `${baseCls}-simple-without-padding`,
	module: `${baseCls}-module`
};

Ext.define('UI.components.TabPanel', {
	extend: 'Ext.tab.Panel',
	baseCls,
	cls: TAB_PANEL_CLS.simple,

	// tabBar: {
	// 	layout: {
	// 		type: 'auto'
	// 	}
	// },

	deferredRender: false,
	autoDestroy: true,
	flex: 1,
	region: 'center',
	layout: 'fit',
	plain: true,

	initComponent: function () {
		var me = this;

		me.beforeInit();
		me.callParent(arguments);
		me.afterInit();
	},

	beforeInit: function () {},
	afterInit: function () {}
});

/**
 * Creates UI.components.TabPanel
 * @param	{Object}	[config]
 * @returns	{Object}	UI.components.TabPanel instance
 */
const createTabPanel = (config) => new UI.components.TabPanel(config);

export { createTabPanel, TAB_PANEL_CLS };
