/**
 * Inserts component into tabPanel and add listeners or/and title
 * @param	{String}	tabName		Tab name
 * @param	{Object}	component	Component to view in tab
 * @param	{Object}	tabPanel	Tab panel
 * @param	{Object}	[options]	Additional options
 */
import {createLabel, createTriggerField} from "@Components/fields";
import {createPanel, createTab} from "@Components/panels";
import {createModalRemoteSelect} from "./modals";

const initTabWithComponent = function(tabName, component, tabPanel, options) {
	options = options || {};
	var title = edi.i18n.getMessage(options.title || tabName),
		showRecordsCountInTitle = options.showRecordsCountInTitle || false,
		customCountFn = options.customCountFn || false;

	var onActivate = "function" == typeof options.callback ? function(component, eOpts) {
		options.callback(tabName, component, eOpts);
	} : null;

	var tabConfig = {};
	if (options.glyph) {
		tabConfig.glyph = options.glyph;
	}
	var tab = createTab({
		title: title,
		layout: "fit",
		closable: false,
		tabConfig: tabConfig,
		activate: onActivate,
		items: [component]
	});

	tab.component = component;
	component.tab = tab;

	if (showRecordsCountInTitle && component.getStore()) {
		var store = component.getStore();
		store.on("load", function() {
			var storeCount = store.getTotalCount();
			if (customCountFn) {
				const filterStore = store.data.items.filter(function(rec) {
					return customCountFn(rec)
				});
				storeCount = filterStore ? filterStore.length : store.getTotalCount()
			}

			let newTitle = title + " (" + storeCount + ")";
			tab.setTitle(newTitle);
			tab.tab.setText(newTitle);
		});
	}

	tabPanel.add(tab);

	var targetTab = edi.utils.getURLParams().tab;
	if ((!targetTab && options.isDefault) || targetTab == tabName) {
		tabPanel.setActiveTab(tab);
	}
};

/**
 * Inserts grid into tabPanel and add listeners or/and title
 * @param	{String}	tabName
 * @param	{Object}	grid
 * @param	{Boolean}	isDefault
 * @param	{Object}	tabPanel
 * @param	{Object}	[tabConfig]
 * @param	{Object}	[options]
 */
const initTabWithGrid = function(tabName, grid, isDefault, tabPanel, tabConfig, options) {
	let opts = Object.assign({
		hideIcon: false
	}, options);
	tabConfig = tabConfig || {};
	var urlParams = edi.utils.getURLParams(), tabToActivate = null,
		onActivate = "function" == typeof tabConfig.callback ? function() {
			tabConfig.callback(tabName);
		} : null;

	if (urlParams.tab) {
		tabToActivate = urlParams.tab;
	}

	//tabConfig.glyphName could be null to hide icon
	var glyphName = tabConfig.hasOwnProperty('glyphName') ? tabConfig.glyphName : tabName;

	var tabCfg = {
		glyph: (opts.hideIcon || !glyphName)
			? null
			: edi.constants.ICONS[edi.constants.DOCUMENTS_TABS_ICON_NAME_MAP[glyphName]],
		cls: tabConfig.hasOwnProperty('cls') ? tabConfig.cls : ("edi-documents-grid-tab-" + tabName)
	};

	delete tabConfig.glyphName;
	delete tabConfig.cls;

	var config = Ext.merge({
		title: "module.tab." + tabName,
		closable: false,
		tabConfig: tabCfg,
		tabName: tabName,
		items: [grid],
		activate: onActivate,
		layout: "fit"
	}, tabConfig);

	config.title = edi.i18n.getMessage(config.title);
	var tab = createTab(config);

	tab.grid = grid;
	grid.tab = tab;
	grid.getStore().on("load", function() {
		let newTitle = config.title + " (" + grid.getStore().getTotalCount() + ")";
		tab.setTitle(newTitle);
		tab.tab.setText(newTitle);
	});

	tabPanel.add(tab);

	if (!tabToActivate && isDefault || tabName == tabToActivate) {
		tabPanel.setActiveTab(tab);
	}
};

/**
 * Creates string by values and their's names map
 * @param	{Object}	infoValues	values object
 * @param	{Object}	hash		map for output values keys
 * @returns	{String}	values separated by ";"
 */
const createInfoValuesToString = function(infoValues, hash) {
	var info = [];
	if (infoValues) {
		for (var i in hash) {
			if (hash.hasOwnProperty(i) && infoValues[hash[i]]) {
				info.push(i + ":" + infoValues[hash[i]]);
			}
		}
	}
	return info.length > 0 ? info.join(";") : "";
};

/**
 * Collect object with values from string (separated by ";") and names map
 * @param	{String}	Info
 * @param	{Object}	hash
 * @returns	{Object}	Object with 'key from hash': 'value from string'
 */
const createInfoValuesFromString = function(Info, hash) {
	var infoValues = {};

	for (var key in hash) {
		if (hash.hasOwnProperty(key)) {
			infoValues[hash[key]] = '';
		}
	}

	if (Info) {
		var data = Info.split(";");
		if (data.length > 0) {
			for (var i = 0; i < data.length; i++) {
				data[i] = data[i].split(":");
				if (data[i].length > 1 && hash[data[i][0]]) {
					key = data[i].shift();
					infoValues[hash[key]] = data[i].join(":");
				}
			}
		}
	}
	return infoValues;
};

/**
 * Creates input field or panel with label and field for selectionModal
 * @param	{Object}	config
 * @returns {Object}	field or panel with label and field
 */
const createPanelWithModalSelect = function(config) {
	config = config || {};

	var valueInput, selectedValue = config.value || null, valueFieldName = config.valueField || 'id',
		inputWidth = config.inputColumnWidth || 0.7, displayField = config.displayField || valueFieldName,
		url;

	if ("function" == typeof config.createURL) {
		url = config.createURL();
	}
	if (!url && config.modalURL) {
		url = config.modalURL;
	}

	var showSelectionModal = function() {
		if ("function" == typeof config.createUrlBeforeShow) {
			url = config.createUrlBeforeShow();
		}

		var currentSelection = null;

		if ('function' == typeof config.getCurrentSelection) {
			currentSelection = config.getCurrentSelection();
		}
		else if (valueInput && valueInput.getValue()) {
			currentSelection = valueFieldName == 'id' ? parseInt(valueInput.getValue()) : valueInput.getValue();
		}

		var createArgs = 'function' == typeof config.createArgs ? config.createArgs : undefined;
		//if no createArgs fn is specified, but modal has filter
		if (!createArgs && !config.noFilter) {
			createArgs = edi.filters.config.generic.createArgs;
		}

		var modal = createModalRemoteSelect(url, function(data) {
			if (valueInput) {
				selectedValue = data[valueFieldName];
				valueInput.setValue(data[displayField]);
				valueInput.removeCls('error-description');
			}

			modal.close();
			if ('function' == typeof config.afterSelect) {
				config.afterSelect(data);
			}
		}, {
			storeConfig: {
				autoLoad: !!config.noFilter && !!url,
				listeners: {
					load: function(store) {
						if (currentSelection && modal) {
							var grid = modal.down('gridpanel'), selectedRec = store.findRecord(valueFieldName, currentSelection, 0, false, true, true);
							if (grid && selectedRec) {
								grid.getSelectionModel().select(selectedRec);
							}
						}
					}
				}
			},
			title: config.modalTitle,
			enableTextSelection: true,
			disablePaging: config.hasOwnProperty('disablePaging') ? config.disablePaging : true,
			checkboxes: false,
			model: config.model,
			columns: config.columns,
			cancelTitle: "action.close",
			noFilter: config.noFilter || false,
			createFilterFormItems: "function" == typeof config.filterItemsMethod ? config.filterItemsMethod() : undefined,
			createArgs: createArgs
		});
	};

	var inputConfig = {
		msgTarget: config.tooltip ? 'under' : 'qtip',
		readOnly: !!config.readOnly,
		qtipText: config.tooltip || null,
		disabled: !!config.hidden || !!config.disabled,
		allowBlank: config.allowBlank,
		regex: config.regex,
		editable: config.editable,
		columnWidth: inputWidth,
		value: selectedValue,
		name: config.fieldName,
		triggers: {
			search: {
				extraCls: 'edi-icon edi-icon-SEARCH test-action-form-trigger-search',
				tooltip: config.triggerQtip || null,
				handler: showSelectionModal
			}
		},
		setSelectedValue: function(value) {
			selectedValue = value;
		},
		getSubmitValue: function() {
			return config.editable ? valueInput.getValue() : selectedValue;
		}
	};
	if (config.listeners) {
		inputConfig.listeners = config.listeners;
	}
	if (config.isClearValue) {
		Ext.merge(inputConfig, {
			triggers: {
				clear: {
					extraCls: 'edi-icon edi-icon-CLOSE test-action-form-trigger-close',
					tooltip: config.trigger2Qtip || null,
					handler() {
						selectedValue = '';
						valueInput.setValue(selectedValue);
						if ('function' == typeof config.afterSelect) {
							config.afterSelect();
						}
					}
				}
			}
		});
	}
	var panelConfig = Ext.apply({
		layout: 'column',
		hidden: config.hidden,
		items: [
			createLabel({
				columnWidth: 1 - inputWidth,
				html: edi.i18n.getMessage(config.fieldLabel)
			}),

			valueInput = createTriggerField(inputConfig)
		]
	}, config.panelConfig || {});

	if (config.isPreloadData && url && !config.value) {
		edi.rest.sendRequest(edi.utils.compileURL(url, params), "GET", undefined, function(data) {
			if (data && data.items && data.items.length) {
				if ('function' === typeof config.preloadConvertData) {
					var convertedRecord = config.preloadConvertData(data.items);
					if (convertedRecord) {
						config.afterSelect(convertedRecord);
					}
				}
			}
			else if (config.hideTriggerIfNoData) {
				valueInput.setHideTrigger(true);
			}
		});
	}

	var panel = createPanel(panelConfig);

	if (valueFieldName != displayField && selectedValue && url) {
		var params = {};
		params[valueFieldName] = selectedValue;
		edi.rest.sendRequest(edi.utils.compileURL(url, params), "GET", undefined, function(data) {
			if (selectedValue === "") {
				valueInput.setValue(selectedValue);
			}
			else if (data && data.items && data.items.length && data.items[0][displayField]) {
				valueInput.setValue(data.items[0][displayField]);
			}
			else {
				valueInput.setValue(selectedValue);
			}
			valueInput.resetOriginalValue();
		});
	}

	panel.valueInput = valueInput;
	return config.isOnlyInput ? valueInput : panel;
};


Ext.namespace("edi.special");
Ext.apply(edi.special, {
	initTabWithComponent,
	initTabWithGrid,
	createInfoValuesToString,
	createInfoValuesFromString,
	createPanelWithModalSelect
});

export {
	initTabWithComponent,
	initTabWithGrid,
	createInfoValuesToString,
	createInfoValuesFromString,
	createPanelWithModalSelect
};