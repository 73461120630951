import { createCheckbox, createCombo } from '@UIkit/components/fields';
import { createLabel } from '@UIkit/components/fields_old/Label';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { createProxyConfig, createStore } from '@Components/storeComponents';

import './PagingBar.scss';

Ext.define('UI.components.PagingBar', {
	extend: 'Ext.toolbar.Paging',
	selfCls: 'ui-pagingbar',
	items: [],
	height: 56,
	hidePagingButtons: false,

	constructor: function (externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	modifyConfig: function (cfg) {
		//не удалять т.к. без этого перезаписываются данные конфиги для всего пэйджинг баров проекта
		this.navigateBackCfg = {};
		this.navigateForwardCfg = {};
		//
		return Ext.merge({}, cfg);
	},

	beforeInit: function () {
		this.setOwnConfig();
	},

	setOwnConfig: function () {
		const me = this;
		let properties = Ext.apply(
			{
				cls: me.selfCls
			},
			me.properties
		);

		me.displayMsgSimple = properties.displayMsgSimple || '{0}-{1}';
		me.itemsPerPage = properties.itemsPerPage || edi.constants.ITEMS_PER_PAGE;

		const pagingBarItems = {};
		pagingBarItems[edi.constants.LIST_ROOT_PROPERTY] = properties.pagingBarItems
			? properties.pagingBarItems
			: edi.constants.DEFAULT.PAGING_BAR_ITEMS;
		me.simpleMode = !!properties.simpleMode;

		var addButtonsConfig = properties.addButtonsConfig;
		delete properties.addButtonsConfig;
		var allowExport = undefined === properties.allowExport || !!properties.allowExport;
		delete properties.allowExport;

		var totalsLabel = undefined !== properties.totalsLabel ? properties.totalsLabel : null;
		delete properties.totalsLabel;

		var refreshBtnHandler =
			properties.refreshBtnHandler ||
			function () {
				me.doRefresh();
			};
		delete properties.refreshBtnHandler;

		me.lastRequestLabel = createLabel({
			cls: `${me.selfCls}-request-time`,
			hidden: true
		});
		var defaults = {
			dock: 'bottom',
			displayInfo: false,
			prependButtons: true,
			flex: 1,
			border: 0,
			items: []
		};

		var pagesStore = createStore({
			proxy: createProxyConfig({
				type: 'memory',
				data: pagingBarItems
			}),
			fields: ['size']
		});

		if (totalsLabel) {
			totalsLabel.cls = `${totalsLabel.cls || ''} ${me.selfCls}-total-text`;
			defaults.items.push(totalsLabel);
		}

		Ext.applyIf(properties, defaults);
		if (allowExport && !edi.constants.DISABLE_GRID_EXPORT) {
			properties.items.push(
				createButton({
					text: '',
					cls: `${me.selfCls}-download-button ${BUTTON_CLS.light}`,
					tooltip: edi.i18n.getMessage('action.export'),
					glyph: edi.constants.ICONS.FILE_DOWNLOAD,
					handler: function () {
						var data = {
							headers: [],
							data: []
						};
						var grid = me.grid;
						var table = grid.getView();
						var selectedNodes = table.getSelectedNodes();
						var allNodes = table.getNodes();
						if (grid.columnManager.headerCt && grid.columnManager.headerCt.items.items) {
							var items = grid.columnManager.headerCt.items.items;
							for (var i = 0; i < items.length; i++) {
								var aColumn = items[i];
								if (!aColumn.hidden && aColumn.cName) {
									var text = aColumn.text;
									if (aColumn.items.items.length) {
										for (var j = 0; j < aColumn.items.items.length; j++) {
											var aCol = aColumn.items.items[j];
											data.headers.push(text + ' ' + String(aCol.text).toLowerCase());
										}
									} else {
										data.headers.push(text);
									}
								}
							}
						}
						var continueExport = function (exportAllVisible) {
							var selection = selectedNodes;
							if (exportAllVisible) {
								selection = allNodes;
							}
							for (var i = 0; i < selection.length; i++) {
								var row = selection[i];
								var rowData = [];
								let tdCells = row.querySelectorAll('td') || [];
								for (var j = 0; j < tdCells.length; j++) {
									var cell = tdCells[j];
									if (
										!cell.hidden &&
										cell.localName === 'td' &&
										-1 === cell.className.indexOf('x-grid-cell-special') &&
										-1 === cell.className.indexOf('x-action-col-cell')
									) {
										var value = (cell.innerText || cell.textContent || '').split('\n');
										value[value.length - 1] === '' ? value.splice(value.length - 1, 1) : null;
										rowData.push(value.join('\n'));
									}
								}
								data.data.push(rowData);
							}
							//edi.debug.debugTableData(data);
							var fileId = String(Ext.id()).replace('ext-gen', '');
							fileId = String(fileId).replace('ext-', '');
							edi.rest.downloadFile(
								edi.utils.formatString(edi.rest.services.DOCUMENTS_EXPORT.LIST.POST, {
									id: fileId
								}),
								fileId,
								data
							);
						};

						if (allNodes.length && data?.headers?.length) {
							if (!selectedNodes.length || selectedNodes.length === allNodes.length) {
								continueExport(true);
							} else {
								edi.core.confirm(
									null,
									'export.only.selected.confirmation',
									function () {
										continueExport();
									},
									function () {
										continueExport(true);
									}
								);
							}
						} else {
							edi.core.showInfo('nothing.to.export');
						}
					}
				})
			);
		} else if (properties.replaceExport) {
			properties.items = properties.items.concat(properties.replaceExport);
		}

		defaults.items.push(
			createButton({
				itemId: 'refresh',
				cls: `${me.selfCls}-refresh-button ${BUTTON_CLS.light}`,
				hidden: properties.isHideRefreshBtn,
				tooltip: edi.i18n.getMessage('paging.refresh'),
				overflowText: edi.i18n.getMessage('paging.refresh'),
				glyph: edi.constants.ICONS.REVERT_RELOAD,
				handler: refreshBtnHandler
			}),
			me.lastRequestLabel
		);

		if (addButtonsConfig) {
			properties.items = properties.items.concat(addButtonsConfig);
		}

		me.comboPageSize = createCombo({
			itemId: 'displayItem',
			store: pagesStore,
			isFormField: false,
			cls: `${me.selfCls}-documents-per-page`,
			hidden: properties.hidePageSizeCombo === true,
			displayField: 'size',
			valueField: 'size',
			hideTrigger: true,
			value: me.itemsPerPage,
			tpl: [
				`<div class="${me.selfCls}-per-page-title">${edi.core.getMessage(
					'pagingbar.documents.per.page'
				)}</div>`,
				`<div class="${me.selfCls}-per-page-items-wrapper">`,
				`<tpl for="."><p role="option" unselectable="on" class="${me.selfCls}-per-page-item x-boundlist-item">`,
				'{size}',
				'</p></tpl>',
				'</div>'
			],
			pickerAlign: 'br-tr',
			pickerOffset: [8, -24],
			listConfig: {
				cls: `${me.selfCls}-per-page-panel`,
				listeners: {
					show: function (picker) {
						//Это нужно, что бы пикер сворачивался по клику снаружи него,
						// т.к. фокус оставался на комбике, то и событие потери фокуса не было,
						// а именно оно вызывает закрытие
						picker.focus();
					}
				}
			},
			listeners: {
				change: function (combo, newValue) {
					const pagingBarStore = me.getStore();
					if (newValue && pagingBarStore && pagingBarStore.pageSize !== newValue) {
						pagingBarStore.pageSize = newValue;
						if (1 !== pagingBarStore.currentPage) {
							me.moveFirst();
						} else {
							me.doRefresh();
						}
					}
				}
			}
		});

		properties.items.push('->');
		if (!!properties.changeModeBtn) {
			properties.items.push(
				createCheckbox({
					boxLabel: edi.i18n.getMessage('paging.mode.changeBtn'),
					name: 'simpleMode',
					checked: me.simpleMode,
					listeners: {
						change: function (comp, value) {
							me.simpleMode = !!value;
							me.getStore().reload();
							me.setSimpleMode(me.simpleMode);
						}
					}
				})
			);
		}
		if (!properties.hidePagingButtons) {
			properties.items.push(me.comboPageSize);
		}

		Ext.merge(me, properties);
	},

	afterInit: function () {
		const me = this;
		me.displayMsgFull = me.displayMsg;
		me.setSimpleMode = function (isSimpleMode) {
			me.simpleMode = isSimpleMode;
			me.displayMsg = isSimpleMode ? me.displayMsgSimple : me.displayMsgFull;
		};

		me.setSimpleMode(me.simpleMode);

		const pagingBarStore = me.getStore();
		pagingBarStore.pageSize = me.itemsPerPage;
		pagingBarStore.on('beforeload', function (store, options) {
			if (store.getProxy().type === 'ajax') {
				me.lastRequestLabel.setText(edi.renderers.dateTimeFromMs(new Date().getTime()));
				me.lastRequestLabel.setVisible(true);
			} else {
				me.lastRequestLabel.setVisible(false);
			}
			if (me.simpleMode) {
				options.params = Ext.apply(options.params || {}, {
					simple: me.simpleMode
				});
			}
		});
	},

	getPagingItems: function () {
		const me = this;
		return me.hidePagingButtons
			? []
			: [
					createButton(
						Object.assign(
							{
								cls: BUTTON_CLS.light,
								itemId: 'prev',
								margin: 0,
								tooltip: me.prevText,
								overflowText: me.prevText,
								glyph: edi.constants.ICONS.KEYBOARD_ARROW_LEFT,
								disabled: true,
								handler: me.movePrevious,
								scope: me
							},
							me.navigateBackCfg
						)
					),
					createButton(
						Object.assign(
							{
								cls: BUTTON_CLS.light,
								itemId: 'next',
								margin: 0,
								tooltip: me.nextText,
								overflowText: me.nextText,
								glyph: edi.constants.ICONS.KEYBOARD_ARROW_RIGHT,
								disabled: true,
								handler: me.moveNext,
								scope: me
							},
							me.navigateForwardCfg
						)
					)
			  ];
	}
});

/**
 * Creates paging bar
 * @param	{Object}	[properties]	store, dock ...
 * @returns	{Object}	Ext.toolbar.Paging instance
 */
export const createPagingBar = function (properties) {
	return Ext.create('UI.components.PagingBar', { properties });
};
