import {
	createActionsColumnConfig,
	createCheckboxSelectionModel,
	createGrid
} from "@Components/grid";
import {createForm, createModalPanel} from "@Components/panels";
import {createFields} from "@Components/fields";
import {createSaveButton} from "./buttons";
import {createAddTool, createTool} from "@Components/buttons";

const createEditableGrid = function (config, data) {
	config = "object" == typeof config ? config : {};
	var fields = config.fields || [];
	var model = config.model || "SIMPLE";
	var limit = config.limit;
	var readOnly = config.readOnly || false;
	var deleteManyBtn = config.deleteManyBtn || { text: '' };
	var deleteRowBtn = config.deleteRowBtn || {};
	var editRowBtn = config.editRowBtn || {};
	var detailsRowBtn = config.detailsRowBtn || {};
	var canAddRow = config.canAddRow || false;
	var isHideAddBtn = config.isHideAddBtn || readOnly;
	var isDisableAddBtn = config.isDisableAddBtn || false;
	var isHideDeleteManyBtn = config.isHideDeleteManyBtn !== false || readOnly;
	var showDetailRowButton = config.showDetailRowButton || false;
	var columns, grid, modalForm;
	var storeConfig = config.storeConfig || {};
	if (config.columnsConfig) {
		columns = edi.columns.get(config.columnsConfig);
	}
	else {
		var columnItems = [];
		for (var i = 0; i < fields.length; i++) {
			var field = fields[i];
			columnItems.push({
				text: edi.i18n.getMessage(field.title || "column." + edi.utils.formatName(field.name)),
				dataIndex: field.name,
				renderer: field.renderer,
				flex: 1
			});
		}
		columns = columnItems;
	}
	var addButton, updateAddButton, deleteManyButton;

	if (!readOnly) {
		columns.push(createActionsColumnConfig({
			items: [
				Ext.merge({
					glyph: edi.constants.ICONS.EDIT,
					testCls: 'test-action-column-edit',
					isActionDisabled: function(view, rowIndex, colIndex, clickItem, record) {
						return 'function' == typeof config.canEditRow ? !config.canEditRow(record, view.getStore()) : false;
					},
					handler: function(grid, rowIndex) {
						var record = grid.getStore().getAt(rowIndex);
						if (record) {
							createModal(record);
						}
					}
				}, editRowBtn),
				Ext.merge({
					glyph: edi.constants.ICONS.REMOVE,
					testCls: 'test-action-column-remove',
					isActionDisabled: function(view, rowIndex, colIndex, clickItem, record) {
						return 'function' == typeof config.canDeleteRow ? !config.canDeleteRow(record, view.getStore()) : false;
					},
					handler: function(grid, rowIndex) {
						edi.core.confirm(null, 'general.line.remove', function() {
							grid.getStore().removeAt(rowIndex);
							updateAddButton();
						});
					}
				}, deleteRowBtn)
			]
		}));

		addButton = createAddTool({
			handler() {
				if (config.beforeAdd && 'function' == typeof config.beforeAdd) {
					config.beforeAdd(createModal);
				}
				else {
					createModal();
				}
			},
			disabled: isDisableAddBtn
		});
		updateAddButton = function() {
			var count = grid.getStore().count();
			isDisableAddBtn = grid.hasOwnProperty('isDisableAddBtn') ? grid.isDisableAddBtn : isDisableAddBtn;
			addButton.setDisabled(isDisableAddBtn || (limit !== undefined && count >= limit));
		};

		deleteManyButton = createTool(Ext.merge({
			handler() {
				edi.core.confirm(null, 'general.manylines.remove', function() {
					var store = grid.getStore();
					var selected = grid.getSelectionModel().getSelection();

					if (selected && selected.length) {
						store.remove(selected);
						updateAddButton();
					}
				});
			},
			tooltip: edi.i18n.getMessage('form.btn.delete'),
			glyph: edi.constants.ICONS.DELETE,
			disabled: true
		}, deleteManyBtn));
	}
	else if (showDetailRowButton) {
		columns.push(createActionsColumnConfig({
			items: [
				Ext.merge({
					glyph: edi.constants.ICONS.DETAILS,
					cls: 'edi-action-column-details',
					testCls: 'test-action-column-details',
					handler: function(grid, rowIndex) {
						var record = grid.getStore().getAt(rowIndex);
						if (record) {
							createModal(record, true);
						}
					}
				}, detailsRowBtn)
			]
		}));
	}

	var createModal = function(record, readOnly, options) {
		var fieldConfigs = Ext.clone(fields);
		Ext.Array.forEach(fieldConfigs, function(fieldConfig) {
			if (readOnly) {
				fieldConfig.readOnly = readOnly;
			}
			else if ('function' == typeof fieldConfig.readOnly) {
				fieldConfig.readOnly = fieldConfig.readOnly(record, grid.getStore());
			}

			if ('function' == typeof fieldConfig.value) {
				fieldConfig.value = fieldConfig.value(record, grid.getStore());
			}
		});

		let modalFieldsData = !!record
			? record.getData()
			: options?.initialFieldsData || null;

		modalForm = createForm({
			bodyPadding: 10,
			overflowY: 'auto',
			items: createFields(fieldConfigs, modalFieldsData)
		});
		var modal = createModalPanel({
			width: config.modalWidth || edi.constants.DEFAULT.MODAL.WIDTH,
			maxHeight: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
			title: config.gridConfig ? config.gridConfig.title || "title" : "title",
			items: [modalForm],
			buttonsBefore: !readOnly ? [
				createSaveButton(function() {
					var store = grid.getStore();
					var values = edi.utils.collectFormValues(modalForm);
					var gridElements = config.fields.filter(element => {
						return element.subType === 'editableGrid';
					});
					if (gridElements) {
						var gridList = modalForm.query("grid");
						gridList.forEach((grid, index) => {
							var element = gridElements[index];
							var name = element?.name ?? null;
							if (!name) return;
							var gridValues = edi.utils.getDataFromGrid(grid);
							edi.utils.setObjectProperty(values, name, gridValues);
						});
					}
					var isEdit = !!record;

					if (config.validFormFunc && "function" == typeof config.validFormFunc) {
						if (!config.validFormFunc(values, store)) {
							return;
						}
					}

					var saveRecord = function() {
						var recordToEdit = record || edi.models.createInstance(config.model);
						for (var i in values) {
							if (values.hasOwnProperty(i)) {
								recordToEdit.set(i, values[i]);
							}
						}

						if (!isEdit) {
							store.add(recordToEdit);
							updateAddButton();
						}

						store.commitChanges();
						modal.close();
					};

					if (!isEdit && config.recordsUniqueByField) {
						var fieldName = config.recordsUniqueByField;
						var existingRec = store.findRecord(fieldName, values[fieldName], 0, false, true, true);
						if (existingRec) {
							edi.core.showError(edi.i18n.getMessage(config.duplicateRecordErrorMsg));
						}
						else {
							saveRecord();
						}
					}
					else {
						saveRecord();
					}
				}, {
					text: edi.i18n.getMessage(!!record ? 'form.btn.save' : 'form.btn.add'),
					bindToForm: modalForm,
					glyph: !!record ? edi.constants.ICONS.SAVE : edi.constants.ICONS.ADD
				})
			] : null
		});
		modal.show();
		modalForm.isValid();

	};


	var defaultGridConfig = {
		columns: columns,
		disablePaging: true,
		dockedItems: [],
		tools: [],
		isGridValid: "function" == typeof config.isGridValid ? config.isGridValid: null,
	};
	if (!isHideAddBtn) {
		defaultGridConfig.tools.push(addButton);
	}
	if (!isHideDeleteManyBtn) {
		defaultGridConfig.tools.push(deleteManyButton);
		defaultGridConfig.disableSelection = false;
		defaultGridConfig.selModel = createCheckboxSelectionModel({
			listeners: {
				selectionchange: function (model, selected) {
					deleteManyButton.setDisabled(!(selected && selected.length));
				}
			}
		});
	}


	var isGridValid = [];

	if ("function" == typeof config.isRowValid) {
		defaultGridConfig.viewConfig = {
			getRowClass: function (record, rowIndex) {
				isGridValid[rowIndex] = config.isRowValid(record);

				return !isGridValid[rowIndex] ? 'with-error' : '';
			}
		};
	}

	if (config.gridConfig && config.gridConfig.tools && Ext.isArray(config.gridConfig.tools)) {
		config.gridConfig.tools = config.gridConfig.tools.concat(defaultGridConfig.tools);
	}
	var conf = Ext.applyIf(config.gridConfig || {}, defaultGridConfig);

	grid = createGrid({
		proxyConfig: config.proxyConfig || {
			type: "memory",
			reader: {
				type: 'json',
				rootProperty: edi.constants.LIST_ROOT_PROPERTY
			},
			model: edi.models.getModel(model),
			data: {
				items: data
			}
		},
		gridConfig: conf,
		storeConfig: Ext.isArray(data) && data.length ? Ext.merge({data: data}, storeConfig) : storeConfig
	});

	var gridStore = grid.getStore();

	if (config.useCanDeleteHandlerForAllRows && 'function' == typeof config.canDeleteRow) {
		var toggleDeleteButton = function (store, record) {
			let actionColumn = grid.columns.find(c => c.xtype === 'actioncolumn');
			if (config.canDeleteRow(record, store)) {
				if (actionColumn?.enableAction) {
					actionColumn.enableAction(1);
				}
			}
			else {
				if (actionColumn?.disableAction) {
					actionColumn.disableAction(1);
				}
			}
		};
		gridStore.on('update', toggleDeleteButton);
		gridStore.on('remove', toggleDeleteButton);
	}

	var rowsValid = function() {
		if ("function" == typeof config.isRowValid) {
			var storeRange = gridStore.getRange(), isValid = true;
			Ext.Array.every(storeRange, function(record) {
				isValid = config.isRowValid(record);
				return isValid;
			});
			return isValid;
		}
		else {
			return true;
		}
	};
	grid.isValid = function() {
		return rowsValid() && ("function" == typeof grid.isGridValid ? grid.isGridValid(gridStore) : true);
	};

	if (!readOnly) {
		updateAddButton();
	}

	grid.getModalForm = function () {
		return modalForm;
	};

	if (canAddRow && 'function' == typeof canAddRow) {
		var canRowFunc = function (store) {
			isDisableAddBtn = canAddRow(store);
		};
		gridStore.on('load', canRowFunc);
		gridStore.on('add', canRowFunc);
		gridStore.on('remove', canRowFunc);
	}

	if (!readOnly) {
		grid.getView().on('refresh', updateAddButton);
	}

	return grid;
};

Ext.namespace('edi.special');
Ext.apply(edi.special, {
	createEditableGrid
});

export {createEditableGrid};