import './Sidebar.scss';
const baseCls = 'ui-sidebar';

Ext.define('UI.component.Sidebar', {
	extend: 'Ext.panel.Panel',
	baseCls,
	modal: false,
	enableBackdrop: false,
	backdropCls: '',
	shadow: false,
	autoShow: false,
	floating: true,
	fixed: true,
	autoScroll: true,
	animation: true,
	animationSpeed: 400, // milliseconds
	buttonAlign: 'left',
	width: 440, // 440, 640, 864
	height: '100%',
	open: false,
	x: 0,
	y: 0,
	listeners: {
		beforedestroy() {
			return this.beforeDestroy();
		}
	},
	constructor: function (config) {
		if (!!this.parent) {
			this.renderTo = this.parent;
		}

		Ext.apply(this, config);

		this.x = this.defaultPosX();
		this.height = this.parent.getHeight();

		this.callParent(arguments);
	},
	initComponent: function () {
		this.callParent();
	},
	afterRender: function () {
		var me = this;

		if (me.animation) {
			me.getEl().setStyle('transition', `all ${me.animationSpeed}ms ease-in-out`);
		}

		if (!!me.parent) {
			me.parent.on('resize', function (comp, width, height, oldWidth, oldHeight) {
				if (width !== oldWidth) {
					if (me.isToggle) {
						me.setX(width - me.convertWidth(me.width));
					} else {
						me.setX(width);
					}
				}
				if (height !== oldHeight) {
					me.setHeight(height);
				}
			});

			let timeout = setTimeout(() => {
				me.getEl().setStyle('top', 0);
				me.getEl().setStyle('left', `${me.parent.getWidth() - me.convertWidth(me.width)}px`);
				clearTimeout(timeout);
			}, 50);
		}

		if (me.enableBackdrop) {
			me.createBackdrop();
		}

		me.initKeys();

		me.callParent();
	},
	initKeys: function () {
		let me = this;
		if (!me.disableKeys) {
			me._keyMap = new Ext.util.KeyMap({
				target: Ext.getBody(),
				binding: me.createKeyBindings()
			});
		}
	},
	createKeyBindings: function () {
		let me = this;
		return [
			{
				key: [27], //ESC
				handler: function () {
					me.toggle(false);
				}
			}
		];
	},
	defaultPosX: function () {
		var me = this;
		return me.animation ? me.parent.getWidth() : me.parent.getWidth() - me.convertWidth(me.width);
	},
	convertWidth: function (size) {
		var me = this,
			percentageRe = /^\s*(\d+(?:\.\d*)?)\s*[%]\s*$/;
		if (percentageRe.test(size)) {
			var match = percentageRe.exec(size);
			size = me.parent.getWidth() * (parseFloat(match[1]) / 100);
		}
		return size;
	},
	toggle: function (isToggle, callback) {
		var me = this;
		if (isToggle) {
			if (!me.isVisible()) {
				me.show();
			}
			me.zIndexManager.bringToFront(me);
			me.setBackdropVisible(true);
			me._keyMap?.enable();
		} else {
			me.setBackdropVisible(false);
			me._keyMap?.disable();
		}
		me.toggleAnimation(isToggle, callback);
		me.open = !!isToggle;
	},
	toggleAnimation: function (isToggle, callback) {
		var me = this;
		if (isToggle) {
			me.getEl().setStyle('left', `${me.parent.getWidth() - me.convertWidth(me.width)}px`);
		} else {
			me.getEl().setStyle('left', `${me.parent.getWidth()}px`);
		}
		if ('function' == typeof callback) {
			let timeout = setTimeout(() => {
				callback();
				clearTimeout(timeout);
			}, me.animationSpeed);
		}
	},
	isToggle: function () {
		var me = this;
		return me.open;
	},
	close: function () {
		let me = this;
		me.toggleAnimation(false, () => {
			me.destroy();
		});
		me.open = false;
	},
	/**
	 * Creates backdrop and attach it into sidebar's parent node
	 */
	createBackdrop() {
		let me = this;
		if (me.backdrop || me.enableBackdrop !== true) {
			return;
		}

		const backdrop = document.createElement('div');
		backdrop.style.setProperty('display', 'none');
		backdrop.classList.add(`${me.baseCls}-backdrop`);
		if (me.backdropCls) {
			backdrop.classList.add(me.backdropCls);
		}
		backdrop.addEventListener('click', function () {
			me.onBackdropClick(me, me.backdrop);
		});

		me.el.dom.parentElement.insertBefore(backdrop, me.body.firstChild);
		me.backdrop = backdrop;
	},
	/**
	 * Backdrop click handler
	 * @param	{Object}	sidebar
	 * @param	{Object}	backdrop
	 */
	onBackdropClick(sidebar, backdrop) {
		sidebar.toggle(false);
	},
	/**
	 * Shows/hides backdrop (if it exists)
	 * @param	{Boolean}	visible
	 */
	setBackdropVisible(visible) {
		let me = this;
		if (typeof me.backdrop?.style?.setProperty === 'function') {
			me.backdrop.style.setProperty('display', visible ? 'block' : 'none');
			let sidebarZIndex = me.el?.dom?.style?.zIndex;
			if (sidebarZIndex) {
				me.backdrop.style.setProperty('z-index', sidebarZIndex - 1);
			}
		}
	},
	/**
	 * Cleans up memory (event, dom, requests) before sidebar will be destroyed
	 * @returns	{boolean}	continue destroying
	 */
	beforeDestroy() {
		let me = this;
		me._keyMap?.destroy();
		if (me.backdrop && me.el?.dom?.parentElement) {
			me.el.dom.parentElement.removeChild(me.backdrop);
			delete me.backdrop;
		}
		return true;
	}
});

const createSidebar = (config) => new UI.component.Sidebar(config);

export { createSidebar };
