import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { createButton } from '@Components/buttons';
import { createModalPanel } from '@Components/panels';
import { createProxyConfig } from '@Components/storeComponents';

Ext.ariaWarn = Ext.emptyFn;
/**
 * @author Anatoly Deryshev
 * Extensions for js core functionality and for extjs core components
 */
if (typeof String.prototype.trimLeft !== 'function') {
	String.prototype.trimLeft = function () {
		return this.replace(/^\s+/, '');
	};
}
if (typeof String.prototype.trimRight !== 'function') {
	String.prototype.trimRight = function () {
		return this.replace(/\s+$/, '');
	};
}
if (typeof String.prototype.trim !== 'function') {
	String.prototype.trim = function () {
		return this.replace(/^\s+|\s+$/gm, '');
	};
}

if ('function' !== typeof Date.prototype.toISOString) {
	(function () {
		var pad = function (number) {
			if (number < 10) {
				return '0' + number;
			}
			return number;
		};
		Date.prototype.toISOString = function () {
			return (
				this.getUTCFullYear() +
				'-' +
				pad(this.getUTCMonth() + 1) +
				'-' +
				pad(this.getUTCDate()) +
				'T' +
				pad(this.getUTCHours()) +
				':' +
				pad(this.getUTCMinutes()) +
				':' +
				pad(this.getUTCSeconds()) +
				'.' +
				(this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
				'Z'
			);
		};
	})();
}
/**
 * Detection of Edge and Opera for Ext 4.2
 * @type {boolean}
 */
Ext.isEdge = false;
Ext.edgeVersion = 0;
if (Ext.isChrome) {
	(function () {
		var ua = navigator.userAgent,
			tmp;
		tmp = ua.match(/\b(OPR|Edge)\/(\d+)/);
		if (tmp) {
			if ('Edge' === tmp[1]) {
				Ext.isEdge = true;
				Ext.edgeVersion = tmp[2];
			} else {
				Ext.isOpera = true;
				Ext.operaVersion = tmp[2];
			}
		}
	})();
}
/**
 * Overrides for Ext.Msg icons
 */
//Вызывается после инициализации объекта экстом ext74/ext-all-debug.js: 196287
Ext.onInternalReady(function () {
	if (Ext.Msg && Ext.Msg.msgButtons) {
		if (Ext.Msg.msgButtons.yes) {
			Ext.Msg.msgButtons.yes.setGlyph('xe876@Material Icons');
		}
		if (Ext.Msg.msgButtons.no) {
			Ext.Msg.msgButtons.no.setGlyph('xe14b@Material Icons');
		}
		if (Ext.Msg.msgButtons.cancel) {
			Ext.Msg.msgButtons.no.setGlyph('xe14b@Material Icons');
		}
	}
});

/**
 * Plugin for disabling multiple buttons click
 */
Ext.ux.DisableDoubleClick = (function () {
	return {
		init: function (cmp) {
			let plugin = this;
			cmp.lastClick = 0;
			let interceptedOnClick = Ext.Function.createInterceptor(cmp.onClick, plugin.onClickInterceptor);
			cmp.onClick = function () {
				interceptedOnClick.apply(cmp, arguments);

				//блокируем скрытие меню, иначе оно пропадет из-за blur у родителя
				let oldOnFocusLeave = cmp.onFocusLeave;
				cmp.onFocusLeave = () => {};

				if (typeof cmp.el?.dom?.blur === 'function') {
					cmp.el.dom.blur();
				}
				if (typeof cmp.blur === 'function') {
					cmp.blur();
				}

				cmp.onFocusLeave = oldOnFocusLeave;
			};
		},
		onClickInterceptor: function () {
			let cmp = this;
			let allow = false;
			if (cmp.allowMultiClick) {
				allow = true;
			}
			if (!allow && !cmp.disabled) {
				var time = new Date().getTime();
				allow = !(time - cmp.lastClick < edi.constants.BUTTON_MULTI_CLICK_DELAY);
				cmp.lastClick = time;
			}
			return allow;
		}
	};
})();

Ext.override(Ext.data.field.Field, {
	allowNull: true
});

/**
 * Adds functions to convert dates to/from UTC
 */
Ext.override(Ext.Date, {
	toUTC: function (date) {
		if (Ext.isDate(date)) {
			return this.add(date, this.MINUTE, date.getTimezoneOffset());
		} else {
			return date;
		}
	},
	fromUTC: function (date) {
		if (Ext.isDate(date)) {
			return this.add(date, this.MINUTE, -date.getTimezoneOffset());
		} else {
			return date;
		}
	}
});

/**
 * Global override that allows to intercept all server calls, and prolong business session, if timeout between requests were too long. Also it intercept all requests that requires login, and forces login form popup
 */
Ext.override(Ext.Ajax, {
	interceptedChain: [],
	interceptionActive: false,
	interceptionFlushActive: false,
	loginInProcess: false,
	requestsCount: 0,
	pendingChain: [],
	pendingFlushActive: false,
	/**
	 * Returns current state of pending requests flush state
	 * @returns {boolean}
	 */
	getPendingFlushState: function () {
		return this.pendingFlushActive;
	},
	/**
	 * Clears pending chain collection
	 */
	clearPendingChain: function () {
		this.pendingChain = [];
	},
	/**
	 * Flushes pending chain collection by firing all requests
	 */
	flushPendingChain: function () {
		if (this.pendingChain && this.pendingChain.length) {
			this.pendingFlushActive = true;
			while (this.pendingChain.length) {
				this.request(this.pendingChain.shift());
			}
			edi.core.logMessage('Pending requests chain was flushed to server');
			this.pendingFlushActive = false;
		}
	},
	/**
	 * Checks if request is pending from user activity(any other independent requests)
	 * @param    {Object}    options        request parameters object
	 * @returns  {boolean}
	 */
	isRequestPending: function (options) {
		return !!options.isPending;
	},
	/**
	 * Checks if request is not interceptable
	 * @param    {Object}    options        request parameters object
	 * @returns  {boolean}
	 */
	isRequestNotInterceptable: function (options) {
		return !!options.notInterceptable;
	},
	/**
	 * Checks if request is from login form
	 * @param    {Object}    options        request parameters object
	 * @returns  {boolean}
	 */
	isLoginRequest: function (options) {
		return (
			options.url === edi.rest.services.USER.LOGIN.POST ||
			options.url === edi.rest.services.USER.CHECK_AUTH_TOKEN.POST
		);
	},
	/**
	 * Checks if request is get user data
	 * @param    {Object}    options        request parameters object
	 * @returns  {boolean}
	 */
	isGetUserRequest: function (options) {
		return options.url === edi.rest.services.USER.SELF.GET;
	},
	/**
	 * Checks if request is check_auth_token
	 * @param    {Object}    options        request parameters object
	 * @returns  {boolean}
	 */
	isCheckAuthTokenRequest: function (options) {
		return options.url === edi.rest.services.USER.CHECK_AUTH_TOKEN.POST;
	},
	/**
	 * Adds intercepted pending request parameters into chain
	 * @param    {Object}    options        request parameters object
	 */
	interceptPendingRequest: function (options) {
		this.pendingChain.push(options);
		edi.core.logMessage('Intercepted pending request - ' + options.url);
	},
	/**
	 * Returns current state of interceptor
	 * @returns {boolean}
	 */
	getInterceptionState: function () {
		return this.interceptionActive;
	},
	/**
	 * Sets state of interceptor and flushes chain if we set state to false
	 * @param    {Boolean}    state           true if active
	 * @param    {Boolean}    preventFlush    true to prevent flush
	 */
	setInterceptionState: function (state, preventFlush) {
		if (this.interceptionActive !== state) {
			this.interceptionActive = state;
			if (!this.interceptionActive) {
				if (!preventFlush) {
					this.flushInterceptedChain();
				} else {
					this.clearInterceptedChain();
					this.clearPendingChain();
				}
			}
		}
	},
	/**
	 * Adds intercepted request parameters into chain
	 * @param    {Object}    options        request parameters object
	 */
	interceptRequest: function (options) {
		this.interceptedChain.push(options);
		edi.core.logMessage('Intercepted request - ' + options.url);
	},
	/**
	 * Clears intercepted chain collection
	 */
	clearInterceptedChain: function () {
		this.interceptedChain = [];
	},
	/**
	 * Flushes intercepted chain collection by firing all requests
	 */
	flushInterceptedChain: function () {
		if (this.interceptedChain && this.interceptedChain.length) {
			this.interceptionFlushActive = true;
			while (this.interceptedChain.length) {
				this.request(this.interceptedChain.shift());
			}
			this.interceptionFlushActive = false;
			edi.core.logMessage('Intercepted chain was flushed to server');
		}
	},
	/**
	 * Aborts intercepted request by removing it from chain
	 * @param    {String}    url     request url
	 * @param    {Object}    args    request parameters to compare
	 * @returns  {boolean}
	 */
	abortInterceptedRequest: function (url, args) {
		var aborted = false,
			i,
			requestsEqual,
			requestOptions;
		if (this.interceptedChain && this.interceptedChain.length) {
			for (i = 0; i < this.interceptedChain.length; i++) {
				requestOptions = this.interceptedChain[i];
				if (url === requestOptions.url) {
					requestsEqual = true;
					if (!edi.utils.isEmptyObject(args)) {
						if (requestOptions.proxy && requestOptions.proxy.extraParams) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.proxy.extraParams);
						} else if (requestOptions.params) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.params);
						} else if (requestOptions.operation && requestOptions.operation.params) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.operation.params);
						}
					}
					if (requestsEqual) {
						this.interceptedChain.splice(i, 1);
						edi.core.logMessage('Aborted intercepted request - ' + url);
						aborted = true;
						break;
					}
				}
			}
		}
		if (!aborted && this.pendingChain && this.pendingChain.length) {
			for (i = 0; i < this.pendingChain.length; i++) {
				requestOptions = this.pendingChain[i];
				if (url === requestOptions.url) {
					requestsEqual = true;
					if (!edi.utils.isEmptyObject(args)) {
						if (requestOptions.proxy && requestOptions.proxy.extraParams) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.proxy.extraParams);
						} else if (requestOptions.params) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.params);
						} else if (requestOptions.operation && requestOptions.operation.params) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.operation.params);
						}
					}
					if (requestsEqual) {
						this.pendingChain.splice(i, 1);
						edi.core.logMessage('Aborted intercepted pending request - ' + url);
						aborted = true;
						break;
					}
				}
			}
		}
		return aborted;
	},
	/**
	 * Updates requests counter by adding delta. By default delta is 1
	 * @param delta
	 */
	updateRequestsCounter: function (delta) {
		delta = parseInt(delta, 10);
		delta = !delta ? 1 : delta;
		this.requestsCount += delta;
		var countData = {};
		countData[edi.constants.REQUESTS_COUNTER_ATTR] = this.requestsCount;
		Ext.getBody().set(countData);
	},
	/**
	 * Start Login
	 */
	startLogin: function () {
		var __self = this;

		this.abortAll();
		edi.core.startLogin(function () {
			__self.loginInProcess = false;
			edi.login.getCurrentOrganization(function (failed) {
				__self.setInterceptionState(false, failed);
			});
		});
	}
});

Ext.override(Ext.data.Connection, {
	/**
	 * @overridden
	 * Overrides default method to allow interception of requests
	 * @param    {Object}    options        request parameters object
	 */
	request: function (options) {
		if (
			Ext.Ajax.isRequestPending(options) &&
			!Ext.Ajax.getPendingFlushState() &&
			!Ext.Ajax.interceptionFlushActive
		) {
			Ext.Ajax.interceptPendingRequest(options);
		} else {
			var requestTime = new Date().getTime(),
				userData = edi.core.getUserData(),
				interceptable = !Ext.Ajax.isRequestNotInterceptable(options),
				isLogin = Ext.Ajax.isLoginRequest(options);
			var interseptingTimeLeft =
				this.getLastRequestTime() &&
				userData &&
				this.getLastRequestTime() + edi.constants.ORG_SELECTION_CHECK_TIMEOUT < requestTime;

			if (!isLogin && interseptingTimeLeft) {
				this.setLastRequestTime(requestTime);
				Ext.Ajax.setInterceptionState(true);
				Ext.Ajax.interceptRequest(options);
				edi.login.getCurrentOrganization(function () {
					Ext.Ajax.setInterceptionState(false);
				});
			} else if (interceptable && Ext.Ajax.getInterceptionState()) {
				Ext.Ajax.interceptRequest(options);
			} else {
				if (!Ext.Ajax.isRequestNotInterceptable(options) && Ext.Ajax.getInterceptionState()) {
					Ext.Ajax.interceptRequest(options);
				} else {
					this.setLastRequestTime(requestTime);
					Ext.Ajax.updateRequestsCounter();
					this.callParent([options]);
				}

				if (!Ext.Ajax.getPendingFlushState()) {
					//We flush pending requests chain on any independent request
					Ext.Ajax.flushPendingChain();
				}
			}
		}
	},
	/**
	 * Sets time for last request that was sent to backend
	 * @param    {Number}    time    time of request in ms
	 */
	setLastRequestTime: function (time) {
		this.lastRequestTime = time || 0;
	},
	/**
	 * Sets time for last request that was sent to backend
	 * @returns	{Number}	time of request in ms
	 */
	getLastRequestTime: function () {
		return this.lastRequestTime || 0;
	}
});

Ext.override(Ext.data.request.Ajax, {
	/**
	 * @overridden
	 * To be called when the request has come back from the server
	 * @private
	 * @param {Object} xdrResult
	 * @return {Object} The response
	 */
	//Не нужно для SSO
	//onComplete: function(xdrResult) {
	//	let request = this;
	//	if (Ext.Ajax.loginInProcess && request.aborted) {
	//		Ext.Ajax.interceptRequest(request.options);
	//	}
	//	else {
	//		//We must skip login for ACTIVATION type of authorization
	//		let loginNeeded = (edi.login.getAuthType() !== "ACTIVATION"
	//			&& request.xhr
	//			&& edi.constants.STATUS.NOT_AUTHORISED === String(request.xhr.status));
	//		if (loginNeeded && !Ext.Ajax.isLoginRequest(request.options)) {
	//			Ext.Ajax.setInterceptionState(true);
	//			Ext.Ajax.interceptRequest(request.options);
	//			if (!Ext.Ajax.loginInProcess) {
	//				Ext.Ajax.loginInProcess = true;
	//				if (Ext.Ajax.isGetUserRequest(request.options)
	//					|| Ext.Ajax.isCheckAuthTokenRequest(request.options)) {
	//					return this.callParent(request, xdrResult);
	//				} else {
	//					Ext.Ajax.startLogin();
	//				}
	//			}
	//		}
	//		else {
	//			return this.super(request, xdrResult);
	//		}
	//	}
	//},
	/**
	 * Updating requests counter in case of request finished or aborted
	 * @private
	 */
	cleanup: function () {
		this.callParent();
		Ext.Ajax.updateRequestsCounter(-1);
	}
});

Ext.override(Ext.data.request.Form, {
	onComplete: function () {
		this.callParent();
		Ext.Ajax.updateRequestsCounter(-1);
	}
});

/**
 * Improved reload store method that not loads data if there are no url in ajax proxy
 */
Ext.override(Ext.data.Store, {
	reload: function (options) {
		if (this.proxy && this.proxy.type === 'ajax' && !this.proxy.url) {
			return this;
		} else {
			return this.load(Ext.apply(this.lastOptions, options));
		}
	}
});

Ext.override(Ext.data.proxy.Server, {
	buildRequest: function (operation) {
		const me = this;
		const request = me.callParent([operation]);
		//сетим дополнительные параметры в jsonData Ext.data.Request из proxy
		//нужно для дальнейшего мерджа данных jsonData с params в doRequest (Ext.data.proxy.Ajax)
		if (me.extraJsonData) {
			request.setJsonData(me.extraJsonData);
		}
		return request;
	}
});
/**
 * Paging Memory Proxy, allows to use paging grid with in memory dataset
 */
Ext.define('Ext.ux.data.PagingMemoryProxy', {
	extend: 'Ext.data.proxy.Memory',
	alias: 'proxy.pagingmemory',
	alternateClassName: 'Ext.data.PagingMemoryProxy',
	enablePaging: true
});

/**
 * Improvement for viewport
 */
Ext.override(Ext.container.Viewport, {
	setSize: function (width, height) {
		if (this.rendered) {
			var el = this.el,
				overflowStyleX = '',
				overflowStyleY = '';
			if (width < this.minWidth) {
				overflowStyleX = 'auto';
			}
			if (height < this.minHeight) {
				overflowStyleY = 'auto';
			}
			el.setStyle('overflow-x', overflowStyleX);
			el.setStyle('overflow-y', overflowStyleY);
		}
		this.callOverridden(arguments);
	}
});

/**
 * Fixes for load mask
 */
Ext.override(Ext.LoadMask, {
	onHide: function () {
		this.callOverridden(arguments);
		this.fireEvent('afterhide', this);
	}
});

Ext.override(Ext.window.Window, {
	closeToolText: '',
	onShow: function () {
		var me = this;
		//сбрасываем focus при открытие любого Window,
		//иначе, после закрытия Window, focus "прыгнет" на последний выбранный элемент
		Ext.get(Ext.Element.getActiveElement())?.blur(); //<-- ADDED BY OVERRIDE

		me.callParent(arguments);
		if (me.expandOnShow) {
			me.expand(false);
		}
		me.syncMonitorWindowResize();
		if (me.rendered && me.tabGuard) {
			me.initTabGuards();
		}

		if (edi.navigation) {
			edi.navigation.observer.fireEvent('hidefloatingmenu'); //<-- ADDED BY OVERRIDE
		}
	}
});

Ext.override(Ext.container.Container, {
	getRefItems: function (deep) {
		var me = this,
			items = me.items?.items || [], //<== FIXED BY OVERRIDE
			len = items.length,
			i = 0,
			item,
			result = [];
		for (; i < len; i++) {
			item = items[i];
			result[result.length] = item;
			if (deep && item.getRefItems) {
				result.push.apply(result, item.getRefItems(true));
			}
		}
		// Append floating items to the list.
		if (me.floatingItems) {
			items = me.floatingItems.items;
			len = items.length;
			for (i = 0; i < len; i++) {
				item = items[i];
				result[result.length] = item;
				if (deep && item.getRefItems) {
					result.push.apply(result, item.getRefItems(true));
				}
			}
		}
		return result;
	}
});

Ext.override(Ext.form.field.Time, {
	formatText: null
});

Ext.override(Ext.form.field.Date, {
	formatText: null,
	initComponent: function () {
		this.triggers.picker.tooltip = edi.i18n.getMessage('form.btn.select');
		this.callParent(arguments);
	}
});

/**
 * Multivalue field, that allows to switch between multi/single value. In multi mode displays modal values dialog, allowing to add/remove values, close dialog firing search.
 */
Ext.define('Ext.form.field.multiValue', {
	extend: 'Ext.form.field.Text',
	multiQtip: function () {
		return edi.i18n.getMessage('form.field.multi.trigger.switch.single.action');
	},
	singleQtip: function () {
		return edi.i18n.getMessage('form.field.multi.trigger.switch.multi.action');
	},
	columnConfigName: 'simple_editable',
	columnConfig: null,
	singleModeValidation: null,
	alias: ['widget.multiValue', 'widget.multivalue', 'widget.multiValueField'],
	alternateClassName: ['Ext.form.multiValue'],
	multiMode: true,
	defaultValues: [],
	monitorTab: false,
	cellEditingPluginConfig: {},
	triggers: {
		mode_multi: {
			extraCls: 'edi-icon edi-icon-MULTI_MODE_OFF',
			hidden: true,
			handler(cmp, target, e) {
				var me = this;
				e.stopPropagation();
				me.setMultiMode(!me.multiMode);
				if (me.multiMode) {
					me.showValuesModal();
				}
			}
		},
		mode_single: {
			extraCls: 'edi-icon edi-icon-MULTI_MODE',
			hidden: true,
			handler(cmp, target, e) {
				var me = this;
				e.stopPropagation();
				me.setMultiMode(!me.multiMode);
				if (me.multiMode) {
					me.showValuesModal();
				}
			}
		}
	},
	initComponent: function () {
		var me = this;
		me.values = [];
		if (me.defaultValues.length) {
			me.values = Ext.Array.clone(me.defaultValues);
		}
		me.rawValue = me.multiMode ? me.values.join(', ') : me.values[0];

		me.triggers.mode_multi.tooltip = me.multiQtip();
		me.triggers.mode_single.tooltip = me.singleQtip();

		this.callParent(arguments);
		this.on('afterrender', function () {
			let me = this;
			me.setMultiMode(me.multiMode);
			me.mon(me.el, 'click', function () {
				if (me.multiMode) {
					me.showValuesModal();
				}
			});
		});
	},
	getRawValue: function () {
		var me = this,
			v = me.callParent();
		if (me.multiMode && me.values.length) {
			v = me.values.join(', ');
		}
		return v;
	},
	setMultiMode: function (mode) {
		var me = this,
			value = me.getValue();
		let newMode = !!mode;
		if (me.multiMode !== newMode) {
			if (newMode) {
				if (value) {
					me.values.length ? (me.values[0] = value) : me.values.push(value);
				} else if (me.values.length) {
					me.values.shift();
				}
			} else {
				me.value = me.values[0];
			}
		}
		me.multiMode = newMode;
		me.setValidation();
		me.setValue(me.multiMode ? me.values : me.value, true);

		if (me.hideTrigger === true) {
			me.triggers.mode_multi.hide();
			me.triggers.mode_single.hide();
		} else {
			me.triggers.mode_multi[!me.multiMode ? 'hide' : 'show']();
			me.triggers.mode_single[me.multiMode ? 'hide' : 'show']();
		}

		me.setEditable(!me.multiMode);
		me.up('form').isValid();
	},
	setValidation: function () {
		var me = this,
			i;
		if (me.singleModeValidation) {
			for (i in me.singleModeValidation) {
				if (me.singleModeValidation.hasOwnProperty(i)) {
					me[i] = !me.multiMode ? me.singleModeValidation[i] : null;
				}
			}
		}
	},
	getValue: function () {
		var me = this,
			v;
		if (me.multiMode) {
			v = Ext.clone(me.values);
		} else {
			v = me.callParent();
		}
		return v;
	},
	getSubmitValue: function () {
		var me = this,
			v;
		if (me.multiMode) {
			v = me.values;
		} else {
			v = me.callParent();
		}
		return v;
	},
	setValue: function (value, skipProcessing) {
		var me = this,
			v;
		if (!skipProcessing) {
			me.values = [];
			if (Ext.isArray(value)) {
				v = value[0];
				if (me.multiMode) {
					me.values = Ext.Array.clone(value);
				}
			} else {
				v = value;
				if ('undefined' != typeof value && null != value) {
					me.values.push(v);
				}
			}
		}
		me.callParent(arguments);
		return me;
	},
	/**
	 * Sets additional properties for editable column config
	 * @param config
	 * @returns {*}
	 */
	valuesColumnConfigurator: function (config) {
		var me = this;
		if (me.columnConfig) {
			Ext.merge(config, me.columnConfig);
		}
		return config;
	},
	/**
	 * Displays modal dialog for values handling
	 */
	showValuesModal: function () {
		if (this.modalInstance && !this.modalInstance.isDestroyed) {
			this.revertFocus();
			return;
		}
		var me = this,
			columns = edi.columns.get(me.columnConfigName, function (config) {
				return me.valuesColumnConfigurator(config);
			});
		columns.push(
			createActionsColumnConfig({
				items: [
					{
						glyph: edi.constants.ICONS.REMOVE,
						handler: function (grid, rowIndex) {
							grid.getStore().removeAt(rowIndex);
						}
					}
				]
			})
		);
		var grid = createGrid({
				enableTextSelection: true,
				store: edi.stores.createDisplayingStore(me.values, 'SIMPLE_MULTIVALUE', 'name'),
				gridConfig: {
					plugins: [
						Ext.create(
							'Ext.grid.plugin.CellEditing',
							Ext.merge(
								{
									clicksToEdit: 1,
									forceFieldValidation: true
								},
								me.cellEditingPluginConfig
							)
						)
					],
					columns: columns,
					disablePaging: true,
					cls: 'is-edit',
					hideSettingsButton: me.hideSettingsButton,
					hideHeaders: true,
					dockedItems: [
						new Ext.toolbar.Toolbar({
							xtype: 'toolbar',
							cls: 'edi-grid-selection',
							dock: 'top',
							items: [
								createButton({
									tooltip: edi.constants.USE_TOOLTIPS
										? edi.i18n.getMessage('form.btn.add')
										: undefined,
									text: !edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage('form.btn.add') : undefined,
									cls: 'edi-grid-actions',
									glyph: edi.constants.ICONS.ADD,
									handler: function () {
										var store = grid.getStore(),
											rec = store.add(edi.models.createInstance('SIMPLE'));
										rec[0].store = store;
										grid.editingPlugin.startEdit(
											rec[0],
											grid.getView().ownerCt.columnManager.getHeaderAtIndex(0)
										);
									}
								})
							]
						})
					]
				}
			}),
			modal;
		modal = createModalPanel({
			cls: 'edi-modal-form',
			width: edi.constants.DEFAULT.MODAL.WIDTH,
			height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGE,
			title: edi.i18n.getMessage('form.field.multi.values.modal.title'),
			items: [grid],
			alwaysOnTop: true,
			buttons: [
				edi.special[this.buttonMethodName ? this.buttonMethodName : 'createSearchButton'](
					function () {
						var records = grid.getStore().getRange(),
							i,
							values = [],
							val;
						for (i = 0; i < records.length; i++) {
							val = Ext.String.trim(records[i].get('name'));
							if (val) {
								values.push(val);
							}
						}
						me.setValue(values);
						modal.close();
					},
					{
						formBind: false,
						disabled: false
					}
				)
			]
		});
		me.modalInstance = modal;
		modal.show();
	}
});

/**
 * Simple canvasJs wrapper for rendering charts, use elements chart property to get access for
 */
Ext.define('Ext.chart.CanvasJsChart', {
	extend: 'Ext.panel.Panel',
	alias: 'widget.CanvasJsChart',
	alternateClassName: ['Ext.chart.CnvCrt', 'Ext.CanvasJsChart', 'Ext.CnvCrt'],
	chartConfig: null,
	chart: null,
	chartCfg: null,
	seriesMap: null,
	defaultChartConfig: {
		culture: 'edi',
		legend: {
			cursor: 'pointer',
			itemclick: function (e) {
				e.dataSeries.visible = !(typeof e.dataSeries.visible === 'undefined' || e.dataSeries.visible);
				e.chart.render();
			},
			fontSize: 15
		}
	},
	initComponent: function () {
		var me = this;
		me.addEdiCulture();
		me.setDefaultChartConfig();
		me.setSeriesMap();
		me.callParent();
		me.on('afterrender', me.afterCompRender);
		me.on('afterlayout', me.afterCompLayout);
	},
	addEdiCulture: function () {
		if (!CanvasJS.ediCultureAdded) {
			CanvasJS.addCultureInfo('edi', {
				decimalSeparator: ',',
				digitGroupSeparator: ' ',
				days: [
					edi.i18n.getMessage('day.sunday'),
					edi.i18n.getMessage('day.monday'),
					edi.i18n.getMessage('day.tuesday'),
					edi.i18n.getMessage('day.wednesday'),
					edi.i18n.getMessage('day.thursday'),
					edi.i18n.getMessage('day.friday'),
					edi.i18n.getMessage('day.saturday')
				],
				shortDays: [
					edi.i18n.getMessage('day.sunday.short'),
					edi.i18n.getMessage('day.monday.short'),
					edi.i18n.getMessage('day.tuesday.short'),
					edi.i18n.getMessage('day.wednesday.short'),
					edi.i18n.getMessage('day.thursday.short'),
					edi.i18n.getMessage('day.friday.short'),
					edi.i18n.getMessage('day.saturday.short')
				],
				zoomText: edi.i18n.getMessage('canvasjs.zoom.text'),
				panText: edi.i18n.getMessage('canvasjs.pan.text'),
				resetText: edi.i18n.getMessage('canvasjs.reset.text'),
				printText: edi.i18n.getMessage('canvasjs.print.text'),
				savePNGText: edi.i18n.getMessage('canvasjs.save.png.text'),
				saveJPGText: edi.i18n.getMessage('canvasjs.save.jpg.text'),
				menuText: edi.i18n.getMessage('canvasjs.menu.text'),
				months: [
					edi.i18n.getMessage('month.january'),
					edi.i18n.getMessage('month.february'),
					edi.i18n.getMessage('month.march'),
					edi.i18n.getMessage('month.april'),
					edi.i18n.getMessage('month.may'),
					edi.i18n.getMessage('month.june'),
					edi.i18n.getMessage('month.july'),
					edi.i18n.getMessage('month.august'),
					edi.i18n.getMessage('month.september'),
					edi.i18n.getMessage('month.october'),
					edi.i18n.getMessage('month.november'),
					edi.i18n.getMessage('month.december')
				],
				shortMonths: [
					edi.i18n.getMessage('month.january.short'),
					edi.i18n.getMessage('month.february.short'),
					edi.i18n.getMessage('month.march.short'),
					edi.i18n.getMessage('month.april.short'),
					edi.i18n.getMessage('month.may.short'),
					edi.i18n.getMessage('month.june.short'),
					edi.i18n.getMessage('month.july.short'),
					edi.i18n.getMessage('month.august.short'),
					edi.i18n.getMessage('month.september.short'),
					edi.i18n.getMessage('month.october.short'),
					edi.i18n.getMessage('month.november.short'),
					edi.i18n.getMessage('month.december.short')
				]
			});
			CanvasJS.ediCultureAdded = true;
		}
	},
	/**
	 * Apply default config properties
	 */
	setDefaultChartConfig: function () {
		var me = this;
		me.chartCfg = Ext.apply({}, me.defaultChartConfig, me.chartConfig);
	},
	/**
	 * Defines series map based on seriesId defined in data objects
	 */
	setSeriesMap: function () {
		var me = this,
			chartData = me.chartCfg.data,
			i;
		if (me.chart) {
			chartData = me.chart.options.data;
		}
		me.seriesMap = {};
		if (chartData.length) {
			for (i = 0; i < chartData.length; i++) {
				if (chartData[i].seriesId) {
					me.seriesMap[chartData[i].seriesId] = chartData[i];
				}
			}
		}
	},
	/**
	 * Event listener that called after panel is rendered to render chart
	 */
	afterCompRender: function () {
		var me = this;
		me.chart = new CanvasJS.Chart(me.body.dom, me.chartCfg);
		me.setSeriesMap();
		if (me.chartCfg.disableZoomResetBtns) {
			me.on(
				'afterlayout',
				function () {
					var me = this;
					var buttonsContainer = Ext.query('div.canvasjs-chart-toolbar', me.body.dom);
					if (buttonsContainer) {
						buttonsContainer[0].children[1].className = 'buttonHidden';
						buttonsContainer[0].children[0].className = 'buttonHidden';
					}
				},
				undefined,
				{
					single: true
				}
			);
		}
	},
	/**
	 * Rerender chart after layout is finished by container
	 */
	afterCompLayout: function () {
		var me = this;
		me.chart.render();
	},
	/**
	 * Routine that should be done before component destroy
	 */
	beforeDestroy: function () {
		var me = this;
		if (me.chart) {
			me.chart.destroy();
			me.chart = null;
		}
		me.callParent();
	},
	/**
	 * returns chart object
	 */
	getChart: function () {
		return this.chart;
	},
	/**
	 * Resets chart to initial state from zooming/panning and rerender it
	 * @param    {Boolean}      skipRendering    true skip rendering of chart
	 */
	resetChart: function (skipRendering) {
		var me = this,
			chart = me.getChart();
		if (chart) {
			chart.options.axisX.viewportMinimum = chart.options.axisX.viewportMaximum = null;
			chart.options.axisY.viewportMinimum = chart.options.axisY.viewportMaximum = null;
		}
		if (chart && !skipRendering && me.rendered && !me.destroying && !me.isDestroyed) {
			chart.render();
		}
	},
	/**
	 * Sets datapoints collections passed from data in the order of they were declared in configuration.
	 * @param    {Array}      data      collection of dataPoints collections
	 * @param    {Boolean}    append    true to append values to the end of series set
	 */
	setRawDataPoints: function (data, append) {
		var me = this,
			chart = me.getChart(),
			chartData = me.chartCfg.data,
			i;
		if (chart) {
			chartData = chart.options.data;
		}
		if (chartData && chartData.length) {
			for (i = 0; i < chartData.length; i++) {
				if (append) {
					if (Ext.isArray(data[i])) {
						chartData[i].dataPoints = chartData[i].dataPoints.concat(data[i]);
					}
				} else {
					chartData[i].dataPoints = Ext.isArray(data[i]) ? data[i] : [];
				}
			}
		}
		if (chart && me.rendered && !me.destroying && !me.isDestroyed) {
			chart.render();
		}
	},
	/**
	 * Object containing properties named according chart seriesIds containing collections of data points
	 * @param    {Object}     data      object with datapoints in properties named as series id
	 * @param    {Boolean}    append    true to append values to the end of series set
	 */
	setDataPoints: function (data, append) {
		var me = this,
			chart = me.getChart(),
			i;
		if (data) {
			for (i in data) {
				if (data.hasOwnProperty(i) && me.seriesMap[i]) {
					if (append) {
						if (Ext.isArray(data[i])) {
							me.seriesMap[i].dataPoints = me.seriesMap[i].dataPoints.concat(data[i]);
						}
					} else {
						me.seriesMap[i].dataPoints = Ext.isArray(data[i]) ? data[i] : [];
					}
				}
			}
		}
		if (chart && me.rendered && !me.destroying && !me.isDestroyed) {
			chart.render();
		}
	}
});

/**
 * Month field control
 */
Ext.define('Ext.form.field.Month', {
	extend: 'Ext.form.field.Date',
	alias: 'widget.monthfield',
	alternateClassName: ['Ext.form.MonthField', 'Ext.form.Month'],
	selectMonth: null,
	createPicker: function () {
		var me = this,
			format = Ext.String.format;
		return Ext.create('Ext.picker.Month', {
			pickerField: me,
			ownerCt: me.ownerCt,
			renderTo: document.body,
			floating: true,
			hidden: true,
			focusOnShow: true,
			cls: 'edi-month-picker',
			minDate: me.minValue,
			maxDate: me.maxValue,
			disabledDatesRE: me.disabledDatesRE,
			disabledDatesText: me.disabledDatesText,
			disabledDays: me.disabledDays,
			disabledDaysText: me.disabledDaysText,
			format: me.format,
			showToday: me.showToday,
			startDay: me.startDay,
			minText: format(me.minText, me.formatDate(me.minValue)),
			maxText: format(me.maxText, me.formatDate(me.maxValue)),
			listeners: {
				select: {
					scope: me,
					fn: me.onSelect
				},
				monthdblclick: {
					scope: me,
					fn: me.onOKClick
				},
				yeardblclick: {
					scope: me,
					fn: me.onOKClick
				},
				OkClick: {
					scope: me,
					fn: me.onOKClick
				},
				CancelClick: {
					scope: me,
					fn: me.onCancelClick
				}
			},
			keyNavConfig: {
				esc: function () {
					me.collapse();
				}
			}
		});
	},
	onCancelClick: function () {
		var me = this;
		me.selectMonth = null;
		me.collapse();
	},
	onOKClick: function () {
		var me = this;
		if (me.selectMonth) {
			me.setValue(me.selectMonth);
			me.fireEvent('select', me, me.selectMonth);
		} else {
			me.setValue(new Date());
		}
		me.collapse();
	},
	onSelect: function (m, d) {
		var me = this;
		me.selectMonth = new Date(d[0] + 1 + '/1/' + d[1]);
	}
});

Ext.override(Ext.Component, {
	constructor: function () {
		this.callParent(arguments);

		var fieldsRenderMethods = function (cmpConfig) {
			if (cmpConfig.name) {
				return cmpConfig.name;
			}
			return null;
		};
		var classesForTestCssClss = {
			'Ext.form.field.Text': {
				name: 'textField',
				renderMethod: fieldsRenderMethods
			},
			'Ext.form.field.ComboBox': {
				name: 'comboBox',
				renderMethod: fieldsRenderMethods
			},
			'Ext.form.field.Checkbox': {
				name: 'checkbox',
				renderMethod: fieldsRenderMethods
			},
			'Ext.form.field.Date': {
				name: 'dateField',
				renderMethod: fieldsRenderMethods
			},
			'Ext.form.field.TextArea': {
				name: 'areaField',
				renderMethod: fieldsRenderMethods
			},
			'Ext.tab.Tab': {
				name: 'tab',
				renderMethod: function (cmpConfig) {
					if (
						cmpConfig.hasOwnProperty('card') &&
						cmpConfig.card &&
						cmpConfig.card.hasOwnProperty('tabName')
					) {
						return cmpConfig.card.tabName;
					}
					return null;
				}
			},
			'Ext.button.Button': {
				name: 'button',
				renderMethod: function (cmpConfig, cmp) {
					var iconKey;
					if (cmpConfig.glyph) {
						iconKey = Ext.Object.getKey(edi.constants.ICONS, cmpConfig.glyph);
					}
					if (!iconKey && cmp.cssTestSuffix) {
						iconKey = cmp.cssTestSuffix;
					}
					return iconKey ? iconKey.toLowerCase() : null;
				}
			}
		};

		var cls;
		var className = Ext.getClassName(this);
		if (classesForTestCssClss.hasOwnProperty(className)) {
			cls = 'test-' + classesForTestCssClss[className].name;
			if (
				classesForTestCssClss[className].renderMethod &&
				'function' == typeof classesForTestCssClss[className].renderMethod
			) {
				var classSuffix = classesForTestCssClss[className].renderMethod(arguments[0], this);
				cls += '-' + (classSuffix ? classSuffix : this.id);
			}
		}
		if (cls) {
			this.cls = cls;
		}
	}
});

/**
 * Tooltips support for form fields
 */
Ext.override(Ext.form.Field, {
	afterRender: function () {
		this.callOverridden(arguments);
		if (this.qtipText) {
			try {
				Ext.tip.QuickTipManager.register({
					target: this.getEl().id,
					text: edi.i18n.getMessage(edi.utils.safeString(this.qtipText))
				});
			} catch (e) {}
		}
	},
	clearTip: function () {
		Ext.tip.QuickTipManager.unregister(this.getEl().id);
	},
	setToolTip: function (tooltip, initial) {
		var me = this;
		tooltip = edi.utils.safeString(tooltip);
		if (me.rendered) {
			if (!initial) {
				me.clearTip();
			}

			Ext.tip.QuickTipManager.register({
				target: me.getEl().id,
				text: tooltip
			});
		} else {
			me.qtipText = tooltip;
		}

		return me;
	},
	onDestroy: function () {
		if (this.qtipText) {
			try {
				Ext.tip.QuickTipManager.unregister(this.getEl().id);
			} catch (e) {}
		}
		this.callOverridden(arguments);
	}
});

/**
 * Tooltips support for form Label
 */
Ext.override(Ext.form.Label, {
	afterRender: function () {
		this.callOverridden(arguments);
		if (this.qtipText) {
			try {
				Ext.tip.QuickTipManager.register({
					target: this.getEl().id,
					text: edi.i18n.getMessage(edi.utils.safeString(this.qtipText))
				});
			} catch (e) {}
		}
	},
	clearTip: function () {
		Ext.tip.QuickTipManager.unregister(this.getEl().id);
	},
	setToolTip: function (tooltip, initial) {
		var me = this;
		tooltip = edi.utils.safeString(tooltip);
		if (me.rendered) {
			if (!initial) {
				me.clearTip();
			}

			Ext.tip.QuickTipManager.register({
				target: me.getEl().id,
				text: tooltip
			});
		} else {
			me.qtipText = tooltip;
		}

		return me;
	},
	onDestroy: function () {
		if (this.qtipText) {
			try {
				Ext.tip.QuickTipManager.unregister(this.getEl().id);
			} catch (e) {}
		}
		this.callOverridden(arguments);
	}
});

/**
 * Fixes for number field, get currency with zero after point
 */
Ext.override(Ext.form.NumberField, {
	currWithZero: false,
	emptyValue: '',
	valueToRaw: function (value) {
		var me = this,
			decimalSeparator = me.decimalSeparator,
			decimalPrecision = me.decimalPrecision;
		value = me.parseValue(value);
		value = me.fixPrecision(value);
		value = Ext.isNumber(value) ? value : parseFloat(String(value).replace(decimalSeparator, '.'));
		value = isNaN(value) ? '' : String(value).replace('.', decimalSeparator);
		if (me.currWithZero) {
			value = Ext.isNumber(value) ? value : parseFloat(String(value).replace(decimalSeparator, '.'));
			value = parseFloat(value).toFixed(decimalPrecision);
		}
		return value;
	},
	rawToValue: function (value) {
		var me = this;
		if (value === '') {
			return me.emptyValue;
		}
		return Ext.isNumber(value) ? value : parseFloat(String(value).replace(me.decimalSeparator, '.'));
	}
});

Ext.define('Ext.selection.FastCheckboxModel', {
	extend: 'Ext.selection.CheckboxModel',
	alias: 'widget.fastCheckboxSelectionModel',
	updateHeaderState: Ext.emptyFn,

	constructor: function () {
		this.callParent(arguments);
		this.on('selectionchange', function (model, sels) {
			model.toggleUiHeader(sels.length && sels.length === model.store.getCount());
		});
	}
});

Ext.override(Ext.grid.column.Check, {
	headerCheckboxCls: Ext.baseCSSPrefix + 'column-header-checkbox test-checkbox-col',
	innerCls: Ext.baseCSSPrefix + 'grid-checkcolumn-cell-inner test-checkbox-col'
});

/**
 * Added reset event for form
 * Added ability to bind external elements to form
 */
Ext.override(Ext.form.Basic, {
	reset: function (resetRecord) {
		Ext.suspendLayouts();

		var me = this,
			fields = me.getFields().items,
			f,
			fLen = fields.length;

		for (f = 0; f < fLen; f++) {
			fields[f].reset();
		}

		Ext.resumeLayouts(true);

		if (resetRecord === true) {
			delete me._record;
		}
		me.fireEvent('reset', me);
		return me;
	},
	getBoundItems: function () {
		var boundItems = this._boundItems;
		if (!boundItems || boundItems.getCount() === 0) {
			boundItems = this._boundItems = new Ext.util.MixedCollection();
			let formBindElements = this.owner.query('[formBind]'); //<--- OVERRIDE
			if (!formBindElements.length && Ext.isArray(this.owner.bindElements)) {
				//<--- OVERRIDE
				formBindElements = this.owner.bindElements; //<--- OVERRIDE
			} //<--- OVERRIDE
			boundItems.addAll(formBindElements); //<--- OVERRIDE
		}
		return boundItems;
	}
});

/**
 * Added reset event for field
 */
Ext.override(Ext.form.BaseField, {
	//triggerWidth: 22,
	reset: function () {
		var me = this;

		me.beforeReset();
		me.setValue(me.originalValue);
		me.clearInvalid();
		// delete here so we reset back to the original state
		delete me.wasValid;
		me.fireEvent('reset', me);
	}
});

// fix hide submenu (in chrome 43)
Ext.override(Ext.menu.Menu, {
	onMouseLeave: function (e) {
		var me = this;
		me.callParent([e]);
		// We need to make sure that menus do not "remember" the last focused item
		// so that the first menu item is always activated when the menu is shown.
		// This is the expected behavior according to WAI-ARIA spec.
		me.lastFocusedChild = null;
		me.mixins.focusablecontainer.onFocusLeave.call(me, e);
		if (me.floating) {
			//me.hide(); <--- OVERRIDE
		}
	}
});

// cell editing. change on typing
Ext.override(Ext.Editor, {
	initComponent: function () {
		var me = this,
			field = (me.field = Ext.ComponentManager.create(me.field || {}, 'textfield'));
		field.msgTarget = field.msgTarget || 'qtip';
		me.mon(field, {
			scope: me,
			//OVERRIDE begin
			change: function (cp, newValue, oldValue) {
				me.fireEvent('change', me, newValue, oldValue);
			},
			//OVERRIDE end
			specialkey: me.onSpecialKey
		});
		if (field.grow) {
			me.mon(field, 'autosize', me.onFieldAutosize, me, {
				delay: 1
			});
		}
		me.floating = {
			constrain: me.constrain
		};
		me.items = field;
		me.callParent();
	}
});

Ext.override(Ext.grid.plugin.CellEditing, {
	showEditor: function (ed, context, value) {
		var me = this,
			record = context.record,
			columnHeader = context.column,
			sm = me.grid.getSelectionModel(),
			selection = sm.getCurrentPosition(),
			otherView = selection && selection.view;

		// Selection is for another view.
		// This can happen in a lockable grid where there are two grids, each with a separate Editing plugin
		if (otherView && otherView !== me.view) {
			return me.lockingPartner.showEditor(
				ed,
				me.lockingPartner.getEditingContext(selection.record, selection.columnHeader),
				value
			);
		}

		me.setEditingContext(context);
		me.setActiveEditor(ed);
		me.setActiveRecord(record);
		me.setActiveColumn(columnHeader);

		// Select cell on edit only if it's not the currently selected cell
		if (
			!sm.checkOnly &&
			sm.selectByPosition &&
			(!selection || selection.column !== context.colIdx || selection.row !== context.rowIdx)
		) {
			sm.selectByPosition({
				row: context.rowIdx,
				column: context.colIdx,
				view: me.view
			});
		}

		ed.startEdit(me.getCell(record, columnHeader), value, context);
		me.editing = true;
		me.scroll = me.view.el.getScroll();
	}
});

/******* fix tooltip width issue with safari 7 Mac for extjs4.2.2 *******/
if (Ext.isSafari && Ext.safariVersion >= 7) {
	// Override button to fix tooltip issue on Safari
	delete Ext.tip.Tip.prototype.minWidth;
}

/**
 * Override renderer grid action column for using isHidden method in actions
 */
Ext.override(Ext.grid.column.Action, {
	defaultRenderer: function (v, cellValues, record, rowIdx, colIdx, store, view) {
		var me = this,
			scope = me.origScope || me,
			items = me.items,
			len = items.length,
			i,
			item,
			ret,
			disabled,
			tooltip,
			altText,
			icon,
			glyph,
			tabIndex,
			ariaRole;
		// Allow a configured renderer to create initial value (And set the other values
		// in the "metadata" argument!)
		// Assign a new variable here, since if we modify "v" it will also modify the arguments
		// collection, meaning we will pass an incorrect value to getClass/getTip
		ret = Ext.isFunction(me.origRenderer) ? me.origRenderer.apply(scope, arguments) || '' : '';
		cellValues.tdCls += ' ' + Ext.baseCSSPrefix + 'action-col-cell';
		/* eslint-disable max-len */
		for (i = 0; i < len; i++) {
			item = items[i];

			//OVERRIDE begin
			if (
				typeof item.isHidden === 'function' &&
				item.isHidden.call(item.scope || scope, view, rowIdx, colIdx, item, record)
			) {
				continue;
			}
			//OVERRIDE end

			icon = item.icon;
			glyph = item.glyph;
			disabled =
				item.disabled ||
				(item.isActionDisabled
					? Ext.callback(
							item.isActionDisabled,
							item.scope || me.origScope,
							[view, rowIdx, colIdx, item, record],
							0,
							me
					  )
					: false);
			tooltip =
				item.tooltip ||
				(item.getTip ? Ext.callback(item.getTip, item.scope || me.origScope, arguments, 0, me) : null);
			altText = item.getAltText
				? Ext.callback(item.getAltText, item.scope || me.origScope, arguments, 0, me)
				: item.altText || me.altText;

			//OVERRIDE begin
			glyph = Ext.isFunction(item.getGlyph) ? item.getGlyph.apply(item.scope || scope, arguments) : item.glyph;
			//OVERRIDE end

			// Only process the item action setup once.
			if (!item.hasActionConfiguration) {
				// Apply our documented default to all items
				item.stopSelection = me.stopSelection;
				item.disable = Ext.Function.bind(me.disableAction, me, [i], 0);
				item.enable = Ext.Function.bind(me.enableAction, me, [i], 0);
				item.hasActionConfiguration = true;
			}
			// If the ActionItem is using a glyph, convert it to an Ext.Glyph instance so we can extract the data easily.
			if (glyph) {
				glyph = Ext.Glyph.fly(glyph);
			}
			// Pull in tabIndex and ariarRols from item, unless the item is this, in which case
			// that would be wrong, and the icon would get column header values.
			tabIndex = item !== me && item.tabIndex !== undefined ? item.tabIndex : me.itemTabIndex;
			ariaRole = item !== me && item.ariaRole !== undefined ? item.ariaRole : me.itemAriaRole;
			ret +=
				'<' +
				(icon ? 'img' : 'div') +
				(typeof tabIndex === 'number' ? ' tabIndex="' + tabIndex + '"' : '') +
				(ariaRole ? ' role="' + ariaRole + '"' : ' role="presentation"') +
				(' aria-label="' + me.text + '"') +
				(icon ? ' alt="' + altText + '" src="' + item.icon + '"' : '') +
				' class="' +
				me.actionIconCls +
				' ' +
				Ext.baseCSSPrefix +
				'action-col-' +
				String(i) +
				' ' +
				(disabled ? me.disabledCls + ' ' : ' ') +
				(item.hidden ? Ext.baseCSSPrefix + 'hidden-display ' : '') +
				(item.getClass
					? Ext.callback(item.getClass, item.scope || me.origScope, arguments, undefined, me)
					: item.iconCls || me.iconCls || '') +
				' ' +
				(item.testCls || '') +
				'"' +
				(tooltip ? ' data-qtip="' + Ext.util.Format.htmlEncode(tooltip) + '"' : '') +
				(icon
					? '/>'
					: glyph
					? ' style="font-family:' + glyph.fontFamily + '">' + glyph.character + '</div>'
					: '></div>');
		}
		/* eslint-enable max-len */
		return ret;
	}
});

/**
 * Plugin for grid - subgrid in row
 */
Ext.define('Ext.ux.grid.RowSubGrid', {
	extend: 'Ext.grid.plugin.RowExpander',
	alias: 'plugin.rowsubgrid',

	rowBodyTpl: [
		'{%this.renderComponent(values);%}',
		'<div id="{[this.subGrid.grid.id]}-component-subgrid-{[values[this.subGrid.rowId]]}"></div>',
		{
			renderComponent: function (rowValues) {
				var me = this.subGrid,
					grid = me.grid,
					store = grid.getStore(),
					recordId = me.rowId ? rowValues[me.rowId] : rowValues.id,
					record = store.findRecord(me.rowId ? me.rowId : 'id', recordId, 0, false, true, true),
					componentWrapId = grid.id + '-component-subgrid-' + recordId;
				var recordData = record.getData();
				var subRecordData = edi.utils.getObjectProperty(recordData, me.parentPropName);

				if (me.components[recordId]) {
					me.components[recordId].destroy();
					delete me.components[recordId];
				}
				if (
					!me.components[recordId] &&
					Ext.isArray(subRecordData) &&
					subRecordData.length &&
					me.columns &&
					me.columns.length &&
					me.model
				) {
					me.components[recordId] = createGrid({
						enableTextSelection: !!me.enableTextSelection,
						gridConfig: {
							columns: me.columns,
							disableSelection: true,
							disablePaging: true,
							width: me.width ? me.width : undefined
						},
						storeConfig: {
							proxy: createProxyConfig({
								type: 'memory',
								data: subRecordData
							}),
							model: me.model
						}
					});
					me.components[recordId].componentId = componentWrapId;
				}
			}
		}
	],
	init: function (grid) {
		var me = this,
			store = grid.getStore(),
			view = grid.getView(),
			recordIdName = me.rowId ? me.rowId : 'id';

		me.components = {};
		me.callParent(arguments);

		this.grid = grid;
		this.rowBodyTpl.subGrid = this;

		grid.on('beforedestroy', me.destroyComponents, me);
		store.on('beforeload', me.destroyComponents, me);
		store.on('filterchange', me.onParentFilterChange, me);

		view.on('expandbody', me.onExpandBody, me);

		var rerenderSubGrid = function (componentId, internalId) {
			if (me.recordsExpanded[internalId] && me.components[componentId] && !me.components[componentId].rendered) {
				me.components[componentId].on(
					'afterrender',
					function (component) {
						this.grid.layout.redoLayout();
						component.layout.redoLayout();
					},
					me,
					{
						delay: 10,
						single: true
					}
				);
				me.components[componentId].render(me.components[componentId].componentId);
			}
		};
		view.on('itemupdate', function (record) {
			rerenderSubGrid(record.get(recordIdName), record.internalId);
		});
		view.on('refresh', function () {
			var internalId, rec;
			for (internalId in me.recordsExpanded) {
				if (me.recordsExpanded.hasOwnProperty(internalId)) {
					rec = store.data.get(internalId);
					if (rec) {
						rerenderSubGrid(rec.get(recordIdName), internalId);
					}
				}
			}
		});
		// modify getRefItems method of grid to allow querying components from rowbody
		grid.getRefItems = (function () {
			var originalFn = grid.getRefItems;
			return function (deep) {
				var result = originalFn.call(grid, deep);

				if (deep) {
					for (var i in me.components) {
						if (me.components.hasOwnProperty(i)) {
							result.push(me.components[i]);
							result.push.apply(result, me.components[i].getRefItems(true));
						}
					}
				}
				return result;
			};
		})();
	},

	destroyComponents: function () {
		var me = this,
			components = me.components;

		for (var i in components) {
			if (components.hasOwnProperty(i)) {
				components[i].destroy();
			}
		}
		me.components = {};
	},

	onExpandBody: function (rowNode, record) {
		var me = this,
			grid = me.grid,
			recordId = me.rowId ? record.get(me.rowId) : record.getId(),
			componentWrapId = grid.id + '-component-subgrid-' + recordId,
			component = me.components[recordId];

		if (component && !component.rendered) {
			component.on(
				'afterrender',
				function () {
					grid.layout.redoLayout();
					component.layout.redoLayout();
				},
				me,
				{
					delay: 10,
					single: true
				}
			);
			component.render(componentWrapId);
		}
	},

	onParentFilterChange: function (store, filters) {
		var me = this,
			i,
			applicableFilters = [],
			compStore;
		for (i = 0; i < filters.length; i++) {
			if (filters[i].subGrid) {
				applicableFilters.push(filters[i].subGrid);
			}
		}
		for (i in me.components) {
			if (me.components.hasOwnProperty(i)) {
				compStore = me.components[i].getStore();
				if (applicableFilters.length) {
					compStore.clearFilter(true);
					compStore.filter(applicableFilters);
				} else {
					compStore.clearFilter();
				}
			}
		}
	}
});

Ext.override(Ext.toolbar.Paging, {
	updateInfo: function () {
		var me = this,
			displayItem = me.child('#displayItem'),
			store = me.store,
			pageData = me.getPageData(),
			count,
			msg;

		if (displayItem) {
			count = store.getCount();
			if (count === 0) {
				msg = Ext.String.format(me.displayMsg, 0, pageData.toRecord, pageData.total);
			} else {
				msg = Ext.String.format(me.displayMsg, pageData.fromRecord, pageData.toRecord, pageData.total);
			}
			displayItem.setFieldLabel(msg);
		}
	},
	getPagingItems: function () {
		var me = this;
		return [
			{
				itemId: 'prev',
				tooltip: me.prevText,
				overflowText: me.prevText,
				glyph: edi.constants.ICONS.KEYBOARD_ARROW_LEFT,
				disabled: true,
				handler: me.movePrevious,
				scope: me
			},
			{
				itemId: 'next',
				tooltip: me.nextText,
				overflowText: me.nextText,
				glyph: edi.constants.ICONS.KEYBOARD_ARROW_RIGHT,
				disabled: true,
				handler: me.moveNext,
				scope: me
			}
		];
	}
});

Ext.override(Ext.Component, {
	showModalNotice: function (msg, delay) {
		var me = this,
			modal,
			timeout = delay || 1000;

		if (me.rendered && msg && 'string' == typeof msg) {
			modal = Ext.create('Ext.window.Window', {
				width: 200,
				height: 20,
				closable: false,
				header: false,
				html: msg,
				cls: 'edi-grid-notify-mask x-mask-msg-text',
				modal: true,
				margin: 5
			});
			me.add(modal);
			modal.show();
			setTimeout(function () {
				modal.hide();
			}, timeout);
		}
	}
});

Ext.override(Ext.toolbar.Paging, {
	onLoad: function () {
		var me = this,
			pageData,
			currPage,
			pageCount,
			afterText,
			count,
			isEmpty,
			item;

		count = me.store.getTotalCount() || me.store.getCount();
		isEmpty = count === 0;
		if (!isEmpty) {
			pageData = me.getPageData();
			currPage = pageData.currentPage;
			pageCount = pageData.pageCount;
			afterText = Ext.String.format(me.afterPageText, isNaN(pageCount) ? 1 : pageCount);
		} else {
			currPage = 0;
			pageCount = 0;
			afterText = Ext.String.format(me.afterPageText, 0);
		}

		Ext.suspendLayouts();
		item = me.child('#afterTextItem');
		if (item) {
			item.setText(afterText);
		}
		item = me.getInputItem();
		if (item) {
			item.setDisabled(isEmpty).setValue(currPage);
		}
		me.setChildDisabled('#first', currPage === 1 || isEmpty);
		me.setChildDisabled('#prev', currPage === 1 || isEmpty);
		me.setChildDisabled('#next', currPage === pageCount || isEmpty);
		me.setChildDisabled('#last', currPage === pageCount || isEmpty);
		me.setChildDisabled('#refresh', false);
		me.updateInfo();
		Ext.resumeLayouts(true);

		if (me.rendered) {
			me.fireEvent('change', me, pageData);
		}
	}
});

Ext.override(Ext.panel.Panel, {
	setTitle: function (newTitle) {
		this.callParent([edi.utils.safeString(newTitle)]);
	}
});

Ext.override(Ext.menu.Item, {
	setText: function (text) {
		var me = this;
		me.text = edi.utils.safeString(text);

		me.callParent(arguments);
	},
	initComponent: function () {
		var me = this;
		if (me.text) {
			edi.utils.safeString(me.text);
		}

		me.callParent(arguments);
	}
});

Ext.override(Ext.grid.column.Column, {
	sortCls: Ext.baseCSSPrefix + 'column-header-sort',
	sortGlyph: edi.constants.ICONS.SORT,
	initComponent: function () {
		var me = this;
		if (me.columns != null) {
			if (me.width && me.width !== Ext.grid.header.Container.prototype.defaultWidth) {
				me.width = Ext.grid.header.Container.prototype.defaultWidth;
			}
		}
		me.callParent(arguments);
		if (me.sortable) {
			me.addCls(me.sortCls);
		}
	},
	initRenderData: function () {
		var me = this,
			params = {
				sortEl: ''
			};
		if (me.sortable && me.sortGlyph) {
			var sortGlyph = me.parseGlyph(me.sortGlyph);
			params.sortEl = Ext.DomHelper.markup({
				tag: 'span',
				cls: Ext.baseCSSPrefix + 'column-header-sort-glyph',
				html: '&#' + sortGlyph.glyph + ';',
				style: sortGlyph.glyphFontFamily ? 'font-family: ' + sortGlyph.glyphFontFamily : undefined
			});
		}
		return Ext.applyIf(me.callParent(arguments), params);
	},
	parseGlyph: function (glyph) {
		glyph = glyph || this.sortGlyph;
		var glyphFontFamily = Ext._glyphFontFamily,
			glyphParts;
		if (typeof glyph === 'string') {
			glyphParts = glyph.split('@');
			glyph = glyphParts[0];
			glyphFontFamily = glyphParts[1];
		}
		return {
			glyph: glyph,
			glyphFontFamily: glyphFontFamily
		};
	}
});

Ext.override(Ext.grid.plugin.HeaderResizer, {
	eResizeCursor: 'url(themes/ext-theme-core/images/grid/cursor_scale.png) 8 0, col-resize'
});

Ext.override(Ext.view.Table, {
	outerRowTpl: [
		'<table id="{rowId}" role="presentation" ',
		'data-boundView="{view.id}" ',
		'data-recordId="{record.internalId}" ',
		'data-recordIndex="{recordIndex}" ',
		'data-recordrealid="{[this.getRecordRealId(values)]}" ', //<-- OVERRIDE
		'class="{[values.itemClasses.join(" ")]}" cellpadding="0" cellspacing="0" style="{itemStyle};width:0">',
		// Do NOT emit a <TBODY> tag in case the nextTpl has to emit a <COLGROUP> column sizer element.
		// Browser will create a tbody tag when it encounters the first <TR>
		'{%',
		'this.nextTpl.applyOut(values, out, parent)',
		'%}',
		'</table>',
		{
			priority: 9999,
			// OVERRIDE begin
			getRecordRealId(values) {
				let rec = values.record;
				return rec.get(rec.getIdProperty());
			}
			// OVERRIDE end
		}
	],
	renderCell: function (column, record, recordIndex, rowIndex, columnIndex, out) {
		var me = this,
			renderer = column.renderer,
			fullIndex,
			selModel = me.selectionModel,
			cellValues = me.cellValues,
			classes = cellValues.classes,
			fieldValue = record.data[column.dataIndex],
			cellTpl = me.cellTpl,
			enableTextSelection = column.enableTextSelection,
			value,
			clsInsertPoint,
			lastFocused = me.navigationModel.getPosition();
		// Only use the view's setting if it's not been overridden on the column
		if (enableTextSelection == null) {
			enableTextSelection = me.enableTextSelection;
		}
		cellValues.record = record;
		cellValues.column = column;
		cellValues.recordIndex = recordIndex;
		cellValues.rowIndex = rowIndex;
		cellValues.columnIndex = cellValues.cellIndex = columnIndex;
		cellValues.align = column.textAlign;
		cellValues.innerCls = column.innerCls;
		cellValues.tdCls = cellValues.tdStyle = cellValues.tdAttr = cellValues.style = '';
		cellValues.unselectableAttr = enableTextSelection ? '' : 'unselectable="on"';
		// Begin setup of classes to add to cell
		classes[1] = column.getCellId();
		// On IE8, array[len] = 'foo' is twice as fast as array.push('foo')
		// So keep an insertion point and use assignment to help IE!
		clsInsertPoint = 2;
		if (renderer && renderer.call) {
			// Avoid expensive header index calculation (uses Array#indexOf)
			// if renderer doesn't use it.
			fullIndex = renderer.length > 4 ? me.ownerCt.columnManager.getHeaderIndex(column) : columnIndex;
			value = renderer.call(
				column.usingDefaultRenderer ? column : column.scope || me.ownerCt,
				fieldValue,
				cellValues,
				record,
				recordIndex,
				fullIndex,
				me.dataSource,
				me
			);
			if (cellValues.css) {
				// This warning attribute is used by the compat layer
				// TODO: remove when compat layer becomes deprecated
				record.cssWarning = true;
				cellValues.tdCls += ' ' + cellValues.css;
				cellValues.css = null;
			}
			// Add any tdCls which was added to the cellValues by the renderer.
			if (cellValues.tdCls) {
				classes[clsInsertPoint++] = cellValues.tdCls;
			}
		} else {
			value = fieldValue;
		}

		//OVERRIDE START
		if (!column.htmlEncode && Ext.isString(value) && value) {
			value = edi.utils.safeString(value);
		}
		//OVERRIDE END

		cellValues.value =
			value == null || value.length === 0 || (Ext.isString(value) && value.replace(/\s/g, '').length === 0)
				? column.emptyCellText
				: value;
		if (column.tdCls) {
			classes[clsInsertPoint++] = column.tdCls;
		}
		if (me.markDirty && record.dirty && record.isModified(column.dataIndex)) {
			classes[clsInsertPoint++] = me.dirtyCls;
			if (column.dirtyTextElementId) {
				cellValues.tdAttr =
					(cellValues.tdAttr ? cellValues.tdAttr + ' ' : '') +
					'aria-describedby="' +
					column.dirtyTextElementId +
					'"';
			}
		}
		if (column.isFirstVisible) {
			classes[clsInsertPoint++] = me.firstCls;
		}
		if (column.isLastVisible) {
			classes[clsInsertPoint++] = me.lastCls;
		}
		if (!enableTextSelection) {
			classes[clsInsertPoint++] = me.unselectableCls;
		}
		if (
			selModel &&
			(selModel.isCellModel || selModel.isSpreadsheetModel) &&
			selModel.isCellSelected(me, recordIndex, column)
		) {
			classes[clsInsertPoint++] = me.selectedCellCls;
		}
		if (lastFocused && lastFocused.record.id === record.id && lastFocused.column === column) {
			classes[clsInsertPoint++] = me.focusedItemCls;
		}
		// Chop back array to only what we've set
		classes.length = clsInsertPoint;
		cellValues.tdCls = classes.join(' ');
		cellTpl.applyOut(cellValues, out);
		// Dereference objects since cellValues is a persistent var in the XTemplate's scope chain
		cellValues.column = cellValues.record = null;
	}
});

Ext.override(Ext.grid.column.Check, {
	htmlEncode: true
});

Ext.override(Ext.grid.column.Action, {
	htmlEncode: true
});

Ext.override(Ext.tip.Tip, {
	initComponent: function () {
		var me = this;
		me.html = edi.utils.safeString(me.html);
		me.title = edi.utils.safeString(me.title) || '&#160;';

		me.callParent(arguments);
	}
});

Ext.override(Ext.tab.Tab, {
	setCard: function (card) {
		var me = this;
		me.card = card;
		if (card.iconAlign) {
			me.setIconAlign(card.iconAlign);
		}
		if (card.textAlign) {
			me.setTextAlign(card.textAlign);
		}
		//Tab является наследником  Button в котором есть safeString в setText
		//т.к. title берется из панели, у котрой тоже есть safeString в setTitle,
		//то получается двойной вызов и ломается отображение текста заголовка таба
		//к моменту создания таба панель уже сделала очистку от инъекций, поэтому передадим skipHtmlEncode: true
		me.setText(me.title || card.title, { skipHtmlEncode: true }); //<-- OVERRIDE
		me.setIconCls(me.iconCls || card.iconCls);
		me.setIcon(me.icon || card.icon);
		me.setGlyph(me.glyph || card.glyph);
	}
});

Ext.override(Ext.button.Button, {
	showTooltipWhileDisabled: true,
	setText: function (text, opts) {
		if (opts?.skipHtmlEncode === true) {
			this.callParent([text || '']);
		} else {
			this.callParent([edi.utils.safeString(text) || '']);
		}
	},
	setTooltip: function (tooltip, initial) {
		var me = this,
			targetEl = me.el;
		if (me.rendered) {
			if (!initial || !tooltip) {
				me.clearTip();
			}
			if (me.disabled && !me.showTooltipWhileDisabled === true) {
				//<-- OVERRIDE
				targetEl = me.tooltipEl;
			}
			if (tooltip) {
				if (Ext.quickTipsActive && Ext.isObject(tooltip)) {
					Ext.tip.QuickTipManager.register(
						Ext.apply(
							{
								target: targetEl.id
							},
							tooltip
						)
					);
					me.tooltip = tooltip;
				} else {
					targetEl.dom.setAttribute(me.getTipAttr(), tooltip);
				}
				me.currentTooltipEl = targetEl;
			}
		} else {
			me.tooltip = tooltip;
		}
		return me;
	}
});

//При незаданном id у record проставляется phantom: true
//override для combobox считает, что это "не выбрано"
Ext.picker.Time.createStore = function (format, increment) {
	var dateUtil = Ext.Date,
		clearTime = dateUtil.clearTime,
		initDate = this.prototype.initDate,
		times = [],
		min,
		max;
	min = clearTime(new Date(initDate[0], initDate[1], initDate[2]));
	max = dateUtil.add(clearTime(new Date(initDate[0], initDate[1], initDate[2])), 'mi', 24 * 60 - 1);
	while (min <= max) {
		times.push({
			disp: dateUtil.dateFormat(min, format),
			date: min,
			id: min.toISOString() // <-- OVERRIDE
		});
		min = dateUtil.add(min, 'mi', increment);
	}
	return new Ext.data.Store({
		model: Ext.picker.Time.prototype.modelType,
		data: times
	});
};

Ext.override(Ext.tip.QuickTip, {
	getTipText: function (target) {
		var titleText = target.title,
			cfg = this.tagConfig,
			attr = cfg.attr || (cfg.attr = cfg.namespace + cfg.attribute);
		if (this.interceptTitles && titleText) {
			target.setAttribute(attr, titleText);
			target.removeAttribute('title');
			return titleText;
		} else {
			if (attr === 'data-errorqtip' || target.getAttribute('data-qtipFormatted') === 'true') {
				return target.getAttribute(attr);
			} else {
				return edi.utils.safeString(target.getAttribute(attr));
			}
		}
	}
});

Ext.override(Ext.grid.plugin.RowExpander, {
	getHeaderConfig: function () {
		var me = this;
		var columnConfig = me.callParent(arguments);
		columnConfig.htmlEncode = true;
		return columnConfig;
	}
});

Ext.override(Ext.form.field.Text, {
	filterKeys: function (e) {
		var charCode;
		/*
		 * Current only FF will fire keypress events for special keys.
		 *
		 * On European keyboards, the right alt key, Alt Gr, is used to type certain special
		 * characters. JS detects a keypress of this as ctrlKey & altKey. As such, we check
		 * that alt isn't pressed so we can still process these special characters.
		 */
		// OVERRIDE begin ФФ при попытке написать в числовое поле знак ! считает что это спец символ и не идет в проверку maskRe
		// if ((e.ctrlKey && !e.altKey) || e.isSpecialKey()) {
		// 	return;
		// }
		// OVERRIDE end
		charCode = String.fromCharCode(e.getCharCode());
		if (!this.maskRe.test(charCode)) {
			e.stopEvent();
		}
	}
});

Ext.override(Ext.picker.Month, {
	initComponent: function () {
		var me = this;
		me.okText = Ext.htmlDecode(me.okText);

		me.callParent(arguments);
	}
});
