import './Tool.scss';

const baseCls = 'ui-tool';

const TOOL_CLS = {
	primary: `${baseCls}-primary`,
	secondary: `${baseCls}-secondary`,
	light: `${baseCls}-light`
};

Ext.define('UI.components.Tool', {
	extend: 'Ext.panel.Tool',
	baseCls,

	initComponent: function () {
		const me = this;
		me.beforeInit();
		me.callParent([arguments]);
		me.afterInit();
	},

	beforeInit: function () {},

	afterInit: function () {},

	handler: function () {
		console.warn('Handler is not defined');
	}
});

/**
 * Create tool for header of panel or grid
 * @param	{Object}	cfg
 * @returns	{Object}	Ext.panel.Tool instance ('tool' widget)
 */
const createTool = function (cfg) {
	return Ext.create('UI.components.Tool', cfg);
};

/**
 * Create add action tool for header of panel or grid
 * @param	{Object}	cfg
 * @returns	{Object}	Ext.panel.Tool instance ('tool' widget)
 */
const createAddTool = function (cfg) {
	return createTool(
		Ext.merge(
			{
				glyph: edi.constants.ICONS.ADD,
				tooltip: edi.i18n.getMessage('form.btn.add')
			},
			cfg
		)
	);
};
/**
 * Create add action tool for header of panel or grid
 * @param	{Object}	cfg
 * @returns	{Object}	Ext.panel.Tool instance ('tool' widget)
 */
const createDeleteTool = function (cfg) {
	return createTool(
		Ext.merge(
			{
				glyph: edi.constants.ICONS.DELETE,
				tooltip: edi.i18n.getMessage('form.btn.delete')
			},
			cfg
		)
	);
};

export { TOOL_CLS, createTool, createAddTool, createDeleteTool };
