import tpl from './FieldBlock.ext.tpl';
import './FieldBlock.scss';

const baseCls = 'ui-fieldblock';
const FIELD_BLOCK_CLS = {
	default: `${baseCls}-default`,
	small: `${baseCls}-small`,
	detailsBlock: `${baseCls}-details-block`
};

/**
 * FieldBlock - компонент контейнер, наследуется от компонента Ext.container.Container
 * добавлены дополнительные методы, описание ниже
 */
Ext.define('UI.components.FieldBlock', {
	extend: 'Ext.container.Container',
	baseCls,
	cls: FIELD_BLOCK_CLS.default,
	title: null,
	subTitle: null,
	layout: 'auto',
	childEls: ['titleEl', 'subTitleEl', 'bodyEl'],
	renderTpl: tpl,

	initComponent: function () {
		var me = this;

		me.beforeInit();
		me.callParent(arguments);
		me.afterInit();
	},

	/**
	 * Базовые дополнительные методы:
	 * beforeInit, afterInit
	 */
	beforeInit: function () {},
	afterInit: function () {
		var me = this;
		me.addItemsMethods();
	},

	beforeRender: function () {
		var me = this;
		me.callParent();

		Ext.apply(me.renderData, {
			title: me.title,
			subTitle: me.subTitle
		});
	},

	afterRender: function () {
		var me = this;
		me.callParent();

		me.updateHeaderCls();
	},

	/**
	 * Метод для изменения заголовка
	 * @param	{String}	text
	 * @param	{boolean}	isHtml
	 */
	setTitle: function (text, isHtml) {
		const me = this;

		if (me.titleEl) {
			me.title = text;
			me.titleEl[isHtml ? 'setHtml' : 'setText'](me.title);
			me.updateHeaderCls();
		}
	},
	/**
	 * Метод для изменения подзаголовка
	 * @param	{String}	text
	 * @param	{boolean}	isHtml
	 */
	setSubTitle: function (text, isHtml) {
		const me = this;

		if (me.subTitleEl) {
			me.subTitle = text;
			me.subTitleEl[isHtml ? 'setHtml' : 'setText'](me.subTitle);
			me.updateHeaderCls();
		}
	},
	updateHeaderCls: function () {
		const me = this;

		me.titleEl[!me.titleEl.getText() ? 'addCls' : 'removeCls'](`${me.baseCls}-hide`);
		me.subTitleEl[!me.subTitleEl.getText() ? 'addCls' : 'removeCls'](`${me.baseCls}-hide`);

		me.titleEl[!me.subTitle ? 'addCls' : 'removeCls'](`${me.baseCls}-title-only`);
	},
	/**
	 * Метод для получения списка вложенные компонентов
	 * @return {Array}
	 */
	getItems: function () {
		const me = this;
		return me?.items && me.items.getRange();
	},
	/**
	 * Метод для получения поля по его параметру name
	 * @param	{String}	name
	 * @return	{Object}
	 */
	getFieldByName: function (name) {
		const me = this;
		let resultArr = [],
			items = me.getItems();

		if (items && items.length) {
			resultArr = items.filter((f) => f.name === name);
		}
		return resultArr[0];
	},
	/**
	 * При инициализации для каждого поля добавляет метод для получения родительского FieldBlock
	 * @return {Object}
	 */
	addItemsMethods: function () {
		const me = this;
		let items = me.getItems();

		if (items && items.length) {
			Ext.Array.forEach(items, (item) => {
				if (!!item) {
					Ext.apply(item, {
						getFieldBlock: () => me
					});
				}
			});
		}
	}
});

const createFieldBlock = (config) => new UI.components.FieldBlock(config);

export { createFieldBlock, FIELD_BLOCK_CLS };
