/**
 * Requests handling object
 * @author Anatoly Deryshev
 */
Ext.namespace("edi.rest");
edi.rest = new function() {
	var __self = this, globalRequestParams = {}, downloadFrame = null, downloadForm = null;
	this.services = {};
	this.prfx = {
		c: edi.constants.DEFAULT.REST_PREFIX + "client/",
		a: edi.constants.DEFAULT.REST_PREFIX + "admin/"
	};
	/**
	 * Sets global request params, that will be used in every request
	 */
	this.setGlobalRequestParams = function(params) {
		params = params && "object" == typeof params ? params : {};
		globalRequestParams = params;
	};
	/**
	 * Returns clone of global request params
	 */
	this.getGlobalRequestParams = function() {
		return Ext.clone(globalRequestParams);
	};
	/**
	 * Sends requests to backend
	 * @param    {String}      uri         rest service uri
	 * @param    {String}      method      rest service request method (GET/POST/PUT/DELETE)
	 * @param    {Object}      params      request params
	 * @param    {Function}    success     callback that will be called on success
	 * @param    {Function}    failure     callback that will be called on failure
	 * @param    {Function}    callback    callback that will be called in any case
	 * @param    {Object}      options     options object that will be applied to Ext.ajax config
	 */
	this.sendRequest = function(uri, method, params, success, failure, callback, options) {
		if (uri && "string" == typeof uri && uri.length && "null" != uri && "undefined" != uri) {
			uri = edi.utils.compileURL(uri, globalRequestParams);
			options = options && "object" == typeof options ? options : {};
			method = -1 != "GET,POST,PUT,DELETE".indexOf(String(method).toUpperCase()) ? String(method).toUpperCase() : edi.constants.DEFAULT.AJAX_METHOD;

			var isAdminOrg = edi.core.getUserData() && edi.utils.getObjectProperty(edi.core.getUserData(), "org.attributes.isAdminOrg.value") == 'true';
			var isClientService = uri.indexOf(edi.rest.prfx.c) != -1;
			var page = location.pathname.split('/');
			var isClientPage = page[page.length - 1] != "admin.html";
			var isPermissionService = uri == edi.rest.services.USER.PERMISSIONS.GET;
			var isException = uri.indexOf(edi.rest.services.USER.ORGANIZATION.GET) != -1 || uri.indexOf(edi.rest.services.USER.LOGOUT.POST) != -1;

			var ajaxProperties = {
				url: uri,
				method: method,
				disableCaching: edi.constants.AJAX.DISABLE_CACHING,
				notInterceptable: false,
				isPending: false,
				timeout: edi.constants.AJAX.TIMEOUT,
				success: function(response, opts) {
					if ("function" == typeof success) {
						if (options.textResponse) {
							success((response.responseText || ''), opts, response);
							return;
						}

						var data = null;
						try {
							data = Ext.decode(response.responseText);
							if (isAdminOrg && isPermissionService && isClientPage) {
								data.items = [];
								data.total = 0;
							}
							if (true === data.success) {
								if (data.status == edi.constants.STATUS.OK) {
									success(data, opts, response);
								}
								else {
									if ("function" == typeof failure) {
										failure(data, opts, response);
									}
								}
							}
							else if (false === data.success) {
								edi.core.logMessage("Error getting data. status: " + data.status + " url: " + uri, "warn");
								if (data.status != edi.constants.STATUS.ACCESS_DENIED && data.status != edi.constants.STATUS.UNEXPECTED_ERROR) {
									if ("function" == typeof failure) {
										failure(data, opts, response);
									}
								}
								else {
									if (data.status == edi.constants.STATUS.UNEXPECTED_ERROR && !options.suppressDefaultError) {
										edi.core.showError(edi.utils.formatComplexServerError(data, "error.getting.data"));
									}
									else if (data.status == edi.constants.STATUS.ACCESS_DENIED && !options.suppressDefaultError) {
										edi.core.showError(edi.utils.formatComplexServerError(data, "error.access.denied"));
									}
									if ("function" == typeof failure) {
										failure(data, opts, response);
									}
								}
							}
							else {
								edi.core.logMessage("Request error. status: " + data.status + " url: " + uri, "warn");
								if (!options.suppressDefaultError) {
									edi.core.showError(edi.utils.formatComplexServerError(data, "error.getting.data"));
								}
								if ("function" == typeof failure) {
									failure(data, opts, response);
								}
							}
						}
						catch (e) {
							edi.core.handleException(e);
							data = {
								success: false,
								status: edi.constants.STATUS.INVALID_JSON,
								error: "error.parsing.data"
							};
							edi.core.logMessage("Error getting data. status: " + data.status + " url: " + uri, "warn");
							if (!options.suppressDefaultError) {
								edi.core.showError(data.error);
							}
							if ("function" == typeof failure) {
								failure(data, opts, response);
							}
						}
					}
				},
				failure: function(response, opts) {
					if (response.responseText || response.status) {
						edi.core.logMessage("Error requesting " + uri + ", status code " + response.status, "warn");
						var data = {
							success: false,
							status: edi.constants.STATUS.SERVER_ERROR,
							error: "error.server"
						}, parsed = true;
						try {
							data = Ext.decode(response.responseText);
						}
						catch (e) {
							edi.core.logMessage("Error parsing json. status: " + data.status + " url: " + uri, "warn");
							parsed = false;
						}
						if (!options.suppressDefaultError) {
							edi.core.showError(parsed ? edi.utils.formatComplexServerError(data, "error.server") : data.error);
						}
						if ("function" == typeof failure) {
							failure(data, opts, response);
						}
					}
					else {
						edi.core.logMessage("Request aborted by browser. url: " + uri, "info");
					}
				},
				callback: function(opts, success, response) {
					if ("function" == typeof callback) {
						callback(opts, success, response);
					}
				},
				params: params
			};
			Ext.apply(ajaxProperties, options);

			if (isAdminOrg && isClientService && edi.core.isInitFinish && isClientPage && !isException) {
				ajaxProperties.failure({});
			}
			else {
				Ext.Ajax.request(ajaxProperties);
			}
		}
		else {
			edi.core.handleException("No rest Uri defined");
		}
	};
	/**
	 * Creates error handler that can be used for sendRequest
	 * @param defaultMessage
	 * @param callback
	 */
	this.getErrorHandler = function(defaultMessage, callback) {
		return function(data) {
			edi.core.showError(edi.utils.formatComplexServerError(data, defaultMessage || "error.getting.data"), callback);
		};
	};
	/**
	 * Process response
	 * @param response
	 * @param success
	 * @param failure
	 * @param options
	 */
	this.processResponse = function(response, success, failure, options) {
		if ("function" == typeof success) {
			options = options && "object" == typeof options ? options : {};
			var data = null;
			try {
				data = response.responseJson || Ext.decode(response?.responseText);
				if (true === data.success) {
					if (data.status == edi.constants.STATUS.OK) {
						success(data);
					}
					else {
						"function" == typeof failure ? failure(data) : null;
					}
				}
				else if (false === data.success) {
					if (data.status != edi.constants.STATUS.ACCESS_DENIED && data.status != edi.constants.STATUS.UNEXPECTED_ERROR) {
						"function" == typeof failure ? failure(data) : null;
					}
					else {
						if (data.status == edi.constants.STATUS.UNEXPECTED_ERROR && !options.suppressDefaultError) {
							edi.core.showError(edi.utils.formatComplexServerError(data, "error.getting.data"));
						}
						else if (data.status == edi.constants.STATUS.ACCESS_DENIED && !options.suppressDefaultError) {
							edi.core.showError(edi.utils.formatComplexServerError(data, "error.access.denied"));
						}
						"function" == typeof failure ? failure(data) : null;
					}
				}
				else {
					if (!options.suppressDefaultError) {
						edi.core.showError(edi.utils.formatComplexServerError(data, "error.getting.data"));
					}
					"function" == typeof failure ? failure(data) : null;
				}
			}
			catch (e) {
				edi.core.handleException(e);
				data = {
					success: false,
					status: edi.constants.STATUS.INVALID_JSON,
					error: "error.parsing.data"
				};
				if (e.msg) {
					var err = e.msg.match(/Connection terminated as request was larger than (\d+)/);
					if (err && err.length) {
						var size = err[1];
						if (size) {
							data.typeError = "error.file.upload.max.size.limit";
							data.additionalData = [edi.utils.formatFileSize(Number(size))]
						}
					}
				}
				if (!options.suppressDefaultError) {
					edi.core.showError(data.error);
				}
				"function" == typeof failure ? failure(data) : null;
			}
		}
	};
	/**
	 * Aborts HTTP request with defined url if it is active
	 * @param    {String}    url     request url
	 * @param    {Object}    args    request parameters to compare
	 */
	this.abortRequest = function(url, args) {
		var i, requestsEqual = false;
		for (i in Ext.Ajax.requests) {
			if (Ext.Ajax.requests.hasOwnProperty(i)) {
				var request = Ext.Ajax.requests[i], requestUrl;
				requestUrl = request.options.url.split("?_dc=")[0];//First check cache salt in the beginning of params
				requestUrl = requestUrl.split("&_dc=")[0];//Then check if salt is appended to params in the url
				if (!edi.utils.isEmptyObject(args) && requestUrl == url) {
					if (request.options.proxy && request.options.proxy.extraParams) {
						requestsEqual = edi.utils.compareObjects(args, request.options.proxy.extraParams);
					}
					else if (request.options.params) {
						requestsEqual = edi.utils.compareObjects(args, request.options.params);
					}
					else if (request.options.operation && request.options.operation.params) {
						requestsEqual = edi.utils.compareObjects(args, request.options.operation.params);
					}
					else {
						requestsEqual = requestUrl == url;
					}
				}
				else {
					requestsEqual = requestUrl == url;
				}
				if (requestsEqual) {
					Ext.Ajax.abort(request);
					break;
				}
			}
		}
		if (!requestsEqual) {
			Ext.Ajax.abortInterceptedRequest(url, args);
		}
	};
	/**
	 * download file
	 * @param    {String}    url
	 * @param    {String}    fileId
	 * @param    {Object}    postData
	 * @param    {Object}    maskElement        optional
	 * @param    {Object}    options
	 */
	this.downloadFile = function(url, fileId, postData, maskElement, options) {
		maskElement = maskElement ? maskElement : edi.modulesHandler.getActiveModule().tab;
		options = Ext.apply({}, options);

		var downloadFile = function() {
			var dropFrame = function() {
				if (downloadFrame) {
					downloadFrame.onload = null;
					downloadFrame.parentNode.removeChild(downloadFrame);
					downloadFrame = null;
				}
				if (downloadForm) {
					downloadForm.parentNode.removeChild(downloadForm);
					downloadForm = null;
				}
			};

			dropFrame();
			downloadFrame = document.createElement("iframe");
			downloadFrame.style.visibility = "hidden";
			downloadFrame.name = "downloadFrame";

			if (postData || options.dataDomElement) {
				var dataField;
				downloadForm = document.createElement("form");
				downloadForm.method = "post";
				downloadForm.encoding = "multipart/form-data";
				downloadForm.name = "downloadForm";
				downloadForm.target = "downloadFrame";
				downloadForm.action = dnlUrl;
				downloadForm.style.visibility = "hidden";

				if (!options.dataDomElement) {
					dataField = document.createElement("input");
					dataField.type = "hidden";
					dataField.name = "data";
					dataField.value = edi.utils.base64.encode(Ext.encode(postData));
				}
				else {
					dataField = options.domElementClone ? options.dataDomElement.cloneNode(true) : options.dataDomElement;
					dataField.id = Ext.id();
				}

				downloadForm.appendChild(dataField);
			}
			else {
				downloadFrame.src = dnlUrl;
			}

			if (fileId) {
				maskElement.setLoading(edi.i18n.getMessage("loading.file.text"));

				downloadHandler.start(fileId, null, function(error) {
					if (error == downloadHandler.ERROR.TIMEOUT) {
						edi.core.showError("file.download.error.timeout");
					}
					else {
						edi.core.showError("file.download.error");
					}
				}, function() {
					dropFrame();
					maskElement ? maskElement.setLoading(false) : null;
				})
			}

			document.body.appendChild(downloadFrame);
			if (postData || options.dataDomElement) {
				document.body.appendChild(downloadForm);
				downloadForm.submit();
			}
		};

		var dnlUrl = options.skipUrlAuth ? url : edi.login.setUrlAuth(url);
		__self.sendRequest(edi.rest.services.USER.SELF.GET, "GET", null, null, null, downloadFile);
	};
	/**
	 * Cookie based download handler
	 */
	var downloadHandler = new function() {
		var _dh = this;
		_dh.ERROR = {
			TIMEOUT: "TIMEOUT",
			SERVER: "SERVER"
		};
		/**
		 * reset handler
		 */
		_dh.reset = function() {
			if (_dh.interval) {
				clearInterval(_dh.interval);
			}
			_dh.fileId = null;
			_dh.success = null;
			_dh.failure = null;
			_dh.callback = null;
			_dh.startTime = null;
			_dh.interval = null;
			clearCookies();
		};
		/**
		 * stop current handler
		 */
		_dh.stop = function() {
			if ("function" == typeof _dh.callback) {
				_dh.callback();
			}
			_dh.reset();
		};
		var checkCookie = function() {
			var fileId = Ext.util.Cookies.get(edi.constants.DOWNLOAD.FILE_ID_PROPERTY);
			var fileStatus = Ext.util.Cookies.get(edi.constants.DOWNLOAD.STATUS_PROPERTY);
			if (fileId == _dh.fileId) {
				if (fileStatus == "true") {
					if ("function" == typeof _dh.success) {
						_dh.success();
					}
				}
				else {
					if ("function" == typeof _dh.failure) {
						_dh.failure(_dh.ERROR.SERVER);
					}
				}
				_dh.stop();
			}
			else if ((_dh.startTime + edi.constants.DOWNLOAD.TIMEOUT) < new Date().getTime()) {
				if ("function" == typeof _dh.failure) {
					_dh.failure(_dh.ERROR.TIMEOUT);
				}
				_dh.stop();
			}
		};
		var clearCookies = function() {
			Ext.util.Cookies.clear(edi.constants.DOWNLOAD.FILE_ID_PROPERTY);
			Ext.util.Cookies.clear(edi.constants.DOWNLOAD.STATUS_PROPERTY);
		};
		/**
		 * start waiting for cookies
		 * @param    {String}    fileId
		 * @param    {Function}    success
		 * @param    {Function}    failure
		 * @param    {Function}    callback
		 */
		_dh.start = function(fileId, success, failure, callback) {
			_dh.reset();
			_dh.fileId = fileId;
			_dh.success = success;
			_dh.failure = failure;
			_dh.callback = callback;
			_dh.startTime = new Date().getTime();
			_dh.interval = setInterval(checkCookie, edi.constants.DOWNLOAD.INTERVAL);
		};
		_dh.reset();
	}();
}();