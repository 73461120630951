import { i18n } from '@UIkit/methods';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import './MonthPicker.scss';

Ext.define('UI.picker.Month', {
	extend: 'Ext.picker.Month',
	baseCls: 'ui-monthpicker',

	monthOffset: 12,
	yearOffset: 6,

	initComponent: function () {
		var me = this;
		me.callParent();

		me.selectedCls = me.baseCls + '-selected';

		if (me.small) {
			me.addCls(me.smallCls);
		}

		me.setValue(me.value);
		me.activeYear = me.getYear(new Date().getFullYear() - 4, -4);

		if (me.showButtons) {
			me.okBtn = createButton({
				cls: [BUTTON_CLS.primary, BUTTON_CLS.small],
				ui: me.footerButtonUI,
				text: i18n('form.btn.select'),
				handler: me.onOkClick,
				scope: me
			});
			me.cancelBtn = createButton({
				cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
				ui: me.footerButtonUI,
				text: i18n('form.btn.cancel'),
				handler: me.onCancelClick,
				scope: me
			});
		}
	},

	getYears: function () {
		var me = this,
			offset = me.yearOffset,
			start = me.activeYear, // put the "active" year on the left
			end = start + offset,
			i = start,
			years = [];

		for (; i < end; ++i) {
			years.push(i);
		}

		return years;
	},
	beforeRender: function () {
		var me = this,
			months = [],
			monthLen = me.monthOffset;

		me.callParent();

		for (let i = 0; i < monthLen; ++i) {
			months.push(i18n(`month.short.${i + 1}`));
		}

		Ext.apply(me.renderData, {
			months: months,
			years: me.getYears(),
			showButtons: me.showButtons
		});
	},

	onMonthClick: function (target, isDouble) {
		var me = this;

		me.value[0] = me.months.indexOf(target);
		me.updateBody();
		me.fireEvent('month' + (isDouble ? 'dbl' : '') + 'click', me, me.value);
		me.fireEvent('select', me, me.value);
	},
	onYearClick: function (target, isDouble) {
		var me = this;

		me.value[1] = me.activeYear + me.years.indexOf(target);
		me.updateBody();
		me.fireEvent('year' + (isDouble ? 'dbl' : '') + 'click', me, me.value);
		me.fireEvent('select', me, me.value);
	},

	updateBody: function () {
		var me = this,
			years = me.years,
			months = me.months,
			yearNumbers = me.getYears(),
			cls = me.selectedCls,
			value = me.getYear(null),
			month = me.value[0],
			monthOffset = me.monthOffset,
			year,
			yearItems,
			y,
			yLen,
			el;

		if (me.rendered) {
			years.removeCls(cls);
			months.removeCls(cls);

			yearItems = years.elements;
			yLen = yearItems.length;

			for (y = 0; y < yLen; y++) {
				el = Ext.fly(yearItems[y]);

				year = yearNumbers[y];
				el.dom.innerHTML = year;

				if (year === value) {
					el.addCls(cls);
				}
			}

			if (month !== null) {
				months.item(month).addCls(cls);
			}
		}
	}
});
