const { PTYPE_FIELD_ERROR } = require('@UIkit/plugins');

const baseCls = 'ui-field';

/**
 * Модифиция базового поведения полей
 */
Ext.override(Ext.form.field.Base, {
	labelAlign: 'top',
	labelSeparator: '',
	msgTarget: 'side',

	_focusCls: `${baseCls}-focus`,
	_invalidCls: `${baseCls}-invalid`,
	_readonlyCls: `${baseCls}-readonly`,
	_disabledCls: `${baseCls}-disabled`,
	_filledCls: `${baseCls}-filled`,

	initComponent: function () {
		var me = this;

		me.beforeInit();
		me.callParent(arguments);
		me.afterInit();

		me.addPlugin(PTYPE_FIELD_ERROR);

		me.on(
			{
				change: function (field) {
					field.setFilledCls();
				},
				validitychange: function (field, isValid) {
					if (field && !isValid && !Ext.isEmpty(me.value)) {
						field.addCls(field._invalidCls);
					} else {
						field.removeCls(field._invalidCls);
					}
				},
				focus: function (field) {
					field.setFilledCls();
					if (!me.isDisabled() && !me.readOnly) field.addCls(me._focusCls);
				},
				blur: function (field) {
					field.setFilledCls();
					if (field.hasCls(me._focusCls)) field.removeCls(me._focusCls);
				},
				afterrender: function (field) {
					field.setFilledCls();

					if (me.isDisabled()) {
						field.addCls(me._disabledCls);
					} else {
						field.removeCls(me._disabledCls);
					}
					if (me.readOnly) field.addCls(me._readonlyCls);
					field[me.isValid() ? 'removeCls' : 'addCls'](me._invalidCls);

					field.setLabelPadding();
				},
				// event setDisabled
				enable: function (field) {
					field.setFilledCls();
					field[field.isDisabled() ? 'addCls' : 'removeCls'](me._disabledCls);
				},
				disable: function (field) {
					field.setFilledCls();
					field[field.isDisabled() ? 'addCls' : 'removeCls'](me._disabledCls);
				},
				// event setReadOnly
				writeablechange: function (field) {
					field.setFilledCls();
					field[field.readOnly ? 'addCls' : 'removeCls'](me._readonlyCls);
				}
			},
			me
		);
	},

	beforeInit: function () {
		this.setValueSrc();
		this.setValueTrim();
	},
	afterInit: function () {},

	setValueSrc: function () {
		const me = this,
			valueSrc = me.valueSrc;
		delete me.valueSrc;

		if (!!(valueSrc && me.config.name)) {
			me.value = edi.utils.getObjectProperty(valueSrc, me.config.name);
		}
	},
	setValueTrim: function () {
		const me = this,
			trimValueSpace = me.trimValueSpace;
		delete me.trimValueSpace;

		if (me.value && trimValueSpace) {
			me.value = String(me.value.trim());

			me.on('blur', function (field) {
				field.setValue(field.getValue().trim());
			});
		}
	},

	setFilledCls: function () {
		var me = this;

		if (!Ext.isEmpty(me.rawValue) || me.emptyText) {
			me.addCls(me._filledCls);
		} else {
			me.removeCls(me._filledCls);
		}
	},
	setLabelPadding: function () {
		var me = this,
			triggers,
			padding = 0;

		if ('function' == typeof me.getTriggers) {
			triggers = me.getTriggers();
			if (!Ext.Object.isEmpty(triggers)) {
				Ext.Object.each(triggers, (k, v) => {
					padding += v.el.dom.offsetWidth + 8 || 0;
				});

				me.labelEl.el.setStyle({
					paddingRight: `${padding}px`
				});
			}
		}
	},
	isValid: function () {
		var me = this;

		me.callParent(arguments);

		let plugins = me.getPlugins(),
			plugin = plugins.find((p) => p.ptype === PTYPE_FIELD_ERROR);

		if (plugin) {
			plugin.onMandatory(me);
		}

		return me.disabled || Ext.isEmpty(me.getErrors());
	}
});
