import {createTwoColumnsLayout} from "@Components/miscComponents";
import {createField, createNumberField, createTextField} from "@Components/fields";
import {createModalRemoteSelect} from "../../core/specialComponents/modals";

/**
 * Creates modal window for catalog selection
 * @param	{Number}	orgId
 * @param	{Function}	[callback]
 * @param	{String}	[type]
 * @param	{Boolean}	[isOwnCatalog]
 * @returns	{Object}	Ext.window.Window instance
 */
const createModalCatalogSelect = function(orgId, callback, type, isOwnCatalog) {
	type = type ? type : "delcat";
	var toOrgId = isOwnCatalog ? orgId : edi.core.getUserOrgID(),
		fromOrgId = isOwnCatalog ? edi.core.getUserOrgID() : orgId,
		url = edi.utils.formatString(edi.rest.services.CATALOGS.V2.RECORDS.GET, {
			type: type,
			fromOrgId: fromOrgId,
			toOrgId: type !== edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT ? fromOrgId : toOrgId
		}, true), isFirstLoad = true;
	var modal = createModalRemoteSelect(url, undefined, {
		storeConfig: {
			model: edi.models.getModel("ORGANIZATION_CATALOG"),
			sortOnLoad: true,
			sorters: {
				property: 'name',
				direction: 'ASC'
			},
			listeners: {
				"load": function(store) {
					if (isFirstLoad && !store.getCount()) {
						edi.core.showInfo(edi.i18n.getMessage(type === "delcat" ? "info.no.delcat" : "info.no.loccat"), function() {
							modal.close();
						});
					}
					isFirstLoad = false;
				}
			},
			autoLoad: false
		},
		gridConfig: {
			columns: edi.columns.get('organization_catalog'),
			listeners: {
				select: function(comp, record) {
					"function" == typeof callback ? callback(edi.converters.convertCatToOrg(record.getData())) : null;
					modal.close();
				}
			}
		},
		hideSaveButton: true,
		createFilterFormItems: [
			createTwoColumnsLayout([
				createField({
					title: edi.i18n.getMessage('company.gln'),
					input: createNumberField({
						columnWidth: 1,
						allowDecimals: false,
						name: 'iln'
					}),
					useFieldLabel: true
				})
			], [
				createField({
					title: edi.i18n.getMessage('company.name'),
					input: createTextField({
						columnWidth: 1,
						name: 'name'
					}),
					useFieldLabel: true
				})
			], 0.5)
		],
		createArgs: edi.filters.config.generic.createArgs,
		cls: "edi-modal-form  edi-modal-catalog-selector",
		title: edi.i18n.getMessage(type === "delcat" ? 'document.delcat.select.title' : 'document.loccat.select.title')
	});
	return modal;
};

Ext.namespace("edi.special");
edi.special.createModalCatalogSelect = createModalCatalogSelect;

export {createModalCatalogSelect};