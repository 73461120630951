import { createCheckbox } from '@UIkit/components/fields';
import { createPanel } from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';

import './GridSettingsButton.scss';

Ext.define('UI.components.GridSettingButton', {
	extend: 'UI.button.Button',

	cls: ['ui-grid-settings-button', BUTTON_CLS.light],
	selfCls: 'ui-grid-settings-button',
	panelCls: 'ui-grid-settings-panel',
	toolbarCls: 'ui-grid-settings-toolbar',
	additionalGridCls: 'ui-grid-with-settings-toolbar',
	glyph: edi.constants.ICONS.SETTINGS,

	_checkboxesPanel: null,
	_columnCount: 1,

	beforeInit: function () {
		const me = this;

		const columns = me.grid.getColumns();
		const columnCheckboxes = me.getCheckboxesFromColumns(columns);

		if (!columnCheckboxes.length) {
			return;
		}

		const checkAll = function (value) {
			const checkBoxGroup = me._checkboxesPanel.down('checkboxgroup');
			checkBoxGroup.eachBox(function (checkBox) {
				checkBox.setValue(value);
			});
		};

		const settingsPanelButtons = [
			createButton({
				cls: BUTTON_CLS.light,
				text: edi.i18n.getMessage('select.all.columns'),
				handler: function () {
					checkAll(true);
				}
			}),
			createButton({
				cls: BUTTON_CLS.light,
				text: edi.i18n.getMessage('deselect.all.selection'),
				handler: function () {
					checkAll(false);
				}
			})
		];

		if (
			me.forceShowResetAction === true ||
			(edi.constants.COLUMN_CONFIG_SAVE_ENABLED && edi.permissions.hasPermission('EDIT_USER_PROFILE'))
		) {
			settingsPanelButtons.push('->');
			settingsPanelButtons.push(
				createButton({
					cls: BUTTON_CLS.light,
					text: edi.i18n.getMessage('reset.column.config'),
					handler: function () {
						edi.core.confirm(null, 'reset.column.config.confirmation', function () {
							me.resetGrid();
						});
					}
				})
			);
		}

		me._columnCount = Math.ceil(columnCheckboxes.length / 6);
		const panelWidth = 520 + (me._columnCount > 2 ? 260 * (me._columnCount - 2) : 0);

		me._checkboxesPanel = createPanel({
			cls: me.panelCls,
			floating: true,
			width: panelWidth,
			layout: 'fit',
			items: [
				{
					xtype: 'checkboxgroup',
					cls: `${me.panelCls}-checkboxgroup`,
					columns: me._columnCount,
					vertical: true,
					items: columnCheckboxes
				}
			],
			dockedItems: [
				{
					xtype: 'toolbar',
					cls: `${me.panelCls}-toolbar`,
					dock: 'bottom',
					items: settingsPanelButtons
				}
			],
			listeners: {
				hide: function () {
					Ext.getBody().un('mousedown', handleDocMouseDown);
				},
				destroy: function () {
					Ext.getBody().un('mousedown', handleDocMouseDown);
				}
			}
		});

		const handleDocMouseDown = function (event, target) {
			var el = Ext.get(target);
			if (!(el.up(`.${me.panelCls}`) || el.up(`.${me.selfCls}`))) {
				me._checkboxesPanel.hide();
				me.removeCls(`${me.selfCls}-active`);
				Ext.getBody().un('mousedown', handleDocMouseDown);
			}
		};

		Ext.merge(me, {
			tooltip: edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage('form.btn.settings') : undefined,
			handler: function (el) {
				if (me._checkboxesPanel.rendered && me._checkboxesPanel.isVisible()) {
					me._checkboxesPanel.hide();
					el.removeCls(`${me.selfCls}-active`);
					Ext.getBody().un('mousedown', handleDocMouseDown);
				} else {
					if (!me._checkboxesPanel.rendered) {
						me._checkboxesPanel.render(document.body);
					}
					me._checkboxesPanel.show();
					me._checkboxesPanel.alignTo(el, 't-t?');
					el.addCls(`${me.selfCls}-active`);
					var row = me.grid.getView().getNode(0);
					let height;
					if (row) {
						height = Ext.get(row).getHeight();
					} else {
						var grid = me.grid?.ownerGrid ?? null;
						var gridView = typeof grid.getView === 'function' ? grid.getView() : null;
						height = gridView?.ownerCt?.headerCt?.el?.dom?.offsetHeight ?? null;
					}
					var gridDom = Ext.dom.Query.select('.edi-main-modules-panel');
					var clientWidth = gridDom[0] ? gridDom[0].clientWidth : window.innerWidth;
					var gridOffsets = Ext.dom.Element.getViewportWidth() - clientWidth;
					me._checkboxesPanel.alignTo(el, 't-t?', [-(gridOffsets + 150), height - 5]);
					Ext.getBody().on('mousedown', handleDocMouseDown);
				}
			},
			listeners: {
				beforedestroy: function () {
					me._checkboxesPanel.destroy();
				}
			}
		});

		me.callParent();
	},

	afterInit: function () {
		const me = this;
		me.grid.addDocked(
			new Ext.toolbar.Toolbar({
				cls: me.toolbarCls,
				minWidth: 0,
				maxWidth: 0,
				width: 0,
				dock: 'right',
				items: me
			})
		);
		me.grid.addCls(me.additionalGridCls);
		me.callParent();
	},

	getCheckboxesFromColumns: function (columns) {
		const columnCheckboxes = [];
		Ext.Array.forEach(columns, function (column) {
			if ((column.hasOwnProperty('hideable') ? column.hideable === true : true) || column.isSubHeader) {
				columnCheckboxes.push(
					createCheckbox({
						boxLabel: column.subTitleCheckboxName
							? edi.i18n.getMessage(column.subTitleCheckboxName)
							: column.isSubHeader
								? column.ownerCt.text + ' ' + column.text.toLowerCase()
								: column.text,
						name: column.cName,
						checked: !column.hidden,
						headerId: column.id,
						listeners: {
							change: function (comp, value) {
								const header = Ext.getCmp(comp.headerId);
								header[value ? 'show' : 'hide']();
							}
						}
					})
				);
			}
		});
		return columnCheckboxes;
	},

	resetGrid: function () {
		const me = this;
		const columnsConfig = me.grid.getColumns().filter((col) => !!col);
		if (columnsConfig.length) {
			const colWithName = columnsConfig.find((col) => !!col.initialConfig?.columnsName);
			const columnsName = colWithName.initialConfig.columnsName;
			try {
				edi.utils.setData('c.' + columnsName, '');
			} catch (err) {
				edi.core.logMessage(err, 'error');
			}
			const defConf = edi.columns.getDefault(columnsName);
			defConf.forEach((col) => {
				col.menuDisabled = true;
				col.tooltip = col.tooltip || col.text;
			});
			columnsConfig.forEach((col) => {
				if (col.initialConfig?.xtype === 'actioncolumn') {
					defConf.push(Object.assign({}, col.initialConfig, { items: col.items }));
				}
			});
			me.grid.reconfigure(null, defConf);

			me._checkboxesPanel.removeAll();
			me._checkboxesPanel.add([
				{
					xtype: 'checkboxgroup',
					cls: `${me.panelCls}-checkboxgroup`,
					columns: me._columnCount,
					vertical: true,
					items: me.getCheckboxesFromColumns(me.grid.getColumns())
				}
			]);
			me._checkboxesPanel.layout.redoLayout();
		}
	}
});

/**
 * Creates grid setting button
 * @param	{Object}	grid	grid component to attach button
 * @param	{Object}	[cfg]	settings button config
 * returns	{Object}	UI.components.GridSettingButton instance
 */
export const createGridSettingButton = function (grid, cfg) {
	return Ext.create('UI.components.GridSettingButton', Object.assign({}, cfg, { grid }));
};
