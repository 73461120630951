import '@UIkit/components/grid/Grid.scss';
import '@UIkit/components/grid/GridHeaderRow.scss';
import '@UIkit/components/grid/GridActionBar.scss';
import '@UIkit/components/grid/GridSettingsButton.scss';
import '@UIkit/components/grid/PagingBar.scss';
import { columnsChangeHandler, createGridSettingButton } from '@UIkit/components/grid';
import './TreePanel.scss';

Ext.define('UI.components.TreePanel', {
	extend: 'Ext.tree.Panel',
	selfCls: 'ui-tree-panel',
	gridCls: 'ui-table',
	showSettingsButton: false,
	settingsBtnConf: {},

	initComponent: function () {
		const me = this;
		me.beforeInit();
		me.callParent([arguments]);
		me.afterInit();
	},

	beforeInit: function () {},

	afterInit: function () {
		const me = this;
		me.addCls(me.selfCls);
		me.addCls(me.gridCls);

		if (me.showSettingsButton) {
			createGridSettingButton(me, me.settingsBtnConf);
		}

		if (edi.constants.COLUMN_CONFIG_SAVE_ENABLED && edi.permissions.hasPermission('EDIT_USER_PROFILE')) {
			// Save column settings
			me.on('boxready', function () {
				me.view.focus = Ext.emptyFn;
			});
			me.on('render', columnsChangeHandler);
		}
	}
});

export const createTreePanel = function (cfg) {
	return Ext.create('UI.components.TreePanel', cfg);
};
