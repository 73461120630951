import './Modal.scss';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { i18n } from '@UIkit/methods';

const baseCls = 'ui-modal';

const MODAL_CLS = {
	default: `${baseCls}-default`,
	withShadows: `${baseCls}-with-shadows`
};

const MODAL_SIZE = {
	widthSmall: 440,
	widthMedium: 640,
	widthLarge: 864
};

Ext.define('UI.components.Modal', {
	extend: 'Ext.window.Window',
	baseCls,
	cls: MODAL_CLS.default,
	title: '',
	minWidth: MODAL_SIZE.widthSmall,
	minHeight: 160,
	width: MODAL_SIZE.widthSmall,
	height: undefined,
	//useMaxHeightLimit: true - окно может распухать в высоту до размеров экрана минус verticalMargin сверху и снизу
	useMaxHeightLimit: true,
	verticalMargin: 40,
	resizable: false,
	scrollable: 'vertical',
	constrain: true,
	modal: true,
	layout: 'auto',
	buttonAlign: 'left',

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	modifyConfig: function (externalCfg) {
		const me = this;
		const config = Ext.merge({}, externalCfg);

		const buttonsBefore = !config.buttons ? config.buttonsBefore || [] : undefined;
		delete config.buttonsBefore;

		if (buttonsBefore && !config.hideButtons) {
			const buttonsCount = config.buttons ? config.buttons.length : buttonsBefore.length;
			config.buttons = buttonsBefore.concat(
				createButton(
					Ext.merge(
						{
							glyph: null,
							cls: buttonsCount > 0 ? BUTTON_CLS.secondary : BUTTON_CLS.primary,
							text: i18n('action.close'),
							handler: function () {
								me.close();
							}
						},
						config.closeButtonOptions
					)
				)
			);
		}

		return config;
	},

	beforeInit: function () {},

	afterInit: function () {
		const me = this;
		if (me.buttons && me.toolBarHeight) {
			let dockedItems = me.getDockedItems();
			let toolbar = dockedItems.find((item) => {
				return item.xtype === 'toolbar';
			});
			toolbar ? toolbar.setHeight(me.toolBarHeight) : null;
		}
		me.setMaxHeightLimit();
	},

	setMaxHeightLimit: function () {
		const me = this;
		if (me.useMaxHeightLimit === true) {
			me.on('beforeshow', function () {
				me.maxHeight = Math.floor(window.innerHeight) - +me.verticalMargin * 2;
			});
		}
	}
});

const createModalPanel = function (config, hideButtons) {
	return Ext.create('UI.components.Modal', Ext.merge({}, config, { hideButtons: !!hideButtons }));
};

export { MODAL_CLS, MODAL_SIZE, createModalPanel };
