Ext.define('UI.components.Form', {
	extend: 'Ext.form.Panel',

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	modifyConfig: function (config) {
		return Ext.merge(
			{
				defaultType: 'textfield',
				border: 0
			},
			config
		);
	},

	beforeInit: function () {},

	afterInit: function () {}
});

export const createForm = function (config) {
	return Ext.create('UI.components.Form', config);
};
