import { i18n } from '@App/js/uikit/methods';
import { createDatePicker } from './pickers/DatePicker';
import './Date.scss';

const baseCls = 'ui-datefield';

Ext.define('UI.components.DateField', {
	extend: 'Ext.form.field.Date',
	baseCls,
	triggerCls: `${baseCls}-trigger`,
	format: edi.constants.DATE_FORMAT.FNS,
	submitFormat: edi.constants.DATE_FORMAT.CLIENT,
	name: 'date',
	startDay: 1,
	enabledClick: true,

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	},

	setValueSrc: function () {
		const me = this,
			valueSrc = me.valueSrc;
		delete me.valueSrc;

		if (!!(valueSrc && me.config.name)) {
			me.value = Ext.Date.parse(edi.utils.getObjectProperty(valueSrc, me.config.name), me.submitFormat);
		}
	},

	initComponent: function () {
		const me = this;
		me.callParent(arguments);

		me.name = me?.prefix ? `${me.prefix}-${me.name}` : me.name;
		me.invalidText = me.invalidText || i18n('invalid.date.format');
	},

	onRender: function () {
		const me = this;
		me.callParent(arguments);

		if (me.enabledClick) {
			me.onEnabledClick();
		}
	},
	onEnabledClick: function () {
		const me = this;

		let triggerClick = function () {
			if (!me.readOnly && !me.isDisabled() && !me.isExpanded) {
				me.onTriggerClick();
			}
		};

		me.triggerWrap.on({
			focus: triggerClick,
			click: triggerClick
		});
	},

	createPicker: function () {
		var me = this,
			format = Ext.String.format;
		// Create floating Picker BoundList. It will acquire a floatParent by looking up
		// its ancestor hierarchy (Pickers use their pickerField property as an upward link)
		// for a floating component.
		return createDatePicker({
			id: me.id + '-picker',
			pickerField: me,
			floating: true,
			preventRefocus: true,
			hidden: true,
			minDate: me.minValue,
			maxDate: me.maxValue,
			disabledDatesRE: me.disabledDatesRE,
			disabledDatesText: me.disabledDatesText,
			ariaDisabledDatesText: me.ariaDisabledDatesText,
			disabledDays: me.disabledDays,
			disabledDaysText: me.disabledDaysText,
			ariaDisabledDaysText: me.ariaDisabledDaysText,
			format: me.format,
			showToday: me.showToday,
			startDay: me.startDay,
			minText: format(me.minText, me.formatDate(me.minValue)),
			ariaMinText: format(me.ariaMinText, me.formatDate(me.minValue, me.ariaFormat)),
			maxText: format(me.maxText, me.formatDate(me.maxValue)),
			ariaMaxText: format(me.ariaMaxText, me.formatDate(me.maxValue, me.ariaFormat)),
			listeners: {
				scope: me,
				select: me.onSelect,
				tabout: me.onTabOut
			},
			keyNavConfig: {
				esc: function () {
					me.inputEl.focus();
					me.collapse();
				}
			}
		});
	},

	getErrors: function (value) {
		value = arguments.length > 0 ? value : this.formatDate(this.processRawValue(this.getRawValue()));
		// eslint-disable-next-line vars-on-top
		var me = this,
			format = Ext.String.format,
			errors = [],
			disabledDays = me.disabledDays,
			disabledDatesRE = me.disabledDatesRE,
			minValue = me.minValue,
			maxValue = me.maxValue,
			len = disabledDays ? disabledDays.length : 0,
			i = 0,
			svalue,
			fvalue,
			day,
			time;

		// if it's blank and textfield didn't flag it then it's valid
		if (value === null || value.length < 1) {
			if (!me.allowBlank) {
				errors.push(me.blankText);
			}
			return errors;
		}

		svalue = value;
		value = me.parseDate(value);
		if (!value) {
			errors.push(format(me.invalidText, svalue, Ext.Date.unescapeFormat(me.format)));
			return errors;
		}
		time = value.getTime();
		if (minValue && time < minValue.getTime()) {
			errors.push(format(me.minText, me.formatDate(minValue)));
		}
		if (maxValue && time > maxValue.getTime()) {
			errors.push(format(me.maxText, me.formatDate(maxValue)));
		}
		if (disabledDays) {
			day = value.getDay();
			for (; i < len; i++) {
				if (day === disabledDays[i]) {
					errors.push(me.disabledDaysText);
					break;
				}
			}
		}
		fvalue = me.formatDate(value);
		if (disabledDatesRE && disabledDatesRE.test(fvalue)) {
			errors.push(format(me.disabledDatesText, fvalue));
		}
		return errors;
	}
});

const createDateField = (config) => new UI.components.DateField(config);

export { createDateField };
