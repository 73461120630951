import {createContainer} from "./miscComponents";
import {createActionsButton, createButton} from "./buttons";
import {createForm, createModalPanel} from "./panels";
import {
	createCheckbox,
	createCombo,
	createFieldContainer,
	createTextField
} from "./fields";
import {createProxyConfig, createStore} from "./storeComponents";

Ext.define('edi.components.moduleFilterForm', {
	extend: 'Ext.form.Panel',
	alias: "widget.ediModuleFilterForm",

	initComponent() {
		let __self = this;
		__self.setOwnConfig();
		__self.callParent();
		__self.afterInit();
	},

	/**
	 * Sets default config and process external params in current configuration
	 */
	setOwnConfig() {
		let __self = this;
		let externalConfig = __self.externalConfig;
		delete __self.externalConfig;
		let externalParams = __self.externalParams;
		delete __self.externalParams;
		//Записываем конфиги по умолчанию
		Ext.merge(__self, {
			cls: 'edi-form edi-module-filter-form document-filter document-filter-floating',
			region: "north",
			bodyCls: "document-filter-body",
			collapseMode: "placeholder",
			header: false,
			hideCollapseTool: true,
			width: "100%",
			maxPanelHeightPercent: 0.7,
			chipMaxWidth: 400,
			showExtendedFilterButtons: true,
			extendedFilterButtonWidth: 220,
			border: 0,
			autosearchEnabled: true,
			autoCloseTime: __self.getAutoCloseTime(),
			onFloatedPointerEvent() {},
			collapsible: edi.constants.FILTERS_COLLAPSIBLE,
			disableChips: !edi.constants.FILTERS_COLLAPSIBLE,
			collapsed: edi.constants.FILTERS_COLLAPSIBLE
				? edi.constants.FILTERS_COLLAPSED
				: false,
			fieldWidthInModalBase: edi.constants.DEFAULT.MODAL.WIDTH,
			fireSearch: externalParams?.fireSearch,
			customButtons: externalParams?.customButtons,
			externalOnFieldChange: externalParams?.onChange
		});
		//Перетираем конфиги теми, что были переданы в функцию создания компонента
		Ext.merge(__self, externalConfig);

		Object.values(__self.formItemsMap || {}).forEach(item => {
			if (item?.hideFromPanel === true) {
				item.addCls('document-filter-hidden-field');
			}
		});

		__self.configureAddFilterButton();
		__self.configureChipsContainer();
		__self.configureTools();
		__self.configureBottomToolbar();
		__self.configureDockedItems();
		__self.configureListeners();
		__self.configurePlaceholder();
	},

	/**
	 * Configure "quick add" button after chips
	 */
	configureAddFilterButton() {
		let __self = this;
		__self.addFilterButton = __self.collapsible
			? createButton({
				cls: 'document-filter-floating-quick-add-button',
				text: edi.i18n.getMessage('form.btn.add'),
				glyph: edi.constants.ICONS.PLUS,
				menu: Ext.menu.Menu({
					plain: true,
					hideMode: 'display',
					items: []
				})
			})
			: null;
	},

	/**
	 * Configure chips container and place "quick add" button into it
	 */
	configureChipsContainer() {
		let __self = this;
		__self.chipsContainer = __self.collapsible
			? createContainer({
				cls: "edi-filter-form-chips-container",
				flex: 1
			})
			: null;

		if (__self.chipsContainer && __self.addFilterButton) {
			__self.chipsContainer.add(__self.addFilterButton);
		}
	},

	/**
	 * Configure tools (filter button, chipsContainer and additional tools from config)
	 */
	configureTools() {
		let __self = this;

		let additionalToolsFromConfig = __self.additionalTools;
		delete __self.additionalTools;

		let tools = undefined;
		if (__self.collapsible) {
			tools = [__self.chipsContainer];

			if (additionalToolsFromConfig && Ext.isArray(additionalToolsFromConfig)) {
				tools = tools.concat(additionalToolsFromConfig);
			}

			if (__self.showExpandCollapseButton === true) {
				tools.tools.push({
					xtype: 'button',
					glyph: edi.constants.ICONS.FILTER_LIST,
					cls: 'edi-button-filter',
					hidden: !__self.showExpandCollapseButton
						|| !!__self.getAutoCloseTime()
						|| (__self.hasOwnProperty('collapsible') && !__self.collapsible),
					handler() {
						__self.toggleHandler();
					}
				});
			}
		}
		else if (additionalToolsFromConfig && Ext.isArray(additionalToolsFromConfig)) {
			tools = additionalToolsFromConfig;
		}

		__self.__tools = tools;
	},

	/**
	 * Configure bottom toolbar for form (default buttons, "save filter" and customButtons from config)
	 */
	configureBottomToolbar() {
		let __self = this;
		let saveFilterConfig = __self.saveFilter && "object" == typeof __self.saveFilter
			? __self.saveFilter
			: null;
		let autoSearchCheckBox;

		let modalSave;
		if(!!saveFilterConfig){
			let filterData = [];
			if(edi.utils.getObjectProperty(edi.utils.getData('filtersSave'), saveFilterConfig.name)){
				filterData = edi.utils.getData(`filtersSave.${saveFilterConfig.name}`);
				filterData = filterData && typeof filterData === 'string' ? JSON.parse(filterData) : filterData;
			}

			modalSave = function(dataFilter, valuesFilter, config, callback){
				let gridName = config?.name;

				let save = function(){
					let formValues = form.getValues();
					let valuesFilter = edi.filters.getValueWithRange(__self);
					let data = dataFilter || [];
					let nextId = data.length ? data[data.length - 1].id + 1 : 1;

					let indexRecord = data.findIndex(item => item.name === formValues.name);
					if(indexRecord !== -1){
						data[indexRecord] = Ext.merge(data[indexRecord], {
							createDate: new Date().getTime(),
							filters: valuesFilter
						});

						edi.core.confirm(null, "filter.form.comfirm.record.already.exists", function() {
							edi.utils.setData(`filtersSave.${gridName}`, JSON.stringify(data), callback);
						});
					} else {
						data.push({
							id: nextId,
							name: formValues.name,
							createDate: new Date().getTime(),
							filters: valuesFilter
						});
						edi.utils.setData(`filtersSave.${gridName}`, JSON.stringify(data), callback);
					}

					modal.destroy();
				};

				let nameField;
				let form = createForm({
					cls: "edi-form",
					fieldDefaults: {
						labelWidth: 150
					},
					defaults: {
						anchor: "100%"
					},
					allowBlank: false,
					bodyPadding: 10,
					items: [
						createFieldContainer({
							fieldLabel: edi.i18n.getMessage('filter.form.saveFilter.field.name'),
							cls: "edi-form-field",
							layout: 'hbox',
							defaults: {
								flex: 1
							},
							fieldDefaults: {
								labelAlign: 'top'
							},
							items: [
								nameField = createTextField({
									name: "name",
									allowBlank: false,
									listeners: {
										specialkey: function(field, e) {
											if (e.getKey() === e.ENTER) {
												save();
											}
										}
									}
								})
							]
						})
					]
				});

				let modal = createModalPanel({
					title: edi.i18n.getMessage('filter.form.saveFilter.modal.title'),
					height: edi.constants.DEFAULT.MODAL.HEIGHT,
					width: edi.constants.DEFAULT.MODAL.WIDTH,
					listeners: {
						show: function() {
							nameField.focus();
						}
					},
					items: [form],
					buttonsBefore: [
						createButton({
							text: edi.i18n.getMessage('form.btn.save'),
							glyph: edi.constants.ICONS.SAVE,
							formBind: true,
							disabled: true,
							bindToForm: form,
							handler: function() {
								save();
							}
						})
					]
				});
				modal.show();
			};
		}

		let resetBtnConf = edi.utils.getObjectProperty(__self, 'defaultButtonsConfig.reset') || {};
		resetBtnConf.cls = (resetBtnConf.cls || '') + ' edi-button-without-glyph';
		let searchBtnConf = edi.utils.getObjectProperty(__self, 'defaultButtonsConfig.search') || {};
		searchBtnConf.cls = (searchBtnConf.cls || '') + ' edi-button-without-glyph edi-button-filter-search';

		let defaultButtons = [
			createButton(Ext.applyIf(resetBtnConf, {
				text: edi.i18n.getMessage('form.btn.reset'),
				handler() {
					__self.getForm().reset();
					if (typeof __self.setFormDefaults === 'function'){
						__self.setFormDefaults();
					}
					autoSearchCheckBox && autoSearchCheckBox.setValue(__self.autosearchEnabled);
					if (__self.autosearchEnabled && "function" == typeof __self.fireSearch) {
						__self.fireSearch();
					}
				}
			})),
			createButton(Ext.applyIf(searchBtnConf, {
				text: edi.i18n.getMessage('form.btn.search'),
				formBind: true,
				disabled: true,
				handler() {
					if (!__self.isDestroyed && !__self.collapsed) {
						__self.toggleHandler();
					}
					if ("function" == typeof __self.fireSearch) {
						__self.fireSearch();
					}
				}
			}))
		];

		let filterButtons = ['->'];
		if ('object' == typeof __self.customButtons) {
			if (Ext.isArray(__self.customButtons?.replaceDefault)) {
				filterButtons = filterButtons.concat(__self.customButtons.replaceDefault);
			}
			else {
				if (Ext.isArray(__self.customButtons?.beforeDefault)) {
					filterButtons = filterButtons.concat(__self.customButtons.beforeDefault);
				}

				if (Ext.isArray(__self.customButtons?.afterDefault)) {
					filterButtons = filterButtons.concat(__self.customButtons.afterDefault);
				}
				filterButtons = filterButtons.concat(defaultButtons);
			}
		}
		else {
			filterButtons = filterButtons.concat(defaultButtons);
		}

		if(!!saveFilterConfig){
			let filterData = [];
			if(edi.utils.getObjectProperty(edi.utils.getData('filtersSave'), saveFilterConfig.name)){
				filterData = edi.utils.getData(`filtersSave.${saveFilterConfig.name}`);
				filterData = filterData && typeof filterData === 'string' ? JSON.parse(filterData) : filterData;
			}

			let saveBtnConf = edi.utils.getObjectProperty(__self, 'defaultButtonsConfig.save') || {};
			saveBtnConf.cls = (saveBtnConf.cls || '') + ' edi-button-without-glyph';
			let saveBtn = createButton(Ext.applyIf(saveBtnConf, {
				text: edi.i18n.getMessage('filter.form.saveFilter.btn'),
				disabled: filterData.length === 5,
				handler() {
					modalSave(filterData, __self.getValues(), saveFilterConfig, function(){
						comboStore.loadData(filterData);
						saveBtn.setDisabled(comboStore.getCount() === 5);
						saveCombo.setDisabled(comboStore.getCount() === 0);
					});
				}
			}));
			Ext.Array.insert(filterButtons, 2, [saveBtn]);

			let comboStore = createStore({
				proxy: createProxyConfig({
					type: "memory",
					data: filterData
				}),
				fields: ["id", "name"],
				listeners: {
					load(store) {
						if (typeof saveCombo?.setDisabled === 'function') {
							saveCombo.setDisabled(store.getCount() <= 0);
						}
					}
				}
			});

			let saveCombo = createCombo({
				cls: 'edi-filter-form-saved-filters-combo',
				name: 'selectSaveFilter',
				ignoreAutoFilter: true,
				ignoreChips: true,
				margin: '0 0 0 16',
				minWidth: 250,
				emptyText: edi.i18n.getMessage('filter.form.saved.filters'),
				store: comboStore,
				disabled: comboStore.getCount() === 0,
				plugins: [{
					ptype: 'actionitem',
					glyph: edi.constants.ICONS.DELETE,
					handler: function(record){
						let deletedItem = filterData.find(item => item.id === record.get('id'));
						deletedItem ? Ext.Array.remove(filterData, deletedItem) : undefined;
						edi.utils.setData(`filtersSave.${saveFilterConfig.name}`, JSON.stringify(filterData), function(){
							comboStore.loadData(filterData);
							saveBtn.setDisabled(comboStore.getCount() === 5);
							saveCombo.setDisabled(comboStore.getCount() === 0);
						});
					}
				}],
				listeners: {
					select: function(comp, record) {
						//delay нужен чтобы функция срабатывала после того, как добавится класс активности на кликнутый элемент
						setTimeout(function() {
							let recordData = record && record.getData ? record.getData() : null;
							__self.getForm().reset();
							recordData?.filters ? edi.filters.defaultRestoreFilterValuesMethod(__self, recordData?.filters) : null;
							comp.setValue(null);
						}, 10);
					}
				}
			});
			Ext.Array.insert(filterButtons, 0, [saveCombo]);
		}

		let showAutoSearch = __self.hasOwnProperty('toggleAutoSearch')
			? __self.toggleAutoSearch
			: edi.constants.TOGGLE_FILTER_AUTOSEARCH;
		if (showAutoSearch) {
			Ext.Array.insert(filterButtons, 1, [autoSearchCheckBox = createCheckbox({
				fieldLabel: edi.i18n.getMessage('filter.form.auto.search'),
				name: 'filterFormAutoSearchCheckbox',
				qtipText: edi.i18n.getMessage('filter.form.auto.search.hint'),
				margin: '0 0 0 20',
				ignoreAutoFilter: true,
				ignoreChips: true,
				checked: true,
				listeners: {
					change: function(comp, checked) {
						__self.autosearchEnabled = !!checked;
					}
				}
			})]);
		}

		__self.filterButtons = filterButtons;
	},

	/**
	 * Configure bottom docked toolbars (filterButtons and collapse\expand)
	 */
	configureDockedItems() {
		let __self = this;
		__self.dockedItems = [
			__self.collapsible !== false ? {
				xtype: 'toolbar',
				cls: 'edi-toolbar-filter-collapse',
				layout: {
					type: 'hbox',
					align: 'bottom',
					pack: 'center'
				},
				dock: 'bottom',
				height: 10,
				items: [__self.createExtendedFilterButton({
					cls: 'document-filter-floating-collapse-button',
					text: edi.i18n.getMessage('action.collapse_extended_filter')
				})]
			} : null,
			{
				xtype: 'toolbar',
				dock: 'bottom',
				ui: 'footer',
				listeners: edi.constants.FILTERS_COLLAPSIBLE
					? {
						render: function(footer) {
							footer.getEl().on("mouseenter", function() {
								if (__self.autoCloseTime) {
									__self.preventClose();
								}
							});
							footer.getEl().on("mouseleave", function() {
								__self.autoClose();
							});
						}
					}
					: undefined,
				items: __self.filterButtons
			}
		]
	},

	/**
	 * Configure listeners for filter panel and process additional "render" listener from config
	 */
	configureListeners() {
		let __self = this;

		__self.listeners = __self.listeners || {};

		__self.originalRenderFromConfig = __self.listeners.render || (() => {});
		__self.listeners.render = function(p, eOpts) {
			if (edi.constants.FILTERS_COLLAPSIBLE) {
				__self.onPanelRender(p)
			}
			__self.originalRenderFromConfig(p, eOpts);
		};

		__self.keyNav = Ext.apply({
			forceKeyDown: true,
			target: Ext.getDoc(),
			enabled: false,
			key: true,
			esc: {
				handler(e) {
					e.browserEvent.stopPropagation();
					!__self.isDestroyed && !__self.collapsed && __self.escHandler();
				},
				scope: __self,
				defaultEventAction: false
			}
		}, __self.keyNavConfig);

		__self.listeners.reset = __self.onFormReset;
		__self.listeners.validitychange = __self.onFormValidityChange;
		__self.listeners.float = __self.onPanelFloat;
		__self.listeners.unfloat = __self.onPanelUnfloat;
		__self.listeners.beforedestroy = __self.onBeforeDestroy;
		__self.listeners.show = __self.onPanelShow;
		__self.listeners.hide = __self.onPanelHide;
	},

	/**
	 * Creates extended filter collapse/expand button
	 * @param cfg
	 * @return {null|*}
	 */
	createExtendedFilterButton(cfg) {
		let __self = this;
		if (__self.showExtendedFilterButtons !== true) {
			return null;
		}

		let defaultCfg = {
			cls: 'document-filter-floating-expand-button',
			glyph: edi.constants.ICONS.ARROW_UP_DOWN,
			text: edi.i18n.getMessage('action.expand_extended_filter'),
			handler() {
				__self.toggleHandler();
			},
			minWidth: __self.extendedFilterButtonWidth,
			maxWidth: __self.extendedFilterButtonWidth
		};

		let effectiveConf = Ext.merge({}, defaultCfg, cfg);
		return createButton(effectiveConf)
	},

	/**
	 * Configure placeholder (the panel instead of header) for collapsible filter panel
	 */
	configurePlaceholder() {
		let __self = this;

		__self.floatingExpandButton = __self.createExtendedFilterButton();

		__self.placeholder = Ext.widget({
			xtype: "panel",
			cls: "edi-filter-form-placeholder document-filter-header document-filter-floating-header",
			flexTitle: !edi.constants.FILTERS_COLLAPSIBLE,
			minHeight: 56,
			maxHeight: 56*4,
			layout: {
				type: 'vbox',
				align: 'middle',
				pack: 'center'
			},
			isLayoutRoot: () => true,
			items: [
				createContainer({
					layout: {
						type: 'hbox',
						align: 'middle',
						pack: 'start'
					},
					width: '100%',
					items: [
						createActionsButton({
							glyph: edi.constants.ICONS.MODULE_FILTER,
							itemId: 'expandCollapseTool',
							handler() {
								__self.toggleHandler();
							}
						})
					].concat(__self.__tools)
				}),
				__self.floatingExpandButton ? createContainer({
					cls: 'document-filter-floating-container-expand-button',
					layout: {
						type: 'hbox',
						align: 'middle',
						pack: 'center'
					},
					height: 0,
					width: '100%',
					items: [__self.floatingExpandButton]
				}) : null
			],
			defaultFocus: '[itemId="expandCollapseTool"]',
			listeners: edi.constants.FILTERS_COLLAPSIBLE
				? {
					afterrender(placeholder) {
						placeholder.getEl().on("mouseenter", function () {
							__self.preventClose();
						});
						placeholder.getEl().on("mouseleave", function() {
							__self.autoClose();
						});
						placeholder.getEl().on('dblclick', function() {
							__self.toggleHandler();
						});
					},
					resize(placeholder, w, h, oldW, oldH) {
						if (!__self.collapsed) {
							//когда меняется ширина плейсхолдера, то нужно свернуть панель, что бы
							//при следующем разворачивании ей просчитались новые размеры.
							//Установка размера через setWidth и updateLayout не работает
							//т.к. лэйаут панели считается нормально посчитанным, а как его
							//инвалидировать я не разобрался =(
							if (w !== oldW) {
								__self.toggleHandler();
							}
							//при этом если меняется только высота плейсхолдера за счет добавления
							//чипсов, то просто подвинем панель ниже на высоту плейсхолдера
							else if (h !== oldH) {
								__self.setPosition(0, h);
							}
						}
					},
					//при наличии плейсхолдера и если панель свернута вызывается
					//событие именно в плейсхолдере, а не в панели
					hide() {
						__self.onPanelHide(__self);
					}
				}
				: undefined
		});
	},

	/**
	 * Process actions after component's initialization
	 */
	afterInit() {
		let __self = this;
		__self.initInputListeners();
	},

	/**
	 * Cleans up before destroy
	 * @returns	{boolean}	continue destroy
	 */
	onBeforeDestroy(__self) {
		__self.closePreviouslyOpenedModal();
		return true;
	},

	/**
	 * Add listeners to inputs on form for starting search after changes
	 */
	initInputListeners() {
		let __self = this;
		let inputs = __self.query('textfield,numberfield,checkbox,datefield,combobox');
		if (typeof __self.onFilterFieldChange !== "function" || !inputs.length) {
			return;
		}

		for (let i = 0; i < inputs.length; i++) {
			let input = inputs[i];
			if (input.ignoreAutoFilter) {
				continue;
			}
			if (input.hasOwnProperty('filterListeners')) {
				let customListeners = input.filterListeners;
				//pass filterListeners: null, false, undefined to prevent binding on default events
				if (!customListeners || (Ext.isArray(customListeners) && !customListeners.length)) {
					continue;
				}

				customListeners.forEach(event => input.on(event, __self.onFilterFieldChange));
			}
			else if (input.xtype === 'combobox') {
				input.on("select", function(combo, records) {
					//if we select "value not specified" record, deselect all other selected records
					//if (!records
					//	|| records.phantom === true
					//	|| (Ext.isArray(records) && !records.length)) {
					//	combo.setValue(null);
					//}
					//else if (Ext.isArray(records)) {
					//	for (let i = 0; i < records.length; i++) {
					//		if (!records[i].get("id") || records[i].phantom === true) {
					//			combo.setValue(null);
					//			break;
					//		}
					//	}
					//}

					__self.onFilterFieldChange();
					return true;
				});
				//input.on("beforedeselect", function(combo, record) {
				//	let selected = combo.getValue();
				//	//If we deselect last item
				//	if (Ext.isArray(selected) && selected.length === 1 && record.get("id") === selected[0]) {
				//		combo.setValue(null);
				//		__self.onFilterFieldChange();
				//	}
				//
				//	return true;
				//});
			}
			else {
				input.on("change", function() {
					__self.onFilterFieldChange();
					return true;
				});
			}
			//когда поля скрываются/показываются будем обновлять меню "+Добавить"
			input.on('show', function() {
				__self.updateAddFilterMenu();
			});
			input.on('hide', function() {
				__self.updateAddFilterMenu();
			});
		}
	},

	/**
	 * Extracts fieldLabel from form item (it can be a simple input and a container as well)
	 * @param	{Object}	formItem
	 * @returns	{string}	field or container label
	 */
	findFilterItemLabel(formItem) {
		// если есть useFieldLable возвращать только chipTitle т.к. нет необходимости склеивать label комбика и поля ввода
		if (formItem?.fieldConf?.useFieldLable && formItem?.fieldConf?.chipTitle) {
			return formItem.fieldConf.chipTitle;
		}
		//ищем заголовок поля или если это сложное поле (контейнер), то все заголовки и склеиваем их
		let title = formItem?.fieldLabel || '';
		if (!title && typeof formItem?.query === 'function') {
			title = formItem.query('field')
			.filter(f => f.fieldLabel)
			.map(f => f.fieldLabel)
			.join(' ');
		}

		return title;
	},

	/**
	 * Update "quick add" menu items based on formItems states
	 */
	updateAddFilterMenu() {
		let __self = this;
		if (!__self.addFilterButton?.menu) {
			return;
		}

		let menuItems = Object.values(__self.formItemsMap || {})
		.filter(formItem => !!formItem && !formItem.hidden)
		.map(formItem => {
			return {
				text: formItem.chipsModalTitle || __self.findFilterItemLabel(formItem) || 'unknown field',
				handler() {
					__self.openAddFilterModal(__self.addFilterButton, formItem);
				}
			};
		});

		__self.addFilterButton.menu.removeAll();
		__self.addFilterButton.menu.add(menuItems);
	},

	/**
	 * Creates backdrop and attach it after filter panel
	 */
	createBackdrop() {
		let __self = this;
		__self.backdrop = document.createElement('div');
		__self.backdrop.style.setProperty('display', 'none');
		__self.backdrop.classList.add('filter-backdrop');
		__self.backdrop.addEventListener('click', function() {
			!__self.collapsed && __self.toggleHandler();
		});
		__self.el.dom.parentElement.appendChild(__self.backdrop);
	},

	/**
	 * Shows/hides backdrop (if it exists)
	 * @param	{Boolean}	visible
	 */
	setBackdropVisible(visible) {
		if (typeof this.backdrop?.style?.setProperty === 'function') {
			this.backdrop.style.setProperty('display', visible ? 'block' : 'none');
		}
	},

	/**
	 * Fires after filterPanel rendered, adds backdrop and listeners for auto hide
	 */
	onPanelRender() {
		let __self = this;

		//для случаев, когда фильтр находится в ТАБе его модалки и слушатели события esc
		//надо вкл\выкл синхронно с активацией таба
		let parentTabPanel = __self.up('tabpanel');
		let parentTab = parentTabPanel ? parentTabPanel.getActiveTab() : null;
		if (parentTab && typeof parentTab.on === 'function') {
			parentTab.on('activate', function() {
				typeof __self.keyNav?.enable === 'function' && __self.keyNav.enable();
			});
			parentTab.on('deactivate', function() {
				__self.closePreviouslyOpenedModal();
				typeof __self.keyNav?.disable === 'function' && __self.keyNav.disable();
			});
		}

		__self.updateAddFilterMenu();
		__self.onMouseLeaveFloated = () => {};
		__self.setTitle = () => {};

		if (__self.collapsible !== false) {
			__self.createBackdrop();
		}

		__self.body.on("mousemove", function() {
			__self.preventClose();
		});

		let pickerEventsSetter = function(picker) {
			picker.getEl().on("mouseenter", function() {
				__self.preventClose();
			});
			picker.getEl().on("mouseleave", function() {
				__self.autoClose();
			});
		};

		let fieldMonitorEventsSetter = function(field) {
			field.mon(__self, "unfloat", function() {
				field.collapse();
			});
		};

		let fields = __self.getForm().getFields().getRange();
		fields.forEach(field => {
			if ("function" == typeof field?.getPicker) {
				let picker = field.getPicker();
				let el = picker.getEl();
				if (!el) {
					picker.on("render", pickerEventsSetter);
				}
				else {
					pickerEventsSetter(picker);
				}
				fieldMonitorEventsSetter(field);
			}
		});
	},

	/**
	 * Esc button handler, collapses modal or panel
	 */
	escHandler() {
		let __self = this;
		//сначала закроем окна, уже потом можно и саму панель фильтров
		if (__self.lastOpenedModal && !__self.lastOpenedModal.isDestroyed){
			__self.lastOpenedModal.close();
		}
		else {
			!__self.isDestroyed && !__self.collapsed && __self.toggleHandler();
			window.removeEventListener('keydown', __self.__registeredEscHandler);
		}
	},

	/**
	 * Fires after panel expand, sync floating elements' z-indexes and shows backdrop
	 * @param	{Object}	__self
	 */
	onPanelFloat(__self) {
		__self.inheritedStateInner.collapsed = false;
		let fields = __self.getForm().getFields().getRange();
		fields.forEach(field => {
			let picker = field.getPicker && field.getPicker();
			picker && picker.syncHidden && picker.syncHidden();
		});
		__self.setBackdropVisible(true);

		typeof __self.keyNav?.enable === 'function' && __self.keyNav.enable();
	},

	/**
	 * Fires after panel collapse, sync floating elements' z-indexes and hides backdrop
	 * @param	{Object}	__self
	 */
	onPanelUnfloat(__self) {
		__self.inheritedStateInner.collapsed = true;
		let fields = __self.getForm().getFields().getRange();
		fields.forEach(field => {
			let picker = field.getPicker && field.getPicker();
			picker && picker.syncHidden && picker.syncHidden();
		});
		__self.setBackdropVisible(false);

		typeof __self.keyNav?.disable === 'function' && __self.keyNav.disable();
		typeof __self.floatingExpandButton?.show === 'function' && __self.floatingExpandButton.show();
	},

	/**
	 * Fires after panel show()
	 * @param	{Object}	__self
	 */
	onPanelShow(__self) {
		typeof __self.keyNav?.enable === 'function' && __self.keyNav.enable();
	},

	/**
	 * Fires after panel hide()
	 * @param	{Object}	__self
	 */
	onPanelHide(__self) {
		__self.closePreviouslyOpenedModal();
		typeof __self.keyNav?.disable === 'function' && __self.keyNav.disable();
	},

	/**
	 * Fires after form reset event, updates chips
	 */
	onFormReset() {
		let __self = this;
		__self.processHeaderChips(__self, __self.getValues());
	},

	/**
	 * Expands filter panel if we made form invalid to be able see what happened (remove chips, i.e)
	 * @param	{Object}	form
	 * @param	{Boolean}	valid
	 */
	onFormValidityChange(form, valid) {
		let __self = this;
		if (!valid && __self.collapsible && __self.collapsed) {
			__self.toggleHandler();
		}
	},

	/**
	 * Fires after field value changed, initiates search and chips update
	 */
	onFilterFieldChange() {
		let __self = this;
		if (__self.autosearchEnabled) {
			let searchFn = __self.externalOnFieldChange
				? __self.externalOnFieldChange
				: __self.fireSearch;
			if (typeof searchFn === 'function') {
				searchFn();
			}
		}
		if (typeof __self.processHeaderChips === 'function') {
			__self.processHeaderChips();
		}
	},

	/**
	 * Retrieve autoCloseTime from user settings and store it in __self
	 * @returns	{Number}	auto close timeout
	 */
	getAutoCloseTime() {
		let __self = this;
		__self.autoCloseTime = +edi.utils.getObjectProperty(
			edi.core.getUserData(),
			"userData.user.autoCloseFilterPanel"
		) || 0;
		return __self.autoCloseTime;
	},

	/**
	 * Stops auto close process
	 */
	preventClose() {
		let __self = this;
		window.clearTimeout(__self.closeTimeout);
	},

	/**
	 * Check states and collapse panel if needed
	 */
	autoClose() {
		let __self = this;
		let autoCloseTime = __self.getAutoCloseTime();//User could change value in personal settings
		__self.preventClose();
		if (autoCloseTime && !__self.isDestroyed) {
			__self.closeTimeout = setTimeout(function() {
				if (!__self.isDestroyed && !__self.collapsed) {
					__self.toggleHandler()
				}
			}, parseInt(autoCloseTime));
		}
	},

	/**
	 * Collapse/expand handler, also sets maximum height of filter panel in expanded state
	 */
	toggleHandler() {
		let __self = this;
		if (!__self.isDestroyed && __self.isVisible() && __self.collapsible) {
			let parent = __self.up();
			__self.maxHeight = parent.el.dom.clientHeight * __self.maxPanelHeightPercent;
			__self.floatCollapsedPanel();
			if (__self.floatingExpandButton?.hide && __self.inheritedStateInner.collapsed === true) {
				__self.floatingExpandButton.hide();
			}

			if (__self.lastOpenedModal && !__self.lastOpenedModal.isDestroyed){
				__self.lastOpenedModal.close();
			}
		}
	},

	/**
	 * Sets values from "quick filter" modal to form
	 * @param	{Object}	newValues
	 */
	setFilterValueFromModal(newValues) {
		let __self = this;
		__self.getForm().setValues(newValues || {});
	},

	/**
	 * Closes last opened "quick filter" modal
	 */
	closePreviouslyOpenedModal() {
		let __self = this;
		if (!__self.lastOpenedModal?.isDestroyed
			&& __self.lastOpenedModal?.isVisible()
			&& typeof __self.lastOpenedModal.close === 'function') {
			__self.lastOpenedModal.close();
		}
	},

	/**
	 * Clones component and it's children (with actual values)
	 * @param	{Object}	originalItem
	 * @param	{Object}	[overrides]
	 * @returns	{Object}	cloned component
	 */
	cloneComponent: function(originalItem, overrides) {
		if (!originalItem) {
			return null;
		}

		let __self = this;
		let cfg = Ext.clone(Ext.applyIf(overrides || {}, originalItem.initialConfig));
		Ext.merge(cfg, cfg.modalCloneConf);

		//скопируем текущие значения полей
		Ext.apply(cfg, {
			id: Ext.id(),
			hidden: originalItem.hidden,
			value: originalItem.value,
			rawValue: originalItem.rawValue,
			checked: originalItem.checked
		});
		//тут нужно покопаться в исходниках, чтобы понять как устанавливаются значения при создании
		//если не задать текущий диапазон, то он будет браться по умолчанию и стирать актуальные значения
		if (originalItem.xtype === 'edi-date-range') {
			Ext.merge(cfg, {
				activeRange: originalItem.dateFrom?.activeRange || originalItem.dateTo?.activeRange,
				fieldFromConf: Ext.merge({
					value: originalItem.dateFrom?.value,
					rawValue: originalItem.dateFrom?.rawValue
				}, originalItem.dateFrom?.modalCloneConf),
				fieldToConf: Ext.merge({
					value: originalItem.dateTo?.value,
					rawValue: originalItem.dateTo?.rawValue
				}, originalItem.dateTo?.modalCloneConf)
			}, originalItem.modalCloneConf);
		}

		if (Ext.isArray(cfg.items) && cfg.items.length > 0) {
			cfg.items = cfg.items.map(child => __self.cloneComponent(child));
		}

		let clone = null;
		let cloneClass = Ext.getClass(originalItem);
		if (cloneClass) {
			clone = new cloneClass(cfg);
		}
		else if (originalItem.xtype) {
			clone = Ext.widget(originalItem.xtype, cfg)
		}
		else {
			edi.core.logMessage('Cannot create the clone of:', 'error');
			edi.core.logMessage(originalItem, 'error');
		}

		if (clone?.hideFromPanel === true) {
			clone.removeCls('document-filter-hidden-field');
		}

		return clone;
	},

	/**
	 * Calculate width for modal based on count of fields need to be shown
	 * @param	{Object}	formItem
	 * @returns	{Number}	modal width
	 */
	calcModalWidth(formItem) {
		let __self = this;

		let fields = typeof formItem?.query === 'function' ? formItem.query('field') : [];
		let fieldsCount = fields.length;
		//т.к. диапазон дат маленький, то его будем считать как одно поле
		if (fields[0]?.xtype === 'datefieldranged') {
			fieldsCount -= 1;
		}

		if (fieldsCount < 1) {
			fieldsCount = 1;
		}
		else if (fieldsCount > 2) {
			fieldsCount = 2;
		}

		return __self.fieldWidthInModalBase * fieldsCount;
	},

	/**
	 * Opens "quick filter" modal and clone original form item there
	 * @param	{Object|undefined}	initiator
	 * @param	{Object}			originalItem
	 */
	openAddFilterModal(initiator, originalItem) {
		let __self = this;

		__self.closePreviouslyOpenedModal();

		let itemToShow = __self.cloneComponent(originalItem);
		let modalForm = createForm({
			items: [itemToShow]
		});

		let modal = createModalPanel({
			title: itemToShow?.chipsModalTitle || __self.findFilterItemLabel(itemToShow) || '',
			modal: false,
			padding: 20,
			constrain: true,
			items: [modalForm],
			width: __self.calcModalWidth(itemToShow),
			buttons: [createButton({
				text: edi.i18n.getMessage('form.btn.search'),
				handler() {
					__self.setFilterValueFromModal(modalForm.getValues(false, false, false, true));
					modal.close();
					if (typeof __self.fireSearch === 'function') {
						__self.fireSearch();
					}
				}
			})]
		});

		__self.lastOpenedModal = modal;
		modal.show();
		//если возможно, то подвинем окно фильтра ближе к месту открытия
		if (typeof initiator?.getXY === 'function') {
			modal.setPosition(initiator.getXY());
		}
	},

	/**
	 * Gets chip identification
	 * @param	{Object}	formItem
	 * @returns	{String}	identification
	 */
	getChipsIdentification(formItem) {
		return 'chip_' + (formItem.id || formItem.name);
	},

	/**
	 * Generates chip's text from fom item
	 * @param	{Object}	formItem
	 * @returns {string}	chip's text
	 */
	getItemChipValue(formItem) {
		let __self = this;
		if (!formItem) {
			return null;
		}

		if (typeof formItem.getChipValue === 'function') {
			return formItem.getChipValue(formItem)
		}

		let txt = '';
		//если поле без всяких контейнеров само по себе на форме
		let fieldValue = typeof formItem.getRawValue === 'function' && formItem.getRawValue();
		if (fieldValue) {
			txt = `${formItem.fieldLabel} ${formItem.getRawValue()}`;
		}
		//для сложных полей или полей в контейнере, то возьмем их по отдельности и склеим значения
		else if (typeof formItem.query === 'function') {
			let fields = formItem.query('field');
			//если это диапазона дат, то запишем обе даты в 1 чипс
			if (fields[0]?.xtype === 'datefieldranged') {
				txt = fields
				.filter(f => f.getRawValue() && !f.ignoreChips)
				.map(f => {
					let label = f.chipTitle ? f.chipTitle : f.getFieldLabel();
					return `${label} ${f.getRawValue()}`;
				})
				.join(' ');
			}
			else {//если просто несколько полей в контейнере, то склеим их значения, если это разрешено
				let containerLabel = __self.findFilterItemLabel(formItem);

				let fieldText = fields
				.filter(f => f.getRawValue() && !f.ignoreChips)
				.map(f => {
					let label = f.chipTitle ? f.chipTitle : f.getFieldLabel();
					if (label) {
						containerLabel = edi.utils.safeString(label, true, true);
					}
					return f.getRawValue();
				})
				.join(' ');

				if (fieldText) {
					txt = `${containerLabel} ${fieldText}`;
				}
			}
		}
		return txt;
	},

	/**
	 * Removes chip by it's identification
	 * @param	{String}	chipIdent
	 */
	removeChip(chipIdent) {
		let __self = this;
		let chip = __self.chips[chipIdent];
		if (chip) {
			__self.chipsContainer.remove(chip, true);
			delete __self.chips[chipIdent];
		}
	},

	/**
	 * Creates new or update existing chip with new text value
	 * @param	{Object}	formItem
	 * @param	{String}	chipIdent
	 * @param	{String}	newValue
	 */
	addOrUpdateChip(formItem, chipIdent, newValue) {
		let __self = this;
		let chip = __self.chips[chipIdent];
		if (chip) {
			chip.setText(newValue);
			chip.setTooltip(newValue);
		}
		else {
			chip = __self.chips[chipIdent] = __self.createChip(formItem, chipIdent, newValue);
			if (__self.chipsContainer) {
				//считаем что, если кнопка создана значит она добавлена в конец после чипсов
				//все новые чипсы добавляем перед ней, что б она оставалась в конце строки
				if (__self.addFilterButton) {
					let insertPosition = __self.chipsContainer.items.length - 1;
					if (insertPosition < 0) {
						insertPosition = 0;
					}
					__self.chipsContainer.insert(insertPosition, chip);
				}
				else {
					__self.chipsContainer.add(chip);
				}
			}
		}
	},

	/**
	 * Close chip handler
	 * @param	{Object}	formItem
	 * @param	{String}	chipIdent
	 */
	closeChipHandler(formItem, chipIdent) {
		let __self = this;
		__self.removeChip(chipIdent);

		let forceStartSearch = false;
		if (typeof formItem.reset === 'function') {
			formItem.reset();
			forceStartSearch = formItem.forceSelection;
		}
		else if (typeof formItem.query === 'function') {
			formItem.query('field')
			.filter(f => typeof f.reset === 'function')
			.forEach(f => {
				f.reset();
				if (f.forceSelection) {
					forceStartSearch = true;
				}
			});
		}

		if (forceStartSearch && typeof __self.fireSearch === 'function') {
			__self.fireSearch();
		}
	},

	/**
	 * Creates new chip object
	 * @param	{Object}	formItem
	 * @param	{String}	chipIdent
	 * @param	{String}	value
	 * @returns	{Object}	new chip (Ext.button.Button instance)
	 */
	createChip(formItem, chipIdent, value) {
		let __self = this;

		return createButton({
			field: formItem,
			chipIdent,
			text: value || "x",
			cls: "edi-filter-chip-button",
			glyph: edi.constants.ICONS.CANCEL,
			iconAlign: "right",
			maxWidth: __self.chipMaxWidth || 400,
			tooltip: {
				text: edi.utils.safeString(value),
				width: 210
			},
			listeners: {
				render(btn) {
					let closeBtn = Ext.query('.x-btn-glyph', true, btn.el.dom)[0];
					if (closeBtn) {
						closeBtn.addEventListener('click', function(e) {
							e.stopPropagation();
							__self.closeChipHandler(formItem, chipIdent);
						});
					}
				},
				click(btn) {
					__self.openAddFilterModal(btn, formItem);
				}
			}
		});
	},

	/**
	 * Updates chips based on formItems states
	 */
	processHeaderChips() {
		let __self = this;
		if (__self.disableChips === true || !__self.chipsContainer) {
			return;
		}

		if (!__self.chips) {
			__self.chips = {};
		}

		Object.values(__self.formItemsMap || {}).forEach(item => {
			if(!Ext.isObject(item)) {
				return;
			}

			let chipIdent = __self.getChipsIdentification(item);
			let chipValue = __self.getItemChipValue(item);
			if (!item || !item.isVisible() || !chipValue) {
				__self.removeChip(chipIdent);
			}
			else {
				__self.addOrUpdateChip(item, chipIdent, chipValue);
			}
		});
	},
	setFormDefaults() {}
});

/**
 * Create filter panel
 * @param	{Object}	config
 * @param	{Function}	[fireSearch]
 * @param	{Function}	[onChange]
 * @param	{Object}	[customButtons]
 * @returns	{Object}	edi.components.moduleFilterForm instance
 */
const createModuleFilterForm = function(config, fireSearch, onChange, customButtons) {
	return Ext.create('edi.components.moduleFilterForm', {
		externalConfig: config,
		externalParams: {fireSearch, onChange, customButtons}
	});
};

export {createModuleFilterForm};