import { BUTTON_CLS, createButton } from '@UIkit/components/buttons/Buttons';
import './ComboItemsAction.scss';

/**
 * Плагин для компонента Combobox, который добавляет в каждый пункт выпадающего списка кнопку действия
 */
const PTYPE_COMBO_ITEMS_ACTION = 'combo_items_action';
Ext.define('Ext.ux.plugin.ComboItemsAction', {
	extend: 'Ext.AbstractPlugin',
	alias: `plugin.${PTYPE_COMBO_ITEMS_ACTION}`,

	baseCls: 'ui-plugin-combo-items-action',
	updateStoreAfterHandleAction: false,
	handler: undefined,

	init: function (cmp) {
		var me = this;
		me.callParent([cmp]);

		const picker = cmp.getPicker(),
			store = cmp.getStore(),
			buttonArr = [];

		picker.on('viewlist', function (__self, record, itemEl) {
			const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();

			if (!record.phantom && !record?.isSection) {
				wrapEl.addCls(me.baseCls);

				let button = buttonArr.find((c) => c.listItemId == record.internalId);

				if (!button) {
					let button = createButton({
						listItemId: record.internalId,
						cls: [BUTTON_CLS.light, BUTTON_CLS.small],
						glyph: me.glyph,
						handler: function (btn, e) {
							e.stopPropagation();

							typeof me.handler == 'function' && me.handler(cmp, record);

							if (me.updateStoreAfterHandleAction) {
								store.reload();
							}
						},
						renderTo: wrapEl
					});

					buttonArr.push(button);
				} else {
					wrapEl.append(button.getEl());
				}
			}
		});
	}
});

export { PTYPE_COMBO_ITEMS_ACTION };
