import {createField, createFields, createTriggerField} from "./fields";
import {createPanel} from "./panels";
import {createButton} from "./buttons";

/**
 * Creates form for receiving and copying translations
 * @returns	{Object}	{panel, toggleVisible, setData}
 */
const createFormForTranslation = function(){
	let self = {}, panel, keyField, keyFieldInput, valField, valFieldInput;

	keyField = createField({
		title: "key",
		input: keyFieldInput = createTriggerField({
			name: "keyField",
			allowBlank: false,
			triggers: {
				copy: {
					extraCls: 'edi-icon edi-icon-COPY',
					tooltip: "Copy",
					handler() {
						navigator.clipboard.writeText(keyFieldInput.getValue());
					}
				}
			},
			columnWidth: 0.8
		}),
		containerConfig: {
			columnWidth: 1
		}
	});

	valField = createField({
		title: "value",
		input: valFieldInput = createTriggerField({
			name: "valField",
			allowBlank: false,
			triggers: {
				copy: {
					extraCls: 'edi-icon edi-icon-COPY',
					tooltip: "Copy",
					handler() {
						navigator.clipboard.writeText(valFieldInput.getValue());
					}
				}
			},
			columnWidth: 0.8
		}),
		containerConfig: {
			columnWidth: 1
		}
	});

	panel = createContainer({
		layout: 'fit',
		width: 300,
		top: 0,
		left: 0,
		hidden: true,
		cls: 'edi-formtr-panel',
		items: [
			keyField,
			valField
		],
		renderTo: Ext.getBody()
	});

	self.panel = panel;

	self.toggleVisible = function(setHide) {
		if (setHide && panel.isVisible()) {
			panel.setVisible(false);
		}
		else if (!setHide && !panel.isVisible()) {
			panel.setVisible(true);
		}
	};

	self.setData = function(key, value) {
		keyFieldInput.setValue(key);
		valFieldInput.setValue(value);
	};

	return self;
};

/**
 * Creates config object for TwoColumnsLayout component
 * @param	{Object}	[config]
 * @returns	{Object}	{items1Conf, items2Conf} merged wth config
 */
const getTwoColumnsFilterOptions = function(config) {
	config = config ? config : {};
	let res = {
		items1Conf: {
			padding: '0 10 0 0'
		},
		items2Conf: {
			padding: '0 0 0 10'
		}
	};
	Ext.applyIf(res, config);
	return res;
};

/**
 * Creates row with add/remove buttons
 * @param	createContentFieldsFn	Function which creates panel with data inputs
 * @param	rowData					Rowdata or null for empty row
 * @param	parentContainer			Container to insert row in
 * @param	props					Additional props
 * @param	checkModuleValid		validate module
 * @return	{Object}	Ext.Panel instance with row's content and buttons(+/-)
 */
const createRowWithAddRemoveButtons = function(createContentFieldsFn, rowData, parentContainer, props, checkModuleValid) {
	rowData = rowData || {};
	props = props || {};

	let isFirstRow = !!props.isFirstRow, rowPanel, addRowBtn, removeRowBtn,
		contentFieldsPanel = createContentFieldsFn(isFirstRow, rowData, props);

	let addRow = function() {
		let newItem = createRowWithAddRemoveButtons(createContentFieldsFn, null, parentContainer, undefined, checkModuleValid);

		if (rowPanel.addBtn) {
			rowPanel.addBtn.setVisible(false);
		}

		parentContainer.add(newItem);
		parentContainer.layout.redoLayout();
		checkModuleValid();
	};

	let removeRow = function() {
		let allRows = parentContainer.items.items, thisItemIndex = Ext.Array.indexOf(allRows, rowPanel),
			onRemoveRow = 'function' == typeof contentFieldsPanel.onRemoveRow ? contentFieldsPanel.onRemoveRow : null;

		if (thisItemIndex === 0) {
			//If we delete first row, don't remove row at all, just clear values in inputs

			let inputs = contentFieldsPanel.query("textfield");
			Ext.Array.each(inputs, function(input) {
				if (!input.readOnly) {
					input.setValue(null);
					input.isValid();
				}
			});
		}
		else {
			if (thisItemIndex === allRows.length - 1) {
				//If we delete last item (which is with "+" button), then we show "+" in previous button
				let prevItem = allRows[thisItemIndex - 1];
				if (prevItem && prevItem.addBtn && !prevItem.addBtn.isDestroyed) {
					prevItem.addBtn.show();
				}
			}

			parentContainer.remove(rowPanel);
		}

		parentContainer.layout.redoLayout();
		if (onRemoveRow) {
			onRemoveRow();
		}

		checkModuleValid();
	};

	rowPanel = createPanel({
		layout: 'column',
		cls: "edi-create-field-line edi-info-block-row",
		items: [
			createContainer({
				columnWidth: 0.9,
				items: contentFieldsPanel
			}),
			createContainer({
				columnWidth: 0.1,
				items: [
					removeRowBtn = createButton({
						margin: "0 0 0 5",
						cls: 'edi-info-block-row-button',
						hidden: !!props.hideRemoveButton,
						glyph: edi.constants.ICONS.CANCEL,
						disabled: props.disableRemoveButton,
						name: "item.remove.button",
						tooltip: edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage('form.btn.delete') : null,
						text: !edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage('form.btn.delete') : null,
						handler: removeRow
					}),

					addRowBtn = createButton({
						margin: "0 0 0 5",
						cls: 'edi-info-block-row-button',
						hidden: props.hideAddButton,
						glyph: edi.constants.ICONS.ADD,
						name: "item.add.button",
						tooltip: edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage('form.btn.add.row') : null,
						text: !edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage('form.btn.add.row') : null,
						handler: addRow
					})
				]
			})
		]
	});

	rowPanel.addBtn = addRowBtn;
	rowPanel.removeBtn = removeRowBtn;
	rowPanel.identifierInput = contentFieldsPanel.identifierInput;
	rowPanel.valueInput = contentFieldsPanel.valueInput;
	rowPanel.contentFieldsPanel = contentFieldsPanel;

	if ('function' == typeof contentFieldsPanel.isNonEmptyRow) {
		rowPanel.isNonEmptyRow = contentFieldsPanel.isNonEmptyRow;
	}

	if ('function' == typeof contentFieldsPanel.setMandatoryNameField) {
		rowPanel.setMandatoryNameField = contentFieldsPanel.setMandatoryNameField;
	}

	if ('function' == typeof props.onCreate) {
		props.onCreate(rowPanel, isFirstRow);
	}

	return rowPanel;
};

/**
 * Creates block of rows witch can be added and removed
 * @param	{Function}	createContentFieldsFn	items row array
 * @param	{String}	fieldsPropertyName		patch to data for array
 * @param	{Object}	panelProps				panel props
 * @param	{Object}	documentData			data to write
 * @param	{Function}	checkModuleValid		validate module
 * @returns	{Object}	TwoColumnsLayout with rows
 */
const createRowsEditingBlock = function(createContentFieldsFn, fieldsPropertyName, panelProps, documentData, checkModuleValid) {
	panelProps = panelProps || {};

	let createFormItemsByValues = function(method, valuePath) {
		let fields = [];
		let fieldsValues = edi.utils.getObjectProperty(documentData, valuePath) || [];
		if (fieldsValues.length > 0) {
			for (let i = 0; i < fieldsValues.length; i++) {
				fields.push(method(!i, fieldsValues[i], i === fieldsValues.length - 1));
			}
		}
		return fields.length > 0 ? fields : [method(true)];
	};

	let initialRows, twoColumnsLayout = createTwoColumnsLayout(
		[], [], panelProps.panelColumnWidth || 0.95, getTwoColumnsFilterOptions()
	);

	delete panelProps.panelColumnWidth;

	twoColumnsLayout.rowsContainer = twoColumnsLayout.items.items[0];
	twoColumnsLayout.rowsContainer.getRows = function() {
		return twoColumnsLayout.rowsContainer.items ? twoColumnsLayout.rowsContainer.items.items : [];
	};

	twoColumnsLayout.rowsContainer.getRowIndex = function(row) {
		return twoColumnsLayout.rowsContainer.items ? Ext.Array.indexOf(twoColumnsLayout.rowsContainer.items.items, row) : -1;
	};

	//Creates row with add & remove buttons
	let createRow = function(isFirstRow, rowData, isLastRow) {
		let showAddBtn = isLastRow || (isFirstRow && !rowData), props = Ext.apply({
			isFirstRow: isFirstRow,
			hideAddButton: !showAddBtn
		}, panelProps);

		return createRowWithAddRemoveButtons(createContentFieldsFn, rowData, twoColumnsLayout.rowsContainer, props, checkModuleValid);
	};

	initialRows = createFormItemsByValues(createRow, fieldsPropertyName);
	twoColumnsLayout.rowsContainer.add(initialRows);
	twoColumnsLayout.rowsContainer.mainRow = initialRows[0];

	if ('function' == typeof panelProps.onRemoveRow) {
		twoColumnsLayout.rowsContainer.onRemoveRow = panelProps.onRemoveRow;
	}

	return twoColumnsLayout;
};

/**
 * Creates 2 columns layout
 * @param	{Array}		[items1]		config options for left panel
 * @param	{Array}		[items2]		config options for right panel
 * @param	{Number}	[proportions]	optional 0 - 1 columnWidth for first column
 * @param	{Object}	[config]
 * @returns	{Object}	Ext.Panel instance with 2 panels inside
 */
const createTwoColumnsLayout = function(items1, items2, proportions, config) {
	config = "object" == typeof config ? config : {};
	let items1Conf = config.items1Conf ? config.items1Conf : {};
	let items2Conf = config.items2Conf ? config.items2Conf : {};
	delete config.items1Conf;
	delete config.items2Conf;
	Ext.applyIf(items1Conf, {
		columnWidth: proportions ? proportions : 0.5,
		items: items1 || [],
		layout: "anchor",
		defaults: {
			anchor: "100%"
		}
	});
	items1Conf.cls = "two-column left-column " + (items1Conf.cls ? items1Conf.cls : "");
	Ext.applyIf(items2Conf, {
		columnWidth: proportions ? 1 - proportions : 0.5,
		items: items2 || [],
		layout: "anchor",
		defaults: {
			anchor: "100%"
		}
	});
	items2Conf.cls = "two-column right-column " + (items2Conf.cls ? items2Conf.cls : "");
	config.layout = 'column';
	config.cls = "two-column-layout " + (config.cls ? config.cls : "");
	config.items = [
		createPanel(items1Conf),
		createPanel(items2Conf),
		{ xtype: 'tbspacer', maxWidth: 5 }
	];
	return createPanel(config);
};
/**
 * Create field set with parameters passed in config
 */
const createFieldSetFromConfig = function(conf) {
	conf = conf || {};
	let defaults = {}, items = conf.items;
	delete conf.items;
	delete conf.type;
	Ext.applyIf(conf, defaults);
	conf.title = edi.i18n.getMessage(conf.title);
	let set = new Ext.form.FieldSet(conf);
	let options = {};
	if (conf.forceChildrenReadOnly) {
		options.forceChildrenReadOnly = true;
	}
	let src = conf.valueSrc || {};
	if (items) {
		set.add(createFields(items, src, {}, {}, options));
	}
	return set;
};

/**
 * Create two columns layout with parameters passed in config
 * @param	{Object}	[conf]
 * @returns	{Object}	Ext.Panel instance with 2 panels inside
 */
const createTwoColumnsLayoutFromConfig = function(conf) {
	conf = conf || {};
	conf.inputConfig = conf.inputConfig || {};
	conf.containerConfig = conf.containerConfig || {};

	Ext.applyIf(conf.containerConfig, {
		items1Conf: {
			margin: "0 5 0 0"
		},
		items2Conf: {
			margin: "0 0 0 5"
		}
	});

	//Apply additional props (passed in conf.inputConfig) to all children fields (include for "twoColumnsLayout" children)
	let applyConfigToChildren = function(layoutConf, additionalConf) {
		if (!Ext.Object.getKeys(additionalConf).length) {
			return;
		}

		let applyParentProps = function(childrenConf) {
			if (childrenConf.type === 'twoColumnsLayout') {
				let resultConf = Ext.applyIf(childrenConf.inputConfig || {}, additionalConf);
				applyConfigToChildren(childrenConf, resultConf);
			}
			else {
				Ext.applyIf(childrenConf, additionalConf);
			}
		};

		if (Ext.isArray(layoutConf.items1)) {
			Ext.Array.forEach(layoutConf.items1, applyParentProps);
		}

		if (Ext.isArray(layoutConf.items2)) {
			Ext.Array.forEach(layoutConf.items2, applyParentProps);
		}
	};

	//Apply additional input config to all fields configs
	if (Ext.Object.getKeys(conf.inputConfig).length) {
		applyConfigToChildren(conf, conf.inputConfig);
	}

	let options = {};
	if (conf.forceChildrenReadOnly === true) {
		options.forceChildrenReadOnly = true;
	}
	let valueSrc = conf.valueSrc || {};
	let leftColumn = Ext.isArray(conf.items1)
		? createFields(conf.items1, valueSrc, {}, {}, options)
		: [];
	let rightColumn = Ext.isArray(conf.items2)
		? createFields(conf.items2, valueSrc, {}, {}, options)
		: [];
	let proportions = conf.proportions || 0.5;

	return createTwoColumnsLayout(leftColumn, rightColumn, proportions, conf.containerConfig);
};

/**
 * Create container
 * @param	{Object}	config
 * @returns	{Object}	Ext.container.Container instance
 */
const createContainer = function(config) {
	return new Ext.container.Container(config);
};

/**
 * Creates menu item component
 * @param	{Object}	config
 * @returns	{Object}	Ext.menu.Item instance
 */
const createMenuItem = function(config) {
	let defaults = {
		text: "",
		plugins: [Ext.ux.DisableDoubleClick]
	};
	Ext.applyIf(config, defaults);
	return new Ext.menu.Item(config);
};

/**
 * Create data selector
 * @param	{Object}	options		selector options
 * @returns {Object}	Ext.Panel instance
 */
const createDataSelector = function(options) {
	let rendered = false, previousData = {};
	options = options ? options : {};
	let updatePanel = function() {
		let data = options.data ? Ext.clone(options.data) : {};
		if (!rendered || !(edi.utils.compareObjects(data, previousData) || (edi.utils.isEmptyObject(previousData) && edi.utils.isEmptyObject(data)))) {
			if (rendered) {
				displayPanel.removeAll();
			}
			else {
				options.mandatory ? container.addCls(options.mandatoryCls) : null;
			}
			if (edi.utils.isEmptyObject(data)) {
				changeButton.setTooltip(edi.i18n.getMessage("form.btn.add"));
				changeButton.setGlyph(edi.constants.ICONS.ADD);
				if (container.body) {
					container.body.removeCls(options.filledCls);
					container.body.addCls(options.emptyCls);
				}
			}
			else {
				changeButton.setTooltip(edi.i18n.getMessage("form.btn.edit"));
				changeButton.setGlyph(edi.constants.ICONS.EDIT);
				if (container.body) {
					container.body.removeCls(options.emptyCls);
					container.body.addCls(options.filledCls);
				}
			}
			displayPanel.add({
				html: edi.utils.isEmptyObject(data) && options.emptyText ? '<div class="edi-empty-label">' + edi.i18n.getMessage(options.emptyText) + '</div>' : options.template.apply(data),
				xtype: "container",
				cls: edi.utils.isEmptyObject(data) && options.emptyText ? "edi-empty-label-container" : undefined
			});
			rendered = true;
		}
	}, valuesProcessor = function(data, callback) {
		if ("function" == typeof options.valuesProcessOnUpdate) {
			if (options.useUpdateCallback) {
				options.valuesProcessOnUpdate(data, callback);
			}
			else {
				callback(options.valuesProcessOnUpdate(data));
			}
		}
		else {
			callback(data);
		}
	}, setData = function(data) {
		previousData = options.data ? Ext.clone(options.data) : {};
		options.data = data ? Ext.clone(data) : {};
		updatePanel();
		"function" == typeof options.onChange ? options.onChange(data) : null;
	};
	let defaultOptions = {
		data: {},
		emptyCls: "edi-data-selector-empty-data",
		filledCls: "edi-data-selector-filled-data",
		mandatoryCls: "edi-data-selector-data-mandatory",
		readOnly: false,
		mandatory: false,
		template: new Ext.XTemplate([
			'<div class="edi-company-row">No template defined</div>'
		]),
		emptyText: "value.not.specified",
		useUpdateCallback: false,
		containerOptions: {}
	}, containerDefaultOptions = {
		cls: "edi-data-selector-data",
		columnWidth: 0.8,
		bodyPadding: "0 0 10 0",
		layout: "fit",
		getData: function() {
			return Ext.clone(options.data);
		},
		setData: function(data) {
			valuesProcessor(data, setData);
		},
		isValid: function() {
			let valid = true;
			if ("function" == typeof options.isValid) {
				valid = options.isValid(container.getData());
			}
			return !!valid;
		},
		setDisabled: function(disable) {
			changeButton.setDisabled(disable);
		},
		get: function(name) {
			let data = container.getData();
			return data[name];
		},
		listeners: {
			render: updatePanel
		}
	};
	Ext.applyIf(options, defaultOptions);
	Ext.applyIf(options.containerOptions, containerDefaultOptions);
	let displayPanel = createContainer({});
	let btnHandler = "function" === typeof options.createMenuBtn ?
		function(newData) {
			"function" === typeof options.dataSelector ? options.dataSelector(newData, function(data) {
				valuesProcessor(data, setData);
			}) : null;
		}
		: function() {
			"function" === typeof options.dataSelector ? options.dataSelector(options.data, function(data) {
				valuesProcessor(data, setData);
			}) : null;
		};
	let changeButton = "function" === typeof options.createMenuBtn ? options.createMenuBtn(btnHandler)
		: createButton({
			tooltip: edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage(edi.utils.isEmptyObject(options.data) ? "form.btn.add" : "form.btn.edit") : undefined,
			text: !edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage(edi.utils.isEmptyObject(options.data) ? "form.btn.add" : "form.btn.edit") : undefined,
			glyph: edi.utils.isEmptyObject(options.data) ? edi.constants.ICONS.ADD : edi.constants.ICONS.EDIT,
			disabled: !!options.disabled,
			handler: btnHandler,
			margin: "0 5 0 0"
		});

	let tbar = ['->'];
	if (!options.readOnly) {
		tbar.push(changeButton);
	}
	options.containerOptions.items = [displayPanel];
	options.containerOptions.tbar = tbar;
	let container = createPanel(options.containerOptions);
	return container;
};

Ext.namespace('edi.components');
Ext.merge(edi.components, {
	createFormForTranslation,
	getTwoColumnsFilterOptions,
	createRowWithAddRemoveButtons,
	createRowsEditingBlock,
	createTwoColumnsLayout,
	createFieldSetFromConfig,
	createTwoColumnsLayoutFromConfig,
	createContainer,
	createMenuItem,
	createDataSelector
});

export {
	createFormForTranslation,
	getTwoColumnsFilterOptions,
	createRowWithAddRemoveButtons,
	createRowsEditingBlock,
	createTwoColumnsLayout,
	createFieldSetFromConfig,
	createTwoColumnsLayoutFromConfig,
	createContainer,
	createMenuItem,
	createDataSelector
};