import './Badge.scss';
import tpl from './Badge.ext.tpl';

const baseCls = 'ui-badge';

const BADGE_CLS = {
	middle: `${baseCls}-middle`,
	big: `${baseCls}-big`,
	rounded: `${baseCls}-rounded`
};

const BADGE_PRESETS = {
	buttonCounter: `${baseCls}-button-counter`,
	tabCounter: `${baseCls}-tab-counter`,
	primary: `${baseCls}-primary`,
	success: `${baseCls}-success`,
	danger: `${baseCls}-danger`,
	warning: `${baseCls}-warning`,
	dark: `${baseCls}-dark`,
	info: `${baseCls}-info`
};

Ext.define('UI.badge.Badge', {
	extend: 'Ext.Component',
	xtype: 'badge',
	requires: ['Ext.util.Format'],
	autoEl: 'span',
	childEls: ['bodyEl'],
	renderTpl: tpl,
	baseCls,
	alias: 'widget.badge',
	text: undefined,
	hidden: true,

	onRender: function () {
		var me = this;
		me.callParent(arguments);

		me.setText(me.text);
	},
	setText: function (text, encode) {
		var me = this,
			text = encode !== false ? Ext.util.Format.htmlEncode(text) : text;
		me.bodyEl.dom.innerHTML = text;

		me.setVisible(text != undefined);
	},
	getText: function () {
		const me = this;
		return me.bodyEl.dom.innerHTML;
	}
});

const PTYPE_BADGE = 'badge';
const BADGE_ALIGN = {
	tl: 'tl', //Сверху слева
	t: 't', //Сверху по центру
	tr: 'tr', //Сверху справа
	trc: 'trc', //Сверху справа, но центр таргета совпадает с левым нижним углом бэйджа
	l: 'l', //Слева по центру
	c: 'c', //По центру
	r: 'r', //Справа по центру
	bl: 'bl', //Снизу слева
	b: 'b', //Снизу по центру
	br: 'br' //Снизу справа
};

Ext.define('UI.plugin.Badge', {
	extend: 'Ext.AbstractPlugin',
	alias: `plugin.${PTYPE_BADGE}`,
	requires: ['UI.badge.Badge'],
	insert: undefined,
	fixed: false,
	x: undefined,
	y: undefined,
	align: BADGE_ALIGN.r,
	init: function (cmp) {
		const me = this,
			cmpConfig = me.config;

		delete cmpConfig.ptype;
		delete cmpConfig.cmp;

		const badgeCmp = new UI.badge.Badge(cmpConfig);
		cmp.badgeCmp = badgeCmp;

		cmp.on('afterrender', function (self) {
			let carrier = self.el,
				role = self.ariaRole;

			switch (role) {
				case 'button':
					carrier = self?.btnEl;
					break;
				case 'textbox':
					carrier = self.triggerWrap;
					break;
				case 'tabpanel':
					carrier = self.tab.btnEl;
					break;
			}

			if (carrier) {
				badgeCmp.render(carrier, me.insert);
				carrier.setStyle({
					position: 'relative'
				});

				if (me.fixed) {
					badgeCmp.addCls(`${badgeCmp.baseCls}-fixed`);
					badgeCmp.addCls(`${badgeCmp.baseCls}-align-${me.align}`);
				}
			}
		});
	}
});

const createBadge = (config) => new UI.badge.Badge(config);

export { createBadge, PTYPE_BADGE, BADGE_ALIGN, BADGE_CLS, BADGE_PRESETS };
