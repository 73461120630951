Ext.define('UI.components.HiddenField', {
	extend: 'Ext.form.field.Hidden',
	baseCls: 'ui-hiddenfield',

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	}
});

const createHiddenField = (config) => new UI.components.HiddenField(config);

export { createHiddenField };
