import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { i18n } from '@UIkit/methods';
import './File.scss';

const icons = {
	attachment: 'xe2c6@Material Icons',
	close: 'xe14c@Material Icons'
};

Ext.define('UI.components.FileField', {
	extend: 'Ext.form.field.File',
	baseCls: 'ui-filefield',
	readOnly: false,
	mainButtonConfig: {},
	triggers: {
		close: {
			glyph: icons.close,
			tooltip: 'Close',
			handler: function () {
				var me = this;

				me.reset();
				me.isValid();
				me.onHiddenTrigger(!!me.getValue());
			}
		}
	},

	constructor(externalCfg) {
		this.mainButtonConfig = {};
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	},

	initComponent: function () {
		var me = this;
		Ext.form.field.File.superclass.setValue.call(this, me.value);

		me.callParent(arguments);

		me.hiddenCls = `${me.baseCls}-hidden`;

		me.on('change', function (field, value) {
			this.setRawValue(value?.replace(/C:\\fakepath\\/g, ''));
			me.onHiddenTrigger(!!me.value);
		});
	},

	onRender: function () {
		var me = this,
			filetrigger = me.getTrigger('filebutton');

		me.callParent(arguments);

		const defailtConfig = {
			width: 166,
			cls: [BUTTON_CLS.outline, BUTTON_CLS.small],
			text: i18n('file.storage.create'),
			glyph: icons.attachment,
			handler: function () {
				me.getTrigger('filebutton').component.fileInputEl.dom.click();
			}
		};

		me.mainButton = createButton(Ext.applyIf(me.mainButtonConfig || {}, defailtConfig));
		me.mainButton.render(me.bodyEl, 0);

		filetrigger.getEl().addCls(me.hiddenCls);

		me.onHiddenTrigger(!!me.value);
	},

	onHiddenTrigger: function (isHide) {
		var me = this;

		if (isHide) {
			me.triggerWrap.removeCls(me.hiddenCls);
		} else {
			me.triggerWrap.addCls(me.hiddenCls);
		}

		me.mainButton.setVisible(!isHide);
	}
});

const createFileField = (config) => new UI.components.FileField(config);

export { createFileField };
