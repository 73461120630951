import {createCompanySelector} from './company.selector.js';
import {createButton} from "./buttons";
import {createCombo, createLabel} from "./fields";
import {createPanel} from "./panels";

/**
 * Creates panel that will be placed in the header with needed controls
 * @returns	{Object}	Ext.container.Container instance
 */
const createHeaderControls = function() {
	var update = function(eventData) {
		panel.removeAll();
		var notificationsButton = edi.notifications ? edi.notifications.getActionButton() : null, userOrg, orgValues, isHideNavButton;
		var documentsStatusesButton = !edi.constants.hasOwnProperty('PERMISSION_GROUP_TAG') ? function() {
			var countUnread = 0;
			var getUnreadCountMessage = function() {
				var text = "";
				if (countUnread != null && edi.permissions.hasPermission('READ_UNSIGNED_NOTICES')) {
					text += " (" + countUnread + ")";
				}
				return text;
			};
			var signNotifyTooltip;
			var sendLastNotifyTime = function() {
				if (window.buildVersion.isAdmin === true) {
					return;
				}

				var lastNotifyTime = new Date().getTime();
				var putData = {
					"lastNotifyTime": lastNotifyTime
				};
				edi.rest.sendRequest(edi.rest.services.USER.SELF.EXTRA_INFO_ATTRIBUTES.PUT, "PUT", Ext.encode(putData), function() {
					signNotifyTooltip.setVisible(false);
					edi.core.setExtraData("user.lastNotifyTime", lastNotifyTime);
				});
			};

			var button = createButton({
				text: getUnreadCountMessage(),
				cls: 'edi-btn-with-custom-tooltip',
				glyph: edi.constants.ICONS.SIGN_NEW,
				margin: "0 10 0 0",
				handler: function() {
					sendLastNotifyTime();
					edi.core.openModule('document.statuses');
				},
				listeners: {
					render: function(elm) {
						signNotifyTooltip = Ext.create('Ext.container.Container', {
							hidden: true,
							renderTo: elm.getEl(),
							width: 30,
							margin: 0,
							items: [
								Ext.create('Ext.Component', {
									cls: 'edi-tooltip-notify',
									html: '<span>'
										+ edi.i18n.getMessage("tlp.msg.sign.notify")
										+ '</span>',
									listeners: {
										afterrender: function(component) {
											component.getEl().on('click', function(event) {
												event.stopPropagation();
												sendLastNotifyTime();
											});
										}
									}
								}),
								{
									xtype: 'button',
									cls: 'edi-tooltip-notify-close-btn',
									glyph: edi.constants.ICONS.CLOSE,
									handler(btn, event) {
										event.stopPropagation();
										sendLastNotifyTime();
										edi.events.notificationTimer.fireEvent("update");
									}
								}
							]
						});
						if (edi.permissions.hasPermission("ACCEPT_UNSIGNED_NOTICES_GROUP")) {
							var signNotifyInterval = edi.utils.getObjectProperty(
								edi.core.getUserData(),
								'org.attributes.signNotifyInterval.value'
							);
							var updateTimeoutSignNotifyInterval = signNotifyInterval * 60000;

							var timer;
							var updateNotifications = function() {
								edi.rest.sendRequest(edi.rest.services.COUNT_NOTICES.GET, "GET", null,
									function(resp) {
										countUnread = +resp.total;

										signNotifyTooltip.setVisible(countUnread > 0);
										edi.events.notificationTimer.on('update', function() {
											timer && window.clearTimeout(timer);
											timer = window.setTimeout(updateNotifications, updateTimeoutSignNotifyInterval);
										})

										timer && window.clearTimeout(timer);
										timer = window.setTimeout(updateNotifications, updateTimeoutSignNotifyInterval);
									},
									null,
									function() {
										if (button) {
											button.setText(getUnreadCountMessage());
										}
									});
							};
							if (signNotifyInterval
								&& signNotifyInterval !== '0'
								&& edi.permissions.hasPermission('READ_UNSIGNED_NOTICES')) {
								updateNotifications();
							}
						}
					}
				}
			});

			var date = {}, weekDate = function() {
				var currentDate = new Date();
				var firstDayOfWeek = new Date(currentDate.getTime()), lastDayOfWeek, dayOfWeekNumber;
				firstDayOfWeek.setHours(0, 0, 0);
				dayOfWeekNumber = (currentDate.getDay() + 6) % 7;
				firstDayOfWeek = new Date(firstDayOfWeek.getTime() - dayOfWeekNumber * edi.constants.DAY_IN_MS);
				date.dateFrom = firstDayOfWeek;

				lastDayOfWeek = new Date(firstDayOfWeek.getTime() + 6 * edi.constants.DAY_IN_MS);
				lastDayOfWeek.setHours(23, 59, 59);
				date.dateTo = lastDayOfWeek;

				return date;
			};
			weekDate();

			if (edi.permissions.hasPermission('READ_UNSIGNED_NOTICES')) {
				let getUnreadCountMessageFromRest = function () {
					edi.rest.sendRequest(edi.rest.services.COUNT_NOTICES.GET, "GET", null,
						function(data) {
							if (data.success) {
								countUnread = data.total;
							}
						},
						undefined,
						function() {
							if (button) {
								button.setText(getUnreadCountMessage());
							}
						});
				};

				getUnreadCountMessageFromRest();

				edi.events.documents.on("change", function() {
					getUnreadCountMessageFromRest();
				});
			}

			return button;
		}() : null;
		if (!edi.constants.HIDE_USER_ORG_DATA) {
			userOrg = edi.core.getUserData().org;
			orgValues = edi.converters.convertOrgToPartie(userOrg);
			edi.methods.header.bankAccountInfo(userOrg, orgValues);
		}

		if (userOrg && edi.constants.CHECK_PAYMENT_DEBT) {
			isHideNavButton = userOrg.paymentDebt;
		}

		var vboxPanel = {
			layout: "anchor",
			defaults: {
				anchor: "100%"
			}
		};
		var fnsDefaults = edi.selectors.defaultsFNS;

		var additonalConf = edi.methods.hasOwnProperty('getAdditionalFNSModalConf') ? edi.methods.getAdditionalFNSModalConf() : {};

		var FNSModalConf = Ext.merge({
			tabs: {
				main: {
					company_type: Ext.clone(edi.selectors.defaults.company_type_combo),
					company_data: {
						type: 'panel',
						panelConf: vboxPanel,
						fields: {
							company_name: fnsDefaults.main.company_name,
							company_gln: fnsDefaults.main.company_gln,
							//company_okpof: fnsDefaults.main.company_okpof,
							company_inn_kpp: fnsDefaults.main.company_inn_kpp,
							fns_id: edi.selectors.defaults.fns_id,
							company_okpo: fnsDefaults.main.company_okpo
						}
					},
					individual_data: {
						type: 'panel',
						panelConf: vboxPanel,
						fields: {
							individual_gln: fnsDefaults.main.individual_gln,
							individual_inn: fnsDefaults.main.individual_inn,
							company_okpo: fnsDefaults.main.individual_okpo,
							registration_items: {
								type: 'fieldset',
								title: 'company.individual.data',
								fields: {
									individual_lastName: fnsDefaults.main.individual_lastName,
									individual_firstName: fnsDefaults.main.individual_firstName,
									individual_patronymicName: fnsDefaults.main.individual_patronymicName,
									certificateRegistrationIP: fnsDefaults.main.certificateRegistrationIP
								}
							}
						}
					},
					structural_subdivision: fnsDefaults.main.structural_subdivision
				},
				address: {
					addressType: Ext.clone(edi.selectors.defaults.address_type_combo),
					rusAddress: {
						type: 'fieldset',
						title: 'company.address.header',
						fields: {
							zip: fnsDefaults.address.zip,
							region: fnsDefaults.address.region,
							area: fnsDefaults.address.area,
							city: fnsDefaults.address.city,
							locality: fnsDefaults.address.locality,
							street: fnsDefaults.address.street,
							home: fnsDefaults.address.home,
							block: fnsDefaults.address.block,
							flat: fnsDefaults.address.flat
						}
					},
					foreignAddress: {
						type: 'fieldset',
						title: 'company.address.foreign.header',
						fields: {
							country: fnsDefaults.address.country,
							foreignAddr: fnsDefaults.address.foreignAddr
						}
					}
				},
				bank: {
					bank_acc: fnsDefaults.bank.bank_acc_select,
					bank_corr_acc: fnsDefaults.bank.bank_corr_acc,
					bank_id: fnsDefaults.bank.bank_id,
					bank_name: fnsDefaults.bank.bank_name
				},
				contact: {
					phone: fnsDefaults.contact.phone,
					fax: fnsDefaults.contact.fax,
					email: fnsDefaults.contact.email
				}
			}
		}, additonalConf);

		let userMenuBtn = createButton({
			cls: "edi-user-menu-button",
			text: "",
			menu: {
				cls: "edi-user-menu",
				plain: true,
				hideMode: 'display',
				items: [
					edi.core.getModule("user.profile") && edi.permissions.hasPermission('READ_USER_PROFILE') ? {
						text: edi.i18n.getMessage("user.profile"),
						handler: function() {
							edi.core.openModule("user.profile");
						}
					} : null,
					edi.constants.HIDE_USER_ORG_DATA ? null : {
						text: edi.i18n.getMessage("company.details"),
						handler: function() {
							var company = createCompanySelector({
								title: 'company.details',
								modalConf: Ext.apply({
									hideSaveBtn: true,
									readOnly: true
								}, FNSModalConf),
								fieldValues: orgValues,
								selectedOrgValues: orgValues
							});
							company.showModalCompanyControl();
						}
					},
					{
						text: edi.i18n.getMessage("version.version") + ": " + edi.core.getBuildVersion().version,
						handler: function() {
							edi.core.showInfo(createVersionTooltipContent());
						}
					},
					(edi.debug && edi.core.getDebugConfig().enabled) ? {
						text: edi.i18n.getMessage("debug.tools"),
						handler: edi.debug.showDebugWindow
					} : null,
					{
						text: edi.i18n.getMessage("user.logout"),
						handler: edi.login.logout
					}
				]
			}
		});
		let navigationBtn = edi.constants.LEFT_NAVIGATION_V2 && !isHideNavButton ? edi.core.getNavigationPanel().getNavigationButton() : null;
		let logo = createLabel({
			cls: "edi-header-caption-label edi-label-left",
			padding: "5 10 10 10",
			html: edi.constants.DEFAULT.COMPONENTS_SETTINGS.HEADER_BAR_LOGO || edi.core.getBuildVersion().title
		});
		let userLabel = createUserDataLabel();
		let userOrgCombo = edi.constants.HIDE_USER_ORG_DATA ? null : (eventData && eventData.failure ? null : createUserOrganizationsSwitch());

		panel.add([
			navigationBtn,
			logo,
			{xtype: 'tbspacer', flex: 1, height: 0},
			documentsStatusesButton,
			notificationsButton,
			userOrgCombo,
			userLabel,
			userMenuBtn
		]);

	};
	var panel = createPanel({
		xtype: "container",
		cls: "edi-header-controls",
		layout: {
			type: "hbox",
			align: 'middle'
		}
	});
	edi.events.modules.on("modulesLoad", update);
	return panel;
};

/**
 * Creates label with first and last user's names
 * @returns	{Object}	Ext.form.Label instance
 */
const createUserDataLabel = function() {
	var user = edi.core.getUserData();
	return createLabel({
		cls: "edi-user-label edi-label-right",
		padding: "10",
		html: "<span class='edi-version-info'>" + user.firstName + " " + user.lastName + "</span>"
	});
};

/**
 * Creates user organization combo
 * @returns	{Object}	Ext.form.ComboBox instance
 */
const createUserOrganizationsSwitch = function() {
	var sortedOrgs = edi.methods.filiations.sortOrgsByFiliations(edi.core.getUserData().orgs);
	var isHideCombo = false;
	if (sortedOrgs.length === 1) {
		isHideCombo = sortedOrgs[0].orgType === edi.constants.ORGANIZATION_TYPES.PERSON || sortedOrgs[0].orgType === edi.constants.ORGANIZATION_TYPES.SELF_EMPLOYED
	}
	return createCombo(Ext.apply({
		store: edi.stores.createInlineStore(sortedOrgs, "ORGANIZATION"),
		columnWidth: null,
		hidden: isHideCombo,
		cls: "edi-user-org-selector",
		width: 250,
		displayField: 'visualName',
		valueField: 'id',
		allowBlank: false,
		anyMatch: true,
		forceSelection: true,
		getQtipText: function(selectedValueObj) {
			var headOrg = selectedValueObj.headOrg, tooltip = selectedValueObj.visualName || "";
			if (selectedValueObj.hasFilials) {
				tooltip += ' (' + edi.i18n.getMessage('org.is.head.org') + ')';
			}
			else if (headOrg && headOrg.id) {
				tooltip += ' (' + edi.i18n.getMessage('org.is.filiation.of.another.org', {
					headOrg: headOrg.name
				}) + ')';
			}

			return tooltip;

		},
		showQtips: true,
		emptyText: edi.i18n.getMessage('form.combo.not.selected'),
		value: edi.core.getUserOrgID(),
		listeners: {
			select: function(comp) {
				var val = comp.getValue();
				if (val && val != edi.core.getUserOrgID()) {
					edi.login.setCurrentOrganization(val, function(failed) {
						if (!failed) {
							document.location.reload();
						}
						else {
							comp.setValue(edi.core.getUserOrgID());
						}
					}, undefined, true);
				}
			},
			afterrender: function() {
				var __combo = this;
				var store = __combo.getStore();
				if (store.getCount()) {
					__combo.setValue(edi.core.getUserOrgID());
				}
				else {
					store.on("load", function() {
						__combo.setValue(edi.core.getUserOrgID());
					});
				}
			}
		}
	}, edi.constants.DEFAULT.COMPONENTS_SETTINGS.USER_ORG_COMBO));
};

/**
 * Creates panel of breadcrumb
 * @param	{Object}	tabPanel	config options in form of Ext.tab.Panel instance
 * @returns	{Object}	Ext.Panel instance
 */
const createBreadCrumb = function(tabPanel) {
	var panel = createPanel({
		cls: "edi-bread-crumb",
		region: "north",
		layout: "column",
		height: 24
	});
	var closeModulesAfter = function(moduleData) {
		var i = 0;
		var closing = false;
		var changed = false;
		while (i < tabPanel.items.items.length) {
			var tabItem = tabPanel.items.items[i];
			var module = tabItem.module;
			if (closing) {
				if (module.isChanged) {
					changed = true;
				}
			}
			i++;
			if ((!module.id && module.objectId == moduleData.objectId) || (module.id && module.id == moduleData.id)) {
				closing = true;
			}
		}
		var startClosingModules = function() {
			var i = 0;
			var closing = false;
			while (i < tabPanel.items.items.length) {
				var tabItem = tabPanel.items.items[i];
				var module = tabItem.module;
				module.isChanged = false;
				if (closing) {
					edi.modulesHandler.removeModule(module);
				}
				else {
					i++;
				}
				if ((!module.id && module.objectId == moduleData.objectId) || (module.id && module.id == moduleData.id)) {
					closing = true;
				}
			}
		};
		if (changed) {
			edi.core.confirm("confirm.changed.close.title", "confirm.changed.close.text", startClosingModules);
		}
		else {
			startClosingModules();
		}
	};

	var addItem = function(module, isLast) {
		var item = createButton({
			cls: "edi-label-button edi-bread-crumb-item" + (panel.items.length == 0 ? " first-item" : "") + (isLast ? " last-item" : ""),
			text: edi.i18n.getMessage(module.title),
			handler: function() {
				closeModulesAfter(module);
			}
		});
		panel.add(item);
		return item;
	};

	var update = function() {
		panel.removeAll();
		var countItems = tabPanel.items.items.length;
		for (var i = 0; i < countItems; i++) {
			var tabItem = tabPanel.items.items[i];
			var module = tabItem.module;
			tabItem.breadCrumbItem = addItem(module, i == countItems - 1);
		}
		var rightContainer = createPanel({
			cls: "edi-breadcrump-right" + (!countItems ? "-alone" : ""),
			layout: "column"
		});
		rightContainer.add(createUserOrganizationsSwitch());
		if (edi.login.getAuthType() != "AB" && !edi.core.getHeader()) {
			rightContainer.add(createButton({
				cls: "edi-logout-button",
				text: edi.i18n.getMessage("user.logout"),
				handler: edi.login.logout
			}));
		}
		if (edi.debug && edi.core.getDebugConfig().enabled) {
			var btn = createButton({
				cls: "edi-label-button edi-bread-crumb-item edi-breadcrump-button-right",
				text: edi.i18n.getMessage("debug.tools"),
				handler: edi.debug.showDebugWindow
			});
			rightContainer.add(btn);
		}
		rightContainer.add(createLabel({
			cls: "edi-bread-crumb-item edi-breadcrump-button-right",
			//html: "<span class='edi-version-info' data-qtip='" + createVersionTooltipContent() + ' data-qtipFormatted="true">' + edi.core.getBuildVersion().version + "</span>"
			html: `<span class='edi-version-info' data-qtip="${createVersionTooltipContent()}" data-qtipFormatted="true">${edi.core.getBuildVersion().version}</span>`
		}));
		panel.add(rightContainer);
	};

	edi.events.module.on("moduleInit", update);
	edi.events.module.on("moduleRemove", update);
	edi.events.login.on("userOrganizationLoad", update);
	return panel;
};

/**
 * Generates html content for version tooltip
 * @returns	{String}	version tooltip html
 */
const createVersionTooltipContent = function() {
	var html = '';
	var version = edi.core.getBuildVersion();
	html += edi.i18n.getMessage("version.version") + ": " + version.version + "<br/>";
	if (edi.debug && edi.core.getDebugConfig().enabled) {
		html += edi.i18n.getMessage("version.branch") + ": " + version.branch + "<br/>";
	}
	html += edi.i18n.getMessage("version.timestamp") + ": " + edi.utils.formatDate(version.timestamp, edi.constants.DATE_FORMAT.DATE_TIME_FNS, edi.constants.DATE_FORMAT.SERVER) + "<br/>";
	html += edi.i18n.getMessage("version.profile") + ": " + version.profile + " " + version.buildConfig + "<br/>";
	html += edi.i18n.getMessage("version.changeset") + ": " + version.changeSet;
	return html;
};

Ext.namespace('edi.components');
Ext.merge(edi.components, {
	createHeaderControls,
	createUserDataLabel,
	createUserOrganizationsSwitch,
	createBreadCrumb,
	createVersionTooltipContent
});

export {
	createHeaderControls,
	createUserDataLabel,
	createUserOrganizationsSwitch,
	createBreadCrumb,
	createVersionTooltipContent
};