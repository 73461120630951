import {createCloseButton} from "../core/specialComponents/buttons";

/**
 * Creates new panel object based on passed params
 * @param	{Object}	[properties]	ExtJs panel options
 * @returns	{Object}	Ext.Panel instance
 */
const createPanel = function(properties) {
	properties = "object" == typeof properties ? properties : {};
	Ext.applyIf(properties, edi.constants.DEFAULT.PANEL);
	return new Ext.Panel(properties);
};

/**
 * Creates a form
 * @param	{Object}	[config]	config options to pass to Ext.form.Panel
 * @returns	{Object}	Ext.form.Panel instance
 */
 const createForm = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		defaultType: 'textfield',
		border: 0
	};
	Ext.applyIf(config, defaults);
	return new Ext.form.Panel(config);
};

/**
 * Creates new tab panel instance
 * @param	{Object}	[properties]	tab panel config options
 * @param	{Boolean}	[isModulePanel]	true to use module panel defaults
 * @returns	{Object}	Ext.tab.Panel instance
 */
const createTabPanel = function(properties, isModulePanel) {
	properties = "object" == typeof properties ? properties : {};
	let defaults = isModulePanel ? {
		autoDestroy: true,
		region: "center",
		layout: "fit",
		flex: 1,
		plain: true,
		cls: "edi-panel tab-panel"
	} : {
		deferredRender: false,
		autoDestroy: true,
		flex: 1,
		cls: "edi-panel tab-panel"
	};
	if (edi.constants.DEFAULT.COMPONENTS_SETTINGS.TAB_PANEL_CLASS) {
		defaults.tabBar = {
			cls: edi.constants.DEFAULT.COMPONENTS_SETTINGS.TAB_PANEL_CLASS
		};
	}
	Ext.applyIf(properties, defaults);
	return new Ext.tab.Panel(properties);
};

/**
 * Creates new tab
 * @param	{Object}	[properties]	tab panel config options
 * @returns	{Object}	Ext.Panel instance
 */
const createTab = function(properties) {
	properties = "object" == typeof properties ? properties : {};
	let defaults = {
		title: "tab",
		closable: true,
		layout: "anchor",
		listeners: {
			beforeclose: {
				fn: function() {
					if ("function" == typeof properties.beforeclose) {
						return properties.beforeclose();
					}
				}
			},
			activate: {
				fn: function(comp, eOpts) {
					if ("function" == typeof properties.activate) {
						return properties.activate(comp, eOpts);
					}
				}
			}
		}
	};
	properties.glyph = properties.glyph || properties?.tabConfig?.glyph;
	Ext.applyIf(properties, defaults);
	return new Ext.Panel(properties);
};

/**
 * Creates module panel
 * @param	{Object}	[config]	options
 * @returns	{Object}	Ext.Panel instance
 */
const createModulePanel = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		cls: "edi-module-panel",
		bodyCls: "edi-module-panel-body"
	};
	Ext.applyIf(config, defaults);
	return createPanel(config);
};

/**
 * Creates module panel with layout of new documents
 * @param	{Object}	[config]	options
 * @returns	{Object}	Ext.Panel instance
 */
const createAddModulePanel = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		region: 'center',
		layout: "fit",
		border: 1,
		frame: true,
		cls: "edi-module-add"
	};
	Ext.applyIf(config, defaults);
	return createPanel(config);
};

/**
 * Creates module panel with layout of document details
 * @param	{Object}	[config]	options
 * @returns	{Object}	Ext.Panel instance
 */
const createDetailsModulePanel = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		autoScroll: true,
		region: 'center',
		layout: "fit",
		cls: "edi-module-details",
		frame: true
	};
	Ext.applyIf(config, defaults);
	return createPanel(config);
};

/**
 * Creates an instance of modal window panel
 * @param	{Object}	[config]			config options
 * @param	{Boolean}	[isHideButtons]		is hide window button panel
 * @returns	{Object}	instance of Ext.window.Window
 */
const createModalPanel = function(config, isHideButtons) {
	config = "object" == typeof config ? config : {};
	let modal, buttonsBefore = !config.buttons ? (config.buttonsBefore || []) : undefined;
	delete config.buttonsBefore;
	let defaults = {
		title: "",
		minWidth: edi.constants.DEFAULT.MODAL.WIDTH_MINIMUM,
		minHeight: edi.constants.DEFAULT.MODAL.HEIGHT_MINIMUM,
		width: edi.constants.DEFAULT.MODAL.WIDTH,
		resizable: true,
		layout: 'fit',
		autoScroll: true,
		modal: true,
		header: {
			height: 40
		}
	};
	if (buttonsBefore && !isHideButtons) {
		defaults.buttons = buttonsBefore.concat(createCloseButton(function() {
			modal.close();
		}, config.closeButtonOptions));
	}
	Ext.applyIf(config, defaults);
	modal = new Ext.window.Window(config);
	return modal;
};

/**
 * Creates panel for user actions like filtering data, print, close etc.
 * @param	{Object}	[config]	config options
 * @returns	{Object}	Ext.Panel instance
 */
const createActionsPanel = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		region: 'north',
		cls: "edi-module-actions",
		bodyPadding: "0 0 5 0"
	};
	Ext.applyIf(config, defaults);
	return createPanel(config);
};

Ext.namespace('edi.components');
Ext.merge(edi.components, {
	createPanel,
	createForm,
	createTabPanel,
	createTab,
	createModulePanel,
	createAddModulePanel,
	createDetailsModulePanel,
	createModalPanel,
	createActionsPanel
});

export {
	createPanel,
	createForm,
	createTabPanel,
	createTab,
	createModulePanel,
	createAddModulePanel,
	createDetailsModulePanel,
	createModalPanel,
	createActionsPanel
};