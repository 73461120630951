import {
	createContainer,
	createFieldSetFromConfig,
	createTwoColumnsLayout,
	createTwoColumnsLayoutFromConfig
} from './miscComponents.js';
import {createButton, createLink} from "./buttons";
import {createPanel} from "./panels";
import {createDateRange} from "./dateRange";
/**
 * Creates a labeled group of fields having default width
 * @param	{Object}    [config]    config for new Ext.form.FieldContainer
 * @returns	{Object}	Ext.form.FieldContainer instance
 */
const createFieldContainer = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		labelWidth: 150
	};
	Ext.applyIf(config, defaults);
	return new Ext.form.FieldContainer(config);
};

/**
 * Creates a date input field with a date picker dropdown
 * @param	{Object}	[config]	config for new Ext.form.field.Date
 * @returns	{Object}	Ext.form.field.Date instance
 */
const createDateField = function(config) {
	config = "object" == typeof config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	let defaults = {
		format: edi.constants.DATE_FORMAT.FNS,
		name: "date",
		startDay: 1,
		invalidText: edi.i18n.getMessage("invalid.date.format.fns")
	};
	if (!config.format && !config.submitFormat) {
		config.submitFormat = edi.constants.DATE_FORMAT.CLIENT;
	}
	Ext.applyIf(config, defaults);
	if (valueSrc && config.name) {
		config.value = Ext.Date.parse(edi.utils.getObjectProperty(valueSrc, config.name), config.submitFormat);
	}

	if (edi.constants.DATEFIELD_CLICK_ENABLED) {
		Ext.merge(config, {
			listeners: {
				render(cmp) {
					if (typeof cmp.onTriggerClick === 'function') {
						cmp.inputCell.on("click",  function() {
							(!cmp.readOnly && !cmp.isDisabled()) ? cmp.onTriggerClick() : null;
						});
					}
				}
			}
		});
	}

	return new Ext.form.field.Date(config);
};

/**
 * Creates a time input field with a dropdown
 * @param	{Object}    [config]    config for new Ext.form.field.Time
 * @returns	{Object}	Ext.form.field.Time instance
 */
const createTimeField = function(config) {
	config = "object" == typeof config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	let defaults = {
		format: 'H:i',
		increment: 5,
		invalidText: edi.i18n.getMessage("invalid.time.format")
	};
	Ext.applyIf(config, defaults);
	if (valueSrc && config.name) {
		config.value = edi.utils.getObjectProperty(valueSrc, config.name);
	}
	if (config.format && config.submitFormat && config.format !== config.submitFormat) {
		config.value = Ext.Date.parse(config.value, config.submitFormat);
	}

	if (edi.constants.TIMEFIELD_CLICK_ENABLED) {
		Ext.merge(config, {
			listeners: {
				render(cmp) {
					if (typeof cmp.onTriggerClick === 'function') {
						cmp.inputCell.on("click", function() {
							(!cmp.readOnly && cmp.isDisabled()) ? cmp.onTriggerClick() : null;
						});
					}
				}
			}
		});
	}

	return new Ext.form.field.Time(config);
};

/**
 * Creates a number field with type of integer and trigger disabled
 * @param	{Object}    [config]    config for new Ext.form.field.Number
 * @returns	{Object}	Ext.form.field.Number instance
 */
const createNumberField = function(config) {
	config = "object" == typeof config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	let maxLength = config.maxLength;
	delete config.maxLength;
	let maxValue = config.maxValue;
	delete config.maxValue;
	if (!maxValue) {
		if (maxLength) {
			maxValue = edi.utils.getMaxValueByLength(maxLength);
		}
		else {
			maxValue = Number.MAX_SAFE_INTEGER;
		}
	}
	let defaults = {
		invalidText: edi.i18n.getMessage("invalid.number.format"),
		decimalSeparator: '.',
		decimalPrecision: edi.core.getPrecisionNumber(),
		allowDecimals: false,
		allowExponential: false,
		minValue: 0,
		maxValue: maxValue,
		maxLength: maxLength,
		hideTrigger: true
	};
	Ext.applyIf(config, defaults);
	if (valueSrc && config.name) {
		config.value = edi.utils.getObjectProperty(valueSrc, config.name);
	}
	return new Ext.form.field.Number(config);
};

/**
 * Creates a trigger field
 * @param	{Object}	[config]	config for new Ext.form.field.Trigger
 * @returns	{Object}	Ext.form.field.Trigger instance
 */
const createTriggerField = function(config) {
	config = "object" == typeof config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	if (valueSrc && config.name) {
		config.value = edi.utils.getObjectProperty(valueSrc, config.name);
	}

	return Ext.create('Ext.form.field.Text', config);
};

/**
 * Creates a multi value field
 * @param	{Object}	[config]	config for new Ext.form.field.multiValue
 * @returns	{Object}	Ext.form.field.multiValue instance
 */
const createMultiValueField = function(config) {
	config = "object" == typeof config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	if (valueSrc && config.name) {
		config.value = edi.utils.getObjectProperty(valueSrc, config.name);
	}

	return Ext.create('Ext.form.field.multiValue', config);
};

/**
 * Creates a text field with email address validation
 * @param	{Object}	[config]	config for new createTextField()
 * @returns	{Object}	Ext.form.field.Text or Ext.form.field.TextArea instance
 */
const createEmailField = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		regex: edi.constants.VALIDATORS.EMAIL,
		maxLength: 175,
		invalidText: edi.i18n.getMessage("invalid.email.format")
	};
	Ext.applyIf(config, defaults);
	return createTextField(config);
};

/**
 * Creates password field instance
 * @param	{Object}	[config]	config for new createTextField()
 * @returns	{Object}	Ext.form.field.Text or Ext.form.field.TextArea instance
 */
const createPasswordField = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		inputType: 'password',
		maskRe: /[\dA-Za-z!@#$%]/
	};
	Ext.applyIf(config, defaults);
	return createTextField(config);
};

/**
 * Creates combobox with OKEI store
 * @param	{Object}	[config]	config for new createCombo()
 * @returns	{Object}	Ext.form.field.Combo
 */
const createOkeiField = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		title: edi.i18n.getMessage('line.item.unit.of.measure'),
		name: "UnitOfMeasure",
		store: edi.stores.initLegacyOkeiStore(),
		valueField: "name_international",
		displayField: "name_code",
		findAndSetValue: function(val) {
			let num = this.getStore().findBy(function(record) {
				let row = record.getData();
				val = val.toUpperCase();
				return (row.name_international.toUpperCase() === val || row.name.toUpperCase() === val);
			});
			if (num < 0) {
				return false;
			}
			else {
				this.setValue(this.getStore().getAt(num).get('name_international'));
				return true;
			}
		}
	};
	Ext.applyIf(config, defaults);
	return createCombo(config);
};

/**
 * Creates a text field with phone number validation
 * @param	{Object}	[config]	config for new createTextField()
 * @returns	{Object}	Ext.form.field.Text or Ext.form.field.TextArea
 */
const createPhoneField = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		regex: edi.constants.VALIDATORS.PHONE,
		invalidText: edi.i18n.getMessage("invalid.phone.format")
	};
	Ext.applyIf(config, defaults);
	return createTextField(config);
};

/**
 * Creates a hidden field
 * @param	{Object}	[config]	config or new Ext.form.field.Hidden
 * @returns	{Object}	Ext.form.field.Hidden instance
 */
const createHiddenField = function(config) {
	config = "object" == typeof config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	let defaults = {};
	Ext.applyIf(config, defaults);
	if (valueSrc && config.name) {
		config.value = edi.utils.getObjectProperty(valueSrc, config.name);
	}
	return new Ext.form.field.Hidden(config);
};

/**
 * Set input required fields (mutate conf)
 * @param	{Object}	conf	config with requiredFields and listeners objects inside
 */
const setRequiredFields = function(conf) {
	if (conf.requiredFields && conf.requiredFields.length) {
		let originalListener;
		if (conf.listeners && conf.listeners.change) {
			originalListener = conf.listeners.change;
		}
		let checkRequired = function(comp, form) {
			if (comp.requiredFields && comp.requiredFields.length) {
				let hasValue = !!comp.getValue();
				let fields = edi.utils.getFormFields(form);
				for (let i = 0; i < comp.requiredFields.length; i++) {
					let field = fields[comp.requiredFields[i]];
					if (field) {
						field.conditional = true;
						if (hasValue) {
							field.isRequired = true;
						}
					}
				}
			}
		};
		conf.listeners = {
			change: function(comp, newValue, oldValue, eOpts) {
				originalListener ? originalListener(comp, newValue, oldValue, eOpts) : null;
				let form = comp.up("form");
				if (form) {
					let fields = edi.utils.getFormFields(form);
					for (let i in fields) {
						if (fields.hasOwnProperty(i)) {
							checkRequired(fields[i], form);
						}
					}
					for (let i in fields) {
						if (fields.hasOwnProperty(i)) {
							let field = fields[i];
							if (field.conditional) {
								field.allowBlank = !field.isRequired;
								field.isRequired = undefined;
							}
						}
					}
					form.isValid();
				}
			}
		};
	}
};

/**
 * Creates a text or a textarea field instance
 * @param	{Object}	[config]	config for new Ext.form.field.Text or Ext.form.field.TextArea
 * @returns	{Object}	Ext.form.field.Text or Ext.form.field.TextArea
 */
const createTextField = function(config) {
	config = "object" == typeof config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	let isTextarea = !!config.isTextarea;
	delete config.isTextarea;
	if (!config.validator && !config.regex && false === config.allowBlank && false !== config.trimValueSpace) {
		config.trimValueSpace = true;
	}
	let defaults = {};
	Ext.applyIf(config, defaults);
	setRequiredFields(config);
	if (valueSrc && config.name) {
		config.value = edi.utils.getObjectProperty(valueSrc, config.name);
	}
	if (config.value && config.trimValueSpace !== false) {
		config.value = String(config.value).trim();
	}
	let field;
	let trimValueSpace = !!config.trimValueSpace;
	delete config.trimValueSpace;
	if (isTextarea) {
		let defaultsTextArea = {
			minHeight: 75
		};
		Ext.merge(defaultsTextArea, config);
		field = new Ext.form.field.TextArea(defaultsTextArea);
	}
	else {
		field = new Ext.form.field.Text(config);
	}
	if (trimValueSpace) {
		field.on("blur", function(field) {
			field.setValue(field.getValue().trim());
		});
	}
	return field;
};

/**
 * Crates display field
 * @param	{Object}	[config]	config for new Ext.form.field.Display
 * @returns	{Object}	Ext.form.field.Display instance
 */
const createDisplayField = function(config) {
	config = "object" == typeof config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	let defaults = {};
	Ext.applyIf(config, defaults);
	if (valueSrc && config.name) {
		config.value = edi.utils.getObjectProperty(valueSrc, config.name);
	}
	return new Ext.form.field.Display(config);
};

/**
 * Handler for change event of tree based combobox (storeTypeTree)
 * @param	{Object}	comp	combobox component
 */
const changeHandlerTreeBasedCombo = function(comp) {
	let allValues, value = comp.getValue(),
		store = comp.getStore(), allRecords = store.queryBy(function() {
			return true;
		}),
		pressedElmId = comp.itemNow ? comp.itemNow.id : undefined;

	if (!value || !value.length) {
		allRecords.each(function(record) {
			record.set('checked', false);
		});
		comp.setValue(null);
		comp.itemNow = null;
		return true;
	}
	if (Ext.isArray(value)) {
		allValues = value;
		if (pressedElmId) {
			let pressedElmObj = store.getById(pressedElmId),
				pressedElmObjData = pressedElmObj.getData();

			pressedElmObj.set('checked', !pressedElmObj.get('checked'));

			if (!pressedElmObjData.leaf) { // Category
				allRecords.each(function(record) {
					let obj = record.getData();
					if (!!obj.leaf && obj.parent_id === pressedElmObjData.category_id) {
						if (!pressedElmObjData.checked) {
							record.set('checked', true);
							allValues.push(obj.id);
						}
						else {
							record.set('checked', false);
							Ext.Array.remove(allValues, obj.id);
						}
					}
				});
				Ext.Array.remove(allValues, pressedElmId);
				comp.getStore().clearFilter();
				comp.setValue(allValues);
			}
			else { // Leaf
				let total_cnt = 0, total_selected = 0;
				allRecords.each(function(record) {
					let obj = record.getData();
					if (obj.parent_id === pressedElmObjData.parent_id) {
						total_cnt++;
						if (!!obj.checked) {
							total_selected++;
						}
					}
				});
				allRecords.each(function(record) {
					let id = record.get('id');
					if (pressedElmObjData.id !== id) {
						if (record.get('category_id') === pressedElmObjData.parent_id) {
							record.set('checked', total_selected === total_cnt);
						}
						else {
							record.set('checked', Ext.Array.contains(allValues, id));
						}
					}
				});
			}
			comp.itemNow = null;
			if (comp.afterChangeHandler) {
				comp.afterChangeHandler(allValues, comp, store, pressedElmObj, pressedElmId);
			}
		}
	}
};

/**
 * Creates an instance of combobox control
 * @param	{Object}	[config]	config for new Ext.form.ComboBox
 * @returns	{Object}	Ext.form.ComboBox instance
 */
const createCombo = function(config) {
	config = "object" == typeof config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	let autoValue = config.autoValue;
	delete config.autoValue;
	let disableOnAutoValue = config.disableOnAutoValue;
	delete config.disableOnAutoValue;
	let setLastValueAfterTextClear = !!config.setLastValueAfterTextClear;
	delete config.setLastValueAfterTextClear;
	let defaults = {
		columnWidth: 0.5,
		queryMode: 'local',
		displayField: 'name',
		valueField: 'id',
		allowBlank: true,
		valueInitialize: false,
		forceSelection: true,
		multiSelect: false,
		emptyText: edi.i18n.getMessage('form.combo.not.selected')
	};

	if (config.store && "string" == typeof config.store) {
		config.store = edi.stores[config.store]();
	}

	Ext.applyIf(config, defaults);

	if (!config.listeners) {
		config.listeners = {};
	}

	let selectEvent = typeof config.listeners.select === 'function'
		? config.listeners.select
		: typeof config.listeners.select?.fn === 'function'
			? config.listeners.select.fn
			: null;
	config.listeners.select = function(comp, opts) {
		//0 может быть нормальным значением
		let newVal = comp.getValue();
		let isEmpty = newVal === undefined || newVal === '' || (Ext.isArray(comp.getValue()) && newVal.length === 0);
		if (isEmpty) {
			comp.setValue(null);
			comp.collapse();
		}
		else if (comp.multiSelect !== true) {
			comp.collapse();
		}
		if (selectEvent && 'function' == typeof selectEvent) {
			selectEvent = Ext.Function.bind(selectEvent, this);
			selectEvent(comp, opts);
		}
	};

	if (config.showListQtip || config.showQtips) {
		Ext.apply(config, {
			tpl: Ext.create('Ext.XTemplate',
				'<tpl for=".">',
				'<div data-qtip="{[this.getTooltipString(values)]}" class="x-boundlist-item">',
				'{[edi.utils.safeString(typeof values === "string" ? values : values["' + config.displayField + '"])]}',
				'</div>',
				'</tpl>',
				{
					getTooltipString: function(values) {
						let qtipText = 'function' == typeof config.getQtipText ? config.getQtipText(values) : values[config.displayField];
						return edi.utils.safeString(qtipText);
					}
				}
			)
		});
	}

	if (edi.constants.COMBOBOXFIELD_CLICK_ENABLED) {
		let afterrender = config.listeners.afterrender || null;
		config.listeners.afterrender = function(comp, opts) {
			if (afterrender && 'function' == typeof afterrender) {
				afterrender = Ext.Function.bind(afterrender, this);
				afterrender(comp, opts);
			}

			comp.mon(comp.inputCell.el, {
				scope: comp,
				click(e) {
					if(!comp.readOnly && !comp.isDisabled() && !comp.isExpanded) {
						comp.onTriggerClick(comp,comp.getTriggers().picker, e)
					}
				}
			});
		};
	}

	if (config.storeTypeTree) {
		Ext.apply(config, {
			afterChangeHandler: 'function' == typeof config.afterChangeHandler
				? config.afterChangeHandler
				: null,
			listConfig: {
				tpl: config.storeTypeTreeTpl
					? config.storeTypeTreeTpl
					: Ext.create('Ext.XTemplate',
						'<ul class="x-list-plain"><tpl for=".">',
						'<li role="option" class="x-boundlist-item <tpl if="checked==true & category_id==0"> x-boundlist-selected-tree</tpl>">',
						'<div class="x-combo-list-item <tpl if="category_id&gt;0"> x-combo-list-item-category <tpl elseif="id!=\'\'"> x-combo-list-item-element</tpl>"> {name} </div>',
						'</li></tpl></ul>'
					),
				listeners: {
					itemclick: function(picker, record) {
						combo.itemNow = record.data;
					}
				}
			}
		});
	}
	if (valueSrc && config.name) {
		let value = edi.utils.getObjectProperty(valueSrc, config.name);
		"undefined" != typeof value ? autoValue = value : null;
		if (autoValue && config.valueInitialize) {
			config.value = autoValue;
		}
	}
	setRequiredFields(config);
	let combo = new Ext.form.ComboBox(config);

	if (config.storeTypeTree) {
		combo.on({
			change: {
				fn: changeHandlerTreeBasedCombo,
				//нужен чтобы change срабатывал после вызова select у SelectionModel
				//без него постоянно выделяется родительский элемент (на котором произошел клик)
				delay: 1
			},
			scope: combo,
		});
	}
	"undefined" != typeof config.value && !autoValue ? autoValue = config.value : null;

	let selectValue = function() {
		let store = combo.getStore();
		if (!store) {
			return;
		}

		if ("undefined" != typeof autoValue) {
			let recordsToSelect = [];
			let result;
			let targetValues = Ext.isArray(autoValue) ? autoValue : [autoValue];
			for (let i = 0; i < targetValues.length; i++) {
				result = store.findRecord(config.valueField, targetValues[i], 0, false, true, true);
				if (result) {
					recordsToSelect.push(result);
				}
			}

			if (recordsToSelect.length > 0 && !combo.isDestroyed) {
				if (!config.valueInitialize) {
					combo.select(recordsToSelect);
				}
				if (disableOnAutoValue) {
					combo.setDisabled(true);
				}
				combo.fireEventArgs("select", [combo, combo.multiSelect ? recordsToSelect : recordsToSelect[0]]);
			}
		}
		else if (config.autoSelect) {
			let first = store.getAt(0);
			if (first) {
				combo.select(first);
				combo.fireEventArgs("select", [combo, first]);
			}
		}
	};

	if ("undefined" != typeof autoValue || config.autoSelect) {
		combo.getStore().on("load", selectValue, combo, {
			single: true
		});
		combo.on("afterrender", selectValue, combo, {
			single: true
		});
	}

	if (config.multiSelect && setLastValueAfterTextClear && config.forceSelection) {
		combo.on("blur", function(combo) {
			if (!combo.getValue() && combo.lastSelection?.length) {
				combo.setValue(combo.lastSelection);
			}
		});
	}
	return combo;
};

/**
 * Creates a wrapper panel consisting labeled input field
 * @param	{Object}	[config]	config options
 * @returns	{Object}	Ext.Panel instance
 */
const createInput = function(config) {
	config = config ? config : {};
	let items = [];
	let title = config.title ? edi.i18n.getMessage(config.title) : "";
	let input = config.input;
	let labelConf = "object" == typeof config.labelConf ? config.labelConf : {};
	let panelConf = "object" == typeof config.panelConf ? config.panelConf : {};
	//panelConf.getTitle = function() {
	//	return title;
	//};
	if (!input) {
		edi.core.logMessage("No input defined: " + title, "warn");
	}
	Ext.applyIf(labelConf, {
		html: edi.i18n.getMessage(title),
		margin: "5 0 0",
		columnWidth: 0.3,
		forId: input && "function" == typeof input.getId ? input.getId() : null
	});
	items.push(createLabel(labelConf));
	items.push(input);
	Ext.applyIf(panelConf, {
		layout: 'column',
		margin: "0 0 5"
	});
	panelConf.items = items;
	let panel = createPanel(panelConf);
	panel.input = input;
	return panel;
};

/**
 * Creates pair of field/label according to passed layout type
 * @param	{Object}					inputConfig		config of pair {title, input, containerConfig}
 * @param	{"horisontal"|"vertical"}	[type]			type of label (default is "vertical")
 * @returns	{Object}	instance of Ext.form.FieldContainer
 */
const createField = function(inputConfig, type) {
	let input = inputConfig.input;
	let infoText = edi.utils.safeString(inputConfig?.infoText || inputConfig?.input?.infoText);
	let title = inputConfig.title;
	let labelConf = inputConfig.labelConf || {};
	let useFieldLabel = !!inputConfig.useFieldLabel;
	let containerConfig = inputConfig.containerConfig ? inputConfig.containerConfig : {};
	let containerDefault = {
		layout: 'column',
		getTitle() {
			return title;
		},
		getInput() {
			return input;
		}
	};
	Ext.applyIf(containerConfig, containerDefault);
	let label = null;
	let container = {};
	let iconInfo;

	if (useFieldLabel) {
		container = createFieldContainer(
			Ext.applyIf(inputConfig.fieldContainerConfig || {}, {
				fieldLabel: edi.i18n.getMessage(title),
				layout: 'column',
				items: [input],
				cls: "edi-form-field"
			})
		);
		container.getInput = function() {
			return input;
		};
		container.getTitle = function() {
			return title;
		};
	}
	else if ("horisontal" === type) {
		if (title) {
			label = createLabel(Ext.applyIf(labelConf, {
				html: edi.i18n.getMessage(title),
				cls: "edi-fieldset-label",
				forId: input && "function" == typeof input.getId ? input.getId() : null
			}));
		}
		container.label = label;
		containerConfig.items = [label, input];
		containerConfig.layout = 'form';
		container = createFieldContainer(containerConfig);
	}
	else {
		if (title) {
			let colWidth = inputConfig.input && parseFloat(inputConfig.input.columnWidth)
				? 1 - parseFloat(inputConfig.input.columnWidth)
				: 0.5;
			if (infoText) {
				input.columnWidth = inputConfig.input.columnWidth - 0.1;
				input.updateLayout();
				iconInfo = createButton({
					columnWidth: 0.1,
					width: undefined,
					glyph: edi.constants.ICONS.INFO,
					tooltip: infoText
				});
			}
			label = createLabel(Ext.applyIf(labelConf, {
				columnWidth: colWidth,
				cls: "edi-fieldset-label",
				html: edi.i18n.getMessage(title),
				forId: input && "function" == typeof input.getId ? input.getId() : null
			}));
		}
		containerConfig.items = [label, input];
		if (iconInfo) {
			containerConfig.items.push(iconInfo);
		}
		container = createFieldContainer(containerConfig);
	}
	container.label = label;
	if (inputConfig.hidden) {
		container.hide();
	}
	if (input) {
		input.getField = function() {
			return container;
		};
	}
	return container;
};

/**
 * Create array of fields using items array with input configurations
 * @param	{Object[]}		items			array of items configs
 * @param	{Object}	[data]			dta source for fields values
 * @param	{Object}	[fieldConf]
 * @param	{Object}	[inputConf]
 * @returns	{Object[]}	Array of fields
 */
const createFields = function(items, data, fieldConf, inputConf) {
	fieldConf = fieldConf ? fieldConf : {};
	Ext.applyIf(fieldConf, {
		columnWidth: 1
	});
	inputConf = inputConf ? inputConf : {};
	Ext.applyIf(inputConf, {
		columnWidth: 0.5,
		type: "text",
		readOnly: false,
		valueSrc: data
	});

	let res = [];
	items.forEach(item => {
		if (!item) {
			return;
		}
		let conf = {};
		Ext.applyIf(conf, item);
		Ext.applyIf(conf, inputConf);
		setRequiredFields(conf);
		let type = conf.type;
		delete conf.type;
		let inputMethod;

		//To prevent disabling readonly field pass conf.disableIfReadonly: false
		if (conf.readOnly
			&& !(conf.hasOwnProperty('disableIfReadonly') && !conf.disableIfReadonly)) {
			conf.disabled = true;
		}

		if (type === "checkbox") {
			conf.checked = !!edi.utils.getObjectProperty(data || {}, conf.name);
		}
		else if (type === "twoColumnsLayout") {
			conf.inputConfig = inputConf;
		}

		if ("function" == typeof type) {
			inputMethod = type;
		}
		else if (
			(conf.readOnly || type === 'label')
			&& type !== 'combo'
			&& type !== 'twoColumnsLayout'
			&& type !== 'fieldset'
			&& type !== 'deliveryGrid'
		) {
			if (undefined === conf.valueLabel) {
				conf.valueLabel = true;
			}
			inputMethod = type === "date"
				? createDateLabel
				: createLabel;
		}
		else {
			inputMethod = getInputMethodByType(type);
		}

		let field = ("function" == typeof type || type === "hidden" || type === 'twoColumnsLayout' || type === 'fieldset' || type === 'deliveryGrid')
			? inputMethod(conf, data)
			: createField(Ext.applyIf({
				title: edi.i18n.getMessage(conf.title || "column." + edi.utils.formatName(conf.name)),
				input: inputMethod(Ext.apply(conf, {
					regexText: conf.regexText ? edi.i18n.getMessage(conf.regexText) : ''
				})),
				mandatory: !conf.allowBlank && undefined !== conf.allowBlank
			}, fieldConf));

		if (conf.hasOwnProperty('isHidden')) {
			field.hidden = "function" == typeof conf.isHidden
				? conf.isHidden(field, data, data ? data[conf.name] : null)
				: !!conf.isHidden;
		}
		res.push(field);
	});

	return res;
};

/**
 * create fieldset with fields
 * @param	{Object}	[config]
 * @param	{Object}	[inputs]	{title, input}
 * @returns	{Object}	Ext.form.FieldSet instance
 */
const createFieldSet = function(config, inputs) {
	config = config || {};
	let defaults = {};
	let type = config.type;
	delete config.type;
	Ext.applyIf(config, defaults);
	let set = new Ext.form.FieldSet(config);

	if (inputs) {
		for (let i = 0; i < inputs.length; i++) {
			set.add(createField(inputs[i], type));
		}
	}
	return set;
};

/**
 * Creates a file upload field
 * @param	{Object}    [containerConfig]		config options for container
 * @param	{Object}    [config]				config options for file field
 * @param	{Object}    [hiddenConfig]			config options for hidden field
 * @returns	{Object}	Ext.form.field.File		instance
 */
const createFile = function(containerConfig, config, hiddenConfig) {
	config = "object" == typeof config ? config : {};
	hiddenConfig = "object" == typeof hiddenConfig ? hiddenConfig : {};
	containerConfig = "object" == typeof containerConfig ? containerConfig : {};

	let passedChange, field, hidden;
	let defaults = {
		xtype: 'filefield',
		buttonText: edi.i18n.getMessage("document.upload.select"),
		anchor: "100%",
		cls: "edi-file-field",
		focusable: false
	};
	Ext.applyIf(config, defaults);

	if (!config.listeners) {
		config.listeners = {};
	}
	if (config.listeners.change) {
		passedChange = config.listeners.change;
	}
	if (!hiddenConfig.disable) {
		config.listeners.change = function(field, value, eOpts) {
			field.selectedFileName = edi.utils.getFileNameFromPath(value);
			hidden.setValue(edi.utils.base64.encode(field.selectedFileName));
			if (value) {
				delete field.duringFileSelect;
				Ext.form.field.File.superclass.setValue.call(field, value.replace(/C:\\fakepath\\/g, ''));
			}
			"function" == typeof passedChange ? passedChange(field, value, eOpts) : null;
		};
		let hiddenDefaults = {
			name: "filename"
		};
		Ext.applyIf(hiddenConfig, hiddenDefaults);
		hidden = createHiddenField(hiddenConfig);
	}

	field = new Ext.form.field.File(config);
	let originalReset = field.reset;
	field.reset = function() {
		originalReset.apply(field, arguments);
		hidden?.setValue(null);
	};

	let containerDefaults = {
		layout: 'anchor'
	};

	Ext.applyIf(containerConfig, containerDefaults);
	containerConfig.items = [field, hidden];
	let panel = createPanel(containerConfig);
	panel.fileField = field;

	return panel;
};

/**
 * Creates a single checkbox field
 * @param	{Object}	[config]	config options
 * @returns	{Object}	Ext.form.field.Checkbox instance
 */
const createCheckbox = function(config) {
	config = "object" == typeof config ? config : {};
	let defaults = {
		text: ""
	};
	Ext.applyIf(config, defaults);
	return new Ext.form.field.Checkbox(config);
};

/**
 * Creates a single radio field
 * @param	{Object}	[config]	config options
 * @returns	{Object}	Ext.form.field.Radio instance
 */
const createRadio = function(config) {
	config = "object" == typeof config ? config : {};
	return new Ext.form.field.Radio(config);
};

/**
 * Creates a date label element to be associated with a field element on a form
 * @param	{Object}	[config]	config options
 * @returns	{Object}	Ext.form.Label instance
 */
const createDateLabel = function(config) {
	config = config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	if (valueSrc && config.name) {
		let dateVal = edi.utils.getObjectProperty(valueSrc, config.name),
			isSrcString = config.typeDate && config.typeDate === "string";
		config.text = isSrcString ? dateVal : parseInt(dateVal, 10);
	}
	if (config.text) {
		let dateFormat = config.dateFormat || edi.constants.DATE_FORMAT.FNS;
		let srcFormat = config.srcFormat || edi.constants.DATE_FORMAT.CLIENT;
		if (dateFormat !== srcFormat) {
			config.text = edi.utils.formatDate(config.text, dateFormat, srcFormat);
		}
	}
	return createLabel(config);
};

/**
 * Creates a standalone label element to be associated with a field element on a form
 * @param	{Object}	[config]	config options
 * @returns	{Object}	Ext.form.Label instance
 */
const createLabel = function(config) {
	config = "object" == typeof config ? config : {};
	let valueLabel = !!config.valueLabel;
	let showZero = !!config.showZero;
	delete config.valueLabel;
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	let defaults = {
		text: ""
	};
	if (valueSrc && config.name) {
		defaults.text = edi.utils.getObjectProperty(valueSrc, config.name);
	}
	Ext.applyIf(config, defaults);
	if (config.text) {
		config.text = String("function" == typeof config.text ? config.text() : config.text).trim();
		//config.text = edi.utils.safeString(config.text);
	}
	if (valueLabel) {
		config.cls = config.cls ? config.cls : "";
		config.cls += " edi-value-label";
		if (showZero && config.text === 0) {
			config.text = String(config.text);
		}
		else if (showZero && config.text === '') {
			config.text = '';
		}
		else if (!config.text && !config.html) {
			config.text = edi.i18n.getMessage('value.not.specified');
			config.cls += " edi-empty-label";
		}
	}
	return new Ext.form.Label(config);
};

/**
 * Creates container panel for filtering grid by custom or predefined date range
 * @param	{Object}	[config]				config options
 * @param	{Array}		[showConditions]		collection of conditions for inclusion in the condition combobox
 * @param	{Boolean}	[isFieldContainer]	true if we need to render field container
 * @param	{Function}	[validator]			method validate field and condition type
 * @param	{Function}	[constructValueName]	method construct ids for conditions list
 * @returns	{Object}	instance of Ext.form.FieldContainer
 */
const createSearchTypeSelectionField = function(config, showConditions, isFieldContainer, validator, constructValueName) {
	config = "object" === typeof config ? config : {};
	showConditions = Ext.isArray(showConditions) && showConditions.length
		? showConditions
		: ['exact', 'LikeRight', 'LikeBoth'];
	let field, comboBox;
	let fieldConf = Ext.applyIf(config.fieldConf ? config.fieldConf : {}, {
		name: 'docName',
		validator: "function" == typeof validator ? function() {
			return validator(comboBox, field);
		} : undefined
	});
	let condsConfig = [];

	for (let i = 0; i < showConditions.length; i++) {
		if ("string" == typeof showConditions[i]) {
			condsConfig.push({
				id: "function" == typeof constructValueName
					? constructValueName(fieldConf.name, showConditions[i])
					: (fieldConf.name + (showConditions[i] !== 'exact' ? showConditions[i] : '')),
				name: edi.i18n.getMessage(
					"filter.form.search.type." + edi.utils.formatName(showConditions[i])
				)
			});
		}
	}

	let twoColConf = Ext.applyIf(config.twoColConf ? config.twoColConf : {}, {
		columnWidth: 1,
		items1Conf: {
			style: "padding-left: 0!important;"
		},
		items2Conf: {
			style: "padding-right: 0!important;"
		}
	});
	let comboStore = config.comboStore
		? config.comboStore
		: edi.stores.createInlineStore(condsConfig, "SIMPLE");
	let comboConf = Ext.applyIf(config.comboConf ? config.comboConf : {}, {
		store: comboStore,
		name: fieldConf.name + "Val",
		allowBlank: false,
		autoSelect: true,
		value: condsConfig.length ? condsConfig[0].id : null,
		ignoreAutoFilter: true,
		ignoreChips: true,
		anyMatch: true,
		margin: "0 5 0 0",
		filterListeners: null,
		validator: "function" == typeof validator ? function() {
			return validator(comboBox, field);
		} : undefined,
		listeners: {
			select(combo) {
				if (field.getValue()) {
					let form = combo.up("form");
					let autoSearchCheckbox = form.down("checkbox[name='filterFormAutoSearchCheckbox']");
					if (form
						&& "function" == typeof form.fireSearch
						&& (!autoSearchCheckbox || !!autoSearchCheckbox.getValue())) {
						form.fireSearch();
					}
				}
				if (typeof config.onSelect === 'function') {
					config.onSelect(combo, field);
				}
			}
		},
		showQtips: true
	});
	let twoColSize = config.twoColSize ? config.twoColSize : 0.25;
	let containerConf;
	let createMethod = createField;
	let fieldCreateMethod = createTextField;
	if (fieldConf.type) {
		fieldCreateMethod = getInputMethodByType(fieldConf.type);
	}
	if (isFieldContainer) {
		containerConf = Ext.applyIf(config.containerConf ? config.containerConf : {}, {
			fieldLabel: edi.i18n.getMessage('column.doc.name'),
			items: [createTwoColumnsLayout(
				[comboBox = createCombo(comboConf)],
				[field = fieldCreateMethod(fieldConf)],
				twoColSize,
				twoColConf
			)]
		});
		createMethod = createFieldContainer;
	}
	else {
		containerConf = Ext.applyIf(config.containerConf ? config.containerConf : {}, {
			title: edi.i18n.getMessage('column.doc.name'),
			input: createTwoColumnsLayout(
				[comboBox = createCombo(comboConf)],
				[field = fieldCreateMethod(fieldConf)],
				twoColSize,
				twoColConf
			),
			useFieldLabel: true
		});
	}
	return createMethod(containerConf);
};

const createSearchTypeSelectionFieldForGrid = function(config, showConditions, isFieldContainer, validator, constructValueName) {
	config = "object" === typeof config ? config : {};
	showConditions = Ext.isArray(showConditions) && showConditions.length
		? showConditions
		: ['exact', 'LikeRight', 'LikeBoth'];
	let field, comboBox;
	let fieldConf = Ext.applyIf(config.fieldConf ? config.fieldConf : {}, {
		name: 'docName',
		validator: "function" == typeof validator ? function() {
			return validator(comboBox, field);
		} : undefined
	});
	let condsConfig = [];

	for (let i = 0; i < showConditions.length; i++) {
		if ("string" == typeof showConditions[i]) {
			condsConfig.push({
				id: "function" == typeof constructValueName
					? constructValueName(fieldConf.name, showConditions[i])
					: (fieldConf.name + (showConditions[i] !== 'exact' ? showConditions[i] : '')),
				name: edi.i18n.getMessage(
					"filter.form.search.type." + edi.utils.formatName(showConditions[i])
				)
			});
		}
	}

	if (config.addEmptyCondition) {
		let emptyRecord = {
			id: "",
			name: edi.i18n.getMessage('form.combo.not.selected')
		};
		condsConfig = [emptyRecord, ...condsConfig]
	}

	let label = config.fieldLabel;
	delete config.fieldLabel;

	let comboStore = config.comboStore
		? config.comboStore
		: edi.stores.createInlineStore(condsConfig, "SIMPLE");
	let comboConf = Ext.applyIf(config.comboConf ? config.comboConf : {}, {
		fieldLabel: label,
		store: comboStore,
		name: fieldConf.name + "Val",
		allowBlank: false,
		autoSelect: true,
		value: condsConfig.length ? condsConfig[0].id : null,
		ignoreAutoFilter: true,
		ignoreChips: true,
		anyMatch: true,
		filterListeners: null,
		validator: "function" == typeof validator ? function() {
			return validator(comboBox, field);
		} : undefined,
		listeners: {
			select(combo) {
				if (field.getValue()) {
					let form = combo.up("form");
					let autoSearchCheckbox = form.down("checkbox[name='filterFormAutoSearchCheckbox']");
					if (form
						&& "function" == typeof form.fireSearch
						&& (!autoSearchCheckbox || !!autoSearchCheckbox.getValue())) {
						form.fireSearch();
					}
				}
			}
		},
		showQtips: true
	});

	let fieldCreateMethod = createTextField;
	if (fieldConf.type) {
		fieldCreateMethod = getInputMethodByType(fieldConf.type);
	}

	if (config.addEmptyCondition) {
		const validateComboWithEmptyRecord = (field, record) => {
			const comboBox = field.up('container').down('combobox');
			const inputField = field.up('container').down("[itemId ='inputField']");
			//кнопка поиска в модальном окне вызванном из чипса
			const searchBtn = field?.up('window')?.down('toolbar')?.down('button');
			if (!comboBox || !inputField) {
				return
			}
			const isComboInValid = inputField?.getValue() && (field === comboBox ? record?.id?.indexOf('edi.models') >= 0 : !comboBox.getValue());
			//Переключение класса здесь решает сразу две проблемы:
			//в модальном окне чипса, при невалидном поле не добавлялся класс had-invalid
			//в FilterForm класс добавлялся с задержкой в секунду
			isComboInValid ? comboBox.addCls('had-invalid') : comboBox.removeCls('had-invalid');
			comboBox.allowBlank = !isComboInValid;
			//disable кнопки в модальном окне чипса если comboBox невалиден
			searchBtn ? searchBtn.setDisabled(isComboInValid) : null;
			comboBox.isValid();
		};
		Ext.merge(comboConf, {
			allowBlank: true,
			listeners: {
				beforeselect: validateComboWithEmptyRecord
			}
		});

		Ext.merge(fieldConf, {
			itemId: 'inputField',
			listeners: {
				change: validateComboWithEmptyRecord,
			}
		});
	}

	if (fieldConf.useFieldLable) {
		Ext.apply(fieldConf, {
			fieldLabel: fieldConf.useFieldLable || label || '',
			chipTitle: label,
			//дополнительный класс нужен, чтобы label не скакал при наведении
			labelClsExtra: 'label-without-hover-effect'
		});
	}

	Ext.apply(comboConf, {
		baseCls: 'ui-core-combofield',
		grid: {
			col: 4
		}
	});
	Ext.apply(fieldConf, {
		baseCls: !!fieldConf.type ? 'ui-core-multifield' : 'ui-core-textfield',
		grid: {
			col: 8
		}
	});
	Ext.apply(config, {
		layout: {
			type: 'grid',
			gap: 0
		},
		items: [
			comboBox = createCombo(comboConf),
			field = fieldCreateMethod(fieldConf)
		]
	});

	return createContainer(config);
};

/**
 * Create search type selection with parameters passed in config
 * @param	{Object}	[conf]
 * @returns	{Object}	instance of Ext.form.FieldContainer
 */
const createSearchTypeSelectionFromConfig = function(conf) {
	conf = conf || {};
	return createSearchTypeSelectionField(
		conf.config,
		conf.showConditions,
		conf.isFieldContainer,
		conf.validator,
		conf.constructValueName
	);
};

const createDoubleFields = function(configContainer){
	let container, itemsConfig = configContainer.items && "object" === typeof configContainer.items ? configContainer.items : {};
	delete configContainer.items;

	let validator = function(comp){
		configContainer.validator(comp, container.items.items);
	};

	let items = [];
	for (let i in itemsConfig) {
		let config = itemsConfig[i], field = getInputMethodByType(config.type);
		delete config.type;

		if("object" === typeof config.listeners){
			Ext.Object.each(config.listeners, (listener, fn)=>{
				if(['change', 'select'].indexOf(listener) !== -1){
					config.listeners[listener] = Ext.Function.createInterceptor(fn, validator);
				}
			});
		}

		items.push(field(config));
	}

	var defaultConfig = Ext.apply({
		baseCls: 'ui-core-doublefields',
		layout: {
			type: 'grid',
			gap: 0
		},
		items: items
	}, configContainer || {});

	container = createContainer(defaultConfig);
	return container;
};

/**
 * Returns field creation method by passed type parameter
 * @param	{String}	[type]	type of the field to create
 * @returns	{Function}	create***Field method (default is createTextField)
 */
const getInputMethodByType = function(type) {
	let inputMethod;
	switch (type) {
		case "password": inputMethod = createPasswordField; break;
		case "okeiCode": inputMethod = createOkeiField; break;
		case "email": inputMethod = createEmailField; break;
		case "number": inputMethod = createNumberField; break;
		case "trigger": inputMethod = createTriggerField; break;
		case "date": inputMethod = createDateField; break;
		case "time": inputMethod = createTimeField; break;
		case "combo": inputMethod = createCombo; break;
		case "file": inputMethod = createFile; break;
		case "link": inputMethod = createLink; break;
		case "hidden": inputMethod = createHiddenField; break;
		case "checkbox": inputMethod = createCheckbox; break;
		case "daterange": inputMethod = createDateRange; break;
		case "displayfield": inputMethod = createDisplayField; break;
		case "multivalue": inputMethod = createMultiValueField; break;
		case "twoColumnsLayout": inputMethod = createTwoColumnsLayoutFromConfig; break;
		case "fieldset": inputMethod = createFieldSetFromConfig; break;
		case "searchTypeSelection": inputMethod = createSearchTypeSelectionFromConfig; break;
		default: inputMethod = createTextField;
	}
	return inputMethod;
};

const createFormActionField = function(config) {
	let {
		label,
		actions,
		actionsColumnsWidth
	} = config;

	actionsColumnsWidth = actionsColumnsWidth || 0.7;
	label = label || '&nbsp;';

	let labelColumnWidth = 1 - actionsColumnsWidth;
	let labelCmp = createLabel({
		text: label,
		columnWidth: labelColumnWidth
	})

	let actionsContainer = createContainer({
		columnWidth: actionsColumnsWidth,
		items: actions
	});

	return createContainer({
		layout: {
			type: 'column',
			pack: 'end',
			align: 'middle'
		},
		items: [
			labelCmp,
			actionsContainer
		]
	})
}

Ext.namespace('edi.components');
Ext.merge(edi.components, {
	createFieldContainer,
	createDateField,
	createTimeField,
	createNumberField,
	createTriggerField,
	createMultiValueField,
	createEmailField,
	createPasswordField,
	createOkeiField,
	createPhoneField,
	createHiddenField,
	setRequiredFields,
	createTextField,
	createDisplayField,
	changeHandlerTreeBasedCombo,
	createCombo,
	createInput,
	createField,
	createFields,
	createFieldSet,
	createFile,
	createCheckbox,
	createRadio,
	createDateLabel,
	createLabel,
	createSearchTypeSelectionField,
	createSearchTypeSelectionFromConfig,
	getInputMethodByType,
	createFormActionField,
	createDoubleFields,
	createSearchTypeSelectionFieldForGrid
});

export {
	createFieldContainer,
	createDateField,
	createTimeField,
	createNumberField,
	createTriggerField,
	createMultiValueField,
	createEmailField,
	createPasswordField,
	createOkeiField,
	createPhoneField,
	createHiddenField,
	setRequiredFields,
	createTextField,
	createDisplayField,
	changeHandlerTreeBasedCombo,
	createCombo,
	createInput,
	createField,
	createFields,
	createFieldSet,
	createFile,
	createCheckbox,
	createRadio,
	createDateLabel,
	createLabel,
	createSearchTypeSelectionField,
	createSearchTypeSelectionFromConfig,
	getInputMethodByType,
	createFormActionField,
	createDoubleFields,
	createSearchTypeSelectionFieldForGrid
};