import {createContainer, createTwoColumnsLayout} from "./miscComponents";

Ext.define('Ext.picker.MonthRanged', {
	extend: 'Ext.picker.Month',
	alias: 'widget.monthpickerranged',
	alternateClassName: 'Ext.MonthPickerRanged',
	useShortNames: false,
	renderTpl: [
		'<div id="{id}-bodyEl" data-ref="bodyEl" class="{baseCls}-body">',
		'<div id="{id}-monthEl" data-ref="monthEl" class="{baseCls}-months">',
		'<table class="{baseCls}-months-inner" cellspacing="0" role="grid">',
		'<tbody role="presentation"><tr role="row">',
		'<tpl for="months">',
		'{#:this.isEndOfRow}',
		'<td role="gridcell">',
		'<div class="{parent.baseCls}-item {parent.baseCls}-month">',
		// the href attribute is required for the :hover selector to work in IE6/7/quirks
		'<a hidefocus="on" class="{parent.baseCls}-item-inner" href="#">{.}</a>',
		'</div>',
		'</td>',
		'</tpl>',
		'</tr></tbody>',
		'</table>',
		'</div>',
		'<div id="{id}-yearEl" data-ref="yearEl" class="{baseCls}-years">',
		'<div class="{baseCls}-yearnav">',
		// the href attribute is required for the :hover selector to work in IE6/7/quirks
		'<a id="{id}-prevEl" data-ref="prevEl" class="{baseCls}-yearnav-button {baseCls}-yearnav-prev" href="#" hidefocus="on" >&#xE314;</a>',
		// the href attribute is required for the :hover selector to work in IE6/7/quirks
		'<a id="{id}-nextEl" data-ref="nextEl" class="{baseCls}-yearnav-button {baseCls}-yearnav-next" href="#" hidefocus="on" >&#xE315;</a>',
		'</div>',
		'<table class="{baseCls}-years-inner" cellspacing="0" role="grid">',
		'<tbody role="presentation"><tr role="row">',
		'<tpl for="years">',
		'{#:this.isEndOfRow}',
		'<td role="gridcell">',
		'<div class="{parent.baseCls}-item {parent.baseCls}-year">',
		// the href attribute is required for the :hover selector to work in IE6/7/quirks
		'<a hidefocus="on" class="{parent.baseCls}-item-inner" href="#">{.}</a>',
		'</div>',
		'</td>',
		'</tpl>',
		'</tr></tbody>',
		'</table>',
		'</div>',
		'<div class="' + Ext.baseCSSPrefix + 'clear"></div>',
		'</div>',
		'<tpl if="showButtons">',
		'<div id="{id}-buttonsEl" class="{baseCls}-buttons">{%',
		'var me=values.$comp, okBtn=me.okBtn, cancelBtn=me.cancelBtn;',
		'okBtn.ownerLayout = cancelBtn.ownerLayout = me.componentLayout;',
		'okBtn.ownerCt = cancelBtn.ownerCt = me;',
		'Ext.DomHelper.generateMarkup(okBtn.getRenderTree(), out);',
		'Ext.DomHelper.generateMarkup(cancelBtn.getRenderTree(), out);',
		'%}</div>',
		'</tpl>',
		{
			isEndOfRow: function(value) {
				value--;
				var end = value % 2 === 0 && value !== 0;
				return end ? '</tr><tr role="row">' : '';
			}
		}
	],
	beforeRender: function() {
		var me = this,
			i = 0,
			months = [],
			shortName = Ext.Date.getShortMonthName,
			monthLen = me.monthOffset;

		me.callParent();

		for (; i < monthLen; ++i) {
			if (me.useShortNames) {
				months.push(shortName(i), shortName(i + monthLen));
			}
			else {
				months.push(Ext.Date.monthNames[i], Ext.Date.monthNames[i + monthLen]);
			}
		}

		Ext.apply(me.renderData, {
			months: months,
			years: me.getYears(),
			showButtons: me.showButtons
		});
	}
});

Ext.define('Ext.picker.DateRanged', {
	extend: 'Ext.picker.Date',
	alias: 'widget.datepickerranged',
	alternateClassName: 'Ext.DatePickerRanged',
	getRangeFields: null,
	childEls: [
		'innerEl', 'rangesEl', 'eventEl', 'prevEl', 'nextEl', 'middleBtnEl', 'footerEl'
	],
	onRangeSelect: null,
	renderTpl: [
		'<div id="{id}-rangesEl" data-ref="rangesEl" class="{baseCls}-ranges<tpl if="!hasRanges"> {baseCls}-ranges-no-ranges</tpl>">',
		'<tpl foreach="ranges">',
		'<div class="{parent.baseCls}-range-type {parent.baseCls}-range-{$}">',
		'<div class="{parent.baseCls}-range-type-title">{$:this.getRangeTypeName}</div>',
		'<div class="{parent.baseCls}-range-type-content" role="grid">',
		'<tpl for=".">',
		'<div class="{baseCls}-range-cnt {baseCls}-range-cnt-{name}<tpl if="selected"> {baseCls}-range-cnt-selected</tpl>" rangeName="{name}" role="row">',
		'<a role="presentation" hidefocus="on" class="{baseCls}-range {baseCls}-range-{name}" rangeName="{name}" href="#">{name:this.getRangeName}</a>',
		'</div>',
		'</tpl>',
		'</div>',
		'</div>',
		'</tpl>',
		'</div>',
		'<div id="{id}-innerEl" data-ref="innerEl" class="{baseCls}-container<tpl if="!hasRanges"> {baseCls}-container-no-ranges</tpl>" role="grid">',
		'<div role="presentation" class="{baseCls}-header">',
		// the href attribute is required for the :hover selector to work in IE6/7/quirks
		'<a id="{id}-prevEl" data-ref="prevEl" class="{baseCls}-prev {baseCls}-arrow" href="#" role="button" title="{prevText}" hidefocus="on" >&#xE314;</a>',
		'<div class="{baseCls}-month" id="{id}-middleBtnEl" data-ref="middleBtnEl">{%this.renderMonthBtn(values, out)%}</div>',
		// the href attribute is required for the :hover selector to work in IE6/7/quirks
		'<a id="{id}-nextEl" data-ref="nextEl" class="{baseCls}-next {baseCls}-arrow" href="#" role="button" title="{nextText}" hidefocus="on" >&#xE315;</a>',
		'</div>',
		'<table id="{id}-eventEl" data-ref="eventEl" class="{baseCls}-inner" cellspacing="0" role="grid" tabindex="0" aria-readonly="true">',
		'<thead role="presentation"><tr role="row">',
		'<tpl for="dayNames">',
		'<th role="columnheader" class="{parent.baseCls}-column-header" aria-label="{.}">',
		'<div role="presentation" class="{parent.baseCls}-column-header-inner">{.:this.firstInitial}</div>',
		'</th>',
		'</tpl>',
		'</tr></thead>',
		'<tbody role="presentation"><tr role="row">',
		'<tpl for="days">',
		'{#:this.isEndOfWeek}',
		'<td role="gridcell" id="{[Ext.id()]}">',
		'<div hidefocus="on" class="{parent.baseCls}-date"></div>',
		'</td>',
		'</tpl>',
		'</tr></tbody>',
		'</table>',
		'<tpl if="showToday">',
		'<div id="{id}-footerEl" data-ref="footerEl" role="presentation" class="{baseCls}-footer">{%this.renderTodayBtn(values, out)%}</div>',
		'</tpl>',
		'</div>',
		{
			firstInitial: function(value) {
				return Ext.picker.Date.prototype.getDayInitial(value);
			},
			isEndOfWeek: function(value) {
				// convert from 1 based index to 0 based
				// by decrementing value once.
				value--;
				var end = value % 7 === 0 && value !== 0;
				return end ? '</tr><tr role="row">' : '';
			},
			renderTodayBtn: function(values, out) {
				Ext.DomHelper.generateMarkup(values.$comp.todayBtn.getRenderTree(), out);
			},
			renderMonthBtn: function(values, out) {
				Ext.DomHelper.generateMarkup(values.$comp.monthBtn.getRenderTree(), out);
			},
			getRangeTypeName: function(value) {
				return edi.i18n.getMessage("module.form.date.range.type." + value);
			},
			getRangeName: function(value) {
				return edi.i18n.getMessage('module.form.date.range.' + value);
			}
		}
	],
	initComponent: function() {
		var me = this;
		me.selectedRangeCls = me.baseCls + '-range-cnt-selected';
		me.callParent();
	},
	beforeRender: function() {
		var me = this,
			i,
			j,
			ranges = me.pickerField.ranges,
			rangesToRender = {},
			hasRanges = false,
			selectedRange = me.pickerField.activeRange;

		me.callParent();
		for (i in ranges) {
			if (ranges.hasOwnProperty(i)) {
				if (ranges[i] && ranges[i].length) {
					hasRanges = true;
					rangesToRender[i] = [];
					for (j = 0; j < ranges[i].length; j++) {
						rangesToRender[i].push({
							name: ranges[i][j],
							type: i,
							baseCls: me.baseCls,
							selected: selectedRange == ranges[i][j]
						});
					}
				}
			}
		}

		Ext.apply(me.renderData, {
			ranges: rangesToRender,
			hasRanges: hasRanges
		});
	},
	// @private
	// @inheritdoc
	onRender: function(container, position) {
		var me = this;

		me.callParent(arguments);

		me.rangeCells = me.rangesEl.select('div.x-datepicker-range-cnt');
		me.mon(me.rangesEl, {
			scope: me,
			click: {
				fn: me.handleRangeClick,
				delegate: 'a.' + me.baseCls + '-range'
			}
		});
	},
	beforeDestroy: function() {
		var me = this;

		if (me.rendered) {
			delete me.rangeCells.elements;
		}
		me.callParent();
	},
	selectedRangeUpdate: function() {
		var me = this,
			cells = me.rangeCells,
			cls = me.selectedRangeCls,
			cellItems = cells.elements,
			c,
			cLen = cellItems.length,
			cell,
			activeRange = me.pickerField.activeRange;

		cells.removeCls(cls);

		if (activeRange) {
			for (c = 0; c < cLen; c++) {
				cell = Ext.fly(cellItems[c]);
				if (cell.dom.getAttribute("rangeName") == activeRange) {
					cell.addCls(cls);
					break;
				}
			}
		}
	},
	handleRangeClick: function(e, t) {
		var me = this,
			handler = me.pickerField.rangeCnt ? me.pickerField.rangeCnt.setRange : null,
			rangeName = t.getAttribute("rangeName");

		e.stopEvent();
		if (!me.disabled && rangeName && handler) {
			if (handler) {
				handler.call(me.pickerField.rangeCnt, rangeName);
				me.selectedUpdate(me.pickerField.getValue());
			}
			me.onRangeSelect ? me.onRangeSelect() : null;
		}
	},
	showMonthPicker: function(animate) {
		var me = this,
			picker;

		if (me.rendered && !me.disabled) {
			picker = me.createMonthPicker();
			picker.setValue(me.getActive());
			picker.setHeight(me.getSize().height);
			picker.setPosition(-1, -1);
			if (me.shouldAnimate(animate)) {
				me.runAnimation(false);
			}
			else {
				picker.show();
			}
		}
		return me;
	},
	createMonthPicker: function() {
		var me = this,
			picker = me.monthPicker;

		if (!picker) {
			me.monthPicker = picker = new Ext.picker.MonthRanged({
				renderTo: me.el,
				floating: true,
				shadow: false,
				monthMargin: 0,
				small: false,
				listeners: {
					scope: me,
					cancelclick: me.onCancelClick,
					okclick: me.onOkClick,
					yeardblclick: me.onOkClick,
					monthdblclick: me.onOkClick
				}
			});
			if (!me.disableAnim) {
				// hide the element if we're animating to prevent an initial flicker
				picker.el.setStyle('display', 'none');
			}
			me.on('beforehide', Ext.Function.bind(me.hideMonthPicker, me, [false]));
		}
		return picker;
	},
	fullUpdate: function(date) {
		var me = this,
			cells = me.cells.elements,
			textNodes = me.textNodes,
			disabledCls = me.disabledCellCls,
			eDate = Ext.Date,
			i = 0,
			extraDays = 0,
			visible = me.isVisible(),
			newDate = +eDate.clearTime(date, true),
			today = +eDate.clearTime(new Date()),
			min = me.minDate ? eDate.clearTime(me.minDate, true) : Number.NEGATIVE_INFINITY,
			max = me.maxDate ? eDate.clearTime(me.maxDate, true) : Number.POSITIVE_INFINITY,
			ddMatch = me.disabledDatesRE,
			ddText = me.disabledDatesText,
			ddays = me.disabledDays ? me.disabledDays.join('') : false,
			ddaysText = me.disabledDaysText,
			format = me.format,
			days = eDate.getDaysInMonth(date),
			firstOfMonth = eDate.getFirstDateOfMonth(date),
			startingPos = firstOfMonth.getDay() - me.startDay,
			previousMonth = eDate.add(date, eDate.MONTH, -1),
			longDayFormat = me.longDayFormat,
			prevStart,
			current,
			disableToday,
			tempDate,
			setCellClass,
			html,
			cls,
			formatValue,
			value,
			selectedValue = me.pickerField.getValue(),
			selectedTime = selectedValue ? selectedValue.getTime() : null;

		if (startingPos < 0) {
			startingPos += 7;
		}

		days += startingPos;
		prevStart = eDate.getDaysInMonth(previousMonth) - startingPos;
		current = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), prevStart, me.initHour);

		if (me.showToday) {
			tempDate = eDate.clearTime(new Date());
			disableToday = (tempDate < min || tempDate > max ||
				(ddMatch && format && ddMatch.test(eDate.dateFormat(tempDate, format))) ||
				(ddays && ddays.indexOf(tempDate.getDay()) != -1));

			if (!me.disabled) {
				me.todayBtn.setDisabled(disableToday);
				me.todayKeyListener.setDisabled(disableToday);
			}
		}

		setCellClass = function(cell, cls) {
			value = +eDate.clearTime(current, true);
			cell.title = eDate.format(current, longDayFormat);
			// store dateValue number as an expando
			cell.firstChild.dateValue = value;
			if (value == today) {
				cls += ' ' + me.todayCls;
				cell.title = me.todayText;

				// Extra element for ARIA purposes
				me.todayElSpan = Ext.DomHelper.append(cell.firstChild, {
					tag: 'span',
					cls: Ext.baseCSSPrefix + 'hide-clip',
					html: me.todayText
				}, true);
			}
			if (value == newDate) {
				cls += ' ' + me.selectedCls;
				me.fireEvent('highlightitem', me, cell);
				if (visible && me.floating) {
					Ext.fly(cell.firstChild).focus(50);
				}
			}

			if (selectedTime && value == selectedTime) {
				cls += ' ' + me.baseCls + '-selectedInField';
			}

			if (value < min) {
				cls += ' ' + disabledCls;
				cell.title = me.minText;
			}
			else if (value > max) {
				cls += ' ' + disabledCls;
				cell.title = me.maxText;
			}
			else if (ddays && ddays.indexOf(current.getDay()) !== -1) {
				cell.title = ddaysText;
				cls += ' ' + disabledCls;
			}
			else if (ddMatch && format) {
				formatValue = eDate.dateFormat(current, format);
				if (ddMatch.test(formatValue)) {
					cell.title = ddText.replace('%0', formatValue);
					cls += ' ' + disabledCls;
				}
			}
			cell.className = cls + ' ' + me.cellCls;
		};

		for (; i < me.numDays; ++i) {
			if (i < startingPos) {
				html = (++prevStart);
				cls = me.prevCls;
			}
			else if (i >= days) {
				html = (++extraDays);
				cls = me.nextCls;
			}
			else {
				html = i - startingPos + 1;
				cls = me.activeCls;
			}
			textNodes[i].innerHTML = html;
			current.setDate(current.getDate() + 1);
			setCellClass(cells[i], cls);
		}

		me.monthBtn.setText(Ext.Date.format(date, me.monthYearFormat));
		me.selectedRangeUpdate();
	},
	selectedUpdate: function(date) {
		var me = this,
			t = date.getTime(),
			cells = me.cells,
			cls = me.selectedCls,
			sCls = me.baseCls + '-selectedInField',
			cellItems = cells.elements,
			c,
			cLen = cellItems.length,
			cell,
			selectedValue = me.pickerField.getValue(),
			selectedTime = typeof selectedValue?.getTime === 'function' ? selectedValue.getTime() : null,
			foundSelectedValue, foundSelectedCurrent;

		cells.removeCls(cls);
		cells.removeCls(sCls);

		for (c = 0; c < cLen; c++) {
			cell = Ext.fly(cellItems[c]);
			if (selectedTime) {
				if (cell.dom.firstChild.dateValue == selectedTime) {
					cell.addCls(sCls);
					foundSelectedValue = true;
				}
			}
			else {
				foundSelectedValue = true;
			}
			if (cell.dom.firstChild.dateValue == t) {
				me.fireEvent('highlightitem', me, cell);
				cell.addCls(cls);

				if (me.isVisible() && !me.doCancelFocus) {
					Ext.fly(cell.dom.firstChild).focus(50);
				}

				foundSelectedCurrent = true;
			}
			if (foundSelectedValue && foundSelectedCurrent) {
				break;
			}
		}
		me.selectedRangeUpdate();
	}
});

Ext.define('Ext.form.field.DateRanged', {
	extend: 'Ext.form.field.Date',
	alias: 'widget.datefieldranged',
	alternateClassName: 'Ext.DateFieldRanged',
	activeRange: null,
	ranges: null,
	rangeCnt: null,
	createPicker: function() {
		var me = this, format = Ext.String.format;
		return new Ext.picker.DateRanged({
			cls: "x-datepicker-ranged",
			pickerField: me,
			getRangeFields: function() {
				return me.getRangeFields();
			},
			dayNames: [
				edi.i18n.getMessage("day.sunday.short"),
				edi.i18n.getMessage("day.monday.short"),
				edi.i18n.getMessage("day.tuesday.short"),
				edi.i18n.getMessage("day.wednesday.short"),
				edi.i18n.getMessage("day.thursday.short"),
				edi.i18n.getMessage("day.friday.short"),
				edi.i18n.getMessage("day.saturday.short")
			],
			ownerCt: me.ownerCt,
			renderTo: document.body,
			floating: true,
			hidden: true,
			focusOnShow: true,
			minDate: me.minValue,
			maxDate: me.maxValue,
			disabledDatesRE: me.disabledDatesRE,
			disabledDatesText: me.disabledDatesText,
			disabledDays: me.disabledDays,
			disabledDaysText: me.disabledDaysText,
			format: me.format,
			showToday: me.showToday,
			startDay: me.startDay,
			minText: format(me.minText, me.formatDate(me.minValue)),
			maxText: format(me.maxText, me.formatDate(me.maxValue)),
			listeners: {
				scope: me,
				select: me.onSelect
			},
			keyNavConfig: {
				forceKeyDown: true,
				esc: {
					handler(e) {
						e.browserEvent.stopPropagation();
						me.collapse();
					},
					scope: me,
					defaultEventAction: false
				}
			},
			onRangeSelect: function() {
				me.collapse();
			}
		});
	},
	getRangeFields: function() {
		var me = this, fields = {};
		if (me.rangeCnt) {
			fields = me.rangeCnt.getFields();
		}
		return fields;
	}
});

Ext.define("edi.components.dateRange", {
	extend: 'Ext.container.Container',
	alias: "widget.edi-date-range",
	cls: "edi-form-field-date-range",
	layout: "hbox",
	fieldFromConf: null,
	fieldToConf: null,
	onFieldChange: null,
	setEndDayInDateTo: null,
	formatHasTime: false,
	formatHasMinutes: false,
	formatHasSeconds: false,
	formatHasHours: false,
	maxPeriod: null,
	maxValueErrorText: "",
	minValueErrorText: "",
	activeRange: null,
	availableRanges: null,
	delimiter: true,
	availableRangesNames: [],
	excludedRanges: [],
	ranges: {
		today: {
			type: "current",
			order: 10,
			fn: function() {
				var currentDate = new Date(), dates = {};
				dates.dateFrom = Ext.Date.clearTime(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0));
				dates.dateTo = Ext.Date.clearTime(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0));
				return dates;
			}
		},
		week: {
			type: "current",
			order: 20,
			fn: function() {
				var currentDate = new Date(), dates = {}, firstDayOfWeek = new Date(currentDate.getTime()), lastDayOfWeek, dayOfWeekNumber;
				firstDayOfWeek.setHours(0, 0, 0);
				dayOfWeekNumber = (currentDate.getDay() + 6) % 7;
				firstDayOfWeek = new Date(firstDayOfWeek.getTime() - dayOfWeekNumber * edi.constants.DAY_IN_MS);
				dates.dateFrom = Ext.Date.clearTime(firstDayOfWeek);

				lastDayOfWeek = new Date(firstDayOfWeek.getTime() + 6 * edi.constants.DAY_IN_MS);
				dates.dateTo = Ext.Date.clearTime(lastDayOfWeek);
				return dates;
			}
		},
		month: {
			type: "current",
			order: 30,
			fn: function() {
				var currentDate = new Date(), dates = {};
				dates.dateFrom = Ext.Date.clearTime(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0));
				dates.dateTo = Ext.Date.clearTime(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 0, 0, 0));
				return dates;
			}
		},
		quarter: {
			type: "current",
			order: 40,
			fn: function() {
				var currentDate = new Date(), dates = {}, theMonth = currentDate.getMonth() + 1, monthDayCount = [31, 30, 30, 31], quarterNum = Math.ceil(theMonth / 3);
				dates.dateFrom = Ext.Date.clearTime(new Date(currentDate.getFullYear(), (quarterNum - 1) * 3, 1, 0, 0, 0));
				dates.dateTo = Ext.Date.clearTime(new Date(currentDate.getFullYear(), quarterNum * 3 - 1, monthDayCount[Math.ceil(theMonth / 3) - 1], 0, 0, 0));
				return dates;
			}
		}
	},
	initComponent: function() {
		this.prepareRanges();
		this.setOwnConfig();
		this.callParent();
	},
	/**
	 * Internal configuration and structure creation
	 */
	setOwnConfig: function() {
		var me = this, defaultFieldConf = {
			labelAlign: "top",
			flex: 1,
			rangeCnt: me
		}, checkHoursReg = /[gGhH]+/, defaultFromConf = Ext.apply({
			fieldLabel: edi.i18n.getMessage('module.form.date.from'),
			name: "dateFrom"
		}, defaultFieldConf), defaultToConf = Ext.apply({
			fieldLabel: "&nbsp;",
			labelSeparator: "",
			name: "dateTo"
		}, defaultFieldConf), passedFromChange, passedToChange, passedToSelect;
		var fieldFromConf = Ext.apply({
			ranges: me.getRanges()
		}, me.fieldFromConf || {}, defaultFromConf), fieldToConf = Ext.apply({
			ranges: me.getRanges()
		}, me.fieldToConf || {}, defaultToConf);

		Ext.applyIf(fieldFromConf, defaultFromConf);
		Ext.applyIf(fieldToConf, defaultToConf);

		if (fieldToConf.format) {
			me.formatHasMinutes = -1 != fieldToConf.format.indexOf("i");
			me.formatHasSeconds = -1 != fieldToConf.format.indexOf("s");
			me.formatHasHours = checkHoursReg.test(fieldToConf.format);
			me.formatHasTime = me.formatHasMinutes || me.formatHasSeconds || me.formatHasHours;
		}

		if (fieldFromConf.listeners && fieldFromConf.listeners.change) {
			passedFromChange = fieldFromConf.listeners.change;
		}
		else if (!fieldFromConf.listeners) {
			fieldFromConf.listeners = {};
		}
		if (fieldToConf.listeners) {
			if (fieldToConf.listeners.change) {
				passedToChange = fieldToConf.listeners.change;
			}
			if (fieldToConf.listeners.select) {
				passedToSelect = fieldToConf.listeners.select;
			}
		}
		else if (!fieldToConf.listeners) {
			fieldToConf.listeners = {};
		}
		fieldFromConf.listeners.change = function(field, newVal, oldVal, eOpts) {
			me.onChange(field, newVal, oldVal, eOpts);
			"function" === typeof passedFromChange ? passedFromChange(field, newVal, oldVal, eOpts) : null;
		};
		fieldToConf.listeners.change = function(field, newVal, oldVal, eOpts) {
			me.onChange(field, newVal, oldVal, eOpts);
			"function" === typeof passedToChange ? passedToChange(field, newVal, oldVal, eOpts) : null;
		};
		fieldToConf.listeners.select = function(field, newVal, eOpts) {
			me.setTimeIntoDateEnd(newVal);
			"function" === typeof passedToSelect ? passedToSelect(field, newVal, eOpts) : null;
		};

		me.dateFrom = createDateRangedField(fieldFromConf);
		// me.dateFrom.on('select', () => me.setRange(null, true));

		me.dateTo = createDateRangedField(fieldToConf);
		// me.dateTo.on('select', () => me.setRange(null, true));

		me.items = [
			me.dateFrom,
			me.delimiter ? Ext.merge({
				xtype: "component",
				cls: "edi-form-field-date-range-delimiter"
			}, me.delimiterConfig) : null,
			me.dateTo
		];
		if (me.activeRange) {
			me.setRange(me.activeRange);
		}
	},
	/**
	 * Called on every field change
	 * @param field
	 * @param newVal
	 */
	onChange: function(field, newVal) {
		var me = this;
		if (field.name === me.dateFrom.name) {
			me.processDateToLimits(newVal);
			me.controlActiveRange(field);
		}
		else {
			me.controlActiveRange(undefined, field);
		}

		"function" === typeof me.onFieldChange ? me.onFieldChange() : null;
	},
	/**
	 * Adds maximal time to end date, to add whole end day into range(used only for controls with time support in format)
	 * @param value
	 */
	setTimeIntoDateEnd: function(value) {
		var me = this;
		if (value && me.setEndDayInDateTo && me.formatHasTime && me.dateTo) {
			value.setHours(23, 59, 59, 999);
			me.dateTo.lastValue = value;
			me.dateTo.setValue(value);
		}
	},
	/**
	 * Compare two timestamps without added end date time(23:59:59) or without any added time, if additional flag clearAny set to true
	 * @param val
	 * @param val2
	 * @param clearAny
	 * @returns {boolean}
	 */
	compareWithoutTime: function(val, val2, clearAny) {
		var me = this;
		var equal = false, dt, dt2, clearTime = {
			h: 23,
			m: 59,
			s: 59
		};
		if (val && val2) {
			dt = new Date(val);
			dt2 = new Date(val2);
			if (!clearAny) {
				if ((clearTime.h == dt.getHours() || !me.formatHasHours) && (clearTime.m == dt.getMinutes() || !me.formatHasMinutes) && (clearTime.s == dt.getSeconds() || !me.formatHasSeconds)) {
					val = Ext.Date.clearTime(dt).getTime();
				}
				if ((clearTime.h == dt2.getHours() || !me.formatHasHours) && (clearTime.m == dt2.getMinutes() || !me.formatHasMinutes) && (clearTime.s == dt2.getSeconds() || !me.formatHasSeconds)) {
					val2 = Ext.Date.clearTime(dt2).getTime();
				}
			}
			else {
				val = Ext.Date.clearTime(dt).getTime();
				val2 = Ext.Date.clearTime(dt2).getTime();
			}
			equal = val == val2;
		}
		return equal;
	},
	/**
	 * Sets limitations to dateTo field
	 * @param dateValue
	 */
	processDateToLimits: function(dateValue) {
		var me = this;
		if (dateValue && Ext.isDate(dateValue)) {
			me.setMinDateTo(dateValue);
			if (me.maxPeriod) {
				me.setMaxDateTo(dateValue);
			}
		}
		else {
			me.removeLimits(me.dateTo);
		}
	},
	/**
	 * Removes all limitation from field
	 * @param field
	 */
	removeLimits: function(field) {
		if (field) {
			field.setMinValue(null);
			field.setMaxValue(null);
			field.disabledDatesRE = undefined;
			field.setDisabledDates();
		}
	},
	/**
	 * Sets minimal allowed date for dateTo field
	 * @param dateValue
	 */
	setMinDateTo: function(dateValue) {
		var me = this;
		me.dateTo.setMinValue(Ext.clone(dateValue));
		me.dateTo.minText = edi.utils.formatString(me.minValueErrorText, {
			date: Ext.Date.format(dateValue, 'd.m.Y')
		});
	},
	/**
	 * Sets maximum allowed date for dateTo field
	 * @param dateValue
	 */
	setMaxDateTo: function(dateValue) {
		var me = this;
		var maxAvailableDate = new Date(dateValue.getTime() + (me.maxPeriod - 1) * edi.constants.DAY_IN_MS);
		maxAvailableDate.setHours(23, 59, 59);
		var disabledDate = new Date(maxAvailableDate.getTime() + edi.constants.SEARCH_DAY_IN_MS);
		disabledDate.setHours(12, 0, 0);

		me.dateTo.maxText = edi.utils.formatString(me.maxValueErrorText, {
			date: Ext.Date.format(maxAvailableDate, 'd.m.Y')
		});

		me.dateTo.disabledDatesText = edi.utils.formatString(me.maxValueErrorText, {
			date: Ext.Date.format(maxAvailableDate, 'd.m.Y')
		});

		//For max value set next day after available
		me.dateTo.setMaxValue(disabledDate);
		me.dateTo.setDisabledDates([Ext.Date.format(disabledDate, me.dateTo.format || edi.constants.DATE_FORMAT.FNS)]);

	},
	/**
	 * Returns object with references to range date fields
	 * @returns {{dateTo: *, dateFrom: *}}
	 */
	getFields: function() {
		var me = this;
		return {
			dateFrom: me.dateFrom,
			dateTo: me.dateTo
		};
	},
	/**
	 * Returns collection of range names available for using in this component
	 * @param forceRecalculation
	 */
	getRanges: function(forceRecalculation) {
		var me = this, i, ranges = {
			current: [],
			last: []
		}, sorted = {
			current: [],
			last: []
		}, names = [];
		if (!me.availableRanges || forceRecalculation) {
			for (i in me.ranges) {
				if (me.ranges.hasOwnProperty(i)) {
					if (!me.excludedRanges ||
						(Ext.isArray(me.excludedRanges) && -1 === me.excludedRanges.indexOf(i)) ||
						(Ext.isObject(me.excludedRanges) && !me.excludedRanges[i])
					) {
						if ("last" != me.ranges[i].type || (!me.maxPeriod || me.maxPeriod >= edi.constants.DEFAULT.FILTER.CUSTOM_PERIODS[i])) {
							ranges[me.ranges[i].type].push({
								range: i,
								order: me.ranges[i].order
							});
							names.push(i);
						}
					}
				}
			}
			ranges.current = Ext.Array.sort(ranges.current, function(a, b) {
				return a.order - b.order;
			});
			ranges.last = Ext.Array.sort(ranges.last, function(a, b) {
				return a.order - b.order;
			});
			Ext.Array.each(ranges.current, function(item) {
				sorted.current.push(item.range);
			});
			Ext.Array.each(ranges.last, function(item) {
				sorted.last.push(item.range);
			});
			me.availableRanges = sorted;
			me.availableRangesNames = names;
		}
		return me.availableRanges;
	},
	/**
	 * Gets current active range name
	 * @returns {null|*}
	 */
	getActiveRange: function() {
		var me = this;
		return me.activeRange;
	},
	/**
	 * Controls currently selected period to mach any available range. First found range will be set as active, otherwise active range will be removed
	 */
	controlActiveRange: function(dateFromField, dateToField) {
		var me = this;
		if (!me.skipActiveRangeChecking) {
			var range, i, dates = {
				dateFrom: (dateFromField || me.dateFrom).getValue(),
				dateTo: (dateToField || me.dateTo).getValue()
			}, check;
			if (typeof dates.dateFrom?.getTime === 'function' && typeof dates.dateTo?.getTime === 'function') {
				dates.dateFrom = dates.dateFrom.getTime();
				dates.dateTo = dates.dateTo.getTime();
				for (i in me.ranges) {
					if (me.ranges.hasOwnProperty(i)) {
						if (!me.excludedRanges || !me.excludedRanges[i]) {
							check = me.ranges[i].fn();
							check.dateFrom = check.dateFrom.getTime();
							check.dateTo = check.dateTo.getTime();
							if ((check.dateFrom == dates.dateFrom && check.dateTo == dates.dateTo) ||
								(me.setEndDayInDateTo && me.formatHasTime && me.compareWithoutTime(check.dateFrom, dates.dateFrom) && me.compareWithoutTime(check.dateTo, dates.dateTo))) {
								range = i;
								break;
							}
						}
					}
				}
			}
			me.setRange(range, true);
		}
	},
	/**
	 * Set selected range as active
	 * @param range
	 * @param skipValuesSetting
	 */
	setRange: function(range, skipValuesSetting) {
		var me = this, dates;
		me.skipActiveRangeChecking = !skipValuesSetting;
		if (range && me.ranges[range] && Ext.Array.contains(me.availableRangesNames, range)) {
			dates = me.ranges[range].fn();
			me.activeRange = range;
			if (!skipValuesSetting) {
				me.dateFrom.setValue(dates.dateFrom);
				if (me.setEndDayInDateTo && me.formatHasTime) {
					me.setTimeIntoDateEnd(dates.dateTo);
				}
				else {
					me.dateTo.setValue(dates.dateTo);
				}
				me.processDateToLimits(dates.dateFrom);
				"function" === typeof me.onFieldChange ? me.onFieldChange() : null;
			}
		}
		else {
			me.activeRange = null;
		}
		edi.utils.async(function() {
			me.skipActiveRangeChecking = false;
		});
		me.dateFrom.activeRange = me.activeRange;
		me.dateTo.activeRange = me.activeRange;
	},
	/**
	 * Used for adding custom ranges - note, that if range will be added, you need to force range recalculation by calling getRanges(true)
	 * @param conf
	 */
	addCustomRange: function(conf) {
		var me = this, rangeObj, getLastPeriod = function(daysCount) {
			return function() {
				var firstDay = new Date(), dateToValue = new Date(), dates = {};
				daysCount = daysCount || 1;
				firstDay = new Date(firstDay.getTime() - (daysCount - 1) * edi.constants.DAY_IN_MS);
				dates.dateFrom = Ext.Date.clearTime(firstDay);
				dates.dateTo = Ext.Date.clearTime(dateToValue);
				return dates;
			};
		};
		if (conf && conf.name && conf.type && conf.fn) {
			rangeObj = {
				type: conf.type,
				order: conf.order || 0,
				fn: "function" == typeof conf.fn ? conf.fn : getLastPeriod(conf.fn)
			};
			me.ranges[conf.name] = rangeObj;
		}
	},
	/**
	 * @private
	 * Prepares custom ranges configs
	 */
	prepareRanges: function() {
		var me = this;
		Ext.Object.each(edi.constants.DEFAULT.FILTER.CUSTOM_PERIODS, function(name, daysCount) {
			me.addCustomRange({
				name: name,
				type: "last",
				order: daysCount,
				fn: daysCount
			});
		});
	}
});

/**
 * Creates a date input field with a date picker dropdown
 * @param	{Object}	config		config options
 * @returns	{Object}	Ext.form.field.Date instance
 */
const createDateRangedField = function(config) {
	config = "object" == typeof config ? config : {};
	var valueSrc = config.valueSrc;
	delete config.valueSrc;
	var defaults = {
		format: edi.constants.DATE_FORMAT.FNS,
		name: "date",
		startDay: 1,
		showToday: false,
		invalidText: edi.i18n.getMessage("invalid.date.format.fns")
	};
	if (!config.format && !config.submitFormat) {
		config.submitFormat = edi.constants.DATE_FORMAT.CLIENT;
	}
	Ext.applyIf(config, defaults);
	if (valueSrc && config.name) {
		config.value = Ext.Date.parse(edi.utils.getObjectProperty(valueSrc, config.name), config.submitFormat);
	}
	return new Ext.form.field.DateRanged(config);
};

/**
 * Creates container panel for filtering grid by custom or predefined date range
 * @param	{Object}	[config]            config options
 * @param	{Function}	[onchange]          callback function to be called while one of the dates on the pane changes
 * @param	{Object}	[containerConfig]   container config options
 * @returns	{Object}	instance of Ext.form.FieldContainer
 */
const createDateRange = function(config, onchange, containerConfig) {
	config = "object" === typeof config ? config : {};
	var nameFrom = config.nameFrom ? config.nameFrom : "dateFrom";
	config.nameFrom ? delete config.nameFrom : null;
	var nameTo = config.nameTo ? config.nameTo : "dateTo";
	config.nameTo ? delete config.nameTo : null;
	if (config.prefix) {
		nameFrom = config.prefix + "-" + nameFrom;
		nameTo = config.prefix + "-" + nameTo;
	}

	let fieldConfBase = {
		format: config.format,
		validator: "function" == typeof config.validator ? config.validator : undefined,
		allowBlank: config.allowBlank || false === config.allowBlank ? config.allowBlank : true,
		submitFormat: config.submitFormat ? config.submitFormat : undefined,
		invalidText: config.invalidText ? config.invalidText : undefined
	};
	if (edi.constants.DATEFIELD_CLICK_ENABLED) {
		Ext.merge(fieldConfBase, {
			listeners: {
				render(cmp) {
					if (typeof cmp.onTriggerClick === 'function') {
						cmp.inputCell.on("click", () => cmp.onTriggerClick());
					}
				}
			}
		});
	}

	var conf = Ext.merge({
		margin: "0 0 20 0",
		fieldFromConf: Ext.merge({
			name: nameFrom,
			fieldLabel: config.title || undefined,
			chipTitle: config.chipTitle
		}, fieldConfBase, config.fieldFromConf),
		fieldToConf: Ext.merge({
			name: nameTo,
			fieldLabel: config.titleTo || edi.i18n.getMessage('module.form.date.to'),
			chipTitle: config.chipTitleTo
		}, fieldConfBase, config.fieldToConf),
		maxPeriod: config.maxSearchPeriodDays,
		onFieldChange: onchange,
		maxValueErrorText: edi.i18n.getMessage('file.storage.date.must.be.less.than.max'),
		minValueErrorText: edi.i18n.getMessage('file.storage.date.must.be.more.than.min'),
		excludedRanges: config.hideAdditionalButtons,
		setEndDayInDateTo: config.setEndDayInDateTo,
		activeRange: config.value
	}, containerConfig || {}, config.dateRangeConfig);
	var range = Ext.create("edi.components.dateRange", conf);

	if (config.withoutContainer === true) {
		return  range;
	}
	else {
		let container = createTwoColumnsLayout([range], []);
		container.getFields = function() {
			return range.getFields();
		};
		return container;
	}
};

const createDateRangeForGrid = function(config, onchange, containerConfig) {
	config = "object" === typeof config ? config : {};
	var nameFrom = config.nameFrom ? config.nameFrom : "dateFrom";
	config.nameFrom ? delete config.nameFrom : null;
	var nameTo = config.nameTo ? config.nameTo : "dateTo";
	config.nameTo ? delete config.nameTo : null;
	if (config.prefix) {
		nameFrom = config.prefix + "-" + nameFrom;
		nameTo = config.prefix + "-" + nameTo;
	}

	let fieldConfBase = {
		baseCls: 'ui-core-datefield',
		grid: {
			col: 6
		},
		format: config.format,
		validator: "function" == typeof config.validator ? config.validator : undefined,
		allowBlank: config.allowBlank || false === config.allowBlank ? config.allowBlank : true,
		submitFormat: config.submitFormat ? config.submitFormat : undefined,
		invalidText: config.invalidText ? config.invalidText : undefined
	};
	if (edi.constants.DATEFIELD_CLICK_ENABLED) {
		Ext.merge(fieldConfBase, {
			listeners: {
				render(cmp) {
					if (typeof cmp.onTriggerClick === 'function') {
						cmp.inputCell.on("click", () => cmp.onTriggerClick());
					}
				}
			}
		});
	}

	let fieldFromConf = Ext.merge({
		name: nameFrom,
		fieldLabel: config.title || undefined,
		chipTitle: config.chipTitle
	}, fieldConfBase, config.fieldFromConf);

	let fieldToConf = Ext.merge({
		name: nameTo,
		fieldLabel: config.titleTo || edi.i18n.getMessage('module.form.date.to'),
		chipTitle: config.chipTitleTo
	}, fieldConfBase, config.fieldToConf);

	var conf = Ext.merge({
		layout: 'grid',
		delimiter: false,
		fieldFromConf,
		fieldToConf,
		maxPeriod: config.maxSearchPeriodDays,
		onFieldChange: onchange,
		maxValueErrorText: edi.i18n.getMessage('file.storage.date.must.be.less.than.max'),
		minValueErrorText: edi.i18n.getMessage('file.storage.date.must.be.more.than.min'),
		excludedRanges: config.hideAdditionalButtons,
		setEndDayInDateTo: config.setEndDayInDateTo,
		activeRange: config.value
	}, config.dateRangeConfig);
	var range = Ext.create("edi.components.dateRange", conf);
	var container = createContainer(Ext.apply({
		items: [range]
	}, containerConfig || {}));

	container.getFields = function() {
		return range.getFields();
	};
	return container;
};

Ext.namespace("edi.components");
edi.components.createDateRange = createDateRange;
edi.components.createDateRangeForGrid = createDateRangeForGrid;

export {createDateRange, createDateRangeForGrid};