import { createPanel, createContainer, createForm } from '@UIkit/components/panels';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { createModalPanel } from '@UIkit/components/modal';
import { i18n } from '@UIkit/methods';
import { createHiddenField } from '@UIkit/components/fields/Hidden/Hidden.js';
import { createLabel } from '@UIkit/components/fields_old/Label.js';

import './SimpleSelector.scss';

const baseCls = 'ui-simple-selector';

Ext.define('UI.components.SimpleSelector', {
	extend: 'Ext.panel.Panel',
	baseCls,
	dotsButtonCls: `${baseCls}-dots-button`,
	actionButtonsCls: `${baseCls}-action-button`,
	detailsPanelCls: `${baseCls}-details-container`,
	detailsPanelBodyCls: `${baseCls}-details-container-body`,
	modalCls: `${baseCls}-modal`,
	invalidClass: `${baseCls}-details-with-errors`,
	fieldBlockCls: `${baseCls}-field-block`,
	errorLabelCls: `${baseCls}-error-label`,
	requiredLabelCls: `${baseCls}-required-label`,
	invalidTooltipBodyCls: `${baseCls}-invalid-tooltip-body`,
	emptyClass: `${baseCls}-empty`,
	emptyLabelCls: `${baseCls}-empty-label`,
	readOnlyClass: `${baseCls}-read-only`,
	focusable: true,
	tabIndex: 2,

	name: '',
	defaultValues: {},
	selectBtnConfig: {},
	editBtnConfig: {},
	resetBtnConfig: {},
	formGrids: {},
	fieldsConf: {},
	modalConf: {},
	fieldsMap: null,
	valuesByMap: false,
	readOnly: false,
	allowBlank: false, // if true - allows to leave ALL fields empty. false - allows to leave only optional fields empty.

	infoPanelComponentTpl: null, //panel template
	createFormPanel: null, //function to create a panel for a form
	selectionModalFunc: null, //function window select record
	getFormValuesFunc: null,

	showSelectButton: true,
	disabledSelectButton: false,
	hiddenSelectButton: false,
	showEditButton: true,
	disabledAddEditButton: false,
	showAddButton: true,
	showResetButton: true,
	additionalButtons: null, // array additional buttons are added to the toolbar

	fieldValues: null,
	is_valid: false,
	//user's callback fires after setValues()
	callback: function () {},

	constructor: function (externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	},

	beforeInit: function () {
		this.setOwnConfig();
	},

	afterInit: function () {
		const __self = this;
		__self.renderInfoPanelContents(false);
		if (__self.name) {
			const field = __self.down('hidden[name=' + __self.name + ']');
			if (field) {
				field.setValue(__self.fieldValues);
			}
		}
	},

	/**
	 * reset values
	 */
	reset: function () {
		var __self = this;
		__self.selectedOrgValues = null;
		__self.selectedOrg = null;
		__self.setValues({});
		__self.resetBtn ? __self.resetBtn.setDisabled(true) : null;
	},

	/**
	 * Creates hidden field configs by fieldsMap
	 */
	getHiddenFieldConfigs: function () {
		const __self = this;
		const items = [];

		if (__self.name) {
			items.push(
				createHiddenField({
					name: __self.name,
					getRawValue() {
						return __self.fieldValues;
					},
					getValue() {
						return __self.fieldValues;
					}
				})
			);
		}

		if (this.useHiddenFields && this.fieldsMap) {
			for (let i in this.fieldsMap) {
				if (this.fieldsMap.hasOwnProperty(i)) {
					let name = 'object' == typeof this.fieldsMap[i] ? this.fieldsMap[i].name : this.fieldsMap[i];
					items.push(
						createHiddenField({
							name: name
						})
					);
				}
			}
		}
		return items;
	},

	/**
	 * Creates self panel
	 */
	createSelfPanel: function () {
		const __self = this;

		let items = [].concat(__self.getHiddenFieldConfigs());

		// Label conf may be passed as 'labelConf' object or as 'title' & 'tooltip' properties inside selector config
		var tooltip = null,
			labelConf = this.labelConfig || {
				title: this.title,
				tooltip: this.tooltip
			};

		if (labelConf.title) {
			labelConf.html = i18n(labelConf.title);
		}

		if (labelConf.tooltip) {
			tooltip = i18n(labelConf.tooltip);
			delete labelConf.tooltip;
		}

		//Create selector label if html defined
		if (labelConf.html) {
			Ext.applyIf(labelConf, {
				columnWidth: 0.2,
				cls: `${baseCls}-title`
			});

			// In general modal title is the same as selector label
			if ('object' == typeof this.modalConf) {
				Ext.applyIf(this.modalConf, {
					title: labelConf.html
				});
			}
			delete this.title;
			delete this.tooltip;

			items.push(createLabel(labelConf));
		}

		//Create main selector panel
		var panel = createPanel({
			cls: __self.detailsPanelCls,
			bodyCls: __self.detailsPanelBodyCls,
			columnWidth: this.columnWidth || 0.8,
			items: [__self.createActions(), (this.infoPanel = createContainer({}))]
		});

		if (tooltip) {
			panel.on('render', function (panel) {
				panel.toolTip = Ext.create('Ext.tip.ToolTip', {
					target: panel.getEl(),
					trackMouse: true,
					html: tooltip
				});
			});
			panel.on('destroy', function (panel) {
				if (panel.toolTip) {
					panel.toolTip.destroy();
				}
			});
		} else {
			panel.on('render', function (panel) {
				var tip = Ext.create('Ext.tip.ToolTip', {
					// bodyCls: __self.invalidTooltipBodyCls,
					target: panel.getEl(),
					layout: 'fit',
					trackMouse: true,
					// header: false,
					html: i18n('form.field.invalid')
				});
				panel.toolTip = tip;
				tip.on('beforeshow', function () {
					if (__self.is_valid || __self.readOnly) {
						return false;
					}
				});
			});
			panel.on('destroy', function (panel) {
				if (panel.toolTip) {
					panel.toolTip.destroy();
				}
			});
		}

		this.panel = panel;

		this.errorEl = createLabel({
			cls: this.errorLabelCls,
			hidden: true,
			text: ''
		});

		this.requiredEl = createLabel({
			cls: this.requiredLabelCls,
			hidden: true,
			text: ''
		});

		items.push(panel);
		items.push(this.errorEl);
		items.push(this.requiredEl);

		//Create items container
		var containerConfig = {
			layout: 'column',
			margin: this.margin,
			items: items
		};

		Ext.applyIf(this, containerConfig);
	},

	setOwnConfig: function () {
		var __self = this;
		if (!__self.ownConfigSet) {
			if (__self.valuesByMap) {
				__self.applyFieldsMapToOrgValues();
			} else {
				__self.fieldValues = __self.fieldValues || {};
			}
			Ext.applyIf(__self.fieldValues, __self.defaultValues);
			__self.createSelfPanel();
			__self.ownConfigSet = true;
		}
	},

	/**
	 * Sets field values using fieldsMap
	 */
	applyFieldsMapToOrgValues: function () {
		let __self = this;
		let applyToRelation = __self.selectedOrgValues && __self.selectedRelationByMap;
		let mapValues = __self.fieldValues;
		let relationValues = __self.selectedOrgValues;
		let mapFieldName, fieldValue, i;

		__self.fieldValues = {};

		if (applyToRelation) {
			__self.selectedOrgValues = {};
		}

		for (i in __self.fieldsMap) {
			if (__self.fieldsMap.hasOwnProperty(i)) {
				mapFieldName = __self.fieldsMap[i];
				fieldValue = edi.utils.getObjectProperty(mapValues, mapFieldName);
				__self.fieldValues[i] = fieldValue;

				if (applyToRelation) {
					fieldValue = edi.utils.getObjectProperty(relationValues, mapFieldName);
					__self.selectedOrgValues[i] = fieldValue;
				}
			}
		}
	},

	createActions: function () {
		const __self = this;
		const actionButtons = !__self.readOnly ? __self.createActionButtons() : [];

		return (__self.actionsMenuBtn =
			actionButtons.length > 0
				? createButton({
						cls: [__self.dotsButtonCls, BUTTON_CLS.withoutArrow],
						menuAlign: 'tr-br?',
						menu: {
							plain: true,
							hideMode: 'display',
							closeAction: 'hide',
							minWidth: 99,
							maxWidth: 200,
							items: actionButtons,
							listeners: {
								click: function (comp) {
									comp.close();
								}
							}
						}
				  })
				: null);
	},

	createButtonCmp: function (conf) {
		const __self = this;
		const btn = createButton(Object.assign({}, conf, { text: i18n(conf.text) }));
		if (btn.tooltip) {
			//если у кнопки есть тултип, то нужно закрывать тултип панели селектора (обычно что не все поля заполнены)
			//иначе будут отображаться оба тултипа и перекрывать друг друга
			//так было когда кнопки располагались не в меню, а на панеле селектора
			btn.on('afterrender', function (elm) {
				Ext.create('Ext.tip.ToolTip', {
					target: elm.getEl().id,
					layout: 'fit',
					trackMouse: true,
					listeners: {
						beforeshow: function (tip) {
							if (__self.panel && 'object' === typeof __self.panel.toolTip) {
								__self.panel.toolTip.close();
							}
							tip.update(elm.ttip);
						}
					}
				});
			});
		}

		return btn;
	},

	/**
	 * Creates panel buttons
	 * @return {Array}
	 */
	createActionButtons: function () {
		const __self = this;
		const actions = [];
		const isEdit = !__self.isEmptyValues() || !__self.showAddButton;

		if (!!__self.showSelectButton) {
			__self.selectBtn = __self.createButtonCmp(
				Ext.merge(
					{
						text: 'form.btn.select',
						disabled: __self.disabledSelectButton || (!__self.showAddButton && __self.isEmptyValues()),
						hidden: __self.hiddenSelectButton,
						cls: `${__self.actionButtonsCls} ${BUTTON_CLS.light} test-companySelector-btn-select`,
						handler: function () {
							if (__self.selectionModalFunc && 'function' == typeof __self.selectionModalFunc) {
								__self.selectionModalFunc(__self.setSelectionRecord, __self);
							}
						}
					},
					__self.selectBtnConfig
				)
			);

			actions.push(__self.selectBtn);
		}

		if (__self.showEditButton) {
			__self.editBtn = __self.createButtonCmp(
				Ext.merge(
					{
						text: isEdit ? 'form.btn.edit' : 'form.btn.add',
						cls: `${__self.actionButtonsCls} ${BUTTON_CLS.light} test-companySelector-btn-${
							isEdit ? 'edit' : 'add'
						}`,
						disabled: __self.disabledAddEditButton || (!__self.showAddButton && __self.isEmptyValues()),
						handler: function () {
							__self.showModalCompanyControl();
						}
					},
					__self.editBtnConfig
				)
			);

			actions.push(__self.editBtn);
		}

		if (__self.showResetButton) {
			__self.resetBtn = __self.createButtonCmp(
				Ext.merge(
					{
						text: 'form.btn.reset',
						cls: `${__self.actionButtonsCls} ${BUTTON_CLS.light} test-companySelector-btn-reset`,
						disabled: __self.isEmptyValues(),
						handler: function () {
							edi.core.confirm('confirm.clear.org.title', 'confirm.clear.org', function () {
								__self.reset();
							});
						}
					},
					__self.resetBtnConfig
				)
			);

			actions.push(__self.resetBtn);
		}
		if (Array.isArray(__self.additionalButtons)) {
			__self.additionalButtons.forEach((btnCfg) => {
				actions.push(__self.createButtonCmp(btnCfg));
			});
		}

		return actions;
	},

	setSelectionRecord: function (record) {
		const __self = this;

		__self.setValues(record);
		__self.editBtn ? __self.editBtn.setDisabled(false) : null;
		__self.resetBtn ? __self.resetBtn.setDisabled(false) : null;
	},

	/**
	 * set values and update validity and details
	 * @param    {Object}    values
	 */
	setValues: function (values) {
		var allFieldsAreEmpty = true;
		var __self = this;
		for (var i in values) {
			if (values.hasOwnProperty(i)) {
				if (values[i]) {
					allFieldsAreEmpty = false;
				} else if (null === values[i]) {
					values[i] = undefined;
				}
			}
		}
		if (this.allowBlank && allFieldsAreEmpty) {
			this.is_valid = true;
		} else {
			this.is_valid = !allFieldsAreEmpty;
		}

		this.fieldValues = values;

		var field = __self.down('hidden[name=' + __self.name + ']');
		if (field) {
			field.setValue(__self.fieldValues);
		}

		this.renderInfoPanelContents(true);

		var objValid = {};
		if ('function' == typeof this.isValidFunc) {
			this.is_valid = this.isValidFunc(this.fieldValues, this);
			this.markInvalid();
		}
		this.callback(values, objValid);
	},

	/**
	 * Renders control data summary
	 * @param    {Boolean}    updateButton    true to update change button label text
	 */
	renderInfoPanelContents: function (updateButton) {
		var __self = this,
			isEmpty = this.isEmptyValues(),
			originalValues = this.fieldValues,
			fieldValues;

		__self.infoPanel.removeAll();
		fieldValues = edi.utils.stringifyObjectFields(originalValues);

		var emptyTemplate = `<span class="${__self.emptyLabelCls}">${i18n('value.not.specified')}</span>`;
		var template = false,
			infoPanelTpl = false;
		if (__self.infoPanelComponentTpl && 'function' == typeof __self.infoPanelComponentTpl) {
			template = __self.infoPanelComponentTpl();
			infoPanelTpl = template.apply(fieldValues);
		} else if (!!__self.infoPanelComponentTpl) {
			infoPanelTpl = __self.infoPanelComponentTpl.apply(fieldValues);
		}

		__self.infoPanel.add(
			createContainer({
				html: (isEmpty && __self.readOnly) || !infoPanelTpl ? emptyTemplate : infoPanelTpl
			})
		);
		if (__self.useHiddenFields && __self.fieldsMap) {
			for (var i in __self.fieldsMap) {
				if (__self.fieldsMap.hasOwnProperty(i)) {
					var name =
						(__self.fieldsMap[i].name ? __self.fieldsMap[i].name : __self.fieldsMap[i]) ||
						'__UnknownFieldName__';
					var field = __self.down('hiddenfield[name=' + name + ']');
					if (field) {
						field.setValue(__self.fieldValues[i]);
					}
				}
			}
		}

		if (updateButton && __self.editBtn) {
			const isEdit = !isEmpty || !__self.showAddButton;
			const editBtnText = i18n(isEdit ? 'form.btn.edit' : 'form.btn.add');

			__self.editBtn.setText(editBtnText);

			__self.editBtn.removeCls('test-companySelector-btn-' + (isEdit ? 'add' : 'edit'));
			__self.editBtn.addCls('test-companySelector-btn-' + (isEdit ? 'edit' : 'add'));

			__self.resetBtn ? __self.resetBtn.setDisabled(isEmpty) : null;
			if (!__self.showAddButton) {
				__self.editBtn.setDisabled(isEmpty);
			}
		}
		if ('function' == typeof this.isValidFunc) {
			__self.is_valid = this.isValidFunc(this.fieldValues, this);
			__self.markInvalid();
		}
		__self.markEmpty();
		__self.markReadOnly();
	},

	/**
	 * Returns control values collected from modal form
	 * @returns	{Object}
	 */
	getValues: function () {
		return Ext.clone(this.fieldValues);
	},

	/**
	 * Returns validation status of current control and sets or remove invalid class
	 * @param    {Boolean}    disableMarkInvalid        True to NOT underline selector by red line
	 * @returns    {Boolean}
	 */
	isValid: function (disableMarkInvalid) {
		if ('function' == typeof this.isValidFunc) {
			this.is_valid = this.isValidFunc(this.fieldValues, this);
		}

		if (!disableMarkInvalid) {
			this.markInvalid();
		}
		return this.is_valid;
	},

	/**
	 * Returns is component not filled
	 * @returns    {Boolean}
	 */
	isEmptyValues: function () {
		if (this.fieldValues) {
			for (var i in this.fieldValues) {
				if (this.fieldValues.hasOwnProperty(i)) {
					return false;
				}
			}
			return true;
		} else {
			return true;
		}
	},

	/**
	 * Sets or remove empty class from component
	 */
	markEmpty: function () {
		var isEmpty = this.isEmptyValues();
		if (isEmpty && !this.panel.hasCls(this.emptyClass)) {
			this.panel.addCls(this.emptyClass);
		} else if (!isEmpty && this.panel.hasCls(this.emptyClass)) {
			this.panel.removeCls(this.emptyClass);
		}
	},

	/**
	 * Sets or remove empty class from component
	 */
	markReadOnly: function () {
		if (this.readOnly && !this.panel.hasCls(this.readOnlyClass)) {
			this.panel.addCls(this.readOnlyClass);
		} else if (!this.readOnly && this.panel.hasCls(this.readOnlyClass)) {
			this.panel.removeCls(this.readOnlyClass);
		}
	},

	getErrorText() {
		return i18n('company.selector.error.label');
	},

	getRequiredText() {
		return i18n('form.field.required.label');
	},

	/**
	 * Sets or remove invalid class from component
	 */
	markInvalid: function () {
		let me = this;
		if (!me?.rendered || me.isDestroyed) {
			return;
		}

		me.suspendLayouts();
		let allFieldsEmpty = me.isEmptyValues();

		let showErrorText = me.allowBlank === false ? allFieldsEmpty !== true && !me.is_valid : !me.is_valid;
		me.errorEl.setText(me.getErrorText());
		me.errorEl.setVisible(showErrorText);

		let showRequiredLabel = me.allowBlank === false && !showErrorText;
		me.requiredEl.setText(me.getRequiredText());
		me.requiredEl.setVisible(showRequiredLabel);

		let addInvalidCls = showErrorText || (showRequiredLabel && allFieldsEmpty === true);
		me[addInvalidCls ? 'addCls' : 'removeCls'](me.invalidClass);
		me.resumeLayouts();
	},

	/**
	 * Creates modal company data form
	 */
	showModalCompanyControl: function (customFormTabPanel) {
		var __self = this,
			modalReadOnly = !!__self.readOnly;
		if ((__self.createFormPanel && 'function' == typeof __self.createFormPanel) || !!customFormTabPanel) {
			this.formTabPanel =
				customFormTabPanel || __self.createFormPanel(this.fieldValues, modalReadOnly, __self.modalConf);
			this.formPanel = createForm({
				layout: 'fit',
				submitEmptyText: false,
				items: [__self.formTabPanel],
				listeners: {
					fieldvaliditychange: function () {
						saveBtn ? saveBtn.setDisabled(false) : null;
					}
				}
			});
			var saveBtn = modalReadOnly
				? null
				: createButton({
						cls: BUTTON_CLS.primary,
						text: i18n('form.btn.save'),
						glyph: edi.constants.ICONS.SAVE,
						formBind: false,
						bindToForm: this.formPanel,
						handler: function () {
							if (__self.checkFormValid()) {
								var values;
								if (__self.getFormValuesFunc && 'function' == typeof __self.getFormValuesFunc) {
									values = __self.getFormValuesFunc(__self.formPanel);
								} else {
									values = edi.utils.collectFormValues(__self.formPanel);
								}

								__self.setValues(values);
								__self.modalWindow.close();
							} else {
								setTimeout(function () {
									__self.focusOnInvalidField();
								}, 100);
							}
						}
				  });
			var modalConf = Ext.apply(
				{
					cls: __self.modalCls,
					title: i18n('modal.company.data'),
					width: edi.constants.DEFAULT.MODAL.WIDTH_LARGER,
					maxHeight: 500,
					items: [this.formPanel],
					buttonsBefore: [modalReadOnly ? null : saveBtn]
				},
				__self.modalConf
			);

			delete modalConf.tabs;

			if (modalConf.title) {
				modalConf.title = i18n(modalConf.title || '');
			}

			__self.modalWindow = createModalPanel(modalConf);
			__self.modalWindow.show();

			__self.formPanel.isValid();
			setTimeout(function () {
				__self.focusOnInvalidField();
			}, 100);
			return __self.modalWindow;
		}
	},

	checkFormValid: function () {
		var __self = this,
			values = edi.utils.collectFormValues(__self.formPanel),
			allFieldsEmpty = true;
		for (var i in values) {
			if (values.hasOwnProperty(i) && values[i]) {
				allFieldsEmpty = false;
				break;
			}
		}

		let formValid = __self.formPanel.isValid();
		let gridValid = true;
		//editableGrid
		for (let key in __self.formGrids) {
			if (__self.formGrids.hasOwnProperty(key)) {
				if (!__self.formGrids[key].isValid()) {
					gridValid = false;
					break;
				}
			}
		}

		return (__self.allowBlank && allFieldsEmpty) || (formValid && gridValid);
	},

	focusOnInvalidField: function () {
		let __self = this;
		if (__self.modalWindow?.isVisible()) {
			let isValidForm = true;
			let fields = __self.formPanel.getForm().getFields().items;
			for (let i = 0, len = fields.length; i < len; ++i) {
				let field = fields[i];
				if (!field.isValid() && field.isVisible() && !field.isDisabled()) {
					isValidForm = false;
					let tab = field.up('panel[panelType="tabpanel"]');
					__self.formTabPanel?.setActiveTab(tab);
					setTimeout(function () {
						field.focus();
					}, 0);
					break;
				}
			}
			//editableGrid
			if (isValidForm) {
				for (let key in __self.formGrids) {
					if (__self.formGrids.hasOwnProperty(key)) {
						if (!__self.formGrids[key].isValid()) {
							let tab = __self.formGrids[key].up('panel[panelType="tabpanel"]');
							__self.formTabPanel.setActiveTab(tab);
							setTimeout(function () {
								__self.formGrids[key].focus();
							}, 0);
							break;
						}
					}
				}
			}
		}
	},

	/**
	 * Function set disabled for select button
	 */
	setDisabledSelectButton: function (value) {
		if (this.selectBtn) {
			let result;
			if (typeof this.disabledSelectButton === 'function') {
				result = this.disabledSelectButton(value);
			} else {
				result = value;
			}
			this.selectBtn.setDisabled(result);
		}
	},

	/**
	 * Function set disabled for edit/add button
	 */
	setDisabledAddEditButton: function (value) {
		if (this.editBtn) {
			this.disabledAddEditButton = value;
			this.editBtn.setDisabled(value);
		}
	}
});

/**
 * Creates instance of UI.components.SimpleSelector
 * @param	{Object}	cfg
 * @param	{Object}	[data]
 * @return	{Object}	Instance of UI.components.SimpleSelector
 */
const createSimpleSelector = function (cfg, data) {
	if (data) {
		cfg.fieldValues = data;
	}
	return Ext.create('UI.components.SimpleSelector', cfg);
};

export { createSimpleSelector };
