/**
 * Creates combobox with permission group tags
 * @param	{String}	name
 * @param	{any}		[value]
 * @param	{Number}	[columnWidth]
 * @returns	{Object}	Ext.form.ComboBox instance
 */
import {createCombo} from "@Components/fields";
import {createStore} from "@Components/storeComponents";

const createPermissionGroupTagCombo = function(name, value, columnWidth) {
	var items = [
		{
			id: null,
			name: edi.i18n.getMessage("permission.group.tag.NONE")
		}
	];
	for (var i in edi.constants.PERMISSION_GROUP_TAG) {
		if (edi.constants.PERMISSION_GROUP_TAG.hasOwnProperty(i)) {
			items.push({
				id: i,
				name: edi.i18n.getMessage("permission.group.tag." + i)
			});
		}
	}
	return createCombo({
		name: name,
		value: value ? value : undefined,
		columnWidth: columnWidth || 0.75,
		store: edi.stores.createInlineStore(items)
	});
};

/**
 * Creates combobox with autocomplete
 * @param	{Object}	[config]
 * @param	{Object}	[storeConfig]
 * @returns	{Object}	Ext.form.ComboBox instance
 */
const createAutocomplete = function(config, storeConfig) {
	config = "object" == typeof config ? config : {};
	storeConfig = "object" == typeof storeConfig ? storeConfig : {};
	var storeDefaults = {
		pageSize: edi.constants.ITEMS_PER_PAGE,
		proxy: {
			type: 'ajax',
			url: "",
			reader: {
				type: 'json',
				rootProperty: edi.constants.LIST_ROOT_PROPERTY
			}
		},
		model: edi.models.getModel("ORGANIZATIONS"),
		remoteSort: true,
		autoLoad: !!storeConfig?.proxy?.url,
		sortOnLoad: true,
		sorters: {
			property: config.filterField || "name",
			direction: 'ASC'
		}
	};

	Ext.applyIf(storeConfig, storeDefaults);

	var defaults = {
		name: "name",
		queryMode: 'remote',
		allowBlank: true,
		minChars: 0,
		queryParam: 'name',
		enforceMaxLength: true,
		maxLength: 150,
		triggers: {
			clear: {
				extraCls: 'edi-icon edi-icon-CLOSE',
				handler() {
					let prevValue = this.getValue();
					this.clearValue();
					this.doQuery();
					if (!(prevValue === null || prevValue === undefined || prevValue === '')) {
						this.fireEvent('select', combo);
					}
				}
			}
		},
		valueField: 'id',
		displayField: 'name',
		//to disable filtering options in list by selected value pass triggerAction: 'all'
		triggerAction: 'query',
		columnWidth: 0.5
	};

	Ext.applyIf(config, defaults);
	config.store = createStore(storeConfig);
	let combo = createCombo(config);

	combo.on('afterrender', function() {
		combo.mon(combo.inputCell.el, {
			scope: combo,
			click(e) {
				if(!combo.readOnly && !combo.isDisabled() && !combo.isExpanded) {
					combo.onTriggerClick(combo, combo.triggers.picker, e)
				}
			}
		});
	});

	combo.triggers.picker.hide();
	return combo;
};

Ext.namespace('edi.special');
Ext.apply(edi.special, {
	createPermissionGroupTagCombo,
	createAutocomplete
});

export {
	createPermissionGroupTagCombo,
	createAutocomplete
};