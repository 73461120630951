import tpl from './Notification.ext.tpl';
import './Notification.scss';

Ext.define('UI.components.Notification', {
	extend: 'Ext.Component',
	baseCls: 'ui-notification',
	requires: ['Ext.XTemplate', 'Ext.button.Button'],
	childEls: ['headerEl', 'glyphEl', 'titleEl', 'dateEl', 'closeEl', 'bodyEl', 'footerEl'],
	renderTpl: [
		tpl,
		{
			renderButtons: function (values, out) {
				Ext.DomHelper.generateMarkup(values.$comp.getItemsRenderTree(values.$comp.buttons), out);
			}
		}
	],

	width: 424,
	glyph: '',
	date: '',
	dateFormat: '',
	title: '',
	text: '',
	buttons: [],
	showClose: true,
	readed: false,

	setDate: function (date, format) {
		var me = this;
		if (date) {
			me.dateEl.setText(Ext.Date.format(new Date(), format || 'd.m.Y H:i:s'));
		}
	},
	setTitle: function (text) {
		var me = this;
		if (text) {
			me.titleEl.setText(text);
		}
	},
	setText: function (text) {
		var me = this;
		if (text) {
			me.bodyEl.setText(text);
		}
	},
	setGlyph: function (glyph) {
		var me = this;
		if (glyph) {
			let glyphConf = new Ext.Glyph(glyph);

			if (glyphConf.character && glyphConf.fontFamily) {
				me.glyphEl.update(glyphConf.character);
				me.glyphEl.setStyle({
					fontFamily: glyphConf.fontFamily
				});
				me.setStyle({
					paddingLeft: '64px'
				});
			}
		}
	},
	setColor: function (color) {
		var me = this;

		if (me.el) {
			me.el.dom.style.setProperty('--color', color);
		}
	},
	setReaded: function (flag) {
		var me = this;

		if (flag) {
			me.addCls(`${me.baseCls}-readed`);
		} else {
			me.removeCls(`${me.baseCls}-readed`);
		}
	},

	beforeRender: function () {
		var me = this;

		me.addDefaultMethods(me.buttons);

		me.callParent();

		if (me.glyph) {
			let glyphConf = new Ext.Glyph(me.glyph);

			if (glyphConf.character && glyphConf.fontFamily) {
				Ext.apply(me.renderData, {
					glyphCls: `${me.baseCls}-glyph`,
					glyph: glyphConf.character,
					glyphStyle: `font-family: ${glyphConf.fontFamily};`
				});
			}
		}

		Ext.apply(me.renderData, {
			date: Ext.Date.format(me.date, me.dateFormat || 'd.m.Y H:i:s'),
			title: me.title,
			text: me.text,
			showClose: me.showClose
		});
	},

	afterRender: function () {
		var me = this;
		me.finishRenderItems(me, me.buttons);

		if (me.glyph) {
			me.setStyle({
				paddingLeft: '64px'
			});
		}
		if (me.color) {
			me.setColor(me.color);
		}

		if (me.showClose) {
			me.closeBtn = me.closeEl.on('click', function () {
				me.addCls(me.baseCls + '-destroy');
				setTimeout(() => {
					me.doDestroy();
				}, 200);
			});
			me.closeEl.addClsOnOver(me.baseCls + '-header-close-over');
		}
	},

	doDestroy: function () {
		var me = this;

		if (me.rendered) {
			Ext.destroy(me.closeBtn, me.buttons);
		}

		me.callParent();
	},

	addDefaultMethods: function (items) {
		var me = this,
			length = items.length,
			i,
			item;

		if (length) {
			for (i = 0; i < length; ++i) {
				item = items[i];

				Ext.apply(item, {
					ownerCt: me,
					ownerLayout: me.getComponentLayout()
				});
			}
		}
	},
	getItemsRenderTree: function (items) {
		var length = items.length,
			i,
			item,
			itemConfig,
			result;

		if (length) {
			result = [];
			for (i = 0; i < length; ++i) {
				item = items[i];
				if (!item.rendered) {
					itemConfig = item.getRenderTree();

					if (itemConfig) {
						result.push(itemConfig);
					}
				}
			}
		}
		return result;
	},
	finishRenderItems: function (target, items) {
		var length = items.length,
			i,
			item;
		for (i = 0; i < length; i++) {
			item = items[i];
			if (item.rendering) {
				item.finishRender(i);
			}
		}
	}
});

const createNotification = (config) => new UI.components.Notification(config);

export { createNotification };
