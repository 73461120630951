import {createPanel} from "./panels";
import {createButton} from "./buttons";

Ext.define("edi.components.navigationPanel", {
	extend: 'Ext.container.Container',
	alias: "widget.edi-navigation-panel",
	cls: "edi-navigation-panel",
	width: 56,
	navButton: null,
	navPanel: null,
	moduleListeners: null,
	navPanelCfg: null,
	overOpened: false,
	opened: false,
	menuTree: [],
	activeModule: null,
	items: [],

	initComponent: function() {
		var me = this;

		me.setOwnConfig();
		me.callParent();
		//будем закрывать плавающее меню, когда открываем любую модалку что бы её маска была поверх меню
		if(edi.navigation) {
			edi.navigation.observer.on('hidefloatingmenu', function() {
				if (!me.opened && !me.closing) {
					me.closeNavigation(true);
				}
			});
		}
	},

	/**
	 * Internal configuration and structure creation
	 */
	setOwnConfig: function() {
		var me = this;
		me.getNavigationButton();
		me.navPanel = createPanel(
			Ext.apply({
				cls: "edi-navigation-panel-inner",
				bodyCls: "edi-navigation-panel-inner-body",
				width: 56,
				autoScroll: true,
				margins: "0 5px 0 0"
			}, me.navPanelCfg)
		);
		me.navPanel.isHidden = !me.opened;
		me.items.push(me.navPanel);
		me.navPanel.mon(me, "resize", function(comp, width, height) {
			me.navPanel.setHeight(height);
		});
	},

	afterRender: function() {
		var me = this, scheduledOpen, el = me.getEl();
		me.callParent();
		if (!me.opened) {
			me.addCls("edi-navigation-panel-closed");
		}
		el.on("mouseover", function() {
			if (!me.opened && !me.opening && !scheduledOpen) {
				if (!scheduledOpen) {
					if (me.closing) {
						me.openNavigation(true);
					}
					else {
						scheduledOpen = setTimeout(function() {
							me.openNavigation(true);
						}, 250);
					}
				}
			}
		});
		el.on("mouseout", function(event) {
			if (!el.contains(event.relatedTarget)) {
				if (scheduledOpen) {
					clearTimeout(scheduledOpen);
					scheduledOpen = null;
				}
				if (!me.opened && !me.closing) {
					me.closeNavigation(true);
				}
			}
		});
	},

	getNavigationButton: function() {
		var me = this;
		if (!me.navButton) {
			me.navButton = createButton({
				cls: "edi-menu-button",
				glyph: edi.constants.ICONS.MENU,
				margin: "5 0 0 20",
				handler: function() {
					me.toggleNavigation();
				}
			})
		}
		return me.navButton;
	},

	toggleNavigation: function() {
		var me = this;
		me.overOpened = false;
		me.closing = false;
		me.opening = false;
		me.removeCls("edi-navigation-panel-hover");
		if (me.opened) {
			me.closeNavigation();
		}
		else {
			me.openNavigation();
		}
	},

	openNavigation: function(onHover) {
		var me = this;
		me.navPanel.animation ? me.navPanel.animation.stop() : null;
		if (onHover) {
			if (me.navPanel.isHidden) {
				me.updateMenu(me.activeModule, true);
				var initWidth = me.navPanel.getWidth();
				var timeLeft = Math.round(56 / initWidth * 300);
				me.navPanel.animation = edi.utils.animate({
					duration: timeLeft,
					start: function() {
						me.addCls("edi-navigation-panel-hover");
						me.removeCls("edi-navigation-panel-closed");
						me.navPanel.isHidden = false;
						me.opening = true;
						me.closing = false;
						//me.layout.redoLayout();
					},
					finish: function() {
						me.overOpened = true;
						me.opening = false;
					},
					progress: function(progress) {
						var val = edi.utils.calculateState(initWidth, 304, progress);
						me.navPanel.setWidth(val);
					}
				});
			}
		}
		else {
			me.removeCls("edi-navigation-panel-closed");
			me.setWidth(304);
			me.navPanel.setWidth(304);
			me.navPanel.isHidden = false;
			me.opened = true;
			me.layout.redoLayout();
		}
	},

	closeNavigation: function(onHover) {
		var me = this;
		me.navPanel.animation ? me.navPanel.animation.stop() : null;
		if (onHover) {
			if (!me.navPanel.isHidden) {
				var initWidth = me.navPanel.getWidth();
				var timeLeft = Math.round(initWidth / 304 * 300);
				me.navPanel.animation = edi.utils.animate({
					duration: timeLeft,
					start: function() {
						me.navPanel.isHidden = true;
						me.closing = true;
						me.opening = false;
					},
					finish: function() {
						me.closing = false;
						me.overOpened = false;
						me.removeCls("edi-navigation-panel-hover");
						me.addCls("edi-navigation-panel-closed");
						//me.layout.redoLayout();
					},
					progress: function(progress) {
						var val = edi.utils.calculateState(initWidth, 56, progress);
						me.navPanel.setWidth(val);
					}
				});
			}
		}
		else {
			me.addCls("edi-navigation-panel-closed");
			me.setWidth(56);
			me.navPanel.setWidth(56);
			me.navPanel.isHidden = true;
			me.opened = false;
			//me.layout.redoLayout();
		}
		var navItems = me.navPanel.items.items;
		Ext.Array.forEach(navItems, function(item) {
			edi.utils.setObjectProperty(item, 'collapsed', true)
		})
	},

	/**
	 * Creates a full menuItems branch from root navigation item to specified menu option
	 * @param      panels     Panels array look throw
	 * @param      menuId     Target menu item id
	 * @return    {Array}     A menu items branch
	 */
	getPanelsChain: function(panels, menuId) {
		let me = this;
		if (!Ext.isArray(panels)) {
			return [];
		}

		var children, menuIdCheck = edi.constants.MENU_PREFIX + menuId, result = [];

		let target = Ext.Array.findBy(panels, function(panel) {
			return panel.menuId === menuIdCheck;
		});

		if (!target) { //search by children
			target = Ext.Array.findBy(panels, function(panel) {
				return (panel.categoryMenuIds && (Ext.Array.indexOf(panel.categoryMenuIds, menuId) > -1));
			});

			if (target) {
				result = [target];
				children = me.getPanelsChain(target.items.items, menuId);
				if (children.length) {
					result = result.concat(children);
				}
			}
		}
		else {
			result = [target];
		}

		return result;
	},

	/**
	 * Activate selected module in navigation menu
	 * @param	{Object}	modData			Module data
	 * @param	{Boolean}	forceUpdate
	 */
	updateMenu: function(modData, forceUpdate) {
		let me = this;
		if (!!modData) {
			var newMenuId = modData.menuId;
			if (forceUpdate || !me.activeModule || (!modData && me.activeModule) || newMenuId !== me.activeModule.menuId) {
				var activePanels = me.activeModule ? me.getPanelsChain(me.menuTree, me.activeModule.menuId) : [],
					panelsToActivate = modData ? me.getPanelsChain(me.menuTree, newMenuId) : [];

				me.updatePanels(activePanels, panelsToActivate);
				me.activeModule = modData;
				modData.menuId = newMenuId;
			}
		}
	},

	/**
	 * Open requested tabs and close unneeded tabs
	 * @param     activePanels         Tabs which are already opened
	 * @param     panelsToActivate     Tabs which must be opened
	 */
	updatePanels: function(activePanels, panelsToActivate) {
		let panelsToOpen = panelsToActivate || [];
		let alreadyOpenedPanels = [];
		let panelsToClose = [];

		if (activePanels) {
			activePanels.forEach(panel => {
				//Common panels are panels which are opened now and must be opened, so no actions needed
				var menuId = panel.menuId;
				var target = Ext.Array.findBy(panelsToActivate, item => item.menuId === menuId);
				if (target) {
					alreadyOpenedPanels.push(menuId);
				}
			});

			// Delete common panels from [panelsToClose] to find out which panels are opened now and must be closed
			panelsToClose = activePanels.filter(item => {
				return alreadyOpenedPanels.indexOf(item.menuId) < 0;
			});
			panelsToClose.reverse();
		}

		var togglePanelState = function(panel, state) {
			let target = panel.getEl() && (Ext.fly(panel.getEl().down(".x-header-text")) || panel);
			if (state === "activate") {
				panel.addCls("active");
				target?.addCls("selectedText");
				if (panel.collapsible) {
					panel.isCollapsingOrExpanding = 0;
					panel.expand();
					panel.isCollapsingOrExpanding = 0;
				}
			}
			else {
				panel.removeCls("active");
				target?.removeCls("selectedText");
				if (panel.collapsible) {
					panel.isCollapsingOrExpanding = 0;
					panel.collapse();
					panel.isCollapsingOrExpanding = 0;
				}
			}
		};

		panelsToClose.forEach(pan => togglePanelState(pan, "deactivate"));
		panelsToOpen.forEach(pan => togglePanelState(pan, "activate"));
		//TODO scroll to target menu item after last panel expanded

		this.navPanel.updateLayout();
	},

	/**
	 * Creates navigation panel content from passed items array
	 * @param    {Array}     data      Navigation items
	 */
	renderNavigation: function(data) {
		var me = this, navPanel = me.navPanel, customMenuId = 0;
		me.currentNavData = [];
		if (me.moduleListeners) {
			Ext.destroy(me.moduleListeners);
		}

		navPanel.removeAll();
		if (!data || !data.length) {
			return;
		}
		me.currentNavData = data;
		var bindOnClickFunction = function(modData, catClick) {

			/**
			 * Menu item click handler
			 * @param     itemData     Selected item
			 */
			var onMenuItemClick = function(itemData) {
				if (itemData.modName) {
					var module = edi.core.getModule(itemData.modName);
					if (!module) {
						return;
					}

					var continueLoadingModule = function() {
						var moduleData = itemData.id ? {
							id: itemData.id
						} : null;

						edi.core.openModule(module.modName, moduleData);
					};

					if (edi.constants.LEFT_NAVIGATION_CLOSE_ALL) {
						edi.modulesHandler.removeAllModules(continueLoadingModule, true);
					}
					else {
						continueLoadingModule();
					}

				}
				else if (itemData['action']) {
					var fn = edi.utils.getObjectProperty(edi, itemData['action']);
					if ("function" == typeof fn) {
						fn(itemData);
					}
					else {
						edi.core.logMessage("Action with name edi." + itemData['action'] + " not found", "warn")
					}
				}
				else if (itemData.url) {
					window.open(itemData.url);
				}
			};

			var clickAction = function() {
				onMenuItemClick(modData);
			};

			return function(panel) {
				var clickElement = catClick ? "header" : "body";
				panel[clickElement].on('click', clickAction);
			};
		};
		/**
		 * Create menu item panel with children
		 * @param     node             Node config
		 * @param     childrenData     Childrens config
		 * @param     isSubnode        True if node is a sub-category node
		 * @return    {Object}         Menu item panel
		 */
		var createNodePanel = function(node, childrenData, isSubnode) {
			customMenuId++;
			return new Ext.Panel({
					title: edi.i18n.getMessage(node.title),
					categoryMenuIds: childrenData.categoryMenuIds,
					collapsible: true,
					collapsed: true,
					autoHeight: true,
					menuId: edi.constants.MENU_PREFIX + "_not_module_" + customMenuId,
					cls: isSubnode ? "subCategoryItem" : "categoryItem" + (node.delimiter ? " categoryItem-with-delimiter" : ""),
					bodyCls: isSubnode ? "subCategoryItemBody" : "categoryItemBody",
					hideCollapseTool: true,
					header: {
						cls: isSubnode ? "subCategoryItemHeader" : "categoryItemHeader",
						iconCls: node.icon || undefined,
						glyph: node.glyph || (isSubnode ? edi.constants.ICONS.ARROW_DOWN : undefined),
						tools: !isSubnode ? [
							{
								xtype: 'button',
								glyph: edi.constants.ICONS.ARROW_DOWN_LARGE,
								cls: 'categoryItemHeader-icon',
								handler: function() {
								}
							}
						] : undefined
					},
					listeners: {
						render(panel) {
							panel.header.on('click', function() {
								panel.toggleCollapse();
							});
						},
						collapse(panel) {
							panel.isCollapsingOrExpanding = 0;
						},
						expand(panel) {
							panel.isCollapsingOrExpanding = 0;
						}
					},
					items: childrenData.items
				}
			);
		};

		/**
		 * Create menu item panel which opens module (or calls a function)
		 * @param     item             Menu item config
		 * @return    {Object}         Menu item panel
		 */
		var createLeafPanel = function(item) {
			return new Ext.Panel({
				title: edi.i18n.getMessage(item.title),
				collapsible: false,
				collapsed: false,
				menuId: edi.constants.MENU_PREFIX + item.menuId,
				autoHeight: true,
				cls: "categoryItem categorySingleItem" + (item.delimiter ? " categoryItem-with-delimiter" : ""),
				bodyCls: "categoryItemSingleBody",
				border: 0,
				hideCollapseTool: true,
				header: {
					cls: "categoryItemHeader",
					iconCls: item.icon ? item.icon : undefined,
					glyph: item.glyph ? item.glyph : undefined
				},
				listeners: {
					render: bindOnClickFunction(item, true),
					collapse(panel) {
						panel.isCollapsingOrExpanding = 0;
					},
					expand(panel) {
						panel.isCollapsingOrExpanding = 0;
					}
				}
			});
		};

		/**Create menu level branch
		 *
		 * @param        menuItems        Menu items configs array
		 * @param        isFirstLevel     True if first (main) menu level
		 * @return       {Array/Object}   Array of panels for main level or object with panels and categoryMenuIds for other levels
		 */
		var createMenuLevel = function(menuItems, isFirstLevel) {
			var categoryMenuIds = [], panels = [];
			isFirstLevel = !!isFirstLevel;

			for (var i = 0; i < menuItems.length; i++) {
				var item = menuItems[i], childs = item.children, panel;
				if (childs && childs.length) {
					var childData = createMenuLevel(childs), hasChildren = childData.items && childData.items.length;
					if (!hasChildren) {
						continue;
					}

					panels.push(createNodePanel(item, childData, !isFirstLevel));
					if (!isFirstLevel && childData.categoryMenuIds && childData.categoryMenuIds.length) {
						categoryMenuIds = categoryMenuIds.concat(childData.categoryMenuIds);
					}

					continue;
				}

				var module = edi.core.getModule(item.modName);
				if (item && item.modName && module && module.menuId) {
					item.menuId = module.menuId;
				}

				if (!item.menuId) {
					customMenuId++;
					item.menuId = "_not_module_" + customMenuId;
				}

				if (!isFirstLevel) {
					categoryMenuIds.push(item.menuId);
					panel = {
						html: edi.i18n.getMessage(item.title),
						cls: "menuItem",
						bodyCls: "menuItemBody" + (i === menuItems.length - 1 ? " lastItem" : "") + (i === 0 ? " firstItem" : ""),
						autoHeight: true,
						border: 0,
						menuId: edi.constants.MENU_PREFIX + item.menuId,
						listeners: {
							render: bindOnClickFunction(item)
						}
					}
				}
				else {
					panel = createLeafPanel(item);
				}

				panels.push(panel);
			}

			return isFirstLevel ? panels : {
				categoryMenuIds: categoryMenuIds,
				items: panels
			};
		};

		me.menuTree = createMenuLevel(me.currentNavData, true);
		navPanel.add(me.menuTree);

		me.updateMenu(edi.modulesHandler.getActiveModule());
		me.moduleListeners = me.mon(edi.events.module, "moduleActivated", function(module) {
			me.updateMenu(module);
		});
	}
});

/**
 * Creates navigation panel
 * @param	{Object}	cfg		config for new navigationPanel
 * @return	{Object}	navigationPanel instance
 */
const createNavigationPanel = function(cfg) {
	return Ext.create("edi.components.navigationPanel", cfg);
};

Ext.namespace('edi.components');
edi.components.createNavigationPanel = createNavigationPanel;

export {createNavigationPanel};