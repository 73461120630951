/**
 * @author Anatoly Deryshev, Ivan Belousov
 * Methods for internationalisation support
 */
Ext.namespace("edi.i18n");

edi.i18n = new function() {
	var language, extLocale, languageLoaded = false, messages = {};
	var defaultMessages = {
		'error.getting.user': 'An error occurred while getting user data.',
		'error': 'Error',
		'loading.text': 'Loading',
		'error.server': 'Error on server side.'
	};

	this.langI18n = {
		RUSSIAN: "ru",
		ENGLISH: "en",
		JAPAN: "jp",
		ESTONIAN: "ee",
		LATVIAN: "lv",
		LITHUANIAN: "lt",
		UKRAINIAN: "ua"
	};
	/**
	 * Prefix used to form default key, if no value found using translation map
	 */
	this.keyPfx = {
		STATE: "documents.status."
	};
	/**
	 * Translation map
	 */
	this.trMap = {
		STATE: {
			AB: {
				FINORDER: {
					CLOSED: "documents.status.EXECUTED",
					FINANCING: "documents.status.FINANCING",
					MANUAL_VERIFICATION: "documents.status.AWAITING_ACCEPT",
					REJECTED: "documents.status.REJECTED",
					REVOKED: "documents.status.REVOKED_ORDER",
					VERIFIED: "documents.status.VERIFIED"
				},
				PACKAGE: {
					MANUAL_VERIFICATION: "documents.status.AWAITING_ACCEPT",
					CLOSED: "documents.status.ACCEPTED_BY_BANK_CLOSED",
					FINANCING: "documents.status.ACCEPTED_BY_BANK_FINANCING",
					REJECTED: "documents.status.PKG_REJECTED_BY_BANK",
					VERIFIED: "documents.status.ACCEPTED_BY_BANK_VERIFIED"
				}
			},
			ACCEPTED: {
				key: "documents.status.ACCEPTED",
				ACCEPTED_REGISTRY_LINE: "documents.status.ACCEPTED_BY_DEBTOR",
				INCOMING: {
					EDI_IFTMBF_APPROVAL: "documents.status.AWAITING_RESPONSE"
				},
				OUTGOING: {
					EDI_IFTMBF_APPROVAL: "documents.status.AWAITING_RESPONSE"
				}
			},
			ADMIN_REVIEW: "documents.status.AWAITING_ACCEPT",
			ANNUL: {
				ACTIVE: "documents.status.CANCELED",
				CREATED: "documents.status.ON_ANNUL",
				DRAFT: "documents.status.ANNUL_SIGN",
				UNSIGNED: {
					UTOCH: {
						UNSIGNED: "documents.status.SIGN_ANNUL_UTOCH"
					}
				}
			},
			BANK_ACCEPTED: {
				key: "documents.status.BANK_ACCEPTED",
				ACCEPTED_REGISTRY_LINE: "documents.status.BANK_ACCEPTED_PROCESSED"
			},
			DOCUMENT_NAME_CODE_222: {
				key: 'documents.status.SENT',
				OUTGOING: {
					key: 'documents.status.SENT',
					LEGACY_ORDER: {
						CORRECTED: 'documents.status.CORRECTED',
						RESPCODE_APERAK_CODE_: 'documents.status.SENDER_APPROVAL',
						RESPCODE_APERAK_CODE_27: 'documents.status.CANCEL_REASON',
						RESPCODE_APERAK_CODE_29: 'documents.status.APPROVED',
					},
					LEGACY_ORDER_RESP: {
						APERAK_CODE_12: 'documents.status.ERROR',
						APERAK_CODE_27: 'documents.status.CANCEL_REASON',
						APERAK_CODE_29: 'documents.status.ACCEPTED',
					}
				},
				INCOMING: {
					key: 'documents.status.RECEIVED',
					LEGACY_ORDER: {
						CORRECTED: 'documents.status.CORRECTED',
						RESPCODE_APERAK_CODE_: 'documents.status.SENDER_APPROVAL',
						RESPCODE_APERAK_CODE_27: 'documents.status.CANCEL_REASON',
						RESPCODE_APERAK_CODE_29: 'documents.status.APPROVED',
					},
					LEGACY_ORDER_RESP: {
						APERAK_CODE_12: 'documents.status.ERROR',
						APERAK_CODE_27: 'documents.status.CANCEL_REASON',
						APERAK_CODE_29: 'documents.status.ACCEPTED',
					}
				}
			},
			COMPLETED: {
				key: "documents.status.COMPLETED",
				COMPLETE: "documents.status.COMPLETED",
				CLAIM_REGISTRY: "documents.status.COMPLETED_EXECUTED",
				OUTGOING: {
					EDI_FNS_DP_PRANNUL_UTOCH: "documents.status.RECEIVED",
					EDI_FNS_UPD_STATUS_9_DP_UVUTOCH: {
						key: "documents.status.RECEIVED",
						UPD_NOTICE: "documents.status.RECEIVED_BY_SENDER"
					},
					EDI_FNS_UKD_STATUS_9_DP_UVUTOCH: {
						key: "documents.status.RECEIVED",
						UPD_NOTICE: "documents.status.RECEIVED_BY_SENDER"
					}
				},
				INCOMING: {
					EDI_FNS_DP_PRANNUL_UTOCH: "documents.status.SENT",
					EDI_FNS_UPD_STATUS_9_DP_UVUTOCH: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UKD_STATUS_9_DP_UVUTOCH: {
						UPD_NOTICE: "documents.status.RECEIVED"
					}
				},
				PAYMENT_REGISTRY: "documents.status.COMPLETED_EXECUTED",
				ROAMING: "documents.status.COMPLETED",
				UPD_NOTICE: "documents.status.RECEIVED_BY_SENDER"
			},
			CONVENTION_COMPLETED: {
				key: "documents.status.COMPLETED",
				ACT: "documents.status.CONVENTION_ACT_SIGNED"
			},
			CONVENTION_COMPLITED: "documents.status.COMPLETED",
			CREATE_DRAFT: "documents.status.DRAFT",
			DEBTOR_REVIEW: {
				key: "documents.status.DEBTOR_REVIEW",
				FP_ACT_OF_ASSIGNMENT: "documents.status.DEBTOR_PROCESSING",
				FP_ACT_OF_REVERSE_ASSIGNMENT: "documents.status.DEBTOR_PROCESSING"
			},
			DELETED: {
				key: "documents.status.DELETED",
				deleted: "documents.status.DELETED"
			},
			DRAFT: {
				key: "documents.status.DRAFT",
				EDI_DSF_DP_UVUTOCH: "documents.status.WAIT_RECEIVER_SIGN",
				INCOMING: {
					UPD_NOTICE: "documents.status.WAIT_SENDER_SIGN"
				},
				UPD_NOTICE: "documents.status.WAIT_SENDER_SIGN"
			},
			DSF_DP_IZVPOL_RECEIVER: {
				INCOMING: "documents.status.SIGN_ACK_ADVISE",
				OUTGOING: "documents.status.RECEIVER_REVIEW"
			},
			DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE: {
				key: "documents.status.DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE"
			},
			DSF_DP_IZVPOL_SENDER: {
				INCOMING: "documents.status.SENDER_REVIEW",
				OUTGOING: "documents.status.SIGN_ACK_ADVISE"
			},
			DSF_DP_UTOCH_RECEIVER: "documents.status.RECEIVED_BY_SENDER",
			INVOICE_SENT: {
				key: "documents.status.INVOICE_SENT",
				INCOMING: "documents.status.INVOICE_RECEIVED_BY_FNS",
				OUTGOING: "documents.status.INVOICE_SENT_BY_FNS"
			},
			FAIL: "documents.status.ERROR",
			PRICAT_EANCOM_DP_IZVPOL_RECEIVER: {
				INCOMING: "documents.status.SIGN_ACK_ADVISE",
				OUTGOING: "documents.status.RECEIVER_REVIEW",
				UPD_NOTICE: "documents.status.AWAITING_SIGN"
			},
			PRICAT_EANCOM_UTOCH_RECEIVER: "documents.status.REJECTED_2",
			PRICAT_EANCOM_DP_UTOCH_RECEIVER: "documents.status.RECEIVED_BY_SENDER",
			READ: {
				key: "documents.status.READ",
				INCOMING: {
					key: "documents.status.READ",
					ROAMING: "documents.status.READ_INCOMING_ROAMING"
				},
				OUTGOING: {
					key: "documents.status.READ_BY_RECEIVER",
					ROAMING: "documents.status.READ_OUTGOING_ROAMING"
				}
			},
			RECEIVER_UKD_RECEIVED_ACK: {
				key: "documents.status.RECEIVER_RECEIVED_ACK",
				UPD_NOTICE: "documents.status.AWAITING_SIGN"
			},
			RECEIVER_UPD_RECEIVED_ACK: {
				key: "documents.status.RECEIVER_RECEIVED_ACK",
				UPD_NOTICE: "documents.status.AWAITING_SIGN"
			},
			RECEIVER_UPD_SERVICE_RECEIVED_ACK: {
				key: "documents.status.RECEIVER_RECEIVED_ACK",
				UPD_NOTICE: "documents.status.AWAITING_SIGN"
			},
			EDI_PRICAT_EANCOM_DP_IZVPOL: "documents.status.RECEIVER_RECEIVED_ACK",
			REJECT: "documents.status.REJECTED",
			REJECTED: {
				key: "documents.status.REJECTED",
				bank: "documents.status.REJECTED_BY_BANK",
				ACCEPTED_REGISTRY_LINE: "documents.status.REJECTED_BY_FIN_AGENT",
				FP_PKG: "documents.status.REJECTED",
				INCOMING: {
					key: "documents.status.REJECTED",
					EDI_FNS_DP_PRANNUL: "documents.status.REJECTED_2",
					FP_PKG: "documents.status.REJECTED"
				},
				OUTGOING: {
					key: "documents.status.REJECTED",
					EDI_FNS_DP_PRANNUL: "documents.status.REJECTED_2"
				},
				INCOMING_FACTOR: "documents.status.REJECTED"
			},
			REJECTED_BY_BANK: "documents.status.REJECTED",
			SENDER_REVIEW: {
				key: "documents.status.SENDER_REVIEW",
				dop: "documents.status.SENDER_REVIEW"
			},
			SENDER_INVOICE_RECEIVED_ACK_ADVISE: "documents.status.SENDER_RECEIVED_OPERATOR_ACK_ADVISE",
			SENDER_UPD_RECEIVED_ACK_ADVISE: {
				key: "documents.status.SENDER_RECEIVED_OPERATOR_ACK_ADVISE",
				UPD_NOTICE: "documents.status.AWAITING_SIGN",
				LOOP: {
					EDI_FNS_UPD_STATUS_8_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.AWAITING_SIGN"
					}
				}
			},
			SENDER_UKD_RECEIVED_ACK_ADVISE: {
				key: "documents.status.SENDER_RECEIVED_OPERATOR_ACK_ADVISE",
				UPD_NOTICE: "documents.status.AWAITING_SIGN",
				LOOP: {
					EDI_FNS_UKD_STATUS_8_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.AWAITING_SIGN"
					}
				}
			},
			SENDER_UPD_SERVICE_RECEIVED_ACK_ADVISE: {
				key: "documents.status.SENDER_RECEIVED_OPERATOR_ACK_ADVISE",
				UPD_NOTICE: "documents.status.AWAITING_SIGN"
			},
			sent: "documents.status.SENT",
			SENT: {
				key: "documents.status.SENT",
				DOCUMENTS_FILTER: "documents.status.SENT_RECEIVED",
				INCOMING: {
					key: "documents.status.RECEIVED",
					EDI_FNS_UKD_STATUS_7_DP_PDOTPR: {
						key: "documents.status.SENT_BY_OPERATOR",
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_7_DP_PDOTPR: {
						key: "documents.status.SENT_BY_OPERATOR",
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_SUPPLY_AGREEMENT: "documents.status.RECEIVED_FROM_PARTNER",
					EDI_FACTORING_AGREEMENT: "documents.status.RECEIVED_FROM_SUPPLIER",
					EDI_FACTORING_AGREEMENT_LITE: "documents.status.RECEIVED_FROM_SUPPLIER"
				},
				INCOMING_FACTOR: {
					key: "documents.status.RECEIVED_FROM_SUPPLIER"
				},
				UNKNOWN: {
					UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR",
					FP_PKG_LITE: "documents.status.RECEIVED",
					EDI_FNS_UKD_STATUS_2_DP_PDPOL: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_3_DP_PDOTPR: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_2_DP_PDPOL: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_3_DP_PDOTPR: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_7_DP_PDOTPR: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_7_DP_PDOTPR: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_3_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UPD_STATUS_6_IOP_DP_PDPOL_DECREE_14: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UPD_STATUS_8_IOP_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UKD_STATUS_3_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UKD_STATUS_6_IOP_DP_PDPOL_DECREE_14: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UKD_STATUS_8_IOP_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UPD_STATUS_6_UOU_DP_PDPOL_DECREE_14: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UKD_STATUS_6_UOU_DP_PDPOL_DECREE_14: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UPD_STATUS_8_UOU_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UKD_STATUS_8_UOU_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UPD_STATUS_2_DP_PDPOL_DECREE_14: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_2_DP_PDPOL_DECREE_14: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_4_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_4_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_7_UOU_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_7_UOU_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_7_IOP_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_7_IOP_DP_PDOTPR_DECREE_14: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					}
				},
				LOOP: {
					EDI_FNS_UPD_STATUS_7_DP_PDOTPR: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_7_DP_PDOTPR: {
						UPD_NOTICE: "documents.status.SENT_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_4_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_4_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_5_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_5_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_8_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_8_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_2_DP_PDPOL: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UPD_STATUS_2_DP_PDPOL: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UKD_STATUS_3_DP_PDOTPR: {
						UPD_NOTICE: "documents.status.RECEIVED"
					},
					EDI_FNS_UPD_STATUS_3_DP_PDOTPR: {
						UPD_NOTICE: "documents.status.RECEIVED"
					}
				},
				OUTGOING: {
					key: "documents.status.SENT",
					EDI_FNS_UKD_STATUS_4_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_5_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					EDI_FNS_UKD_STATUS_8_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_4_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_5_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					EDI_FNS_UPD_STATUS_8_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.RECEIVED_BY_OPERATOR"
					},
					ROAMING: "documents.status.RECEIVE_ACK",
					EDI_FACTORING_AGREEMENT: "documents.status.SENT_TO_FACTOR",
					EDI_FACTORING_AGREEMENT_LITE: "documents.status.SENT_TO_FACTOR",
					FP_PKG: "documents.status.SENT_TO_FACTOR",
					EDI_SUPPLY_AGREEMENT: "documents.status.SENT_TO_PARTNER",
					UPD_NOTICE: "documents.status.SENT"
				}
			},
			SENT_DP_PRANNUL_TO_RECEIVER: "documents.status.ON_ANNUL",
			SIGNED: {
				key: "documents.status.SIGNED"
			},
			SIGNED_BY_CONSUMER: {
				key: "documents.status.SIGNED_BY_CONSUMER",
				INCOMING: {
					EDI_FNS_UPD: "documents.status.SIGNED",
					EDI_FNS_UKD: "documents.status.SIGNED"
				},
				OUTGOING: {
					EDI_FNS_UPD: "documents.status.SIGNED_BY_RECEIVER",
					EDI_FNS_UKD: "documents.status.SIGNED_BY_RECEIVER"
				}
			},
			OPERATOR_UPD_SENT_ACK_TO_SENDER_RECEIVE_ADVISE: {
				key: "documents.status.OPERATOR_SENT_ACK_TO_SENDER_RECEIVE_ADVISE",
				UPD_NOTICE: "documents.status.AWAITING_SIGN",
				LOOP: {
					EDI_FNS_UPD_STATUS_4_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.AWAITING_SIGN"
					}
				}
			},
			OPERATOR_UKD_SENT_ACK_TO_SENDER_RECEIVE_ADVISE: {
				key: "documents.status.OPERATOR_SENT_ACK_TO_SENDER_RECEIVE_ADVISE",
				UPD_NOTICE: "documents.status.AWAITING_SIGN",
				LOOP: {
					EDI_FNS_UKD_STATUS_4_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.AWAITING_SIGN"
					}
				}
			},
			OPERATOR_UPD_SERVICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE: {
				key: "documents.status.OPERATOR_SENT_ACK_TO_SENDER_RECEIVE_ADVISE",
				UPD_NOTICE: {
					UPD_NOTICE: "documents.status.AWAITING_SIGN"
				}
			},
			OPERATOR_UPD_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE: {
				key: "documents.status.OPERATOR_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE",
				UPD_NOTICE: "documents.status.AWAITING_SIGN",
				LOOP: {
					EDI_FNS_UPD_STATUS_5_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.AWAITING_SIGN"
					}
				}
			},
			OPERATOR_UKD_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE: {
				key: "documents.status.OPERATOR_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE",
				UPD_NOTICE: "documents.status.AWAITING_SIGN",
				LOOP: {
					EDI_FNS_UKD_STATUS_5_DP_IZVPOL: {
						UPD_NOTICE: "documents.status.AWAITING_SIGN"
					}
				}
			},
			OPERATOR_INVOICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE: "documents.status.SENDER_RECEIVED_OPERATOR_ACK_ADVISE",
			OPERATOR_INVOICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE: "documents.status.SENDER_RECEIVED_OPERATOR_ACK_ADVISE",
			OPERATOR_UPD_SERVICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE: {
				key: "documents.status.OPERATOR_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE",
				UPD_NOTICE: "documents.status.AWAITING_SIGN"
			},
			OPERATOR_UPD_SENT_ACK_TO_SENDER: "documents.status.OPERATOR_SENT_ACK_TO_SENDER",
			OPERATOR_UKD_SENT_ACK_TO_SENDER: "documents.status.OPERATOR_SENT_ACK_TO_SENDER",
			WAIT_BANK_DECISION: "documents.status.AWAITING_ACCEPT",
			WAIT_PARTNER_DECISION: "documents.status.WAIT_RECEIVER_SIGN",
			WAIT_SENDER_DECISION: "documents.status.SENDER_REVIEW",
			WAIT_SIGNATURE_STATUS10: {
				UPD_NOTICE: "documents.status.AWAITING_SIGN"
			},
			UTOCH: {
				key: "documents.status.UTOCH",
				DRAFT: "documents.status.SIGN_UTOCH"
			},
			DSF_REJECTED: {
				key: "documents.status.REJECTED",
				DRAFT: "documents.status.SIGN_REJECTED"
			},
			APERAK_CODE: {
				APERAK_CODE_6:"documents.status.APERAK_CODE_6",
				APERAK_CODE_10:"documents.status.APERAK_CODE_10",
				APERAK_CODE_12:"documents.status.APERAK_CODE_12",
				APERAK_CODE_13:"documents.status.APERAK_CODE_13",
				APERAK_CODE_23:"documents.status.APERAK_CODE_23",
				APERAK_CODE_27: "documents.status.APERAK_CODE_27",
				APERAK_CODE_29:"documents.status.APERAK_CODE_29",
				APERAK_CODE_34:"documents.status.APERAK_CODE_34"
			}
		}
	};

	/**
	 * Get translation key by map or return default translation
	 * @param    {Array}     path             translation map path, starting from root
	 * @param    {String}    defaultPrefix    prefix used for default key creation
	 * @returns  {String}                     translated value
	 */
	this.getTr = function(path, defaultPrefix) {
		defaultPrefix = defaultPrefix || this.keyPfx.STATE;
		var key = this.findKey(undefined, path);
		if (!key) {
			path.shift();
			key = defaultPrefix + path.join(".");
		}
		return edi.i18n.getMessage(key);
	};
	/**
	 * Finds key in translation map by path, if {map} passed, it will be used for searching, otherwise whole map is used
	 * @param    {Object}    map     translations map
	 * @param    {Array}     path    translation map path
	 * @returns  {string}            found key
	 */
	this.findKey = function(map, path) {
		var key = '', i = 0, parentKey, segment;
		map = map ? map : this.trMap;
		if (map && Ext.isArray(path)) {
			for (; i < path.length; i++) {
				if (!segment) {
					segment = map;
				}
				if (segment[[path[i]]]) {
					segment = segment[[path[i]]];
					if (Ext.isObject(segment) && segment.key) {
						parentKey = segment.key;
					}
					else if (Ext.isString(segment)) {
						key = segment;
						break;
					}
				}
			}
			if (!key && parentKey) {
				key = parentKey;
			}
		}
		return key;
	};

	/**
	 * Returns translation template type
	 * @param    {String}    docType    document type to template
	 */
	this.convertMessageToTemplateTypes = function(docType) {
		var config = [].concat(edi.constants.DR_ATTACHMENTS);
		return config.indexOf(docType) !== -1
			? edi.i18n.getMessage('documents.doctype.pdf.template.' + docType)
			: edi.i18n.getMessage('documents.doctype.' + docType)
	};

	/**
	 * Returns translation from messages bundle, or key if no translation found
	 * @param    {String}     key             key used for translation
	 * @param    {Object=}     formatValues    key used for translation
	 * @param    {Boolean=true}    dontClear       true to not clear placeholders
	 * @param    {Boolean=true}    isSource        true to show translation text - param only for debug showKeys
	 */
	this.getMessage = function(key, formatValues, dontClear, isSource) {
		var msg = messages[key] || key;
		if (msg === key) {
			msg = defaultMessages[key] || key;
		}

		if (formatValues && msg != key) {
			msg = edi.utils.formatString(msg, formatValues, dontClear);
		}

		if( edi.utils.getURLParams() && edi.utils.getURLParams().showKeys ){
			return isSource !== undefined ? msg : key;
		} else {
			return msg;
		}
	};

	/**
	 * Returns currently loaded language
	 */
	this.getLanguage = function() {
		if (!language) {
			language = edi.utils.getCookie(edi.constants.DEFAULT.LANGUAGE_COOKIE_NAME) || edi.constants.DEFAULT.LANGUAGE;
			if (!edi.constants.LANGUAGES[language]) {
				language = edi.constants.DEFAULT.LANGUAGE;
			}
		}
		return language;
	};

	/**
	 * Loads Ext.locale for current language
	 * @param lang
	 * @param callback
	 */
	this.loadExtLocale = function(lang, callback) {
		var load = true;
		if (lang && edi.constants.LANGUAGES[lang] && edi.constants.LANGUAGES[lang].EXT_LOCALE && extLocale != edi.constants.LANGUAGES[lang].EXT_LOCALE) {
			extLocale = edi.constants.LANGUAGES[lang].EXT_LOCALE;
		}
		else if (!lang || !edi.constants.LANGUAGES[lang] || !edi.constants.LANGUAGES[lang].EXT_LOCALE) {
			extLocale = edi.constants.LANGUAGES["ENGLISH"].EXT_LOCALE;
		}
		else {
			callback();
			load = false;
		}
		if (load) {
			edi.loading.processor.loadScript({
				path: "js/ext74/locale/ext-lang-" + extLocale + ".js",
				success: callback,
				failure: callback
			});
		}
	};

	/**
	 * Loads messages bundle from backend
	 * @param    {Function}   callback            will be called after translations loading
	 * @param    {Boolean}    limited             full translation list or limited
	 * @param    {Function}   afterLoadMessages   for layout set data
	 * @param    {String}     lang                language to load
	 */
	this.loadMessages = function(callback, limited, afterLoadMessages, lang) {
		var self = this, isLastLoad;
		lang = lang || language;
		var isSuccess = true;
		var success = function(data) {
			language = lang;
			edi.constants.DEFAULT.CURRENCY = edi.constants.LANGUAGES[language].CURRENCY;
			if (data && data.success && data.data && data.data.translations) {
				Ext.merge(messages, data.data.translations);
			}
			if (isLastLoad) {
				afterLoadMessages(isSuccess);
			}
		};
		var failure = function() {
			isSuccess = false;
			edi.core.handleException("Messages bundle did not loaded properly");
			if (isLastLoad) {
				afterLoadMessages(isSuccess);
			}
		};
		if (language == lang && languageLoaded) {
			"function" == typeof callback ? callback() : null;
		}
		else {
			var loadLocalizationGroup = function(groupName, nextCallback) {
				var url = edi.utils.formatString(edi.rest.services.I18N.SYSTEM.TRANSLATION.GROUP.GET, {
					groupName: groupName,
					lang: self.langI18n.hasOwnProperty(lang) ? self.langI18n[lang] : lang,
					packageName: edi.constants.LOCALIZATION_PACKAGE_NAME
				});
				edi.rest.sendRequest(url, "GET", null, success, failure, nextCallback, {
					suppressDefaultError: true
				});
			};

			var recursiveLoaded = function(itemNumber, loadLocalizationGroups) {
				if (itemNumber == (loadLocalizationGroups.length - 1)) {
					isLastLoad = true;
				}
				loadLocalizationGroup(loadLocalizationGroups[itemNumber++], function() {
					if (itemNumber == loadLocalizationGroups.length) {
						"function" == typeof callback ? callback() : null;
					}
					else {
						recursiveLoaded(itemNumber, loadLocalizationGroups);
					}
				});
			};

			if (limited ) {
				isLastLoad = true;
				recursiveLoaded(0, edi.constants.INIT_LOCALIZATION_GROUPS);
			}
			else if (edi.core.loadLocalizationGroups.length) {
				recursiveLoaded(0, edi.core.loadLocalizationGroups);
			}
		}
	};
};