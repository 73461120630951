/**
 * Adds default properties to config object of action column to be displayed on document's grid
 * @param	{Object}	[config]	config options
 * @returns	{Object}	config options of action column with defaults
 */
export const createActionsColumnConfig = function (config) {
	config = 'object' == typeof config ? config : {};
	var defaults = {
		xtype: 'actioncolumn',
		cls: 'x-column-header-action',
		align: 'center',
		hideable: false,
		menuDisabled: true,
		sortable: false,
		width: edi.utils.getActionColumnWidth(config.items && config.items.length ? config.items.length : 1)
	};
	Ext.applyIf(config, defaults);
	return config;
};

export const columnsChangeHandler = function (comp) {
	const getColumnConfig = function (cName) {
		const columnsConfig =
			comp.getInitialConfig().columns || comp.getColumns().map((column) => column.initialConfig) || [];
		let found = null;
		for (let i = 0; i < columnsConfig.length; i++) {
			const aColumn = columnsConfig[i];
			if (columnsConfig[i].cName === cName) {
				found = columnsConfig[i];
				break;
			}
			if (aColumn.columns) {
				for (let j = 0; j < aColumn.columns.length; j++) {
					if (aColumn.columns[j].cName === cName) {
						found = aColumn.columns[j];
						break;
					}
				}
			}
		}
		if (!found) {
			// для двухуровневых гридов
			let colCnf = comp.getInitialConfig().columns?.find((column) => column.cName === cName);
			if (colCnf?.columns?.length) {
				found = colCnf;
			}
		}
		return found;
	};

	comp.on('columnhide', function (comp, column) {
		column.columnsName && column.cName
			? edi.utils.setData('c.' + column.columnsName + '.' + column.cName + '.visible', '0')
			: null;
		edi.utils.setData('c.' + column.columnsName + '_version', column.columnsVersion);
	});
	comp.on('columnshow', function (comp, column) {
		column.columnsName && column.cName
			? edi.utils.setData('c.' + column.columnsName + '.' + column.cName + '.visible', '1')
			: null;
		edi.utils.setData('c.' + column.columnsName + '_version', column.columnsVersion);
	});
	const saveSize = function (col, actioncolumnSize) {
		if (col.columnsName && col.cName) {
			const columnConfig = getColumnConfig(col.cName);
			const savedConfig = edi.utils.getData('c.' + col.columnsName + '.' + col.cName);
			if (!col.hidden) {
				if (columnConfig && columnConfig.flex) {
					var value = ((col.getWidth() / (comp.getWidth() - actioncolumnSize)) * 10).toFixed(3);
					edi.utils.setData('c.' + col.columnsName + '.' + col.cName + '.flex', value);
				} else {
					edi.utils.setData('c.' + col.columnsName + '.' + col.cName + '.width', col.getWidth());
				}
			} else {
				const flex = savedConfig.flex ? savedConfig.flex : columnConfig?.flex;
				if (flex) {
					edi.utils.setData('c.' + col.columnsName + '.' + col.cName + '.flex', flex);
				} else {
					edi.utils.setData(
						'c.' + col.columnsName + '.' + col.cName + '.width',
						savedConfig.width ? savedConfig.width : columnConfig.width
					);
				}
			}
			edi.utils.setData('c.' + col.columnsName + '_version', col.columnsVersion);
		}
	};
	comp.on('columnresize', function (comp) {
		if (comp.columnManager.headerCt && comp.columnManager.headerCt.items.items) {
			const items = comp.columnManager.headerCt.items.items;
			let actioncolumnSize = 0;
			if (items.length) {
				const lastColumn = items[items.length - 1];
				if (lastColumn.xtype === 'actioncolumn') {
					actioncolumnSize = lastColumn.width || 0;
				}
			}
			for (let i = 0; i < items.length; i++) {
				const aColumn = items[i];
				saveSize(aColumn, actioncolumnSize);
				if (aColumn.items.items) {
					for (let j = 0; j < aColumn.items.items.length; j++) {
						const aCol = aColumn.items.items[j];
						saveSize(aCol, 0);
					}
				}
			}
		}
	});
	const saveOrder = function (col, order) {
		if (col.columnsName && col.cName) {
			edi.utils.setData('c.' + col.columnsName + '.' + col.cName + '.order', order);
			edi.utils.setData('c.' + col.columnsName + '_version', col.columnsVersion);
		}
	};
	comp.on('columnmove', function (comp) {
		const items = comp.columnManager.headerCt.items.items;
		for (let i = 0; i < items.length; i++) {
			const aColumn = items[i];
			saveOrder(aColumn, i);
			if (aColumn.items.items) {
				for (let j = 0; j < aColumn.items.items.length; j++) {
					const aCol = aColumn.items.items[j];
					saveOrder(aCol, j);
				}
			}
		}
	});
};
