import { createPagingBar } from './PagingBar';
import { createGridSettingButton } from './GridSettingsButton';
import { createGridActionBar } from './GridActionBar';
import { columnsChangeHandler, createActionsColumnConfig } from './Columns';
import { createProxyConfig, createStore } from '@Components/storeComponents';
import columnTpl from './Column.ext.tpl';
import './Grid.scss';
import './GridHeaderRow.scss';

const ROW_COLOR_CLS = {
	valid: 'valid-record',
	warning: 'yellow-cell',
	error: 'with-error',
	markedPrimary: 'marked-primary',
	markedError: 'marked-error',
	markedWarning: 'marked-warning'
};

Ext.override(Ext.grid.column.Column, {
	renderTpl: columnTpl
});

Ext.define('UI.components.Grid', {
	extend: 'Ext.grid.Panel',
	baseCls: 'ui-table',

	constructor: function (externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	},

	beforeInit: function () {
		this.setOwnConfig();
	},

	setOwnConfig: function () {
		const me = this;
		let options = Ext.apply({}, me.options);
		options.storeConfig = 'object' == typeof options.storeConfig ? options.storeConfig : {};
		options.proxyConfig = 'object' == typeof options.proxyConfig ? options.proxyConfig : {};
		options.gridConfig = 'object' == typeof options.gridConfig ? options.gridConfig : {};
		options.viewConfig = 'object' == typeof options.viewConfig ? options.viewConfig : {};
		var pagingBarConfig = 'object' == typeof options.pagingBarConfig ? options.pagingBarConfig : {};
		var setViewAttr = function (view, value) {
			//Used to simplify automatic testing
			if (view && view.rendered) {
				value = value || store.proxy.type === 'memory' ? 'true' : 'false';
				var attr = {};
				attr[edi.constants.RENDERED_CONTENT_ATTR] = value;
				var elWrapper = Ext.get(view.el);
				if (elWrapper) {
					elWrapper.set(attr);
				}
			}
		};
		if (options && options.proxyConfig) {
			options.proxyConfig.url = options?.proxyConfig.url || ' ';
		}

		Ext.applyIf(options.storeConfig, {
			proxy: options.proxy ? options.proxy : createProxyConfig(options.proxyConfig),
			remoteSort: true,
			listeners: {}
		});
		if ('function' == typeof options.storeConfig.listeners.beforeload) {
			var oldBeforeLoad = options.storeConfig.listeners.beforeload;
			options.storeConfig.listeners.beforeload = function (store, operation, eOpts) {
				store.firstLoadingStarted = true;
				if (me.isCreated && 'function' == typeof me.getView) {
					setViewAttr(me.getView());
				}
				oldBeforeLoad(store, operation, eOpts);
			};
		} else {
			Ext.applyIf(options.storeConfig.listeners, {
				beforeload: function (store) {
					store.firstLoadingStarted = true;
					if (me.isCreated && 'function' == typeof me.getView) {
						setViewAttr(me.getView());
					}
				}
			});
		}

		let saveAndRestoreSortersIsEnabled =
			options.saveSorters === true &&
			edi.constants.RESTORE_FILTER_VALUES_ENABLED &&
			edi.utils.getObjectProperty(edi.core.getUserData(), 'userData.user.saveSort') === 'true';
		let sortersSaveConfName = `sorters.${options.savedSortersName || 'modulename_gridname'}`;
		if (saveAndRestoreSortersIsEnabled) {
			let savedSorters = edi.utils.getData(sortersSaveConfName);
			savedSorters = savedSorters && typeof savedSorters === 'string' ? JSON.parse(savedSorters) : savedSorters;
			if (savedSorters) {
				if (options.store) {
					options.store.sorters.removeAll();
					options.store.sorters.add(savedSorters);
				} else {
					options.storeConfig.sorters = savedSorters;
				}
			}
		}

		let store = options.store ? options.store : createStore(options.storeConfig);

		if (saveAndRestoreSortersIsEnabled) {
			store.on('load', function () {
				let newSorters = [];
				store
					.getSorters()
					.getRange()
					.forEach((sorter) => {
						if (sorter) {
							newSorters.push(sorter.getConfig());
						}
					});
				newSorters = JSON.stringify(newSorters);
				edi.utils.setData(sortersSaveConfName, newSorters);
			});
		}

		/**
		 * Handles params for testing
		 * @param view
		 */
		var viewHandler = function (view) {
			if (
				view.loadMask &&
				'object' == typeof view.loadMask &&
				'function' == typeof view.loadMask.isVisible &&
				view.loadMask.isVisible(true)
			) {
				view.loadMask.on(
					'afterhide',
					function () {
						setViewAttr(view, true);
					},
					view.loadMask,
					{
						single: true
					}
				);
			} else {
				setViewAttr(view, true);
			}
		};
		var viewConfigListeners = {},
			oldViewready,
			oldRefresh;
		if ('object' == typeof options.viewConfig.listeners) {
			viewConfigListeners = options.viewConfig.listeners;
			delete options.viewConfig.listeners;
			if (viewConfigListeners.viewready) {
				oldViewready = viewConfigListeners.viewready;
				delete viewConfigListeners.viewready;
			}
			if (viewConfigListeners.refresh) {
				oldRefresh = viewConfigListeners.refresh;
				delete viewConfigListeners.refresh;
			}
		}
		var defaultViewListeners = {
			viewready: function (view, eOpts) {
				//Will be fired when display grid in tab
				if (view.store && view.store.firstLoadingStarted && !view.store.loading) {
					viewHandler(view);
				}
				'function' == typeof oldViewready ? oldViewready(view, eOpts) : null;
			},
			refresh: function (view, eOpts) {
				//Skip initial refresh and grid activation without loading data
				if (view.viewReady && view.store && view.store.firstLoadingStarted) {
					viewHandler(view);
				}
				'function' == typeof oldRefresh ? oldRefresh(view, eOpts) : null;
			}
		};
		Ext.applyIf(viewConfigListeners, defaultViewListeners);
		var defaultViewConfig = {
			loadingText: edi.i18n.getMessage('loading.text'),
			loadMask: true,
			forceFit: false,
			autoScroll: true,
			emptyText: edi.i18n.getMessage('grid.empty'),
			deferEmptyText: false,
			enableTextSelection: options.enableTextSelection === undefined ? true : options.enableTextSelection,
			listeners: viewConfigListeners
		};
		Ext.applyIf(options.viewConfig, defaultViewConfig);
		var defaults = {
			store: store,
			forceFit: false,
			autoScroll: true,
			viewConfig: options.viewConfig,
			border: 0,
			showSettingsButton: options.gridConfig.hideSettingsButton
				? false
				: edi.constants.DEFAULT.COMPONENTS_SETTINGS.GRID.SHOW_SETTING_BUTTON,
			enableColumnHide: options.gridConfig.hideSettingsButton
				? true
				: !edi.constants.DEFAULT.COMPONENTS_SETTINGS.GRID.SHOW_SETTING_BUTTON
		};
		if (!options.gridConfig.disablePaging) {
			Ext.applyIf(pagingBarConfig, {
				store: store
			});
			let pagingBar = createPagingBar(pagingBarConfig);
			defaults.bbar = pagingBar;
			defaults.pagingBar = pagingBar;
		}
		if (options.gridConfig.actionBarCfg) {
			defaults.tbar = createGridActionBar(options.gridConfig.actionBarCfg);
		}
		if (edi.constants.DEFAULT.COMPONENTS_SETTINGS.GRID.SHOW_SETTING_BUTTON) {
			for (var i = 0; i < options.gridConfig.columns.length; i++) {
				options.gridConfig.columns[i].menuDisabled = true;
				options.gridConfig.columns[i].tooltip = options.gridConfig.columns[i].text;
			}
		}
		if (options.gridConfig.detailsArrowBtnConfig) {
			var btnArrowConf =
				'object' === typeof options.gridConfig.detailsArrowBtnConfig
					? options.gridConfig.detailsArrowBtnConfig
					: {};

			var openDetails = function (record) {
				var recordData = record.getData();
				edi.core.openModule(
					edi.constants.DETAILS_MODULE_NAME_BY_TYPE[recordData.type],
					recordData,
					recordData.id,
					false
				);
			};
			if (!btnArrowConf.hasOwnProperty('isActionDisabled')) {
				btnArrowConf.isActionDisabled = false;
			}
			if (!btnArrowConf.hasOwnProperty('handler') || 'function' !== typeof btnArrowConf.handler) {
				btnArrowConf.handler = function (grid, rowIndex) {
					openDetails(grid.getStore().getAt(rowIndex));
				};
			}
			if (true === btnArrowConf.celldblclickHandler) {
				if (
					!options.gridConfig.hasOwnProperty('listeners') ||
					'object' !== typeof options.gridConfig.listeners
				) {
					options.gridConfig.listeners = {};
				}
				if (!options.gridConfig.listeners.hasOwnProperty('celldblclick')) {
					options.gridConfig.listeners['celldblclick'] = function (view, td, cellIndex, record) {
						openDetails(record);
					};
				}
			}
			options.gridConfig.columns.push(
				createActionsColumnConfig({
					tdCls: 'edi-td-grid-cell-details',
					items: [
						{
							glyph: edi.constants.ICONS.ARROW_NAVIGATE_NEXT,
							iconCls: 'edi-grid-row-button-details-arrow',
							testCls: 'test-action-column-details',
							handler: btnArrowConf.handler,
							isActionDisabled: function (view, rowIndex, colIndex, item, record) {
								return 'function' === typeof btnArrowConf.isActionDisabled
									? btnArrowConf.isActionDisabled(view, rowIndex, colIndex, item, record)
									: !!btnArrowConf.isActionDisabled;
							}
						}
					]
				})
			);
		}
		const cfg = Ext.applyIf(options.gridConfig, defaults);
		Ext.merge(me, cfg);
	},

	afterInit: function () {
		const grid = this;
		if (grid.options.gridConfig.showSettingsButton) {
			createGridSettingButton(grid, grid.options.gridConfig.settingsBtnConf);
		}

		if (grid.pagingBar) {
			grid.pagingBar.grid = grid;
		}

		if (edi.constants.COLUMN_CONFIG_SAVE_ENABLED && edi.permissions.hasPermission('EDIT_USER_PROFILE')) {
			// Save column settings
			grid.on('boxready', function (grid) {
				grid.view.focus = Ext.emptyFn;
			});
			grid.on('render', columnsChangeHandler);
		}

		grid.isCreated = true;
	}
});

/**
 * Creates grid
 * @param	{Object}	[options]	store, proxy and grid config options
 * @returns	{Object}	UI.components.Grid instance
 */
const createGrid = function (options) {
	return Ext.create('UI.components.Grid', { options });
};

export { createGrid, ROW_COLOR_CLS };
