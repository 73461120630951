import './Trigger.scss';

Ext.define('UI.components.TriggerField', {
	extend: 'Ext.form.field.Text',
	baseCls: 'ui-triggerfield',

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	}
});

const createTriggerField = (config) => new UI.components.TriggerField(config);

export { createTriggerField };
