import './Combobox.scss';

// В компоненте комбобокс добавленно много дополнительной логики, смотреть в uikit/components/fields_old/Combobox.js
// переношу по частям, всё что пененёс в новый в старом закоментированно

// осталось разобраться с TypeTree, есть отдельный компонент, основанный на функционале TypeTree (createComboDocType)
// нужен рефакторинг

// и нужно реализовать отдельно плагин setRequiredFields
// функционал уже затрагивает бизнес логику, думаю лучше реализовывать в core

const baseCls = 'ui-comboboxfield';

Ext.define('UI.components.ComboboxField', {
	extend: 'Ext.form.field.ComboBox',
	baseCls,
	triggerCls: `${baseCls}-trigger`,
	openCls: `${baseCls}-picker-open`,
	enabledClick: true,
	queryMode: 'local',
	displayField: 'name',
	valueField: 'id',
	emptyText: '',

	autoSelect: false,
	valueInitialize: false,
	forceSelection: true,
	setLastValueAfterTextClear: false,

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	modifyConfig: function (externalCfg) {
		const me = this;
		const cfg = Ext.merge({}, externalCfg);

		cfg.listeners = Ext.apply(cfg.listeners, {
			select: me.replacementListeners('select', me.onSelectListener, cfg)
		});

		return cfg;
	},

	initComponent: function () {
		const me = this;

		if (me.store && 'string' == typeof me.store) {
			me.store = edi.stores[me.store]();
		}

		me.callParent(arguments);

		if ('undefined' != typeof me.value && ('undefined' == typeof me.autoValue || me.autoValue == null)) {
			me.autoValue = me.value;
		}

		if ('undefined' != typeof me.autoValue || me.autoSelect) {
			me.getStore().on('load', me.onAutoValue, me, {
				single: true
			});
			me.on('afterrender', me.onAutoValue, me, {
				single: true
			});
		}

		if (me.multiSelect && me.setLastValueAfterTextClear && me.forceSelection) {
			me.on('blur', function (__self) {
				if (!__self.getValue() && __self.lastSelection?.length) {
					__self.setValue(__self.lastSelection);
				}
			});
		}
	},

	onValueCollectionEndUpdate: function () {
		var me = this,
			store = me.store,
			selectedRecords = me.valueCollection.getRange(),
			selectedRecord = selectedRecords[0],
			selectionCount = selectedRecords.length;
		me.updateBindSelection(me.pickerSelectionModel, selectedRecords);
		if (me.isSelectionUpdating()) {
			return;
		}
		Ext.suspendLayouts();
		me.lastSelection = selectedRecords;
		if (selectionCount) {
			// Track the last selection with a value (non blank) for use in
			// assertValue
			me.lastSelectedRecords = selectedRecords;
		}

		me.updateValue();
		// If we have selected a value, and it's not possible to select any more values
		// or, we are configured to hide the picker each time, then collapse the picker.
		// eslint-disable-next-line max-len
		if (
			selectionCount &&
			((!me.multiSelect && store.contains(selectedRecord)) || me.collapseOnSelect || !store.getCount())
		) {
			me.updatingValue = true;
			me.collapse();
			me.updatingValue = false;
		}
		Ext.resumeLayouts(true);
		if (!me.suspendCheckChange) {
			if (!me.multiSelect) {
				selectedRecords = selectedRecord;
			}

			//me.fireEvent('select', me, selectedRecords);
			//override begin
			if (me.allowManualInput || me.isXType('timefield')) {
				me.fireEvent('select', me, selectedRecords);
			} else if (selectedRecords !== null && Ext.isObject(selectedRecords) && selectedRecords.phantom === true) {
				me.setValue(null);
				me.fireEvent('select', me, null);
			} else if (Ext.isArray(selectedRecords)) {
				let phantomRecord = (selectedRecords || []).find((rec) => {
					let hasId = rec.get(rec.getIdProperty()) !== undefined && rec.get(rec.getIdProperty()) !== null;
					return !hasId || rec.phantom === true;
				});
				if (phantomRecord) {
					me.setValue(null);
					me.fireEvent('select', me, null);
				} else {
					me.fireEvent('select', me, selectedRecords);
				}
			} else {
				me.fireEvent('select', me, selectedRecords);
			}
			//override end
		}
	},

	onRender: function () {
		const me = this;
		me.callParent(arguments);

		if (me.enabledClick) {
			me.onEnabledClick();
		}
	},

	onEnabledClick: function () {
		const me = this;

		let triggerClick = function () {
			if (!me.readOnly && !me.isDisabled() && !me.isExpanded) {
				me.onTriggerClick();
			}
		};

		me.triggerWrap.on({
			focus: triggerClick,
			click: triggerClick
		});
	},

	setValueSrc: function () {
		const me = this,
			valueSrc = me.valueSrc;
		delete me.valueSrc;

		let value;

		if (!!(valueSrc && me.config.name)) {
			value = edi.utils.getObjectProperty(valueSrc, me.config.name);
		}

		'undefined' != typeof value ? (me.autoValue = value) : null;
		if (me.autoValue && me.valueInitialize) {
			me.value = me.autoValue;
		}
	},

	// данный метод лишний, нет необходимости переписывать listener и в нём вызывать исходный, нужно просто определить слушатель через on
	replacementListeners: function (listener, fn, originCfg) {
		let event;

		if (originCfg?.listeners) {
			event =
				typeof originCfg.listeners[listener] == 'function'
					? originCfg.listeners[listener]
					: typeof originCfg.listeners[listener]?.fn == 'function'
					? originCfg.listeners[listener].fn
					: null;
		}

		return function () {
			const args = arguments;

			typeof fn == 'function' &&
				fn(...args, function () {
					typeof event == 'function' && event(...args);
				});
		};
	},

	onAutoValue: function () {
		const me = this;
		let store = me.getStore();
		if (!store) {
			return;
		}

		if ('undefined' != typeof me.autoValue && me.autoValue != null) {
			let recordsToSelect = [];
			let targetValues = Ext.isArray(me.autoValue) ? me.autoValue : [me.autoValue];

			for (let i = 0; i < targetValues.length; i++) {
				let result = store.findRecord(me.valueField, targetValues[i], 0, false, true, true);
				if (result) {
					recordsToSelect.push(result);
				}
			}

			if (recordsToSelect.length > 0 && !me.isDestroyed) {
				if (!me.valueInitialize) {
					me.select(recordsToSelect);
				}
				me.fireEventArgs('select', [me, me.multiSelect ? recordsToSelect : recordsToSelect[0]]);
			}
		} else if (me.autoSelect) {
			let first = store.getAt(0);

			if (first) {
				me.select(first);
				me.fireEventArgs('select', [me, first]);
			}
		}
	},

	onSelectListener: function (comp, records, eOpts, callback) {
		let newVal = comp.getValue();
		let isEmpty = newVal === undefined || newVal === '' || (Ext.isArray(comp.getValue()) && newVal.length === 0);

		if (isEmpty) {
			comp.setValue(null);
			comp.collapse();
		} else if (comp.multiSelect !== true) {
			comp.collapse();
		}
		typeof callback == 'function' && callback();

		comp.isValid();
	}
});

const createCombo = (config) => new UI.components.ComboboxField(config);

export { createCombo };
