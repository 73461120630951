// import { i18n } from '@UIkit/methods';
import '@UIkit/components/fields/Combobox/Combobox';
import './Autocomplete.scss';

const icons = {
	close: 'xe14c@Material Icons'
};

Ext.define('UI.components.AutocompleteField', {
	extend: 'UI.components.ComboboxField',
	baseCls: 'ui-autocomplete',

	name: 'name',
	queryMode: 'remote',
	allowBlank: true,
	minChars: 0,
	queryParam: 'name',
	enforceMaxLength: true,
	maxLength: 150,
	triggers: {
		clear: {
			glyph: icons.close,
			handler() {
				let prevValue = this.getValue();
				this.clearValue();
				this.doQuery();
				if (!(prevValue === null || prevValue === undefined || prevValue === '')) {
					this.fireEvent('select', this, undefined);
				}
			}
		},
		picker: {
			hidden: true
		}
	},
	valueField: 'id',
	displayField: 'name',
	triggerAction: 'query',

	constructor(externalCfg, storeCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg, storeCfg);
		this.callParent([modifiedConfig]);
	},

	modifyConfig: function (cfg, storeCfg) {
		storeCfg = 'object' == typeof storeCfg ? storeCfg : {};
		return Ext.merge(
			{
				store: storeCfg
			},
			cfg
		);
	}
});

const createAutocomplete = (config, storeConfig) => new UI.components.AutocompleteField(config, storeConfig);

export { createAutocomplete };
