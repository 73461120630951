import './Panel.scss';

Ext.define('UI.components.Panel', {
	extend: 'Ext.Panel',

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, edi.constants.DEFAULT.PANEL, cfg);
	},

	beforeInit: function () {},

	afterInit: function () {}
});

/**
 * Creates panel
 * @param	{Object}	[config]
 * @returns	{Object}	UI.components.Panel instance
 */
export const createPanel = function (config) {
	return Ext.create('UI.components.Panel', config);
};
