const { i18n } = require('@UIkit/methods');

Ext.override(Ext.form.field.Text, {
	blankTextKey: 'form.field.required.label',
	initComponent: function () {
		const me = this;

		me.blankText = i18n(me.blankTextKey);
		me.callParent(arguments);
	}
});
