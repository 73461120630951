import { createMenuItem } from '@Components/miscComponents';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import './GridActionBar.scss';

Ext.define('UI.components.GridActionBar', {
	extend: 'Ext.toolbar.Toolbar',

	cls: 'ui-grid-top-bar',
	otherMenuCls: 'ui-grid-top-bar-other-actions-menu',
	actionButtonCls: 'edi-grid-actions',
	visibleButtonsNumber: 3,
	dock: 'top',

	constructor: function (externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	},

	beforeInit: function () {
		this.setOwnConfig();
	},

	setOwnConfig: function () {
		const me = this;
		let options = Ext.apply({}, me.options);
		let getHandler = function (cfg) {
			return function () {
				if ('function' == typeof cfg.handler) {
					cfg.handler(cfg.id, cfg);
				} else if ('function' == typeof options.defaultHandler) {
					options.defaultHandler(cfg.id, cfg);
				} else {
					edi.core.showWarn('not.implemented');
				}
			};
		};
		let createMenuItemCmp = function (cfg) {
			cfg = Ext.apply({}, cfg);
			var compConf = {
				text: cfg.name,
				disabled: !cfg.notDisabled,
				handler: getHandler(cfg)
			};
			var mItem = createMenuItem(Ext.merge(compConf, cfg.additionalData));
			mItem.rulesData = cfg;
			return mItem;
		};
		let createMenu = function (cfg, skipProcessing) {
			var i,
				items = [];
			if (skipProcessing) {
				items = cfg.menu;
			} else {
				for (i = 0; i < cfg.menu.length; i++) {
					items.push(createMenuItemCmp(cfg.menu[i]));
				}
			}
			return {
				plain: true,
				hideMode: 'display',
				cls: me.otherMenuCls,
				items: items
			};
		};
		let createBtn = function (cfg, compConf) {
			cfg = Ext.apply({}, cfg);
			compConf = {
				text: cfg.name,
				cls: `${BUTTON_CLS.light} ${me.actionButtonCls}`,
				disabled: !cfg.notDisabled,
				allowMultiClick: !!cfg.menu,
				glyph: edi.document.actions.getActionIcon(cfg.id),
				handler: !cfg.menu ? getHandler(cfg) : undefined,
				menu: cfg.menu ? createMenu(cfg, cfg.skipMenuItemsCreation) : undefined
			};
			compConf = Ext.merge(compConf, cfg.additionalData);
			var btn = createButton(compConf);
			btn.rulesData = cfg;
			return btn;
		};
		let other = [];
		let grouped = {
			withoutGroup: []
		};
		let addToGroup = function (cfg) {
			var groupId;
			if (cfg.groupId) {
				groupId = cfg.groupId;
			} else {
				groupId = 'withoutGroup';
			}
			if (!grouped[groupId]) {
				grouped[groupId] = [];
			}
			var item = createMenuItemCmp(cfg);
			grouped[groupId].push(item);
			return item;
		};
		let createOtherAction = function (cfg) {
			cfg = Ext.apply({}, cfg);
			var menuItems = [],
				groupId,
				groupName,
				i,
				menuItem;
			if (cfg.menu) {
				if (cfg.groupId) {
					groupId = cfg.groupId;
					groupName = undefined;
				} else {
					groupId = cfg.name;
					groupName = cfg.name;
				}
				for (i = 0; i < cfg.menu.length; i++) {
					menuItem = cfg.menu[i];
					if (!menuItem.groupId) {
						menuItem.groupId = groupId;
						if (groupName) {
							menuItem.groupName = groupName;
						}
					}
					if (!menuItem.id) {
						menuItem.id = cfg.id;
					}
					menuItems.push(addToGroup(menuItem));
				}
			} else {
				menuItems.push(addToGroup(cfg));
			}
			return menuItems;
		};

		const properties = Object.assign({}, options.barConfig);
		if (!properties.items) {
			properties.items = [];
		}

		const actionCfgs = options.actionCfgs;
		if (Array.isArray(actionCfgs) && actionCfgs.length > 0) {
			let cntr = 0;
			for (let i = 0; i < actionCfgs.length; i++) {
				if (cntr < me.visibleButtonsNumber) {
					properties.items.push(createBtn(actionCfgs[i]));
				} else {
					other = other.concat(createOtherAction(actionCfgs[i]));
				}
				cntr++;
			}
			if (other.length) {
				var otherItems = [],
					currentGroup = undefined;
				for (let i in grouped) {
					if (grouped.hasOwnProperty(i) && grouped[i].length) {
						if (i !== currentGroup && i !== 'withoutGroup') {
							//Render group header
							otherItems.push(
								Ext.create('Ext.menu.Separator', {
									text: grouped[i][0].rulesData.groupName
										? grouped[i][0].rulesData.groupName
										: edi.i18n.getMessage('document.action.group.name.' + i)
								})
							);
							currentGroup = i;
						}
						otherItems = otherItems.concat(grouped[i]);
					}
				}
				properties.items.push(
					createBtn({
						id: 'OTHER_ACTIONS',
						name: edi.i18n.getMessage('document.action.OTHER_ACTIONS'),
						notDisabled: true,
						menu: otherItems,
						skipMenuItemsCreation: true
					})
				);
			}
		}

		me.allActions = [].concat(properties.items, other);
		me.menuActionItemsCnt = me.allActions.length;
		me.getMenuActionItems = function () {
			return me.allActions;
		};

		Ext.merge(me, properties);
	},

	afterInit: function () {}
});

/**
 * Creates actions bar for grid
 * @param	{Object}	[options]
 * @returns	{Object}	Ext.toolbar.Toolbar instance OR null if no actionCfgs are passed
 */
export const createGridActionBar = function (options) {
	return Array.isArray(options?.actionCfgs) && options.actionCfgs.length > 0
		? Ext.create('UI.components.GridActionBar', { options })
		: null;
};
