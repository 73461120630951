import './Menu.scss';

Ext.override(Ext.menu.Menu, {
	baseCls: 'ui-menu',
	// anchor: 'tr-tl',
	showSeparator: false,

	initComponent: function () {
		var me = this;
		me.callParent();

		me.cls = [];
	}
});
